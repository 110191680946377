import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {
  startDate = new Date();
  endDate = new Date(new Date().getTime() + (8.64e+7 * 7));

  fromDay: any;
  toDay: any;
  startTime = new Date();
  mytime: Date = new Date();
  isShowClock: boolean = false;
  @Output('onScheduleSelect') onScheduleSelect = new EventEmitter();

  daysList = [
    {
      id: 1,
      name: 'Sunday',
      status: 'disable'
    },
    {
      id: 2,
      name: 'Monday',
      status: 'active'
    },
    {
      id: 3,
      name: 'Tuesday',
      status: 'inactive'
    },
    {
      id: 4,
      name: 'Wednesday',
      status: 'active'
    },
    {
      id: 5,
      name: 'Thursday',
      status: 'inactive'
    },
    {
      id: 6,
      name: 'Friday',
      status: 'active'
    },
    {
      id: 7,
      name: 'Saturday',
      status: 'disable'
    },
  ]
  constructor() { }

  ngOnInit() {
  }

  onScheduleFromDateChange(event) {
    this.fromDay = new Date(event);
    let startDay = this.fromDay.getDay();
    this.onScheduleSelect.emit(this.fromDay)
    //let fromDate = this.fromDay.getFullYear() + this.fromDay.getMonth() + this.fromDay.getDate();
    //let toDate = this.toDay.getFullYear() + this.toDay.getMonth() + this.toDay.getDate();

    // if (fromDate == toDate) {
    //   console.log("same daty");
    // }
  }

  onScheduleToDateChange(event) {
    this.toDay = new Date(event);
    let endDay = this.toDay.getDay();


    // let fromDate = this.fromDay.getFullYear() + this.fromDay.getMonth() + this.fromDay.getDate();
    // let toDate = this.toDay.getFullYear() + this.toDay.getMonth() + this.toDay.getDate();

    // if (fromDate == toDate) {
    //   console.log("same datys");
    // }
  }
  setTime(){
    this.startTime = this.mytime;
    this.isShowClock = false;
  }
  clearTime(){
    this.mytime = new Date();
    this.isShowClock = false;
  }
 }
