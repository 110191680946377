import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/';
import { RolesComponent } from '../roles/roles.component';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap';
import { UsersComponent } from '../users/users.component';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-n-role.component.html',
  styleUrls: ['./user-n-role.component.scss']
})
export class UserNRoleComponent implements OnInit {
  @ViewChild('UserRole') UserRole: RolesComponent;
  @ViewChild('Users') users: UsersComponent;

  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  userList:any;
  userCount: any;
  recordsPerPage = 10;
  activePageNo=0;
  //****** Pagination *********/
  page: number;
  startItem = 0;
  endItem = 10;
  propertyName: any;
  propertyValue: any;
  selectedTab = "roleTab";

  constructor(private commonService: CommonService,private router: Router,
    private activatedRoute: ActivatedRoute
) {
  

   }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.user == 'user-tab') {
        this.staticTabs.tabs[1].active = true;
      }
      if (params.landedFrom=="role") {
        this.staticTabs.tabs[0].active = true;
      } else if (params.landedFrom == "user") {
        this.staticTabs.tabs[1].active = true;
      }
    });
  }

  // onUserTabSelected(pageNumber = 0, pageSize = this.recordsPerPage) {
  //   this.activePageNo = pageNumber;
  //   let body = {
  //     pageIndex:pageNumber,
  //     pageSize:pageSize,
  //     propertyName: this.propertyName,
  //     propertyValue: this.propertyValue
  //   }
  //   this.commonService.getData('get', '/v0/api/v1/users',body).subscribe(res => {
  //     this.userList = res.data.rows;
  //     this.userCount = res.data.count;
  //   });
  // }

  onRoleTabSelected() {
    this.UserRole.getRoleList();
  }
  onUserTabSelected() {
    this.users.getUserList(0,10);
}
  // getUserData(params){
  //   this.onUserTabSelected(params.pageNumber)
  // }

  onSortingUsers(params){
    this.propertyName = params.propertyName;
    this.propertyValue = params.propertyValue;
    this.onUserTabSelected();
  }

}
