import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
declare var OneSignal;


@Injectable({
  providedIn: 'root'
})
export class UserService {
  userAccessDetails: any[] = [];
  userProfileImage: any;
  loggedinUserEmailID: string = "";


  constructor(private commonService: CommonService,
  ) { }

  logout() { }


  getUserData(dataType) {
    let userData: any;
    if (localStorage.getItem('iotwua')) {
      userData = localStorage.getItem('iotwua');
    }
    let returnData: any;
    userData = JSON.parse(atob(userData));
    switch (dataType) {
      case 'profileImage':
        returnData = userData.profile;
        break;
      case 'roleAccess':
        returnData = userData.accessPermissions;
        break;
    }
    return returnData;
  }

  isAuthenticated() {
    return false;
  }

  getUserAccessPermissions() {
    this.commonService.getData('get', '/v0/api/v1/my-profile/').subscribe(res => {
      console.log(res);
      this.userProfileImage = res.profile;
      this.userAccessDetails = res.accessPermissions;
      this.loggedinUserEmailID = res.emailId;
      localStorage.setItem('profile', btoa(JSON.stringify(this.userProfileImage)));
      localStorage.setItem('iotwua', btoa(JSON.stringify(this.userAccessDetails)));
      console.log(this.userAccessDetails);
    });
  }

  sendOneSignalTag() {
    if (OneSignal && localStorage.getItem('isPushNotificationsEnabled') && localStorage.getItem('isPushNotificationsEnabled') == "true") {
      OneSignal.getTags(function (tags) {
        console.log('sending user tag');
        console.log(tags);
        if (!tags['email_id']) {
          OneSignal.sendTag("email_id", this.loggedinUserEmailID);
        }
      });
    }
  }

  deleteOneSignalTag() {
    if (OneSignal && localStorage.getItem('isPushNotificationsEnabled') && localStorage.getItem('isPushNotificationsEnabled') == "true") {
      OneSignal.getTags(function (tags) {
        if (tags['email_id']) {
          console.log('deleting user tag');
          OneSignal.deleteTag("email_id");
        }
      })
    }
  }
}
