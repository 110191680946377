
//import { formatDate } from '@angular/common';
export class RouteListExport {
    routeId: any;
    origin: any;
    destination: any;
    stops: any;
    status: any;

    constructor(
        routeId: any,
        origin: any,
        destination: any,
        stops: any,
        isActive: any,
    ) {
        this.routeId = routeId;
        this.origin = origin;
        this.destination = destination;
        this.stops = stops;
        this.status = isActive;
    }
}
export class RouteListExportMaker {
    static exportToExcel(obj: any) { 
        let returnData = new RouteListExport(
            obj.routeId,
            obj.origin,
            obj.destination,
            obj.stops,
            obj.isActive == 1 ? "Active" : obj.isActive==0?"Inactive":"",
  
        )
        return returnData;
    }
}
