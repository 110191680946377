import { environment } from 'src/environments/environment';

const reportUrl = environment["report_urls"];
export const reportList = [
  // {
  //   img: 'assets/img/temp-hist-report.svg',
  //   name: 'Temp. History Report',
  //   status: 'active',
  //   url: '#'
  // },
  {
    img: 'assets/img/trip-report.svg',
    name: 'Trip Report',
    status: 'active',
    url: reportUrl.reports.TripsReport.url,
    reportId: reportUrl.reports.TripsReport.reportId
    //url: 'https://app.powerbi.com/reportEmbed?reportId=bfbfdac7-987a-4ae5-9f57-6c98c2172ce8&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
  },
  {
    img: 'assets/img/alert-hist.svg',
    name: 'Alerts Report',
    status: 'active',
    url: reportUrl.reports.AlertsReport.url,
    reportId: reportUrl.reports.AlertsReport.reportId
   // url: 'https://app.powerbi.com/reportEmbed?reportId=60a13313-8602-4c27-aa28-1a3111944133&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  },
  {
    img: 'assets/img/summary.svg',
    name: 'Sensors History Report',
    status: 'active',
    url: reportUrl.reports.SensorHistoryReport.url,
    reportId: reportUrl.reports.SensorHistoryReport.reportId
    //url: 'https://app.powerbi.com/reportEmbed?reportId=27c79c04-66a3-4ddd-8454-ec0693b21aed&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  },
  // {
  //   img: 'assets/img/ref-his.svg',
  //   name: 'Compliance report',
  //   status: 'active',
  //   url: 'https://app.powerbi.com/reportEmbed?reportId=4012cf56-fbaf-4457-8c46-f8d7043925e8&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  // },
  // {
  //   img: 'assets/img/truck-report.svg',
  //   name: 'Truck Temp. Report',
  //   status: 'active',
  //   url: '#'
  // }
];

