import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services';
import { StoreDataService } from '../../../services/common/store-data.service';
import { UserModelMaker, UserModel } from '../add-users/user.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {
  userListCount: any;
  recordsPerPage: any=100;
  activePageNo: any = 0;
  currAlertType: string;
  redirectedFrom: string;
  userList = [];
  isAllChecked = false;
  selectedRoleMasterId: number;
  roleName: any;
  selectedRole: any;
  searchKey: string = '';
  constructor(
    private commonService: CommonService,
    private dataService: StoreDataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(p => {
      this.currAlertType = p["alertType"];
      this.redirectedFrom = p["redirectedFrom"];
      this.selectedRoleMasterId = +p["roleMasterId"];
      this.roleName = p["roleName"];
     })
  }

  ngOnInit() {
    this.getRoleDDL();
    this.dataService.alertUserData = [];
    this.dataService.alertFirstUserDataForCreate = [];
    this.dataService.alertEscUserDataForCreate = [];
    
    if (this.redirectedFrom =="Edit_Driver_Role") {
      this.mapUserList(this.dataService.alreadyAddedDriverList,[]);
    } else {
      this.getUserList();
    }
  }
  //getDriverList() {
  //  this.userList = this.dataService.alreadyAddedDriverList;
  //}
  getUserList(pageNumber = 0, pageSize = this.recordsPerPage) {
    this.activePageNo = pageNumber;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      roles: this.selectedRole,
      search: this.searchKey.trim(),
    }

    this.commonService.getData('get', '/v0/api/v1/users', body).subscribe(resp => {
      let users: any[] = resp.data.rows;
      let userIds: any[] = [];

      //for edit user list on alert config
      if (this.redirectedFrom == 'edit') {
        if (this.currAlertType == "firstAlert") {
          userIds = this.dataService.alertManagementData && this.dataService.alertManagementData["firstAlert"] ? this.dataService.alertManagementData["firstAlert"].filter(x => x.accessType == "Users").map(x => x.id) : [];
        } else if (this.currAlertType == "Escalation") {
          userIds = this.dataService.alertManagementData && this.dataService.alertManagementData["escalationAlert"] ? this.dataService.alertManagementData["escalationAlert"].filter(x => x.accessType == "Users").map(x => x.id) : [];
        }
        this.mapUserList(users, userIds);
      }
      //for add new user on alert config
      if (this.redirectedFrom == 'create') {
        if (this.currAlertType == "first_user") {
          userIds = this.dataService.alertManagementDataForCreate.firstAlert.length > 0 ? this.dataService.alertManagementDataForCreate.firstAlert.filter(x => x.accessType == "Users").map(x => x.id) : [];         
        } else if (this.currAlertType == "escalate_user") {
          userIds = this.dataService.alertManagementDataForCreate.escalationAlert.length > 0 ? this.dataService.alertManagementDataForCreate.escalationAlert.filter(x => x.accessType == "Users").map(x => x.id) : [];        
        }
        this.mapUserList(users, userIds);
      }

      //for adding new driver 
      if (this.redirectedFrom == 'Add_Driver_Role') {
        userIds = this.dataService.alreadyAddedDriverList && this.dataService.alreadyAddedDriverList.length > 0 ? this.dataService.alreadyAddedDriverList.map(x => x.userMasterId) : [];
        this.mapUserList(users, userIds)
      }    
      this.getSelectedCount();
    });
  }
  onUserCheck(user, event) {
    if (this.redirectedFrom =='edit') {
   
      this.onSelectUserToEditAlert(user, event);
    }
    if (this.redirectedFrom == 'create') {
      this.onSelectUserToCreateAlert(user, event);
    }
    if (this.redirectedFrom == 'Add_Driver_Role') {
      this.onSelectUserToAddAsDriver(user, event);
    }
    if (this.redirectedFrom == 'Edit_Driver_Role') {
      
    }
    this.userList.forEach(e => {
      if (e.id == user.id) {
        e.isChecked = event.target.checked;
      }
    });
    this.getSelectedCount();
  }
  onAddRemoveUser() {
    if (this.redirectedFrom == 'edit') {
      if (this.currAlertType == "firstAlert") {
        this.dataService.alertManagementData.firstAlert = this.dataService.alertManagementData.firstAlert.filter(x => x.accessType == 'Users');
      } else if (this.currAlertType == "Escalation") {
        this.dataService.alertManagementData.escalationAlert = this.dataService.alertManagementData.escalationAlert.filter(x => x.accessType == 'Users');
      }
     
      this.router.navigate(["/alerts-management"], { queryParams: { pageLoadFrom: 'updateUsers' } });
    }
    if (this.redirectedFrom == 'create') {

      if (this.currAlertType == 'first_user') {
        this.dataService.alertManagementDataForCreate.firstAlert = this.dataService.alertManagementDataForCreate.firstAlert.filter(x => x.accessType == 'Users');
      }
      else if (this.currAlertType == "escalate_user") {
        this.dataService.alertManagementDataForCreate.escalationAlert = this.dataService.alertManagementDataForCreate.escalationAlert.filter(x => x.accessType == 'Users');

      }
      this.router.navigate(["/create-alert"], { queryParams: { pageLoadFrom: 'AddUsers' } });
    }
    if (this.redirectedFrom == 'Add_Driver_Role') {
      //call Api to update user role to driver
      this.updateUserRoleToDriver();
    } if (this.redirectedFrom == 'Edit_Driver_Role') {
      //call Api to update user role to driver
      //this.updateUserRoleToDriver();
    }
  }
  selectedCount = 0;
  getSelectedCount() {
    if (this.userList.length == 0) {
      return;
    }
    let checkedflag = true;
    this.selectedCount = 0;
    this.userList.forEach((e) => {
      if (!e.isChecked) {
        checkedflag = false;
      } else {
        this.selectedCount++;
      }
    });
    this.isAllChecked = checkedflag;
  }

  onCancel() {
    if (this.redirectedFrom == "edit") {
      this.router.navigate(["/alerts-management"], { queryParams: { pageLoadFrom: 'updateUsers' } });
    } else if (this.redirectedFrom == "create") {
      this.router.navigate(["/alerts-management"]);
    } else if (this.redirectedFrom == "Add_Driver_Role" || this.redirectedFrom == "Edit_Driver_Role") {
      this.router.navigate(["/user-role-driver"], { queryParams: { roleMasterId: this.selectedRoleMasterId, roleName: this.roleName } });
    }
  }
  
  checkAll(event) {
    this.userList.forEach(e => {
      e.isChecked = !this.isAllChecked;
      this.onUserCheck(e, event);
    });
    this.getSelectedCount();
  }
  mapUserList(users,userIds) {
    this.userList = users.map(e => {
      return {
        id: e.userMasterId,
        roleName: e.roleName,
        userName: e.userName,
        isChecked: userIds.find(x => x == e.userMasterId) != undefined ? true : false,
      }
    });
    if (this.redirectedFrom == "Add_Driver_Role") {
      this.userList = this.userList.filter(e => !e.isChecked);
    }
  }
  onSelectUserToEditAlert(user, event) {
    
    if (event.target.checked) {
      let data;
      if (this.currAlertType == "firstAlert") {
        data = {
          alertType: "firstAlert",
          accessType: "Users",
          alertConfigUsersId: user.id,
          roleOrUserName: user.userName,
          roleOrUserId: user.id,
          id: user.id,
        }
        this.dataService.alertManagementData.firstAlert.push(data);
      } else if (this.currAlertType == "Escalation") {
        data = {
          alertType: "Escalation",
          accessType: "Users",
          alertConfigUsersId: user.id,
          roleOrUserName: user.userName,
          roleOrUserId: user.id,
          id: user.id,
        }
        this.dataService.alertManagementData.escalationAlert.push(data);
      }
      
    } else {
      if (this.currAlertType == "firstAlert") {
        this.dataService.alertManagementData["firstAlert"] = this.dataService.alertManagementData["firstAlert"].filter(x => x.id != user.id)
      } else if (this.currAlertType == "Escalation") {
        this.dataService.alertManagementData["escalationAlert"] = this.dataService.alertManagementData["escalationAlert"].filter(x => x.id != user.id)
      }
    }
  }
  onSelectUserToCreateAlert(user, event) {
    if (event.target.checked) {
      let data;
      if (this.currAlertType == "first_user") {
        data = {
          alertType: "firstAlert",
          accessType: "Users",
          id: user.id,
          userName: user.userName,
          isChecked: true,

        }
        this.dataService.alertManagementDataForCreate.firstAlert.push(data);
      } else if (this.currAlertType == "escalate_user") {
        data = {
          alertType: "Escalation",
          accessType: "Users",
          id: user.id,
          userName: user.userName,
          isChecked: true,
        }
        this.dataService.alertManagementDataForCreate.escalationAlert.push(data);
      }

    } else {
      if (this.currAlertType == "first_user") {
        this.dataService.alertManagementDataForCreate.firstAlert = this.dataService.alertManagementDataForCreate.firstAlert.filter(x => x.id != user.id)
      } else if (this.currAlertType == "escalate_user") {
        this.dataService.alertManagementDataForCreate.escalationAlert = this.dataService.alertManagementDataForCreate.escalationAlert.filter(x => x.id != user.id)
      }
    }
  }
  onSelectUserToAddAsDriver(user,event) {
    let driver = {
      userMasterId: user.id,
      roleName: "Driver",
      userName: user.userName,
      isChecked: user.isChecked,
    }
    if (event.target.checked) {
      if(!this.dataService.alreadyAddedDriverList){
        this.dataService.alreadyAddedDriverList = [];
      }
      this.dataService.alreadyAddedDriverList.push(driver);
    } else {
      let drivers = this.dataService.alreadyAddedDriverList.filter(e => e.userMasterId != user.id);
      this.dataService.alreadyAddedDriverList = drivers && drivers.length > 0 ? drivers : [];
      drivers = [];
    }
  }
  updateUserRoleToDriver() {
    let param = {
      "roleId": this.selectedRoleMasterId,
      "users": this.dataService.alreadyAddedDriverList.map(e => Number(e.userMasterId)),
    }
    this.commonService.getData('put', '/v0/api/v1/user-roles', param).subscribe(res => {
      this.router.navigate(["/user-role-driver"], { queryParams: { roleMasterId: this.selectedRoleMasterId, roleName: this.roleName } });
    });
  }

  roleTypefilterChange(eve) {
    this.selectedRole = eve.target.value;
    this.getUserList();
  }
  DDLRole: any[] = [];
  getRoleDDL() {
    this.commonService.getData('get', '/v0/api/v1/roles/names').subscribe(x => {
      this.DDLRole = x.data;
    });
  }
  onSearchFilter() {
    this.getUserList();
  }
}
