export class Cooler {
  assetMasterId: any;
  coolerId: any;
  coolerType: any;
  coolerCapacity: any;
  coolerSize: any;
  // tempSitepoint: any;
  currentTemperature: any;
  units: any;
  totalAlerts: any;
  status: any;
  isActive: any;
  isChecked: boolean;
  date: Date;
  alertStatus: any;
  alertColor: any;
  coolerStatus: any;
  currentTemperatureStatus: any;
  statusValue: any;

  constructor(
    assetMasterId: any,
    coolerId: any,
    coolerType: any,
    coolerCapacity: any,
    coolerSize: any,
    // tempSitepoint: any,
    currentTemperature: any,
    units: any,
    totalAlerts: any,
    isActive: any,
    status: any,
    date: Date,
    alertStatus: any,
    alertColor: any,
    coolerStatus: any,
    currentTemperatureStatus: any,
    statusValue: any
  ) {
    this.assetMasterId = assetMasterId;
    this.coolerId = coolerId;
    this.coolerType = coolerType;
    this.coolerCapacity = coolerCapacity;
    this.coolerSize = coolerSize;
    // this.tempSitepoint = tempSitepoint;
    this.currentTemperature = currentTemperature;
    this.units = units;
    this.totalAlerts = totalAlerts;
    this.status = status;
    this.isActive = isActive;
    this.isChecked = false;
    this.date = date;
    this.alertStatus = alertStatus;
    this.alertColor = alertColor;
    this.coolerStatus = coolerStatus;
    this.currentTemperatureStatus = currentTemperatureStatus;
    this.statusValue = statusValue
  }
}

export class CoolerMaker {
  static create() {
    return new Cooler(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  static castInto(obj: any) {
    return new Cooler(
      obj.assetMasterId,
      obj.coolerId,
      obj.coolerType,
      obj.coolerCapacity,
      obj.coolerSize,
      // obj.tempSitepoint,
      obj.currentTemperature,
      obj.units,
      obj.totalAlerts,
      obj.isActive,
      obj.status,
      obj.date,
      obj.alertStatus,
      obj.alertColor,
      obj.coolerStatus,
      obj.currentTemperatureStatus,
      obj.statusValue
    );
  }

  static clone(obj: Cooler) {
    return new Cooler(
      obj.assetMasterId,
      obj.coolerId,
      obj.coolerType,
      obj.coolerCapacity,
      obj.coolerSize,
      // obj.tempSitepoint,
      obj.currentTemperature,
      obj.units,
      obj.totalAlerts,
      obj.isActive,
      obj.status,
      obj.date,
      obj.alertStatus,
      obj.alertColor,
      obj.coolerStatus,
      obj.currentTemperatureStatus,
      obj.statusValue
    );
  }

}
