export class TripPlanner {
    id: string;
    tripId: any;
    date: Date;
    driver: string;
    truckId: any;
    route: any;
    coolers: any;
    tripMasterId: any;
    status: any;
    image: any;
    isChecked: boolean;
    constructor(
        id: string,
        tripId: any,
        date: Date,
        driver: string,
        truckId: any,
        route: any,
        coolers: any,
        tripMasterId: any,
        status: any,
        image: any,
        isChecked: boolean
    ) {
        this.id = id;
        this.tripId = tripId;
        this.date = date;
        this.driver = driver;
        this.truckId = truckId;
        this.route = route;
        this.coolers = coolers;
        this.tripMasterId = tripMasterId;
        this.status = status;
        this.image = image;
        isChecked = false;
    }
}

export class TripPlannerMaker {
    static create() {
        return new TripPlanner(null, null, null, null, null, null, null, null, null, null, null);
    }
    static castInto(obj: any) {
        return new TripPlanner(
            obj.id,
            obj.tripId,
            obj.date,
            obj.driver,
            obj.truckId,
            obj.route,
            obj.coolers,
            obj.tripMasterId,
            obj.status,
            obj.image,
            obj.isChecked
        );
    }
    static clone(obj: TripPlanner) {
        return new TripPlanner(
            obj.id,
            obj.tripId,
            obj.date,
            obj.driver,
            obj.truckId,
            obj.route,
            obj.coolers,
            obj.tripMasterId,
            obj.status,
            obj.image,
            obj.isChecked
        );
    }
    static exportToExcel(obj: any) {
        var returnData = new TripPlanner(
            null,
            obj.tripId,
            null,
            obj.driver,
            obj.truckId,
            obj.route,
            obj.coolers,
            null,
            obj.status,
            null,
            null
        )
        for (var propName in returnData) {
            if (returnData[propName] === null || returnData[propName] === undefined) {
                delete returnData[propName];
            }
        }
        return returnData;
    }
    
}
