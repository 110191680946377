import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-optimization',
  templateUrl: './root-optimization.component.html',
  styleUrls: ['./root-optimization.component.scss']
})
export class RootOptimizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openModal(temp: any = ""){}
}
