export class Route {
  id: string;
  routeId: string;
  stops: string;
  status: string;
  routeMasterId: string;
  constructor(
    id: string,
    routeId: string,
    stops: string,
    status: string,
    routeMasterId: any,
  ) {
    this.id = id;
    this.routeId = routeId;
    this.stops = stops;
    this.status = status;
    this.routeMasterId = routeMasterId;
  }
}

export class RouteMaker {
  static createEmpty() {
    return new Route(null, null, null, null,null);
  }
  static clone(obj: Route) {
    return new Route(obj.id, obj.routeId, obj.stops, obj.status,
      obj.routeMasterId);
  }
  static createRoute(obj: any) {
    return new Route(obj.id, obj.routeId, obj.stops, obj.status,
      obj.routeMasterId);
  }
}
