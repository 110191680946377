import { Component, OnInit, ViewChild, ChangeDetectorRef  } from "@angular/core";
import { CommonService } from "src/app/services";
import { OutOfRangeReportModelMaker } from "./out-of-range-report.model";
import { formatDate } from "@angular/common";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-out-of-range-report",
  templateUrl: "./out-of-range-report.component.html",
  styleUrls: ["./out-of-range-report.component.scss"],
})
export class OutOfRangeReportComponent implements OnInit {
  search: any = "";
  sortingPropName: string = "alertDatetime";
  sortingOrder: string = "ASC";
  outOfRangeReport: any[] = [];
  outOfRangeReportCount: any;
  currentPageIndex = 0;
  recordsPerPage = 10;
  startItem = 0;
  endItem = 10;
  activePageNo = 0;
  isShowFilter: boolean = false;
  createdOn_From_filter = '';
  createdOn_Upto_filter = '';
  dateFilterType = 'today';
  byDefaultSelectedFilter: any;
  outOfRangeReportRangeValue: Date[];
  selectedFilterType: any;
  fromDate: any;
  endDate: any;
  dataModel: any;
  refreshSubscription: Subscription;
  @ViewChild('ngxDropdown') ngxDropdown: any;
  constructor(private commonService: CommonService,
  private chRef:ChangeDetectorRef
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getOutOfRangeReportList();
    });
   }

  ngOnInit() {
    this.getOutOfRangeReportList();
    this.outOfRangeReportRangeValue = [new Date(), new Date()];
    this.byDefaultSelectedFilter = [this.dropdownOptions[0]];
  }
  dropdownOptions = [
    { name: 'Today', id: 'today', order: 1 },
    { name: 'Week', id: 'week', order: 2 },
    { name: 'Month', id: 'month', order: 3 },
    { name: 'Year', id: 'year', order: 4 },
    { name: 'Date Range', id: 'dateRange', order: 5 },
  ];
  outOfRangeReportList: any[] = [];

  getOutOfRangeReportList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = "onInit") {
    this.activePageNo = pageNumber;

    this.outOfRangeReportCount = 0;
    this.outOfRangeReportList = [];
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      dateFilterType: this.dateFilterType,
      from: this.fromDate,
      to: this.endDate,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData('get', '/v0/api/v1/coolers/out-of-range', body).subscribe(res => {
      this.outOfRangeReportCount = res.count;
      this.outOfRangeReportList = res && res.rows ? res.rows : [];
      this.outOfRangeReport = this.outOfRangeReportList.length > 0 ? this.outOfRangeReportList.map(x => OutOfRangeReportModelMaker.castInto(x)) : [];
    });
  }

  // onClearSearch() {
  //   if (!this.search) {
  //     return;
  //   }
  //   this.search = "";
  //   // this.getOutOfRangeReportList();
  // }

  sortingList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getOutOfRangeReportList();
  }

  validateInput(event) {
    this.commonService.validateInput(event);
  }

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getOutOfRangeReportList(pageNo);
  }

  // toggleFilter(){
  //   this.isShowFilter = !this.isShowFilter;
  //   // this.setDefaultFilterValues();
  // }

  selectedDateFiler(event) {
    if (event.value && event.value.id) {
      let type = event.value.id;
      this.dateFilterType = type;
      this.selectedFilterType = type;
      if (type !== 'dateRange') {
        this.fromDate = undefined;
        this.endDate = undefined;
        this.getOutOfRangeReportList(0);
      } else {
        this.dateFilterType = undefined;
      }
    }
  }

  outOfRangeReportDateChange (event) {
    if (event != undefined && event != null && event.length > 1) {
      this.fromDate = formatDate(event[0], "yyyy-MM-dd", "en-US");
      this.endDate = formatDate(event[1], "yyyy-MM-dd", "en-US");
      // this.dateFilterType = {
      //   from: fromDate,
      //   to: endDate,
      // }
      this.getOutOfRangeReportList(0);
    }
  }

  resetFilter () {
    let fromDate = formatDate(new Date(), "yyyy-MM-dd", "en-US");
    let endDate = formatDate(new Date(), "yyyy-MM-dd", "en-US");
    // this.dateFilterType = {
    //   from: fromDate,
    //   to: endDate,
    // }
    this.getOutOfRangeReportList(0);
  }
  sortOptions() {
    this.ngxDropdown.availableItems = this.ngxDropdown.options.sort(function (a, b) {
      return a.order - b.order;
    });
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}
