import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Driver, DriverMaker } from '../driver.model';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss']
})
export class DriverListComponent implements OnInit {
  driverArray: Array<Driver>;
  @Output('onDriverSelect') onDriverSelect = new EventEmitter();
  @Input() set selectedDriverId (newId: string) {
    this._selectedDriverId = newId;
  }
  private _selectedDriverId: any;;
  get selectedDriverId(): string {
    return this._selectedDriverId;
  }
  pageCounter = 0;
  driversPerPage = 6;
  selectedDriver: any;
  drivers: any = [];
  totalDriverCount: any
  driverStatus: any;
  disabledNextBtn = false;
  

  constructor(
    private commonService: CommonService
  ) {
    // this.driverArray = this.drivers.map(e => DriverMaker.createDriver(e));
    // console.log(this.driverArray);
    // this.paginateDriverList();
  }

  ngOnInit() {
    this.getDriversList();
  }

  processName(name) {
    return name.replace(/ +/g, "") + "-image";
  }

  paginateDriverList(change = 'next') {
    if (change == 'next' && (this.pageCounter + 1) * this.driversPerPage < this.totalDriverCount) {
      this.pageCounter++;
      this.getDriversList(this.pageCounter, this.driversPerPage);
      if ((this.pageCounter + 1) * this.driversPerPage > this.totalDriverCount) {
        this.disabledNextBtn = true;
      }
    } else if (change == 'next' && (this.pageCounter + 1) * this.driversPerPage == this.totalDriverCount) {
      this.disabledNextBtn = true;
    }
    else if (change == 'prev' && this.pageCounter > 0) {
      this.pageCounter--;
      this.getDriversList(this.pageCounter, this.driversPerPage);
      this.disabledNextBtn = false;
    }
    else return;
    
  }

  selectDriver(driver) {
    this.selectedDriver = driver;
    this.onDriverSelect.emit(driver);
    this.selectedDriverId = driver.id;
  }

  getDriversList(pageNumber = 0, pageSize = this.driversPerPage) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      driverStatus: this.driverStatus,
    };
    this.driverArray = [];
    this.drivers = [];
    this.commonService.getData('get', '/v0/api/v1/drivers', body).subscribe(driverListResponse => {
      console.log(driverListResponse);
      this.totalDriverCount = driverListResponse.data.count;
      driverListResponse.data.rows.forEach(element => {
        console.log(element, 'elementelement')
        this.drivers.push({
          id: element.driversId,
          name: element.name,
          profilePic: element.image,
          // conditions: 'available',
          conditions: element.driverStatus,
        });
      });
      this.driverArray = this.drivers.map(e => DriverMaker.createDriver(e));
      //setTimeout(() => {
      //  this.driverArray = this.drivers.map(e => DriverMaker.createDriver(e));
      //  console.log(this.driverArray);
      //  this.paginateDriverList();
      //}, 200);
    });
  }
  onFilterChange(eve) {
    this.driverStatus = eve.target.checked ? 'Available' : undefined;
    this.getDriversList();
  }
  //selectedDriverId: any;
  //onEditTrip(driver) {
  //  this.selectedDriverId = driver.id;
  //}
}
