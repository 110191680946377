import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Trucks, TruckMaker } from './truckList.model';
import { CommonService } from '../../services';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { Subscription } from 'rxjs';
import { TruckListExportMaker } from './truck-list-export';


@Component({
  selector: 'app-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit {
  isShownDropdown: boolean = false;
  isShowFilter: boolean = false;
  sortOrder: any;
  refreshSubscription: Subscription;
  truckList = [];
  // truckList : any;
  searchTruckList = [];
  truckType: any;
  status: string = '';
  search: any = "";
  capacitylevel: any;
  sizelevel: any;

  modalRef: BsModalRef;
  ischecked: boolean = false;
  isAllChecked = false;
  showSidebar = false;
  onclickAddbtn: boolean = false;
  capacity = '';
  totalTrucksCount: any;
  recordsPerPage = 10;
  activePageNo = 0;
  //****** Pagination *********/
  currentPage = 1;
  page: number;
  startItem = 0;
  endItem = 10;

  truckTypeList: any[] = [];
  truckTypeListFilter: any[] = [];
  totalTrucksResponseList: any[] = [];
  sortingPropName: string = "truckId"
  sortingOrder: string = "DESC"
  currentPageIndex = 0;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private commonService: CommonService,
    private exportExelService: ExportExcelService,
  ) { 
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getTruckList();
    });
  }

  ngOnInit() {
    this.getTruckList();
  }

  gotoDetailsPage() {
    this.router.navigate(['truck-view']);
  }


  getTruckList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = "onInit") {
    this.activePageNo = pageNumber;

    this.totalTrucksCount = 0;
    this.totalTrucksResponseList = [];
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      // truckType: this.truckType,
      truckCapacity: this.capacity,
      truckStatus: this.status == 'null' ? '' : this.status,
      search: this.search.trim() === '' ? null : this.search.trim(),
      // truckSize: this.sizelevel,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData('get', '/v0/api/v1/trucks', body).subscribe(res => {
      this.totalTrucksCount = res.data.count;
      this.totalTrucksResponseList = res && res.data && res.data.rows ? res.data.rows : [];
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => TruckListExportMaker.exportToExcel(e)): [];
        this.exportExelService.exportAsExcelFile(exportableData, 'Truck List');
      }
      this.truckList = this.totalTrucksResponseList.length > 0 ? this.totalTrucksResponseList.map(x => TruckMaker.castInto(x)) : [];
    });

  }

  truckDetails(list, truckDetailsPopup: TemplateRef<any>) {
    //this.modalRef = this.modalService.show(truckDetailsPopup, { class: "modal-lg" });
    this.showSidebar = true;
  }
  closeModal() {
    this.modalRef.hide();
    this.onclickAddbtn = false;
  }
  //********* Checkbox selections **********/
  checkAll(status) {
    this.truckList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }
  countCheckedRows() {
    let checkedflag = true;
    let counter = 0;
    this.truckList.forEach(e => {
      if (!e.isChecked) {
        checkedflag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedflag;
    return counter;
  }

  selectRow(obj: Trucks, event) {
    obj.isChecked = event.target.checked;
    this.countCheckedRows();
  }
  //******Page change Event ******//
  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  //*********sorting *********/
  sortCoolerlist(prop, asc) {
    this.truckList = this.truckList.sort(function (a, b) {
      if (asc) {
        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
      } else {
        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }

  showAddTruckModal(addTruckModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addTruckModal, { 
      class: 'modal-dialog-centered',
      backdrop: 'static' 
    });
    this.onclickAddbtn = true;
  }

  resetFilter() {
    // this.truckType = "";
    this.setDefaultFilterValues();
    // this.sizelevel = "";
    this.getTruckList(0);
  }


  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getTruckList(pageNo);
  }
  
  toggleFilter(){
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues(){
    // this.truckType = 'null';
    // this.sizelevel = 'null';
    this.status = 'null';
  }
  getTruckTypes() {
    this.commonService.getData('get', '/v0/api/v1/master-data/truck-types').subscribe(res => {
      this.truckTypeList = res.data;
      let ddlArray = this.truckTypeList.map(x => x.truckType);
      this.truckTypeListFilter = Array.from(new Set(ddlArray));
    })
  }
  onExportToExcel() {
    if (!this.totalTrucksCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getTruckList(0, this.totalTrucksCount, 'export')
  }

  onSortTruckList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getTruckList();
  }

  //clear Search
  onClearSearch() {
    if (!this.search) {
      return;
    }
    this.search = "";
    this.getTruckList(0);
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  // search validate input
  validateInput(event) {
    this.commonService.validateInput(event);
  }
}
