import { Component, OnInit, TemplateRef, OnDestroy } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "../../services";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-shp-kpi-cards",
  templateUrl: "./shp-kpi-cards.component.html",
  styleUrls: ["./shp-kpi-cards.component.scss"],
})
export class ShpKpiCardsComponent implements OnInit {
  modalRef: BsModalRef;
  // kpiCards = [];
  kpiCards: any;
  datePipeAngular: DatePipe;
  refreshSubscription: Subscription;
  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.datePipeAngular = new DatePipe('en-US');
    this.getCardsData();
    
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getCardsData();
    });
  }

  ngOnInit() {}

  getCardsData() {
    const body = {
      currentDate: this.datePipeAngular.transform(new Date().toISOString(), 'yyyy-MM-dd')
    };
    this.commonService
      .getData("get", "/v0/api/v1/dashboard/today/sensor/count",body)
      .subscribe((res) => {
        this.kpiCards = res.data;
        console.log(this.kpiCards);
      });
  }

  getCardClass(key) {
    let className = "";
    switch (key) {
      case "coolerAlerts":
      case "openAlerts":
        className += " alerts";
        break;
      case "doorSensorsAlerts":
      case "unresponsiveSensor":
        className += " truck";
        break;
      case "trips":
        className += " trips";
        break;
      case "trucksWithAlerts":
        className += " trailer-temp";
        break;
    }
    return className;
  }

  goToDetailsPage(cardName) {
    // if (cardName == 'coolerAlerts') {
    if (cardName == "openAlerts") {
      this.router.navigate(["/alerts"], { queryParams: { key: cardName } });
    } else if (cardName == "doorSensorsAlerts") {
      this.router.navigate(["/alerts"], { queryParams: { key: cardName } });
    } else if (cardName == "trips") {
      // this.router.navigate(['/trip-tracker'], { queryParams: { key: cardName } });
      this.router.navigate(["/trip-tracker"]);
    } else if (cardName == "trucksWithAlerts") {
      this.router.navigate(["/alerts"], { queryParams: { key: cardName } });
    }
  }

  goToTripDetailsPage(key, infoKey, status, truckId) {
    // console.log(key, 'key')
    // console.log(infoKey, 'infoKey')
    // console.log(status, 'status')
    // console.log(truckId, 'truckId')
    if (key == "trips") {
      this.router.navigate(["/trip-tracker"], {
        queryParams: { status: status },
      });
    }
    // if(status){

    // }
    if (key == "trucksWithAlerts" && truckId) {
      this.router.navigate(["/alerts"], { queryParams: { truckId: truckId } });
    }
    if (key == "openAlerts" && infoKey == "coolerAlerts") {
      this.router.navigate(["/alerts"], { queryParams: { search: status } });
    } else if (key == "openAlerts" && infoKey == "escalatedAlerts") {
      this.router.navigate(["/alerts"], { queryParams: { search: status } });
    } else if (key == "openAlerts" && infoKey == "outOfRange") {
      this.router.navigate(["/out-of-range-report"]);
    }

    // if (key == "unresponsiveSensor" && status == "otherissues") {
    if (key == "trucksWithAlerts" && infoKey == "unresponsiveGateways") {
      // this.router.navigate(["/all-sensors"], {
      //   queryParams: { unresponsiveSensor: true },
      // });
      this.router.navigate(["/all-sensors"]);
    } 
    // else if (key == "unresponsiveSensor" && status == "batteryDown") {
    //   this.router.navigate(["/all-sensors"], {
    //     queryParams: { batteryDown: true },
    //   });
    // }
  }

  openModal(template: TemplateRef<any>) {
    console.log(template);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "shp-modal" })
    );
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}
