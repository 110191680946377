import { Component, TemplateRef, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/';
import { formatDate } from '@angular/common';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscribable, Subscription } from 'rxjs';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit,OnDestroy {
  @Input('userListData') userListData;
  @Input('userCount') userCount;
  @Input('recordsPerPage') recordsPerPage;
  // @Output('onPageChange') onPageChange = new EventEmitter<{ pageNumber: number }>();
  // @Output('onSorting') onSorting: any = new EventEmitter<{ propertyName: string, propertyValue:string }>();


  addUserForm: FormGroup;
  userList: any;
  roles: any;
  createdDate: any;
  actionType: any;
  onclickAddbtn: boolean = false;
  isShowFilter: boolean = false;
  startItem = 0;
  endItem = 10;
  sortOrder: any;
  search: any;
  status: any;
  dateOfJoiningfrom : any="";
  dateOfJoiningto :any="";
 
  sortingPropName: string = "userName"
  sortingOrder: string = "ASC"

  userRole: any;
  firstName: any;
  lastName: any;
  username: any;
  email: any;
  employeeId: any;
  mobile: any;
  allRoles: any;
  activePageNo = 0;
  //****** Pagination *********/
  currentPage = 1;
  page: number;
  currentPageIndex = 0;
  refreshSubscription:Subscription
  constructor(private modalService: BsModalService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private router: Router) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getUserList();
    });
  }

  modalRef: BsModalRef;
  ngOnInit() {
    this.getRoleDDL();
    this.createUserForm();
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
}

  // onSortList(propType, prop, asc) {
  //   switch (propType) {
  //     case "int":
  //       this.userListData = this.userListData.sort(function (a, b) {
  //         if (asc) {
  //           return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
  //         } else {
  //           return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
  //         }
  //       });
  //       break;

  //     case "date":
  //       this.userListData = this.userListData.sort(function (a, b) {
  //         if (asc) {
  //           return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
  //         } else {
  //           return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
  //         }
  //       })
  //       break;
  //     default:
  //       this.userListData = this.userListData.sort(function (a, b) {
  //         if (asc) {
  //           return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
  //         } else {
  //           return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
  //         }
  //       });
  //   }
  // }

  showAddUserModal(addRouteModal: TemplateRef<any>) {
    this.addUserForm.reset();
    this.userRole = '';
    this.firstName = '';
    this.lastName = '';
    this.employeeId = '';
    this.username = '';
    this.mobile = '';
    this.email = '';
    this.modalRef = this.modalService.show(addRouteModal, { class: 'modal-dialog-centered', ignoreBackdropClick: true });
    this.onclickAddbtn = true;
    this.getAllRoles();
  }

  getUserList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage) {
    this.activePageNo = pageNumber;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.search,
      roles: this.roles == 'null' ? '' : this.roles,
      status: this.status == 'null' ? '' : this.status,
      fromDate: this.dateOfJoiningfrom ? formatDate(this.dateOfJoiningfrom, 'yyyy-MM-dd', 'en-US') : this.dateOfJoiningfrom,
      toDate: this.dateOfJoiningto ? formatDate(this.dateOfJoiningto, 'yyyy-MM-dd', 'en-US') : this.dateOfJoiningto,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    }
    this.commonService.getData('get', '/v0/api/v1/users', body).subscribe(res => {
      //this.userList = res.data.rows;
      this.userListData = res.data.rows;
      this.userCount = res.data.count;
    });
  }

  createUser() {
    this.markFormGroupTouched(this.addUserForm);
    const ctrl = this.addUserForm.controls;
    let userName = ctrl["userName"].value;
    let roleName = +ctrl["roleName"].value;
    let firstName = ctrl["firstName"].value;
    let lastName = ctrl["lastName"].value;
    let epmployeeID = ctrl["epmployeeID"].value;
    let phone = ctrl["phone"].value;
    let email = ctrl["email"].value;
    if (this.addUserForm.valid) {
      let body = {

        "userName": userName,
        "firstName": firstName,
        "lastName": lastName,
        "roleMasterId": roleName,
        "emailId": email,
        "mobile": phone,
        "employeeId": epmployeeID,
        "profile": "",
        "address": "",
        "city": "",
        "state": "",
        "country": "",
        "zipcode": 0
      }
      this.commonService.getData('post', '/v0/api/v1/users', body).subscribe(res => {
        if (res.status) {
          this.userListData;
          this.sendResetPasswordLink(email);
          this.closeModal();
          this.addUserForm.reset();
        }
      });
    }
  }

  sendResetPasswordLink(emailId){
    this.commonService.getData('post','/v0/api/v1/change-password',{email:emailId}).subscribe(res=>{
    });
  }

  closeModal() {
    this.addUserForm.reset();
    this.modalRef.hide();
    this.onclickAddbtn = false;
    this.getUserList();
  }
  onRoleNameClick(role) {
   
    this.router.navigate(['/user-role-driver'], { queryParams: { roleMasterId: role.roleMasterId,roleName: role.roleName, landedFrom:"user"}});
    
  }

  getAllRoles() {
    this.commonService.getData('get', '/v0/api/v1/roles/names').subscribe(res => {
      this.allRoles = res.data;
    })
  }

  loadPageData(pageNumber) {
  this.currentPageIndex = pageNumber;
    this.getUserList(pageNumber);
  }

  goToUserProfile(id) {
    this.router.navigate(['/profile'], { queryParams: { id: id,redirectedFrom:"Users" } });
  }
  DDLRole: any[] = [];
  getRoleDDL() {
    this.commonService.getData('get', '/v0/api/v1/roles/names').subscribe(x => {
      this.DDLRole = x.data;
    });
  }
  // OnRoleChange(e) {
  //   this.roles = e.target.value;   
  // }
  onFilterApply() {
    this.getUserList(0);
  }
  resetFilter() {
    this.setDefaultFilterValues();
    this.dateOfJoiningfrom = "";
    this.dateOfJoiningto = "";
    this.getUserList(0);
  }
  // onStatusFilterChange(event) {
  //   this.status = +event.target.value;
  // }

  createUserForm() {
    this.addUserForm = this.fb.group({
      roleName: new FormControl('', Validators.required),
      firstName: new FormControl('', [Validators.required, Validators.pattern("([a-zA-Z',.-]+( [a-zA-Z',.-]+)*)")]),
      lastName: new FormControl('', [Validators.required, Validators.pattern("([a-zA-Z',.-]+( [a-zA-Z',.-]+)*)")]),
      epmployeeID: new FormControl('', Validators.required),
      userName: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
      email: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]),
    });
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  onSortUserList(propertyName, sortOrder) {
  
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getUserList();
    //this.onSorting.emit({ propertyName, sortOrder });
  }

  toggleFilter(){
    this.isShowFilter = !this.isShowFilter;

    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.roles = 'null';
    this.status = 'null';
  }

  // clear search
  onClearSearch(){
    if(!this.search){
      return;
    }
    this.search = "";
    this.getUserList(0);
  }
  validateInput(event) {
    this.commonService.validateInput(event);
  }
}
