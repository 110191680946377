import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'temperatureConvert'
})
export class TemperatureConvertPipe implements PipeTransform {


  transform(value: any, args?: any): any {
    if (value === "") {
      return "";
    } else if (isNaN(value)) {
      return "";
    }
    // convert to string to remove C and F
    value = value + "";
    // convert to number
    let modifiedValue: any =  Math.round( parseFloat(value )*10)/10;

    // set default to celsius
    let hhsPreferredTemperatureType = "c";
    // check if temperature available then set
    if (localStorage.getItem('hhsPreferredTemperatureType') && localStorage.getItem('hhsPreferredTemperatureType') != "") {
      hhsPreferredTemperatureType = localStorage.getItem('hhsPreferredTemperatureType');
    }
    if (hhsPreferredTemperatureType == "f") {
      modifiedValue = (value * 9 / 5) + 32;
      modifiedValue =  Math.round( parseFloat(modifiedValue )*10)/10
      modifiedValue += "&deg;F";
    } else {
      modifiedValue += "&deg;C";
    }
    return modifiedValue;
  }

}
