import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'todayDateFormat'
})
export class TodayDateFormatPipe implements PipeTransform {

  transform(initialValue: any, format?: any, isTodayshow?: any): any {
    if (initialValue === null || initialValue === '') {
      return;
    }

    const todaydate = new Date();
   let value = new Date(initialValue);
    const today = todaydate.getDate() + '-' + todaydate.getMonth() + '-' + todaydate.getFullYear();
    const valueDate = new Date(value).getDate() + '-' + new Date(value).getMonth() + '-' + new Date(value).getFullYear();

    if (isTodayshow && isTodayshow.toLowerCase() === 'today') {
      if (today === valueDate) {
        return 'Today ,' + moment(initialValue).format(format); // 'Today ,' + new Date(value).toLocaleTimeString('en-US')
      } else {
        return moment(initialValue).format('MMM DD, HH:mm');
      }
    } else {
      return moment(initialValue).format(format);
    }
  }

}
