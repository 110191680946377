import { formatDate } from '@angular/common';

export class SensorHistoryExport {
    date: any;
    time: any;
    sensorReading: any;
    units: any;
    batteryLevel: any;
    status: any;
    
    constructor(
        date: any,
        time: any,
        sensorReading: any,
        units: any,
        batteryLevel: any,
        status: any,
    ) {
        this.date = date;
        this.time = time;
        this.sensorReading = sensorReading;
        this.units = units;
        this.batteryLevel = batteryLevel;
        this.status = status;
        
    }
}
export class SensorHistoryExportMaker {
    static create() {
        return new SensorHistoryExport(null, null, null, null, null, null);
    }
    static exportToExcel(obj: SensorHistoryExport) {
        let selectedUnit = obj.units;
        if (selectedUnit && (selectedUnit.toLowerCase() == 'f' || selectedUnit.toLowerCase() == 'c')) {
            selectedUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType').toLowerCase() : 'c';
         
            var sensorReading;
            if (selectedUnit == 'f') { 
                sensorReading = this.convertTemp(obj.sensorReading);
            } else {
                sensorReading = obj.sensorReading;
            }
        } else {
            sensorReading = obj.sensorReading;
        }
        let returnData = new SensorHistoryExport(
            obj.date,
            formatDate(obj.time, "HH:mm", 'en-US'),
            sensorReading,
            selectedUnit.toUpperCase(),
            obj.batteryLevel,
            obj.status,
            
        )
        return returnData;
    }
    static convertTemp(temp): any {
        let modifiedTemp;
        modifiedTemp = (temp * 9 / 5) + 32;
        modifiedTemp = Math.round(parseFloat(modifiedTemp) * 10) / 10
        return modifiedTemp;
    }
}
