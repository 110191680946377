import { Component, OnInit, TemplateRef, Inject, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import HC_more from 'highcharts/modules/variable-pie';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DOCUMENT, DatePipe } from '@angular/common';
import { CommonService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { TemperatureConvertPipe } from 'src/app/shared/pipes/temperature-convert/temperature-convert.pipe';
import { Subscription } from 'rxjs';


HC_more(Highcharts);

@Component({
  selector: 'app-truck-view',
  templateUrl: './truck-view.component.html',
  styleUrls: ['./truck-view.component.scss']
})


export class TruckViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tempHistoryModal') tempHistoryModal: ElementRef;
  @ViewChild('coolerInfo') coolerInfo: ElementRef;
  coolerInfoList = [
    {
      date: 'Today , 10:00 am',
      temp: '4',
      status: 'normal'
    },
    {
      date: 'Today , 01:00 pm',
      temp: '5',
      status: 'normal'
    },
    {
      date: 'Today , 01:40 pm',
      temp: '12',
      status: 'above'
    },
    {
      date: 'Today , 02:00 pm',
      temp: '5',
      status: 'normal'
    },
    {
      date: 'Today , 02:10 pm',
      temp: '7',
      status: 'normal'
    }
  ]
  datePipeAngular: DatePipe;
  tempUnitConvertPipe: TemperatureConvertPipe;
  refreshSubscription: Subscription;
  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private actRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    const today = new Date();
    this.timestamp = Date.parse(today.toISOString());
    // this.alertRangeValue = [new Date('2020-01-30'), new Date('2020-01-30')];
    this.alertRangeValue = [new Date(), new Date()];

    // let dateRange = {
    //   from: '2020-01-30',
    //   to: '2020-01-30'
    // };
    // this.getAlertList(dateRange)
    this.insideTruckContainer = null;
    this.datePipeAngular = new DatePipe('en-US');
    this.tempUnitConvertPipe = new TemperatureConvertPipe();

    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getTruckDetails();
      this.getTempHistory();
      this.getAlertList(this.currentDateRange, this.currentPageIndex);
    });

  }
  @ViewChild('truckViewObj') truckViewObj: ElementRef;
  @ViewChild('currentTruckContainerData') currentTruckContainerData: ElementRef;
  lat = 29.732773;
  lng = -95.234703;
  zoom = 13;
  timestamp: any;
  modalRef: BsModalRef;
  tempHistory: any;
  alertRangeValue: Date[];
  currentDate: any;
  historyCoolerType: any;
  historyCoolerId: any;
  graphCategories = [];
  temperatureReading = [];
  formatCurrentDate: any;
  tripTempHistory: any;
  tripTempHistoryData: any;
  tripTempStopsData: any;
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 7));
  form = new FormGroup({
    currentDate: new FormControl(new Date())
  });
  sortBy = {
    params: null,
    direction: 'asc'
  };
  bsValue = new Date();
  /********Pagination********/
  currentPage = 1;
  page: number;
  totalRecordsCount: any;
  recordsPerPage = 10;
  activePageNo = 0;
  currentDateRange: any;
  itemsPerPage = 10;
  startItem = 0;
  tripMasterId: any;
  endItem = this.startItem + this.itemsPerPage;
  that = this;
  minRange: any;
  maxRange = [];
  tempUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType') : 'c';
  public options: any = {
    that: this,
    chart: {
      type: 'spline',
      height: 355
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      unit: this.tempUnit,
      useHTML: true,
      formatter() {
        return (
          'Temp. <img src="assets/img/ice-crystal.svg"> ' +
          this.y.toString() +
          ''
          + '' +
          '&nbsp;&nbsp;'
        );
      }
    },
    xAxis: {
      crosshair: {
        color: '#0EA72E',
        dashStyle: 'ShortDash',
        snap: 'true',
        width: '2',
        zIndex: '2'
      },
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      categories: this.graphCategories,
      tickmarkPlacement: 'on',
      title: {
        text: 'Time',
        margin: 20
      }
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      min: -40,
      max: 40,
      tickInterval: 10,
      // plotLines: [{
      //   color: '#0EA72E', // Color value
      //   dashStyle: 'solid', // Style of the plot line. Default to solid
      //   value: 20, // Value of where the line will appear
      //   width: 1, // Width of the line 
      // }, {
      //   color: '#0EA72E', // Color value
      //   dashStyle: 'solid', // Style of the plot line. Default to solid
      //   value: -20, // Value of where the line will appear
      //   width: 1, // Width of the line 
      // }],
      title: {
        useHTML: true,
        text: '°C (avg)',
        align: 'middle'
      },
      labels: {
        overflow: 'justify'
      }
    },
    legend: {
      itemDistance: 50,
      itemStyle: {
        color: '#A5A5A5',
        fontWeight: '400',
        fontSize: '10px',
        align: 'left'
      },
      symbolRadius: 5,
      useHTML: true,
      symbolWidth: 2,
      // labelFormatter: function () {
      //   return '<div>' +
      //     '<div style="border-radius: 50%; width: 10px; height: 10px; border:2px solid ' + this.color + '; display: inline-block; margin-right:5px"> </div>' +
      //     "<span style='color:#666666;font-waight:300;opacity:0.7; font-family:proximaNovaBold'> " + this.name + " </span>" +
      //     '</div>'
      // }

    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            mouseOver: function (event) {
              this.fetchTruckLocationFromGraphByIndex(event.target.x);
            }.bind(this),
            mouseOut: function (event) {
              if (this.trackingChart.indexFocused != null) {
                event.preventDefault();
                return true;
              }
              this.trackingMarker = null;
            }.bind(this)
          }
        },
        pointPlacement: 'on',
      }
    },
    series: [
      {
        that: this,
        name: 'Actual Temperature',
        color: '#1AB0DB',
        data: this.temperatureReading,
        marker: {
          symbol: 'round'
        },
        threshold: -40,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, '#1AB0DB'],
            [0.8, '#FFFFFF1D']
          ]
        }
      }
      // {
      //   type: 'area',
      //   // lineWidth: 1,
      //   name: 'Ideal Range',
      //   color: '#0EA72E',
      //   data: this.maxRange,
      //   marker: {
      //     enabled: false
      //   },
      //   threshold: this.minRange,
      //   fillColor: {
      //     linearGradient: [0, 0, 0, 300],
      //     stops: [
      //       [0, '#0EA72E'],
      //       [.9, '#FFFFFF1D']
      //     ]
      //   },
      //   enableMouseTracking: false
      // }
    ]
  };

  alertList: any;
  tempGraphData: any;
  sensorList: any;
  truckID = '';
  truckNumber: any;
  truckDetails: any;
  truckDetailsCard1: any;
  truckDetailsCard2: any;
  truckDetailsCard3: any;
  dailyTripId: any;
  tripHistoryDetails = [];
  stopList = [];
  truckCoolers: any[] = [];

  plannedStop: any[] = [];
  unplannedStop: any[] = [];

  sensorId = '';
  selectDate: any;

  insideTruckContainer: any;

  trackedData = [];

  trackingMarker = null;

  route = [];

  trackingChart = {
    chart: null,
    indexFocused: null
  };

  sortingPropName: string = 'alertType'
  sortingOrder: string = 'ASC'
  showSortingIcon: any;
  coolerTempHistoryData: any;
  historyData: any = [];
  currentPageIndex = 0;


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.buildTruckView();
  }

  ngOnInit() {
    this.actRoute.queryParamMap.subscribe(params => {
      this.truckID = params['params'].truckID;
      this.dailyTripId = params['params'].dailyTripId;
      this.truckNumber = params['params'].truckNumber;
    });
    this.getTruckDetails();
    this.trackingChart.chart = Highcharts.chart('chart3', this.options);
    // this.alertRangeValue = [new Date('2020-01-30'), new Date('2020-01-30')];
    this.document.body.classList.add('truck-view-component');
    this.getTempHistory();
  }
  getTruckDetails() {
    //let body = {
    //  id: this.truckID
    //}
    this.commonService.getData('get', '/v0/api/v1/trucks/' + this.truckID + '/active-trip/' + this.dailyTripId).subscribe(res => {
      this.truckDetails = res['data'];
      this.truckCoolers = this.truckDetails && this.truckDetails.coolers ? this.truckDetails.coolers.map(data => {
        return {
          coolerColor: data.coolerColor,
          coolerId: data.coolerId,
          coolerStatusType: data.coolerStatusType,
          dailyTripCoolerId: data.dailyTripCoolerId,
          humidity: data.humidity,
          name: data.name,
          receivedFrom: data.receivedFrom,
          status: data.status,
          statusValue: data.statusValue,
          tarnsactionTime: data.tarnsactionTime,
          transferredFrom: data.transferredFrom,
          units: data.units,
          value: data.value,
          priority: data.coolerColor.toLowerCase() == 'red' ? 0 :
            (data.coolerColor.toLowerCase() == 'amber' || data.coolerColor.toLowerCase() == 'yellow') ? 1 : 2
        };
      }).sort((a, b) => Number(a.priority) - Number(b.priority)) : [];


      // this.modifyCoolerData(truckCoolers);
      this.tripMasterId = this.truckDetails.tripMasterId;
      this.getSensorsList();
      // this.getTempGraphData(this.form.value.currentDate);
      this.truckDetailsCard1 = res['data']['cards'][0];
      this.truckDetailsCard1.eventTime = this.truckDetailsCard1.eventTime.trim() == '' ? new Date().toISOString() : this.truckDetailsCard1.eventTime;
      this.truckDetailsCard2 = res['data']['cards'][1];
      this.truckDetailsCard2.eventTime = this.truckDetailsCard2.eventTime.trim() == '' ? new Date().toISOString() : this.truckDetailsCard2.eventTime;
      this.truckDetailsCard3 = res['data']['cards'][2];
      this.truckDetailsCard3.eventTime = this.truckDetailsCard3.eventTime.trim() == '' ? new Date().toISOString() : this.truckDetailsCard3.eventTime;
    });
  }
  modifyCoolerData(truckCoolers: any[]) {
    this.truckCoolers = truckCoolers;
    this.truckCoolers.forEach((item, index, obj) => {
      if (item.value != '') {
        if (!isNaN(item.value) && item.units && (item.units.toLowerCase() == 'c' || item.units.toLowerCase() == 'celsius')) {
          if (item.name && item.name.toLowerCase() == 'ambient') {
            if (item.value >= 18.0 && item.value <= 21.0) {
              item['coolerColor'] = 'green'
            }
            else if ((item.value >= 16.1 && item.value <= 17.9) || (item.value >= 21.1 && item.value <= 22.9)) {
              item['coolerColor'] = 'yellow'
            }
            else if (item.value <= 16.0 || item.value >= 23.0) {
              item['coolerColor'] = 'red'
            }
          }
          if (item.name && item.name.toLowerCase() == 'refrigerated') {
            if (item.value >= 4.0 && item.value <= 5.0) {
              item['coolerColor'] = 'green'
            }
            else if ((item.value >= 2.1 && item.value <= 3.9) || (item.value >= 5.1 && item.value <= 6.9)) {
              item['coolerColor'] = 'yellow'
            }
            else if (item.value <= 2.0 || item.value >= 7.0) {
              item['coolerColor'] = 'red'
            }
          }
          if (item.name && item.name.toLowerCase() == 'frozen') {
            if (item.value <= -22.0) {
              item['coolerColor'] = 'green'
            }
            else if (item.value >= -21.9 && item.value <= -19.0) {
              item['coolerColor'] = 'yellow'
            }
            else if (item.value >= -18.9) {
              item['coolerColor'] = 'red'
            }
          }
        } else if (!isNaN(item.value) && item.units && (item.units.toLowerCase() == 'f' || item.units.toLowerCase() == 'fahrenheit ')) {
          if (item.name && item.name.toLowerCase() == 'ambient') {
            if (item.value >= 64.4 && item.value <= 69.8) {
              item['coolerColor'] = 'green'
            }
            else if ((item.value >= 60.98 && item.value <= 64.22) || (item.value >= 69.98 && item.value <= 73.22)) {
              item['coolerColor'] = 'yellow'
            }
            else if (item.value <= 60.8 || item.value >= 73.4) {
              item['coolerColor'] = 'red'
            }
          }
          if (item.name && item.name.toLowerCase() == 'refrigerated') {
            if (item.value >= 39.2 && item.value <= 41.0) {
              item['coolerColor'] = 'green'
            }
            else if ((item.value >= 35.78 && item.value <= 39.02) || (item.value >= 41.18 && item.value <= 44.42)) {
              item['coolerColor'] = 'yellow'
            }
            else if (item.value <= 35.6 || item.value >= 44.6) {
              item['coolerColor'] = 'red'
            }
          }
          if (item.name && item.name.toLowerCase() == 'frozen') {
            if (item.value <= -7.6) {
              item['coolerColor'] = 'green'
            }
            else if ((item.value >= -7.6 && item.value <= -2.2)) {
              item['coolerColor'] = 'yellow'
            }
            else if (item.value >= -2.02) {
              item['coolerColor'] = 'red'
            }
          }
        } else if (item.units == undefined || item.units == null || item.units == '' || isNaN(item.value)) {
          item['coolerColor'] = 'green'
        }
      } else {
        item['coolerColor'] = 'green';
      }
    });
  }

  onDateRangeChange(eve) {
    if (eve) {
      let dateRange = {
        from: formatDate(eve[0], 'yyyy-MM-dd', 'en-US'),
        to: formatDate(eve[1], 'yyyy-MM-dd', 'en-US')
      }
      this.currentDateRange = dateRange;
      this.getAlertList(dateRange, 0, this.recordsPerPage)
    }
  }


  getAlertList(dateRange, pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage) {
    this.activePageNo = pageNumber;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      from: dateRange.from,
      to: dateRange.to,
      truckId: this.truckNumber,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData('get', '/v0/api/v1/alerts', body).subscribe(res => {
      this.alertList = res.data.rows;
      this.totalRecordsCount = res.data.count;
      this.showSortingIcon = this.sortingPropName + '_' + this.sortingOrder;

    });
  }

  onDateChange(eve) {
    this.selectDate = formatDate(eve, 'yyyy-MM-dd', 'en-US');
    this.getTempGraphData(this.selectDate);
  }

  getTempGraphData(date = '') {


    // console.log('%c TEMP UNIT :::  ' + this.tempUnit, 'color:yellow;font-weight:600; background-color:green');
    this.temperatureReading = [];
    this.graphCategories = [];
    this.trackedData = [];
    this.tempGraphData = [];
    this.maxRange = [];
    if (this.currentDate) {
      this.formatCurrentDate = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');
    }
    // let body = {
    //   date: formatDate(date, 'yyyy-MM-dd', 'en-US'),
    //   sensorId: this.sensorId
    // };

    const body = {
      responseTimeZone: 'Central Standard Time',
      startDateTime: this.selectDate + ' 00:00:00',
      endDateTime: '',
      truckName: this.truckNumber,
      sensorId: this.sensorId + "",
      sensorName: ''
    };
    if (this.tripMasterId) {
      /*  this.commonService.getData(
        'get', '/v0/api/v1/trips/' + 
        this.tripMasterId + '/daily-trip/' 
        + this.dailyTripId + '/tracking-graph-map', body).subscribe(res => {
 */

      this.commonService.getData('post', '/v1/getTruckLocationHistory'
        , body).subscribe(res => {

          this.tempGraphData = res && res[0] && res[0].recordSet ? res[0].recordSet.map(obj => {
            const tempInCel = obj.sensor_plot_value;
            let tempToShow = tempInCel;
            if (this.tempUnit == 'f') {
              const tempInFar = this.tempUnitConvertPipe.transform(tempInCel);
              tempToShow = Number(tempInFar.split('&deg;')[0]);
            } else {
              tempToShow = Number(tempInCel);
            }
            return {
              lat: obj.lat,
              lng: obj.lng,
              response_zone_datetime: obj.response_zone_datetime,
              sensor_id: obj.sensor_id,
              sensor_name: obj.sensor_name,
              sensor_plot_label: obj.sensor_plot_label,
              sensor_plot_value: tempToShow,
              sensor_type: obj.sensor_type,
              the_hour: obj.the_hour,
              truck_name: obj.truck_name,
              utc_time: obj.utc_time,
              unit: '&deg;' + this.tempUnit.toUpperCase()
            };
          }) : [];
          if (this.tempGraphData.length == 0) {
            // toast not needed - saurabh
            // this.commonService.showToast('No Data Found', 0);
            // return true;
          } else {
            /*
            this.tempGraphData.forEach(element => {
              this.graphCategories.push(element.time);
              this.temperatureReading.push(parseInt(element.sensorReading));
              this.minRange = element.min;
              this.maxRange.push(element.max);
            });
            */
            this.graphCategories = this.tempGraphData.map(e => {
              const localDateTime = new Date(e.utc_time);
              return this.datePipeAngular.transform(localDateTime, 'HH:mm');
            });
            this.temperatureReading = this.tempGraphData.map(e => Number(e.sensor_plot_value));
            this.minRange = 0;
            this.maxRange = this.tempGraphData.map(e => {
              return 6;
            });
            this.trackedData = [];
            this.trackedData = this.tempGraphData.map(e => {
              const localDateTime = new Date(e.utc_time);
              return {
                time: localDateTime,
                coords: e.lat + ',' + e.lng,
                sensorReading: e.sensor_plot_value,
              };
            });
            for (let i = 0; i < this.options.series.length; i++) {
              if (this.options.series[i].type == 'area') {
                this.options.series[i].threshold = this.minRange;
              }
            }
          }
          this.options.series[0].data = [];
          // this.options.series[0].threshold = 0;
          this.options.series[0].data = this.temperatureReading.map(e => e);
          // this.options.series[1].data = [];
          // this.options.series[0].threshold = 0;
          // this.options.series[1].data = this.maxRange.map(e => e);
          this.options.xAxis.categories = [];
          this.options.xAxis.categories = this.graphCategories.map(e => e);
          this.options.yAxis.title.text = '°' + this.tempUnit.toUpperCase() + ' (avg)';
          this.options.yAxis.max = Math.max(...this.temperatureReading);
          this.options.yAxis.min = Math.abs(Math.min(...this.temperatureReading)) * -1;

          // console.log(this.trackedData);
          // console.log(this.options);
          this.route = this.trackedData.map(e => e.coords);
          this.trackingChart.chart = Highcharts.chart('chart3', this.options);
        });
    }

  }


  getSensorsList() {
    this.commonService.getData('get', '/v0/api/v1/trips/' + this.dailyTripId + '/sensors').subscribe(res => {
      this.sensorList = res.data;
      if (!this.sensorId) {
        this.sensorId = this.sensorList[0].sensorId;
      }
      this.getTempGraphData(this.form.value.currentDate);
    });
  }


  nextDay() {
    const currentDate = Date.parse(this.form.get('currentDate').value);
    this.form.patchValue({ currentDate: new Date(currentDate + 8.64e7) });
    let graphDate = new Date(currentDate + 8.64e7)
    let selectedDate = formatDate(graphDate, 'yyyy-MM-dd', 'en-US');
  }

  prevDay() {
    const currentDate = Date.parse(this.form.get('currentDate').value);
    this.form.patchValue({ currentDate: new Date(currentDate - 8.64e7) });
    let graphDate = new Date(currentDate - 8.64e7)
    let selectedDate = formatDate(graphDate, 'yyyy-MM-dd', 'en-US');
  }



  closeModal() {
    this.modalRef.hide();
  }

  sortList(params) {
    if (this.sortBy.direction == 'asc') {
      this.sortBy.direction = 'des';
      this.alertList.sort((a, b) => {
        if (isNaN(a[params]) && isNaN(b[params])) {
          if (a[params] < b[params]) {
            return -1;
          }

          if (a[params] == b[params]) {
            return 0;
          }

          if (a[params] > b[params]) {
            return 1;
          }
        } else {
          if (Number(a[params]) < Number(b[params])) {
            return -1;
          }

          if (Number(a[params]) == Number(b[params])) {
            return 0;
          }

          if (Number(a[params]) > Number(b[params])) {
            return 1;
          }
        }
      });
      return true;
    }
    if (this.sortBy.direction == 'des') {
      this.sortBy.direction = 'asc';
      this.alertList.sort((b, a) => {
        if (isNaN(a[params]) && isNaN(b[params])) {
          if (a[params] < b[params]) {
            return -1;
          }

          if (a[params] == b[params]) {
            return 0;
          }

          if (a[params] > b[params]) {
            return 1;
          }
        } else {
          if (Number(a[params]) < Number(b[params])) {
            return -1;
          }

          if (Number(a[params]) == Number(b[params])) {
            return 0;
          }

          if (Number(a[params]) > Number(b[params])) {
            return 1;
          }
        }
      });
      return true;
    }
  }

  // ******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  getLongLats(str) {
    const coords = str.split(',');
    return {
      lat: Number(coords[0]),
      long: Number(coords[1])
    };
  }

  public fetchTruckLocationFromGraph(str) {
    this.trackedData.some(e => {
      if (e.time == str) {
        this.trackingMarker = {
          lat: this.getLongLats(e.coords).lat,
          long: this.getLongLats(e.coords).long,
          timestamp: e.time,
          sensorReading: e.sensorReading
        };
        return true;
      }
    });
  }

  public fetchTruckLocationFromGraphByIndex(index) {
    const el = this.trackedData[index];
    if (el) {
      this.trackingMarker = {
        lat: this.getLongLats(el.coords).lat,
        long: this.getLongLats(el.coords).long,
        timestamp: el.time,
        sensorReading: el.sensorReading
      };
    }
  }

  focusOnSpline(index) {
    this.trackingChart.chart.series[0].points[index].setState('hover');
    this.fetchTruckLocationFromGraphByIndex(index);
    this.trackingChart.chart.tooltip.refresh(
      this.trackingChart.chart.series[0].data[index]
    );
    this.trackingChart.indexFocused = index;
  }

  unfocusOnSpline() {
    if (this.trackingChart.indexFocused != null) {
      this.trackingChart.chart.series[0].points[
        this.trackingChart.indexFocused
      ].setState();
    }
    this.trackingChart.chart.tooltip.hide();
    this.trackingChart.indexFocused = null;
    this.trackingMarker = null;
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('truck-view-component');
    this.refreshSubscription.unsubscribe();
  }

  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getAlertList(this.currentDateRange, pageNo);
  }



  getTempHistory() {
    this.commonService.getData('get', '/v0/api/v1/daily-trip/' + this.dailyTripId + '/stops-temp-history').subscribe(res => {
      this.tripTempHistory = res.data;
      this.tripTempStopsData = res.data.temperatureHistory;
      res.data.temperatureHistory.forEach(element => {
        this.tripTempStopsData.push(element);
      });
      this.setStopList(this.tripTempStopsData[0].coolerId);

      this.tripTempHistoryData = res.data.temperatureHistory;
    });
  }

  setStopList(coolerId) {

    this.tripTempStopsData.forEach(cooler => {
      if (cooler.coolerId == coolerId) {
        this.stopList = cooler.stops;

        this.plannedStop = this.stopList.filter(x => x.stopType != 'Received');
        this.unplannedStop = this.stopList.filter(x => x.stopType == 'Received');

        this.historyCoolerId = cooler.coolerId;
        this.historyCoolerType = cooler.coolerType;
      }
    })
  }

  tempHistoryPopup(e, tempHistory: TemplateRef<any>) {
    if (e == 'tempHistoryModal') {
      this.modalRef = this.modalService.show(this.tempHistoryModal, { class: 'add-alert modal-dialog-centered', backdrop: 'static' });
    } else if (e == 'coolerInfo') {
      this.modalRef = this.modalService.show(this.coolerInfo, { class: 'modal-dialog-centered modal-coolerinfo', backdrop: 'static' });
    }
  }
  onSortAlertList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.showSortingIcon = propertyName + '_' + sortOrder;
    this.getAlertList(this.currentDateRange);
  }

  initTruckView() {
    // console.clear();
    // const svgO = document.getElementById("truck-img") as HTMLObjectElement;
    const svgObject = this.truckViewObj.nativeElement;
    svgObject.addEventListener('load', () => {
      this.buildTruckView()
    });

  }

  buildTruckView() {
    const svgObject = this.truckViewObj.nativeElement;
    const doc = svgObject.contentDocument.documentElement;
    const el = doc.querySelector('.truck-container') as HTMLElement;
    const truckContainer = el.getBoundingClientRect();
    this.insideTruckContainer = {
      width: truckContainer.width - 30,
      height: truckContainer.height - 30,
      left: truckContainer.left + 40,
      right: truckContainer.right + 40,
      bottom: truckContainer.bottom + 25,
      top: truckContainer.top + 25
    }
    console.log({ data: truckContainer });
    console.log(this.insideTruckContainer);
    this.changeDetectorRef.detectChanges();
    // truckContainer
    console.log(this.truckViewObj);
  }

  ngAfterViewInit() {
    this.initTruckView();
  }

  showTempHistoryLis(dailyTripCoolerId) {
    console.log(this.dailyTripId, 'this.dailyTripId');
    console.log(dailyTripCoolerId, 'dailyTripCoolerId');
    this.commonService.getData('get', '/v0/api/v1/trucks/cooler/' + this.dailyTripId + '/' + dailyTripCoolerId + '/temperature-history').subscribe(res => {
      console.log(res, 'res')
      this.coolerTempHistoryData = res;
      this.historyData = res.historyData;
    });
  }



}
