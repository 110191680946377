import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  refreshSub: Subject<any>;
  constructor(
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) { 
    this.refreshSub = new Subject();
  }

  showToast(message, status) {
    if (status == 1) {
      this.toastr.success(message, '');

    } if (status == 2) {
      this.toastr.info(message, '');
    } else {
      this.toastr.error(message, '');
    }
  }

  scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getData(type: string, api: string, body: any = ''): Observable<any> {
    if (type == 'get') {
      let params = "";
      if (body) {
        params += "?";
        for (var key in body) {
          // console.log((body[key]+"").length);
          // console.log(body[key]);
          if ((body[key] + "").length > 0 && body[key] != undefined) {
            params += key + '=' + body[key] + '&';
          }
        }
      }
      return this.httpClient.get(api + params);
    } else if (type == 'post') {
      return this.httpClient.post(api, body);
    } else if (type == 'put') {
      return this.httpClient.put(api, body);
    } else if (type == 'delete') {
      return this.httpClient.delete(api, body);
    }
    return;
  }


  sortTable(prop: string, listToSort, config) {
    const list = listToSort.map(e => e);
    config.sortBy = prop;
    config.sortDirection =
      config.sortDirection !== 'des' ? 'des' : 'asc';
    if (config.sortDirection === 'asc') {
      list.sort((a: any, b: any) => {
        if (isNaN(a[prop]) && isNaN(b[prop])) {
          if (a[prop] < b[prop]) {
            return -1;
          } else {
            if (a[prop] > b[prop]) {
              return 1;
            } else {
              return 0;
            }
          }
        } else {
          if (Number(a[prop]) < Number(b[prop])) {
            return -1;
          } else {
            if (Number(a[prop]) > Number(b[prop])) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
    } else {
      list.sort((b: any, a: any) => {
        if (isNaN(a[prop]) && isNaN(b[prop])) {
          if (a[prop] < b[prop]) {
            return -1;
          } else {
            if (a[prop] > b[prop]) {
              return 1;
            } else {
              return 0;
            }
          }
        } else {
          if (Number(a[prop]) < Number(b[prop])) {
            return -1;
          } else {
            if (Number(a[prop]) > Number(b[prop])) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
    }
    console.log(list);
    return list;
  }
  
  isInvalidEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return false;
    }
    return true;
  }


  refreshData() {
    const date = new Date().toISOString();
    this.refreshSub.next(date);
    return date;
  }

  validateInput(event) {
    const key = event.key.charCodeAt(0);
    if (key === 35 || key === 39) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

}
