import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SensorMaker, Sensor } from '../../all-sensors/sensors.model';

@Component({
  selector: 'app-sensors-history',
  templateUrl: './sensors-history.component.html',
  styleUrls: ['./sensors-history.component.scss']
})
export class SensorsHistoryComponent implements OnInit {
  @Output('onDateChange') onDateChange: any = new EventEmitter();
  @Output('ExportToExcel') ExportToExcel: any = new EventEmitter();
  @Input('sensorHistoryData') sensorHistoryData;
  @Input('activePageNo') activePageNo: any;

  // @Input('dateRangeSensorHistory') historyRangeValue: any[];

  @Input('totalSensorHistoryCount') totalSensorHistoryCount;
  @Output('onPageChange') onPageChange = new EventEmitter<{ currentDateRange: {}, pageNumber: number, pageSize: number }>();
  @Output('onSorting') onSorting: any = new EventEmitter<{ propertyName: string, propertyValue:string }>();

  historyValue = new Date();
  historyRangeValue: Date[];
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 7));
  prevDate = new Date(new Date().getTime() - (8.64e+7 * 7));

  alertHistoryValue = new Date();
  sortOrder: any;
  sensorHistoryList:any;
  currentDateRange: any;
  recordsPerPage = 10;

  getSensorsList() {
   // const list = [  ];

    //const newList = [  ]

    //this.sensorsList = list.map(e => SensorMaker.castInto(e));
    //this.sensorAlertHistory = newList;
  }


  constructor() {
    
    this.sensorsList = [];
    this.getSensorsList();

  }
  //********Pagination********
  currentPage = 1;
  page: number;
  returnedSensorsList: string[];
  startItem = 0;
  endItem = 10;

  sensorsList: any[] = [];
  sensorHistory: any[] = [];
  sensorAlertHistory: any[] = [];
  sortingPropName: string = "date"
  sortingOrder: string = "DESC"

  ngOnInit() {
    this.getSensorsList();
    this.sensorHistory = this.sensorsList;
    // this.historyRangeValue = [new Date(), this.nextDate];
    // this.historyRangeValue = [this.prevDate, new Date()];
    this.historyRangeValue = [new Date(), new Date()];


    ///this.returnedSensorsList = this.sensorsList.slice(0, 10);
  }

  onHistoryDateChange(event) {
      if (event != undefined && event != null && event.length > 1) {
        let startDate = event[0].getTime();
        let endDate = event[1].getTime();
  
        let from = this.converDateFormat(startDate);
        let to = this.converDateFormat(endDate);
  
        let dateRange = {
          from:from,
          to:to
        };
        this.onDateChange.emit(dateRange);
        this.currentDateRange = dateRange;
      //Don't Remove
      //this.returnedSensorsList = this.sensorsList.filter(x => new Date(x.date).getTime() >= startDate && new Date(x.date).getTime() <= endDate)
    }
  }
  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    // this.returnedSensorsList = this.sensorsList.slice(this.startItem, this.endItem);
  }

  //*******sorting sensor-List table *******/
  sortlist(propType, prop, asc) {

    switch (propType) {
      case "int":
        this.sensorHistoryData.rows = this.sensorHistoryData.rows.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case "date":
        this.sensorHistoryData.rows = this.sensorHistoryData.rows.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        })
        break;
      default:
        this.sensorHistoryData.rows = this.sensorHistoryData.rows.sort(function (a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
            return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
          }
        });
    }
  }

  converDateFormat(dateFormat) {
    let from = new Date(dateFormat);
    let fromYear = from.getFullYear();
    let fromMonth = from.getMonth() + 1;
    let fromDate = from.getDate();

    let month = "" + fromMonth;
    let date = "" + fromDate;

    if (fromMonth < 10) {
      month = "0" + fromMonth;
    }
    if (fromDate < 10) {
      date = "0" + fromDate;
    }
    let finalDate = fromYear + "-" + month + "-" + date;

    return finalDate;
  }
  loadPageData(pageNumber: number) {
    let currentDateRange = this.currentDateRange
    let pageSize = this.recordsPerPage;
    this.onPageChange.emit({ currentDateRange, pageNumber, pageSize })
  }
  onExportClick() {
    this.ExportToExcel.emit()
  }

  onSortSensorHistoryList(propertyName1, sortOrder) {
    this.sortingPropName = propertyName1;
    this.sortingOrder = sortOrder;
    let propertyName = this.sortingPropName;
    let propertyValue = this.sortingOrder; 
    this.onSorting.emit({ propertyName,propertyValue });
  }
}
