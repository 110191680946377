import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {

  month = [];
  week = [];

  currentMonth: string = null;
  currentYear: string = null;
  currentWeek: number = -1;
  selectedDate: Date = null;

  dayOffset: number;
  constructor() {
    this.selectedDate = this.setDate(new Date());
    this.currentMonth = this.selectedDate.getMonth().toString();
    this.currentYear = this.selectedDate.getFullYear().toString();
  }

  ngOnInit() {
    this.getCalendar();
  }

  ngAfterViewInit() {
    this.selectedDate = this.setDate(new Date());

  }

  toHighlight(date) {
    return date.toDateString() == this.selectedDate.toDateString();
  }

  isCurrentMonth(date) {
    return date.getMonth().toString() == this.currentMonth;
  }

  setDate(date: Date) {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  changeDate(date: any) {
    this.selectedDate = this.setDate(date);
    if (this.selectedDate.getMonth().toString() !== this.currentMonth) {
      this.getCalendar();
      console.log('month switched');

    }

    this.currentWeek = this.getWeekRow();
    // console.log(this.currentWeek);
  }
  getMonthName(d) {

  }
  getWeekRow() {

    return Math.ceil((this.selectedDate.getDate() + this.dayOffset) / 7) - 1;
  }

  getCalendar(month = null, year = null) {
    this.month = [];
    const today = this.selectedDate != null ? this.selectedDate : new Date();
    const date = today.getDate();

    if (month !== null) {
      today.setMonth(month);
    }
    if (year !== null) {
      today.setFullYear(year);
    }
    let day = today.getDay();
    let millisec = Date.parse(today.toString());

    const inc = 8.64e+7;

    // Shift to start of the month
    millisec = millisec - (inc * (date - 1));
    const firstDay = new Date(millisec);

    // Now we know the first day of the month, we now shift to the starting date of that week
    day = firstDay.getDay();
    this.dayOffset = day;
    millisec = millisec - (inc * day);
    let counter = 0;

    for (let i = 0; i < 5; i++) {



      this.week = [];
      for (let j = 0; j < 7; j++) {

        // inc = inc * counter;

        this.week.push(this.setDate(new Date(millisec + (inc * counter))));
        counter++;
      }
      this.month.push(this.week);

      this.currentMonth = this.selectedDate.getMonth().toString();
      this.currentYear = this.selectedDate.getFullYear().toString();

    }
    this.currentWeek = this.getWeekRow();
    console.log(this.currentWeek);

  }

}
