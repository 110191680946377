import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { CommonService, UserService } from 'src/app/services';
import { AuthService } from '../../auth.service';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewChecked {
  public isMenuActive: boolean = false;
  valueStatus: string = "c";
  preferredTemperatureTypeStatus = true;
  notificationList: boolean = false;
  recordsPerPage = 5;
  // notificationListData=[];
  notificationListData: any[] = [];
  usertemp: any[] = [];
  profile: any;

  finalMenuListData: Observable<any> = of([]);
  menuListData = [
    {
      id: 1,
      dbId: 1,
      dbAbbr: "HOME",
      img: 'assets/img/home.svg',
      name: 'Home',
      routerLink: '/home',
      access: false,
    },
    {
      id: 2,
      dbId: 2,
      dbAbbr: "ALERTS",
      img: 'assets/img/siren.svg',
      name: 'Alerts',
      routerLink: '/alerts',
      access: false,
    },
    {
      id: 3,
      dbId: 3,
      dbAbbr: "SENSOR_LIST",
      img: 'assets/img/icons/all-sensor.svg',
      name: 'Sensor List',
      routerLink: '/all-sensors',
      access: false,
    },
    {
      id: 4,
      dbId: 4,
      dbAbbr: "TRUCK_LIST",
      img: 'assets/img/delivery-truck.svg',
      name: 'Truck List',
      routerLink: '/trucks',
      access: false,
    },
    {
      id: 5,
      dbId: 5,
      dbAbbr: "DRIVER_LIST",
      img: 'assets/img/driver-list-icon.svg',
      name: 'Driver List',
      routerLink: '/drivers',
      access: false,
    },
    {
      id: 6,
      dbId: 6,
      dbAbbr: "COOLER_LIST",
      img: 'assets/img/icons/cooler-box.svg',
      name: 'Cooler List',
      routerLink: '/cooler-list',
      access: false,
    },
    {
      id: 7,
      dbId: 7,
      dbAbbr: "ROUTE_LIST",
      img: 'assets/img/route-list-icon.svg',
      name: 'Route List',
      routerLink: '/routes',
      access: false,
    },
    {
      id: 8,
      dbId: 8,
      dbAbbr: "TRIP_TRACKER",
      img: 'assets/img/TripTracker-icon.svg',
      name: 'Trip Tracker',
      routerLink: '/trip-tracker',
      access: false,
    },
    {
      id: 9,
      dbId: 9,
      dbAbbr: "TRIP_PLANNER",
      img: 'assets/img/icons/tripplanning-icon.svg',
      name: 'Trip Planner',
      routerLink: '/trip-planner-list',
      access: false,
    },
    {
      id: 10,
      dbId: 10,
      dbAbbr: "DASHBOARD",
      img: 'assets/img/dashboard.svg',
      name: 'Dashboards',
      routerLink: '/dashboard',
      access: false,
    },
    {
      id: 11,
      dbId: 11,
      dbAbbr: "REPORTS",
      img: 'assets/img/reports.svg',
      name: 'Reports',
      routerLink: '/reports',
      access: false,
    },
    {
      id: 12,
      dbId: 12,
      dbAbbr: "ALERT MANAGEMENT",
      img: 'assets/img/alert-mngmt-icon.svg',
      name: 'Alert Management',
      routerLink: '/alerts-management',
      access: false,
    },
    {
      id: 13,
      dbId: 13,
      dbAbbr: "USERS",
      img: 'assets/img/avatar.svg',
      name: 'User & Roles',
      routerLink: '/user-role',
      access: false,
    },

  ];

  refreshTimestamp: any;
  constructor(
    private router: Router,
    private commonService: CommonService,
    public auth: AuthService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService
  ) {
    this.refreshTimestamp = new Date().toISOString();
    this.router.events.subscribe((res: RouterEvent) => {
      if (res instanceof NavigationEnd) {
        //show body scroll
        document.body.style.overflow = 'auto';

        this.commonService.scrollTop();
        this.refreshTimestamp = new Date().toISOString();

        // send onesingal tag
        this.userService.sendOneSignalTag();
      }
    });
  }



  ngOnInit() {
    this.getPreferredTemperature();
    this.usertemp = localStorage.getItem('iotwua') ? JSON.parse(atob(localStorage.getItem('iotwua'))) : [];
  }

  getAccessList(name): boolean {
    let x = this.usertemp.filter(ele => ele.permissionAbbr == name)[0]
    let a = false;
    if (x && x.permissionTypes) {
      x.permissionTypes.forEach(element => {

        if (element.permissionType == 'Web') {
          a = element.status;
        }

      })
    }
    return a;
  }

  getPreferredTemperature() {
    let hhsPreferredTemperatureType = "c";
    if (localStorage.getItem('hhsPreferredTemperatureType') && localStorage.getItem('hhsPreferredTemperatureType') != "") {
      hhsPreferredTemperatureType = localStorage.getItem('hhsPreferredTemperatureType');
    }
    this.valueStatus = hhsPreferredTemperatureType;
    if (hhsPreferredTemperatureType == 'f') {
      this.preferredTemperatureTypeStatus = false;
    }
  }

  logout() {
    this.auth.logout();
    if (localStorage.getItem('iotwua')) {
      localStorage.removeItem('iotwua');
    }
    if (localStorage.getItem('profile')) {
      localStorage.removeItem('profile');
    }
    this.router.navigate(['/login']);
    this.userService.deleteOneSignalTag();
  }

  getNotificationPopup() {
    if (!this.notificationList) {
      this.notificationList = true;
      this.getNotificationlist();
    } else {
      this.notificationList = false;
    }
  }

  isViewProfile() { }

  changePreferredTemp(event) {
    if (event.target.checked == true) {
      this.valueStatus = "c";
    } else {
      this.valueStatus = "f";
    }
    localStorage.setItem('hhsPreferredTemperatureType', this.valueStatus);
    this.refreshData();
  }

  getNotificationlist(pageNumber = 0, pageSize = this.recordsPerPage) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize
    };
    this.commonService.getData('get', '/v0/api/v1/alerts', body).subscribe(res => {
      console.log(res);
      // this.totalNotificationCount = res.data.count;
      this.notificationListData = res.data.rows;
      this.notificationListData = this.notificationListData.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime())

    }, err => this.notificationListData = []);
  }

  goToAlerts() {
    this.router.navigate(['/alerts']);
    this.notificationList = false;
  }
  //scrolltoView(id) {
  //  console.log(id)
  //  const element = document.querySelector('#' + id);
  //  element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  //}
  isMenuRendered = false;
  public renderMenu() {
    this.menuListData.forEach(m => {
      this.usertemp.forEach(e => {
        if (m.dbAbbr == e.permissionAbbr) {
          e.permissionTypes.forEach(element => {
            if (element.permissionType == 'Web') {
              m.access = element.status;
            }
          })
        }
      })
    });
    this.isMenuRendered = true;
    this.finalMenuListData = of(this.menuListData);
  }

  ngAfterViewChecked(): void {
    this.usertemp = localStorage.getItem('iotwua') ? JSON.parse(atob(localStorage.getItem('iotwua'))) : [];
    if (this.usertemp.length > 0 && !this.isMenuRendered) {
      this.renderMenu();
      // this.cdRef.detectChanges();
    }
    this.profile = localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : "";
    this.cdRef.detectChanges();
  }


  refreshData() {
    this.refreshTimestamp = this.commonService.refreshData();
    // console.log(this.commonService.refreshData());
  }
}
