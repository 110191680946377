import { environment } from 'src/environments/environment';

const dashboardReportUrl = environment["report_urls"];
export const dashboardReportList = [
  {
    img: 'assets/img/trip-report.svg',
    name: 'Trips Dashboard',
    status: 'active',
    url: dashboardReportUrl.dashboard.TripsDashboard.url,
    reportId: dashboardReportUrl.dashboard.TripsDashboard.reportId
   // url: 'https://app.powerbi.com/reportEmbed?reportId=bb46be20-cadc-4aae-82ba-62a8158531ae&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
  },
  {
    img: 'assets/img/alert-hist.svg',
    name: 'Alerts Dashboard',
    status: 'active',
    url: dashboardReportUrl.dashboard.AlertsDashboard.url,
    reportId: dashboardReportUrl.dashboard.AlertsDashboard.reportId
   // url: 'https://app.powerbi.com/reportEmbed?reportId=442d7e38-0471-4667-8eac-ec8d75e88f57&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  },
  {
    img: 'assets/img/temp-hist-report.svg',
    name: 'Sensor Analytics Dashboard',
    status: 'active',
    url: dashboardReportUrl.dashboard.SensorAnalyticsDashboard.url,
    reportId: dashboardReportUrl.dashboard.SensorAnalyticsDashboard.reportId

    //url: 'https://app.powerbi.com/reportEmbed?reportId=ee548881-4ca6-4685-a889-7a5d4fbd602f&groupId=752bad58-f32e-4a2e-8a1f-f039aa99bcc0&autoAuth=true&ctid=ae691f81-3cb4-4c6f-a6d4-dfbb4c6303bb&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  },
  // {
  //   img: 'assets/img/ref-his.svg',
  //   name: 'Reference Summary Dashboard',
  //   status: 'active',
  //   url: '#'
  // },
  // {
  //   img: 'assets/img/summary.svg',
  //   name: 'Sensors History Dashboard',
  //   status: 'active',
  //   url: '#'
  // },
  // {
  //   img: 'assets/img/truck-report.svg',
  //   name: 'Truck Temp. Dashboard',
  //   status: 'active',
  //   url: '#'
  // }
];
