import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../services';
import { StoreDataService } from '../../../services/common/store-data.service';

@Component({
  selector: 'app-create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAlertComponent implements OnInit {
  modalRef: BsModalRef;
  isEditStatusSelected = true;
  alertStatus= true;
  fieldArray: Array<any>=[];
  roleFirstAlert: boolean = true;
  usersFirstAlert: boolean = false;
  usersFirstEscalationAlert: boolean = false;
  roleFirstEscalationAlert: boolean = true;
  roleSecondEscalationAlert = true;
  usersSecondEscalationAlert = false;
  roleList: any[] = [];
  addRoleSource = '';
  addUsersSource = '';
  FirstAlert = [];
  firstEscalateAlert = [];
  secondEscalateAlert = [];
  AllUsersList = [];
  //secondEscalationAlert: any[] = [];
  notification = '';
  
  ddlAlertType = [];
  iotAssets = [];
  alertName = '';
  alertType = '';
  alertSubType ='';
  isInAppChecked = false;
  isEmailChecked = false;
  selectedAsset:string = '';
  warningMinValue = 0;
  warningMaxValue = 0;
  alertMaxValue = 0;
  alertMinValue = 0;
  toleranceVal = 0;
  firstEscalationVal = 0;
  secondEscalationVal = 0;
  toleranceUnit = 'Seconds';
  firstEscalationUnit = 'Seconds';
  secondEscalationUnit = 'Seconds';
  escalationUnits: string = "C";
  firstAlertUnits: string = "C";
  ddlSubtype: any[] = [];
  selectedAssetId: number;
 // firstAlert$: Observable<any> = of([]);
  //firstAlertUsers = [];
 // escalateAlert$: Observable<any> = of([]);
  //escalateAlertUsers = [];

  //Validations
  nameIsInvalid: any;
  alertTypeIsInvalid: any;
  alertSubTypeIsInvalid: any;
  subscriptionIsInvalid: any;
  iotAssetsIsInvalid: any;
  firstAlertIsInvalid: any;
  escAlertIsInvalid: any;
  secondescAlertIsInvalid: any;
  isNegativeToleranceValue = false;
  isNegativeFirstEcalationValue = false;
  isNegativeSecondEcalationValue = false;
  isNegativeFirstDoorAlertValue = false;
  isNegativeEscalatedDoorAlertValue = false;

  genUserPicture = "assets/img/generic-user-icon.png";

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private commonService: CommonService,
    private dataService: StoreDataService,
    private route: ActivatedRoute,
    private detectChange: ChangeDetectorRef
    //private cdref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (localStorage.getItem('hhsPreferredTemperatureType')) {
      this.escalationUnits = localStorage.getItem('hhsPreferredTemperatureType').toLocaleUpperCase();
      this.firstAlertUnits = localStorage.getItem('hhsPreferredTemperatureType').toLocaleUpperCase();
    }

   this.getUserList();
   this.getAllRoleList()
    this.getIotAssets();
  
  }

  getUserList(pageNumber = 0, pageSize = 1000) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
    }
    this.commonService.getData('get', '/v0/api/v1/users', body).subscribe(resp => {
      this.AllUsersList = resp.data.rows;
    });
  }
  getAllRoleList() {
    this.commonService.getData('get', '/v0/api/v1/roles/names').subscribe(resp => {
      if (resp.data) {
        this.roleList = resp["data"];
      }
    })

  }

  ontypeChange(eve) {
    let currentTempUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType') : "C";
    let alertTypeId = eve.target.value;
    let typeData = this.ddlAlertType.filter(e => e.id == alertTypeId);
    this.alertType = typeData && typeData.length > 0 ? typeData[0].name : '';
    this.ddlSubtype = typeData && typeData.length > 0 ? typeData[0].assetSubTypes : [];

    if (this.selectedAssetId == 4){ /* selectedAssetId=4 for 'truck' Assets */
      if (alertTypeId == 5) {       /*alertType=5 for alertType 'Humidity'*/
        this.firstAlertUnits = "%"
        this.escalationUnits = "%"
      } else if (alertTypeId == 4) {  /*alertType=4 for alertType 'Temprature'*/
        this.firstAlertUnits = currentTempUnit.toLocaleUpperCase();
        this.escalationUnits = currentTempUnit.toLocaleUpperCase();
      }      
    } else if (this.selectedAssetId == 5) {
      this.firstAlertUnits = "Seconds";
      this.escalationUnits = "Seconds";
    } else {
      this.firstAlertUnits = currentTempUnit.toLocaleUpperCase();
      this.escalationUnits = currentTempUnit.toLocaleUpperCase();
     }
    this.detectChange.detectChanges();
  }
  onSubTypeChange(event) {
    this.alertSubType = event.target.value;
  }
  onStatusChange(event) {
    this.alertStatus = event.target.checked 
    this.isEditStatusSelected = event.target.checked;
  }

  firstAlertChange(alert) {
    this.FirstAlert = [];
    if(alert == 'users'){
      this.usersFirstAlert = true;
      this.roleFirstAlert = false;
    }
    if(alert == 'roles'){
      this.roleFirstAlert = true;
      this.usersFirstAlert = false;
    }
  }

  firstEscalationAlertChange(alert) {
    this.firstEscalateAlert = [];
    if(alert == 'users'){
      this.usersFirstEscalationAlert = true;
      this.roleFirstEscalationAlert = false;
    }
    if(alert == 'roles'){
      this.roleFirstEscalationAlert = true;
      this.usersFirstEscalationAlert = false;
    }
  }
  seccondEscalationAlertChange(alert) {
    this.secondEscalateAlert = [];
    if (alert == 'users') {
      this.usersSecondEscalationAlert = true;
      this.roleSecondEscalationAlert = false;
    }
    if (alert == 'roles') {
      this.roleSecondEscalationAlert = true;
      this.usersSecondEscalationAlert = false;
    }
  }
  getDDLAlertType(id) {
    this.commonService.getData('get', '/v0/api/v1/iot-assets-types/'+id).subscribe(res => {
      this.ddlAlertType = res;//["data"];
      this.detectChange.detectChanges();
    });
  }
  getIotAssets() {
    this.commonService.getData('get', '/v0/api/v1/iot-assets').subscribe(res => {
      this.iotAssets = res;
      this.detectChange.detectChanges();
    });
  }
  onIotAssetChange(iotAssets) {
    this.selectedAsset = iotAssets.name;
    this.selectedAssetId = iotAssets.id;
    this.alertType = "";
    this.alertSubType = "";
    this.firstAlertUnits = "C"
    this.escalationUnits = "C"
    // if (iotAssets.name && iotAssets.name.toLowerCase()=="door") {
    //   this.firstAlertUnits = "Seconds";
    //   this.escalationUnits = "Seconds";
    // }
    this.getDDLAlertType(iotAssets.id);
  }
  onToleranceValChange(type:any) {
    if (type == 'inc') {
      this.toleranceVal ++;
    } else if (type='dec') {
      this.toleranceVal >0 ?this.toleranceVal--:0;
    }
  }
  onToleranceUnitChange(e) {
    this.toleranceUnit = e.target.value;
  }
  onEscalationUnitChange(e,type) {
    if (type=='first') {
      this.firstEscalationUnit = e.target.value
    }else
    this.secondEscalationUnit = e.target.value;
  }
  onEscalationValChange(changeType, alertType) {
    if (alertType=='first') {
      if (changeType == 'inc') {
        this.firstEscalationVal++;
      } else if (changeType == 'dec') {
        this.firstEscalationVal >0 ?this.firstEscalationVal--:0;
      }
    } else {
      if (changeType == 'inc') {
        this.secondEscalationVal++;
      } else if (changeType == 'dec') {
        this.secondEscalationVal > 0 ? this.secondEscalationVal--:0;
      }
    }
   
  }
  onInAppChange(e) {
    this.isInAppChecked = e.target.checked;
  }
  onEmailChange(e) {
    this.isEmailChecked = e.target.checked;
  }

  onAddRoles(event, alertType) {
    let role = {
      id: event.id,
      accessType: "Roles",
      roleName: event.roleName
    }
    if (alertType == "firstAlert") {
      this.FirstAlert.push(role);
      this.FirstAlert = this.FirstAlert.filter(e => e.accessType == "Roles");
    }
    else if (alertType == 'firstEscalteAlert') {
      this.firstEscalateAlert.push(role);
      this.firstEscalateAlert = this.firstEscalateAlert.filter(e => e.accessType == "Roles");
    }
    else if (alertType == 'secondEscalteAlert') {
      this.secondEscalateAlert.push(role);
      this.secondEscalateAlert = this.secondEscalateAlert.filter(e => e.accessType == "Roles");
    }
  }
  onAddUser(event, alertType) {
    let role = {
      id: event.id,
      accessType: "Users",
      userName: event.userName,
      profile: event.profile,
    }
    if (alertType == "firstAlert") {
      this.FirstAlert.push(role);
      this.FirstAlert = this.FirstAlert.filter(e => e.accessType == "Users");
    }
    else if (alertType == 'firstEscalteAlert') {
      this.firstEscalateAlert.push(role);
      this.firstEscalateAlert = this.firstEscalateAlert.filter(e => e.accessType == "Users");
    }
    else if (alertType == 'secondEscalteAlert') {
      this.secondEscalateAlert.push(role);
      this.secondEscalateAlert = this.secondEscalateAlert.filter(e => e.accessType == "Users");
    }
  }
  onRemoveUser(item, source) {
    if (source == 'firstAlert') {
      this.FirstAlert = this.FirstAlert.filter(x => x.id != item.id);
    }
    else if (source == 'firstEscalteAlert') {
      this.firstEscalateAlert = this.firstEscalateAlert.filter(x => x.id != item.id);
    }
    else if (source == 'secondEscalteAlert') {
      this.secondEscalateAlert = this.secondEscalateAlert.filter(x => x.id != item.id);
    }
  }
  onRemoveRole(role, from) {
    if (from == 'firstAlert') {
      this.FirstAlert = this.FirstAlert.filter(x => x.id != role.id);
    }
    else if (from =='firstEscalteAlert') {
      this.firstEscalateAlert = this.firstEscalateAlert.filter(x => x.id != role.id);
    }
    else if (from =='secondEscalteAlert') {
      this.secondEscalateAlert = this.secondEscalateAlert.filter(x => x.id != role.id);
    }
  }

  onSubmit() {   
    let body = {
      "alertConfigId": 0,
      "alertName": this.alertName,
      "alertType": this.alertType,
      "alertSubType": this.alertSubType,
      "status": this.alertStatus,
      "inApp": this.isInAppChecked,
      "email": this.isEmailChecked,
      "asset": this.selectedAsset,
      "firstAlertMinValue":  + this.alertMinValue,
      "firstAlertMaxValue": + this.alertMaxValue,
      "firstAlertUnits": this.escalationUnits,
      "escalationMinValue": +this.warningMinValue,
      "escalationMaxValue": +this.warningMaxValue,
      "escalationUnits": this.firstAlertUnits,
      "tolerance": +this.toleranceVal,
      "toleranceUnits": this.toleranceUnit,
      "firstEscalation": +this.firstEscalationVal,
      "firstEscalationUnits": this.firstEscalationUnit,
      "secondEscalation": +this.secondEscalationVal,
      "secondEscalationUnits": this.secondEscalationUnit,
      "firstAlert": this.FirstAlert,
      "escalationAlert": this.firstEscalateAlert,
      "secondEscalationAlert": this.secondEscalateAlert,
      "alertNotification": this.notification,
    };
    if (this.validateAlert()) {
      this.commonService.getData('post', '/v0/api/v1/alerts/configuration', body).subscribe(x => {
       this.router.navigate(["/alerts-management"]);
      });
    } else {
      this.commonService.showToast("Invalid entry", 0);
    }

  }
  onCancel() {  
    this.router.navigate(["/alerts-management"]);
  }

  validateAlert(): boolean {
    if (!this.selectedAsset.length) {
      this.iotAssetsIsInvalid = true;
      return false;
    } else {
      this.iotAssetsIsInvalid = false;
    }
    if (!this.alertName.length) {
      this.nameIsInvalid = true;
      return false;
    } else {
      this.nameIsInvalid = false;
    }
    if (!this.alertType.length) {
      this.alertTypeIsInvalid = true;
      return false;
    } else {
      this.alertTypeIsInvalid = false;
    }
    if (!this.isInAppChecked && !this.isEmailChecked) {
      this.subscriptionIsInvalid = true;
      return false;
    } else {
      this.subscriptionIsInvalid = false;
    }
   
    if ((this.toleranceVal < 0)) {
      this.isNegativeToleranceValue = true;
      return false;
    } else {
      this.isNegativeToleranceValue = false;
    }
    if ((this.firstEscalationVal < 0)) {
      this.isNegativeFirstEcalationValue = true;
      return false;
    } else {
      this.isNegativeFirstEcalationValue = false;
    }
    if ((this.secondEscalationVal < 0)) {
      this.isNegativeSecondEcalationValue = true;
      return false;
    } else {
      this.isNegativeSecondEcalationValue = false;
    }
    if (!(this.FirstAlert.length >0)) {
      this.firstAlertIsInvalid = true;
      return false;
    } else {
      this.firstAlertIsInvalid = false;
    }
    if (!(this.firstEscalateAlert.length > 0)) {
      this.escAlertIsInvalid = true;
      return false;
    } else {
      this.escAlertIsInvalid = false;
    }
    if (!(this.secondEscalateAlert.length > 0)) {
      this.secondescAlertIsInvalid = true;
      return false;
    } else {
      this.secondescAlertIsInvalid = false;
    }

    return true;
  }
  
  
  onValidateNegativeValue(event, fieldName) {
    let value = event.target.value;
    if (fieldName == "tolerance") {
      if (value < 0 || isNaN(value) || event.key=="-") {
        this.isNegativeToleranceValue = true;
      } else
        this.isNegativeToleranceValue = false;
    }
    if (fieldName == "firstEscalation") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeFirstEcalationValue = true;
      } else
        this.isNegativeFirstEcalationValue = false;
    }
    if (fieldName == "secondEscalation") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeSecondEcalationValue = true;
      } else
        this.isNegativeSecondEcalationValue = false;
    }
    if (fieldName == "firstDoorAlert") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeFirstDoorAlertValue = true;
      } else
        this.isNegativeFirstDoorAlertValue = false;
    } 
    if (fieldName == "escalatedDoorAlert") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeEscalatedDoorAlertValue = true;
      } else
        this.isNegativeEscalatedDoorAlertValue = false;
    } 
  }
}
