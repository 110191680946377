import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-add-truck",
  templateUrl: "./add-truck.component.html",
  styleUrls: ["./add-truck.component.scss"]
})
export class AddTruckComponent implements OnInit {
  truckDataList: any = [];
  userMasterId: any;
  numberCount: number = 0;
  searchKey: any;
  disabledTruckList = [];
  truckList = [
    {
      id: "ABC1230",
      size: "Medium",
      route: "F 30",
      name: "Tyler Elliot",
      img: "assets/img/aran.png",
      status: "active"
    },
    {
      id: "ABC1231",
      size: "Large",
      route: "F 28",
      name: "Jose Wogner",
      img: "assets/img/bobby.png",
      status: "inactive"
    },
    {
      id: "ABC1232",
      size: "Small",
      route: "F 27",
      name: "Jow Wagner",
      img: "assets/img/jeremy.png",
      status: "active"
    },
    {
      id: "ABC1233",
      size: "Medium",
      route: "F 24",
      name: "Frank Lawson",
      img: "assets/img/frank.png",
      status: "active"
    },
    {
      id: "ABC1234",
      size: "Large",
      route: "F 25",
      name: "Jeremy Riley",
      img: "assets/img/jose.png",
      status: "inactive"
    },
    {
      id: "ABC1235",
      size: "Medium",
      route: "F 20",
      name: "Larry Evans",
      img: "assets/img/larry.png",
      status: "active"
    }
  ];

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe(param => {
      this.userMasterId = param.userMasterId;
    });
  }

  ngOnInit() {
    this.getTruckList();
  }

  getTruckList() {
    let body = {
      search: this.searchKey
    }
    this.commonService
      .getData('get', '/v0/api/v1/profile/' + this.userMasterId + '/all-trucks-with-status', body)
      .subscribe(res => {
        this.truckDataList = res;
        this.truckDataList.forEach(e => {
          if (e.selected == true) {
            this.numberCount++;
          }
        });
        if(this.numberCount >= 6){
          this.selectedFalseData();
        }
      });
  }

  selectTruck(item, event) {
    if (event.target.checked) {
      item.selected = true;
      this.numberCount++;
      if(this.numberCount == 6){
        this.selectedFalseData();
      }
    } else {
      item.selected = false;
      this.numberCount--;
      this.disabledTruckList = []
    }
  }

  selectedFalseData(){
    this.disabledTruckList = [];
    this.truckDataList.forEach(e => {
      if (e.selected == false) {
        this.disabledTruckList.push(e.truckMasterId);
      }
    });
  }

  addTruck() {
    let logData = [];
    this.truckDataList.forEach(e => {
      if (e.selected == true) {
        logData.push({
          userMasterId: parseInt(this.userMasterId),
          truckMasterId: parseInt(e.truckMasterId)
        });
      }
    });
    this.commonService
      .getData('post', "/v0/api/v1/profile/add-trucks", logData)
      .subscribe(res => {
        if (res.status) {
          this.router.navigate(["/my-profile"]);
        }
      });
  }

  // clear search
  onClearSearch(){
    if (!this.searchKey) {
      return;
    }
    this.searchKey = "";
    this.getTruckList();
  }
}
