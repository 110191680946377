import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { SensorMaker, Sensor } from '../../all-sensors/sensors.model';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services';

@Component({
  selector: 'app-sensor-alert-history',
  templateUrl: './sensor-alert-history.component.html',
  styleUrls: ['./sensor-alert-history.component.scss']
})
export class SensorAlertHistoryComponent implements OnInit {

  @Output('onDateChange') onDateChange:any = new EventEmitter();
  @Output('onExportToExcel') onExportToExcel:any = new EventEmitter();
  @Input('sensorAlertHistoryData') sensorAlertHistoryData;
  @Input('totalAlertHistoryCount') totalAlertHistoryCount;
  @Input('activePageNo') activePageNo: any;
  
  alertHistoryValue = new Date();
  alertRangeValue: Date[];
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 7));
  prevDate = new Date(new Date().getTime() - (8.64e+7 * 7));
  sortOrder: any;
  currentDateRange: any;
  AlertHistoryList: any[] = [];
  refreshSubscription: Subscription;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
  ) {
    this.AlertHistoryList = this.sensorAlertHistoryData? this.sensorAlertHistoryData.rows.map(e => SensorMaker.castInto(e)):[];
    console.log(this.AlertHistoryList);
    //this.sensorsList = [];
    //this.getSensorsList();

  }
  //@Output('getSensorHistory') getSensorHistory = new EventEmitter();
  @Output('onPageChange') onPageChange = new EventEmitter<{ currentDateRange: {}, pageNumber: number, pageSize:number }>();
  @Output('onSorting') onSorting: any = new EventEmitter<{ propertyName: string, propertyValue:string }>();
  //********Pagination********
  currentPage = 1;
  page: number;
  returnedSensorsList: string[];
  startItem = 0;
  endItem = 10;

  //sensorsList: any[] = [];
  sensorAlertHistory: any[] = [];
  recordsPerPage = 10;
  sortingPropName: string = "date"
  sortingOrder: string = "DESC"

  ngOnInit() {
   // this.AlertHistoryList = this.sensorAlertHistoryData.rows.map(e => SensorMaker.castInto(e));
    //console.log(this.AlertHistoryList);
    //this.sensorAlertHistory = this.sensorsList;
    
    // this.alertRangeValue = [new Date('2020-01-01'), new Date('2020-02-03')];
    // this.alertRangeValue = [this.prevDate, new Date()];
    this.alertRangeValue = [new Date(), new Date()];

    //this.returnedSensorsList = this.sensorsList.slice(0, 10);
    this.document.body.classList.add('sensors-alert-history-component');
  }



  //getSensorsList() {
  //  this.AlertHistoryList = this.sensorAlertHistoryData.rows.map(e => SensorMaker.castInto(e));
  //}

  onAlertHistyDateChange(event) {
    if (event != undefined && event != null && event.length > 1) {
      let startDate = event[0].getTime();
      let endDate = event[1].getTime();

      let from = this.converDateFormat(startDate);
      let to = this.converDateFormat(endDate);

      let dateRange = {
        from:from,
        to:to
      };
      this.onDateChange.emit(dateRange);
      this.currentDateRange = dateRange;
    //Don't Remove
    //this.returnedSensorsList = this.sensorsList.filter(x => new Date(x.date).getTime() >= startDate && new Date(x.date).getTime() <= endDate)
  }
  }

  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    // this.returnedSensorsList = this.sensorsList.slice(this.startItem, this.endItem);
  }

  //*******sorting sensor-List table *******/
  sortSensorlist(propType, prop, asc) {

    switch (propType) {
      case "int":
        this.sensorAlertHistoryData.rows = this.sensorAlertHistoryData.rows.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case "date":
        this.sensorAlertHistoryData.rows = this.sensorAlertHistoryData.rows.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        })
        break;
      default:
        this.sensorAlertHistoryData.rows = this.sensorAlertHistoryData.rows.sort(function (a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
            return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('sensors-alert-history-component');
  }

  converDateFormat(dateFormat) {
    let from = new Date(dateFormat);
    let fromYear = from.getFullYear();
    let fromMonth = from.getMonth() + 1;
    let fromDate = from.getDate();

    let month = "" + fromMonth;
    let date = "" + fromDate;

    if (fromMonth < 10) {
      month = "0" + fromMonth;
    }
    if (fromDate < 10) {
      date = "0" + fromDate;
    }
    let finalDate = fromYear + "-" + month + "-" + date;

    return finalDate;
  }
  loadPageData(pageNumber: number) {
    let currentDateRange = this.currentDateRange
    let pageSize = this.recordsPerPage ;
    this.onPageChange.emit({ currentDateRange, pageNumber,pageSize })
  }
  onExportToClick() {
    this.onExportToExcel.emit();
  }

  onSortSensorAlertList(propertyName1, sortOrder) {
    this.sortingPropName = propertyName1;
    this.sortingOrder = sortOrder;
    let propertyName = this.sortingPropName;
    let propertyValue = this.sortingOrder; 
    this.onSorting.emit({ propertyName,propertyValue });
  }
}
