import { Component, OnInit, TemplateRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { StoreDataService } from '../../services/common/store-data.service';
import { Observable, of, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { AlertConfigMaker} from './alert-config'


@Component({
  selector: 'app-alerts-management',
  templateUrl: './alerts-management.component.html',
  styleUrls: ['./alerts-management.component.scss']
})
export class AlertsManagementComponent implements OnInit,OnDestroy {
  modalRef: BsModalRef;
  isShowFilter = false;
  ischecked = false;
  isAllChecked = false;
  currentPage = 1;
  page: number;
  startItem = 0;
  endItem = 10;
  showSidebar = false;
  showEditSidebar = false;
  isEditStatusChecked = true;
  isAddBtnClicked = false;
  firstAccessType = 'Roles';
  firstEscalationAccessType = 'Roles';
  secondEscalationAccessType = 'Roles';
  recordsPerPage = 10;
  totalAlertCount: any;
  searchKey: string;
  alertsList: any = [];
  activePageNo: any;
  alertConfigDetails: any;
  alertSubscriber: any[] = [];
  subscriberData: any[] = [];

  firstAlertType: any;
  firstEscalatedAlertType: any;
  secondEscalatedAlertType: any;

  firstData: any[] = [];
  firstEscalatedData: any[] = [];
  secondEscalatedData: any[] = [];

  roleList: any[] = [];
  ddlAlertType: any[] = [];
  AllUsersList: any[] = [];
  ddlSubtype: any[] = [];
  pageLoadFrom: any;
  cardAlertCount: any;
  showFRole = false;
  showFUser = false;
  showERole = false;
  showEUser = false;

  genUserPicture = "assets/img/generic-user-icon.png";
  //data to update alerts
  alertConfigId = 0;
  addRoleSource = '';
  addUsersSource = '';

  alertName = '';
  AlertCreatedBy = '';
  alertType = '';
  SubAlertType = '';
  AlertCreatedOn = '';
  firstAlertMinValue = 0;
  firstAlertMaxValue = 0;
  firstAlertUnits = "C";

  escalationMinValue = 0;
  escalationMaxValue = 0;
  escalationAlertUnits = "C";

  tolerance = 0;
  subscriptionsArray = [];
  iotAssetVal = '';
  toleranceUnit = 'Seconds';
  firstEscalationUnits = 'Seconds';
  secondEscalationUnits = 'Seconds';
  firstEscalation = 0;
  secondEscalation = 0;
  alertStatus = 0;
  alertNotification = '';
  inAppSubscription = false;
  emailSubscription = false;
  selectedAssetId: number;
  firstRoleData$: Observable<any> = of([]);
  firstUserData$: Observable<any> = of([]);
  firstEscRoleData$: Observable<any> = of([]);
  firstEscUserData$: Observable<any> = of([]);
  secondEscRoleData$: Observable<any> = of([]);
  secondEscUserData$: Observable<any> = of([]);

  firstRoleData: any[] = [];
  firstUserData: any[] = [];
  firstEscalatedRoleData: any[] = [];
  firstEscalatedUserData: any[] = [];
  secondEscalatedRoleData: any[] = [];
  secondEscalatedUserData: any[] = [];

  //Validation
  nameIsInvalid: any;
  alertTypeIsInvalid: any;
  alertSubTypeIsInvalid: any;
  subscriptionIsInvalid: any;
  iotAssetsIsInvalid: any;
  toleranceIsInvalid: any;
  firstAlertIsInvalid: any;
  escAlertIsInvalid: any;
  negativeFieldValue;
  isNegativeToleranceValue = false;
  isNegativeFirstEcalationValue = false;
  isNegativeSecondEcalationValue = false;

  //filter
  createdOn_From_filter = '';
  createdOn_Upto_filter = '';
  // alertType_filter = '';
  // createdBy_filter = '';
  // status_filter = '';
  alertType_filter: any;
  createdBy_filter: any;
  status_filter: any;

  sortingPropName: string = "createdOn"
  sortingOrder: string = "DESC"
  currentPageIndex = 0;

  iotAssets = [];
  createdUserList: any[] = [];
  refreshSubscription: Subscription;
  todaydate = new Date();
  ddlAlertTypeForFilter: any[] = [];
  constructor(
    private router: Router,
    private modalService: BsModalService,
    public commonService: CommonService,
    public exportExcelService: ExportExcelService,
    private dataService: StoreDataService,
    private route: ActivatedRoute,
    private detectChange: ChangeDetectorRef
  ) {
    this.route.queryParams.subscribe(p => {
      this.pageLoadFrom = p["pageLoadFrom"];
    });
    this.refreshSubscription=this.commonService.refreshSub.subscribe(res => {
      this.getAlertCountCardData();
      this.getAlertList();
    });
  }

  ngOnInit() {
    this.getIotAssets();
    this.getAlertCountCardData();
    this.getAlertList();
    this.getUserList();
    this.getAllRoleList([], '');
    this.getCreatedUserList();
    this.getAlertTypesForFilter();
  }

  getDDLAlertType(id) {
    let iotId = id;
    if (isNaN(id)) {
      let lst = this.iotAssets.filter(e => e.name == id);
      iotId = lst && lst.length > 0 ? lst[0].id : 0;
    }
    this.commonService.getData('get', '/v0/api/v1/iot-assets-types/' + iotId).subscribe(res => {
      this.ddlAlertType = res;//["data"];
      this.selectedAssetId = res ? res[0].assetId : 0;
      let data = this.ddlAlertType.filter(x => x.assetId == iotId);
      // let alertType = data.length > 0 ? data[0].id : this.alertType;
      let alertType = data.filter(e => e.name == this.alertType).length > 0 ? data.filter(e => e.name == this.alertType)[0].id : this.alertType;
      this.onAlertTypeChange(alertType)
    });
  }

  getCreatedUserList() {
    this.commonService.getData('get', '/v0/api/v1/alerts-configuration-users').subscribe(res => {
      this.createdUserList = res;
    });
  }

  getIotAssets() {
    this.commonService.getData('get', '/v0/api/v1/iot-assets').subscribe(res => {
      this.iotAssets = res;
      this.detectChange.detectChanges();
    });
  }

  getAlertCountCardData() {
    this.commonService.getData('get', '/v0/api/v1/alerts/configuration/status/count').subscribe(res => {
      this.cardAlertCount = res["data"];
    });
  }

  getAlertList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = "onInit") {

    this.activePageNo = pageNumber;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.searchKey,
      alertType: this.alertType_filter == 'null' ? '' : this.alertType_filter,
      createdBy: this.createdBy_filter == 'null' ? '' : this.createdBy_filter,
      fromDate: this.createdOn_From_filter ? formatDate(this.createdOn_From_filter, 'yyyy-MM-dd', 'en-US') : this.createdOn_From_filter,
      toDate: this.createdOn_Upto_filter ? formatDate(this.createdOn_Upto_filter, 'yyyy-MM-dd', 'en-US') : "" ? this.createdOn_Upto_filter : formatDate(this.todaydate, 'yyyy-MM-dd', 'en-US'),
      status: this.status_filter == 'null' ? '' : this.status_filter,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    }
    this.commonService.getData('get', '/v0/api/v1/alerts/configuration', body).subscribe(res => {
      this.totalAlertCount = res.data.count;
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => AlertConfigMaker.exportToExcel(e)) : [];
        this.exportExcelService.exportAsExcelFile(exportableData, 'Alert configuration list');
      }
      this.alertsList = res.data.rows;
    });
  }
  checkAll(isCheck) {
    this.alertsList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }
  countCheckedRows(): any {
    if (this.alertsList.length == 0) {
      return;
    }
    let checkedflag = true;
    let counter = 0;

    this.alertsList.forEach((e) => {
      if (!e.isChecked) {
        checkedflag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedflag;
    return counter;
  }
  selectRow(obj: any, event) {
    obj.isChecked = event.target.checked;
    this.countCheckedRows();
  }
  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  itemsAsObjects = [{ id: 0, name: 'Angular' }, { id: 1, name: 'React' }];

  gotoAlertDetails(alerts) {
    this.alertConfigId = alerts.alertConfigId;
    this.showSidebar = true;
    this.commonService.getData('get', '/v0/api/v1/alerts/configuration/' + alerts.alertConfigId).subscribe(res => {
      this.alertConfigDetails = res;
      if (res.alertConfigId) {
        this.alertName = res.alertName;
        this.iotAssetVal = res.asset
        this.AlertCreatedBy = res.createdBy;
        this.alertStatus = res.status;
        this.alertType = res.alertType;
        this.SubAlertType = res.alertSubType;
        this.AlertCreatedOn = res.createdOn;
        this.firstAlertMinValue = +res.firstAlertMinValue;
        this.firstAlertMaxValue = +res.firstAlertMaxValue;
        this.firstAlertUnits = res.firstAlertUnits;

        this.escalationMinValue = +res.escalationMinValue;
        this.escalationMaxValue = +res.escalationMaxValue;
        this.escalationAlertUnits = res.escalationUnits;

        this.tolerance = res.tolerance;
        //this.subscriptionsArray = res.subscriptions;
        this.toleranceUnit = res.toleranceUnits;

        this.firstEscalationUnits = res.firstEscalationUnits;
        this.firstEscalation = res.firstEscalation;

        this.secondEscalationUnits = res.secondEscalationUnits;
        this.secondEscalation = res.secondEscalation;

        this.inAppSubscription = res.inApp;
        this.emailSubscription = res.email;
        this.alertNotification = res.alertNotification;

        this.alertSubscriber = this.alertConfigDetails.subscribers ? this.alertConfigDetails.subscribers : [];
        if (this.alertSubscriber.length > 0) {
          this.alertSubscriber.forEach((val, i, obj) => {
            if (val.alertType.toLowerCase() == "tolerance") {
              this.firstAlertType = val.alertType;
              this.firstData = val.data;
              this.firstAccessType = val.accessType;
            }
            if (val.alertType.toLowerCase() == "first escalation") {
              this.firstEscalatedAlertType = val.alertType;
              this.firstEscalatedData = val.data;
              this.firstEscalationAccessType = val.accessType;
            }
            if (val.alertType.toLowerCase() == "second escalation") {
              this.secondEscalatedAlertType = val.alertType;
              this.secondEscalatedData = val.data;
              this.secondEscalationAccessType = val.accessType;
            }
          });

          this.firstRoleData = this.firstData.filter(x => x.accessType == 'Roles');
          this.firstUserData = this.firstData.filter(x => x.accessType == 'Users');
          this.firstEscalatedRoleData = this.firstEscalatedData.filter(x => x.accessType == 'Roles');
          this.firstEscalatedUserData = this.firstEscalatedData.filter(x => x.accessType == 'Users');
          this.secondEscalatedRoleData = this.secondEscalatedData.filter(x => x.accessType == 'Roles');
          this.secondEscalatedUserData = this.secondEscalatedData.filter(x => x.accessType == 'Users');

          this.firstRoleData$ = of(this.firstRoleData);
          this.firstUserData$ = of(this.firstUserData);
          this.firstEscRoleData$ = of(this.firstEscalatedRoleData);
          this.firstEscUserData$ = of(this.firstEscalatedUserData);
          this.secondEscRoleData$ = of(this.secondEscalatedRoleData);
          this.secondEscUserData$ = of(this.secondEscalatedUserData);
        }
        this.getDDLAlertType(this.iotAssetVal);
      }
    });
  }
  CreateNewAlert() {
    this.isAddBtnClicked = true;
    setTimeout(() => {
      this.router.navigate(['/create-alert'])
    }, 200)
  }
  roleNameArray: any[] = []
  getAllRoleList(roles: any[], source) {
    this.addRoleSource = source;
    let roleIds = roles.filter(x => x.accessType == 'Roles').map(x => x.id);
    this.commonService.getData('get', '/v0/api/v1/roles/names').subscribe(resp => {
      // this.roleList = resp["data"];
      if (resp.data) {
        this.roleList = resp["data"]
          .map(e => {
            return {
              id: e.roleMasterId,
              roleName: e.roleName,
              alertConfigUsersId: e.roleMasterId,
              isChecked: roleIds.find(x => x == e.roleMasterId) != undefined ? true : false,
            }
          })
      }
      this.roleNameArray = this.roleList.map(x => x.roleName);
    })

  }

  firstAlertChange(accessType) {
    this.firstAccessType = accessType;
  }

  fisrtEscalationAlertChange(factype) {
    this.firstEscalationAccessType = factype;
  }
  secondEscalationAlertChange(sactype) {
    this.secondEscalationAccessType = sactype;
  }
  onExportAsExcel() {
    if (!this.totalAlertCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getAlertList(0, this.totalAlertCount, 'export')
  }
  onSearch() {
    this.getAlertList(0);
  }



  onAlertTypeChange(e) {
    let currentTempUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType') : "C";
    var alertTypeId = e && e.target && e.target.value ? e && e.target && e.target.value : e;
    if (e && e.target && e.target.value) {
      let id = e.target.value
      let typeData = this.ddlAlertType.filter(e => e.id == id);
      this.alertType = typeData && typeData.length > 0 ? typeData[0].name : '';
      this.ddlSubtype = typeData && typeData.length > 0 ? typeData[0].assetSubTypes : [];
    } else {
      let sybtypeArr: any[] = this.ddlAlertType.length > 0 ? this.ddlAlertType.filter(z => z.name == this.alertType) : [];
      this.ddlSubtype = sybtypeArr.length > 0 ? sybtypeArr[0].assetSubTypes : [];
    }

    if (this.selectedAssetId == 4) { /*selectedAssetId=4 for 'truck' Assets */
      if (alertTypeId == 5 || this.alertType.toLocaleLowerCase() == "humidity") {       /*alertType=5 for alertType 'Humidity'*/
        this.firstAlertUnits = "%"
        this.escalationAlertUnits = "%"
      } else if (alertTypeId == 4 && this.alertType.toLocaleLowerCase() != "humidity") {  /*alertType=4 for alertType 'Temprature'*/
        this.firstAlertUnits = currentTempUnit.toLocaleUpperCase();
        this.escalationAlertUnits = currentTempUnit.toLocaleUpperCase();
      }
    }
    else if (this.selectedAssetId == 5) {
      this.firstAlertUnits = this.firstAlertUnits == "Seconds" || this.firstAlertUnits == "Minutes" ? this.firstAlertUnits : "Seconds";
      this.escalationAlertUnits = this.escalationAlertUnits == "Seconds" || this.escalationAlertUnits == "Minutes" ? this.escalationAlertUnits : "Seconds";
    }
    else {
      this.firstAlertUnits = currentTempUnit.toLocaleUpperCase();
      this.escalationAlertUnits = currentTempUnit.toLocaleUpperCase();
    }
    this.detectChange.detectChanges();
  }
  onSubTypeChange(x) {
    this.SubAlertType = x.target.value;
  }
  onSubscriptionChange(event, subs_list) {
    this.subscriptionsArray.forEach((item) => {
      if (item.subscription == subs_list.subscription) {
        item.checked = event.target.checked;
      }
    })
  }
  onIotAssetsValChange(asset) {
    this.selectedAssetId = asset.id;
    this.iotAssetVal = asset.name;
    this.alertType = "";
    this.SubAlertType = "";
    this.firstAlertUnits = "C"
    this.escalationAlertUnits = "C"
    this.getDDLAlertType(asset.id);
  }
  onToleranceValChange(type) {
    if (type == 'inc') {
      this.tolerance++;
    } else
      this.tolerance--;
  }
  onToleranceUnitChange(eve) {
    this.toleranceUnit = eve.target.value;
  }
  onEscalationValChange(optype, alertType) {
    if (alertType == 'first') {
      if (optype == 'inc') {
        this.firstEscalation++;
      } else
        this.firstEscalation > 0 ? this.firstEscalation-- : 0;
    } else {
      if (optype == 'inc') {
        this.secondEscalation++;
      } else
        this.secondEscalation > 0 ? this.secondEscalation-- : 0;
    }

  }
  onEscalationUnitChange(eve, alertType) {
    if (alertType == "first") {
      this.firstEscalationUnits = eve.target.value;
    }
    this.secondEscalationUnits = eve.target.value;
  }
  onAddRoles(role, source) {
    this.addRoleSource = source;
    let data;
    if (this.addRoleSource == 'FromFirstAlert') {
      data = {
        alertType: "firstAlert",
        accessType: "Roles",
        alertConfigUsersId: role.id,
        roleOrUserName: role.roleName,
        roleOrUserId: role.id,
        id: role.id
      }
      this.firstData.push(data);
      this.firstData = this.firstData.filter(x => x.accessType == 'Roles');
      this.firstRoleData = this.firstData;
      this.firstRoleData$ = of(this.firstRoleData);
      this.firstUserData = [];
      this.firstUserData$ = of([]);

    } else if (this.addRoleSource == 'FromEscalate') {
      data = {
        alertType: "firstEscalation",
        accessType: "Roles",
        alertConfigUsersId: role.id,
        roleOrUserName: role.roleName,
        roleOrUserId: role.id,
        id: role.id
      }
      this.firstEscalatedData.push(data);
      this.firstEscalatedData = this.firstEscalatedData.filter(x => x.accessType == 'Roles');
      this.firstEscalatedRoleData = this.firstEscalatedData;
      this.firstEscRoleData$ = of(this.firstEscalatedRoleData);
      this.firstEscalatedUserData = [];
      this.firstEscUserData$ = of([]);
    }
    else if (this.addRoleSource == 'FromSecondEscalate') {
      data = {
        alertType: "secondEscalation",
        accessType: "Roles",
        alertConfigUsersId: role.id,
        roleOrUserName: role.roleName,
        roleOrUserId: role.id,
        id: role.id
      }
      this.secondEscalatedData.push(data);
      this.secondEscalatedData = this.secondEscalatedData.filter(x => x.accessType == 'Roles');
      this.secondEscalatedRoleData = this.secondEscalatedData;
      this.secondEscRoleData$ = of(this.secondEscalatedRoleData);
      this.secondEscalatedUserData = [];
      this.secondEscUserData$ = of([]);
    }
  }

  onAddUsers(user, source) {
    this.addRoleSource = source;
    let data;
    if (this.addRoleSource == 'FromFirstAlert') {
      data = {
        alertType: "firstAlert",
        accessType: "Users",
        alertConfigUsersId: user.userMasterId,
        roleOrUserName: user.roleOrUserName,
        roleOrUserId: user.userMasterId,
        id: user.userMasterId,
        profile: user.profile,
      }
      this.firstData.push(data);
      this.firstData = this.firstData.filter(x => x.accessType == 'Users');

      this.firstUserData = (this.firstData);
      this.firstUserData$ = of(this.firstUserData);
      this.firstRoleData$ = of([]);
      this.firstRoleData = [];

    } else if (this.addRoleSource == 'FromEscalate') {
      data = {
        alertType: "firstEscalation",
        accessType: "Users",
        alertConfigUsersId: user.userMasterId,
        roleOrUserName: user.roleOrUserName,
        roleOrUserId: user.userMasterId,
        id: user.userMasterId,
        profile: user.profile,
      }
      this.firstEscalatedData.push(data);
      this.firstEscalatedData = this.firstEscalatedData.filter(x => x.accessType == 'Users');

      this.firstEscalatedUserData = this.firstEscalatedData;
      this.firstEscUserData$ = of(this.firstEscalatedUserData);
      this.firstEscRoleData$ = of([]);
      this.firstEscalatedRoleData = [];
    }
    else if (this.addRoleSource == 'FromSecondEscalate') {
      data = {
        alertType: "SecondEscalation",
        accessType: "Users",
        alertConfigUsersId: user.userMasterId,
        roleOrUserName: user.roleOrUserName,
        roleOrUserId: user.userMasterId,
        id: user.userMasterId,
        profile: user.profile,
      }
      this.secondEscalatedData.push(data);
      this.secondEscalatedData = this.secondEscalatedData.filter(x => x.accessType == 'Users');

      this.secondEscalatedUserData = this.secondEscalatedData;
      this.secondEscUserData$ = of(this.secondEscalatedUserData);
      this.secondEscRoleData$ = of([]);
      this.secondEscalatedRoleData = [];
    }
  }

  onRemoveRole(role, target) {
    if (target == 'FromFirstAlert') {
      let filteredAry = this.firstData.filter(x => x.alertConfigUsersId != role.alertConfigUsersId);
      this.firstData = filteredAry;
      this.firstRoleData = this.firstData.filter(x => x.accessType == 'Roles');
      this.firstRoleData$ = of(this.firstRoleData);

    } else if (target == 'FromEscalate') {
      let filteredAry = this.firstEscalatedData.filter(x => x.alertConfigUsersId != role.alertConfigUsersId);
      this.firstEscalatedData = filteredAry;
      this.firstEscalatedRoleData = this.firstEscalatedData.filter(x => x.accessType == 'Roles');
      this.firstEscRoleData$ = of(this.firstEscalatedRoleData);
    }
    else if (target == 'FromSecondEscalate') {
      let filteredAry = this.secondEscalatedData.filter(x => x.alertConfigUsersId != role.alertConfigUsersId);
      this.secondEscalatedData = filteredAry;
      this.secondEscalatedRoleData = this.secondEscalatedData.filter(x => x.accessType == 'Roles');
      this.secondEscRoleData$ = of(this.secondEscalatedRoleData);

    }
  }
  onRemoveUser(role, target) {
    if (target == 'FromFirstAlert') {
      let filteredAry = this.firstData.filter(x => x.alertConfigUsersId != role.alertConfigUsersId);
      this.firstData = filteredAry;
      this.firstUserData = this.firstData.filter(x => x.accessType == 'Users');
      this.firstUserData$ = of(this.firstUserData);

    } else if (target == 'FromEscalate') {
      let filteredAry = this.firstEscalatedData.filter(x => x.alertConfigUsersId != role.alertConfigUsersId);
      this.firstEscalatedData = filteredAry;

      this.firstEscalatedUserData = this.firstEscalatedData.filter(x => x.accessType == 'Users');
      this.firstEscUserData$ = of(this.firstEscalatedUserData);
    }
    else if (target == 'FromSecondEscalate') {
      let filteredAry = this.secondEscalatedData.filter(x => x.alertConfigUsersId != role.alertConfigUsersId);
      this.secondEscalatedData = filteredAry;

      this.secondEscalatedUserData = this.secondEscalatedData.filter(x => x.accessType == 'Users');
      this.secondEscUserData$ = of(this.secondEscalatedUserData);
    }
  }
  onListStatusChange(e, list) {
    let status = e.target.checked;
    let body = {
      "alertConfigId": list.alertConfigId,
      "status": status
    }

    this.commonService.getData('put', '/v0/api/v1/alerts/configuration/status-update', body).subscribe(x => {
      this.getAlertList();
      this.getAlertCountCardData();
    })
  }

  onListSubscriptionChange(eve, list, type) {
    let body;
    if (type == 'inapp') {
      body = {
        "alertConfigId": list.alertConfigId,
        "inApp": eve.target.checked,
        "email": list.email
      }
    }

    if (type == 'email') {
      body = {
        "alertConfigId": list.alertConfigId,
        "inApp": list.inApp,
        "email": eve.target.checked,

      }
    }

    this.commonService.getData('put', '/v0/api/v1/alerts/configuration/subscription-update', body).subscribe(x => {
      this.router.navigate(["/alerts-management"]);
      this.showSidebar = false;
      this.getAlertList();

    })
  }

  resetUrl() {
    //this.router.navigate(["/alerts-management"]);
    this.isNegativeToleranceValue = false;
    this.isNegativeFirstEcalationValue = false;
    this.isNegativeSecondEcalationValue = false;
  }

  //Apply Filter
  onFilterApply() {
    this.getAlertList(0);
  }

  //clear Filter
  onClearFilter() {
    this.setDefaultFilterValues();
    this.createdOn_From_filter = '';
    this.createdOn_Upto_filter = '';
    this.getAlertList(0);
  }
  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getAlertList();
  }

  validateAlert(): boolean {
    if (!this.alertName.length) {
      this.nameIsInvalid = true;
      return false;
    } else {
      this.nameIsInvalid = false;
    }
    if (!this.alertType.length) {
      this.alertTypeIsInvalid = true;
      return false;
    } else {
      this.alertTypeIsInvalid = false;
    }

    if (!this.inAppSubscription && !this.emailSubscription) {
      this.subscriptionIsInvalid = true;
      return false;
    } else {
      this.subscriptionIsInvalid = false;
    }

    if ((this.tolerance < 0)) {
      this.isNegativeToleranceValue = true;
      return false;
    } else {
      this.isNegativeToleranceValue = false;
    }
    if ((this.firstEscalation < 0)) {
      this.isNegativeFirstEcalationValue = true;
      return false;
    } else {
      this.isNegativeFirstEcalationValue = false;
    }
    if ((this.secondEscalation < 0)) {
      this.isNegativeSecondEcalationValue = true;
      return false;
    } else {
      this.isNegativeSecondEcalationValue = false;
    }
    if (!(this.firstData.length > 0)) {
      this.firstAlertIsInvalid = true;
      return false;
    } else {
      this.firstAlertIsInvalid = false;
    }
    if (!(this.firstEscalatedData.length > 0)) {
      this.escAlertIsInvalid = true;
      return false;
    } else {
      this.escAlertIsInvalid = false;
    }
    if (this.negativeFieldValue < 0) {
      return false;
    }
    return true;
  }

  onUpdateAlerts() {

    let body = {
      "alertConfigId": this.alertConfigId,
      "alertName": this.alertName,
      "alertType": this.alertType,
      "alertSubType": this.SubAlertType,
      "asset": this.iotAssetVal,
      "inApp": this.inAppSubscription,
      "email": this.emailSubscription,
      "status": this.alertStatus,
      "firstAlertMinValue": +this.firstAlertMinValue,
      "firstAlertMaxValue": +this.firstAlertMaxValue,
      "firstAlertUnits": this.firstAlertUnits,
      "escalationMinValue": +this.escalationMinValue,
      "escalationMaxValue": +this.escalationMaxValue,
      "escalationUnits": this.escalationAlertUnits,
      "alertNotification": this.alertNotification,
      "tolerance": +this.tolerance,
      "toleranceUnits": this.toleranceUnit,
      "firstEscalation": +this.firstEscalation,
      "firstEscalationUnits": this.firstEscalationUnits,
      "secondEscalation": +this.secondEscalation,
      "secondEscalationUnits": this.secondEscalationUnits,
      "firstAlert": this.firstData,
      "escalationAlert": this.firstEscalatedData,
      "secondEscalationAlert": this.secondEscalatedData
    }
    if (this.validateAlert()) {
      this.commonService.getData('put', '/v0/api/v1/alerts/configuration', body).subscribe(resp => {
        this.showEditSidebar = false;
        this.getAlertList();
        this.resetUrl();
      });
    } else {
      this.commonService.showToast("Invalid entry", 0);
    }
  }

  getUserList(pageNumber = 0, pageSize = 1000) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
    }
    this.commonService.getData('get', '/v0/api/v1/users', body).subscribe(resp => {
      this.AllUsersList = resp.data.rows;
    });
  }

  onValidateNegativeValue(event, fieldName) {
    let value = event.target.value;
    if (fieldName == "tolerance") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeToleranceValue = true;
      } else
        this.isNegativeToleranceValue = false;
    }
    if (fieldName == "firstEscalation") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeFirstEcalationValue = true;
      } else
        this.isNegativeFirstEcalationValue = false;
    }
    if (fieldName == "secondEscalation") {
      if (value < 0 || isNaN(value) || event.key == "-") {
        this.isNegativeSecondEcalationValue = true;
      } else
        this.isNegativeSecondEcalationValue = false;
    }
  }

  onSortAlertsConfigList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getAlertList();
  }
  toggleFilter(){
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.alertType_filter = 'null';
    this.createdBy_filter = 'null';
    this.status_filter = 'null';
  }

  //clear Search
  onClearSearch() {
    if (!this.searchKey) {
      return;
    }
    this.searchKey = "";
    this.getAlertList(0);
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  validateInput(event) {
    this.commonService.validateInput(event);
  }
  getAlertTypesForFilter(){
    this.commonService.getData('get', '/v0/api/v1/iot-assets-types').subscribe(res => {
      this.ddlAlertTypeForFilter = res;
    });
  }
}
