import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-chart',
  templateUrl: './home-chart.component.html',
  styleUrls: ['./home-chart.component.scss']
})
export class HomeChartComponent implements OnInit {
  energyLevels: any = {};
  tempLevels: any = {};
  constructor() { }

  ngOnInit() {
    this.energyLevels = {
      id: 'chart1',
      xAxis : {
        name: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
        title: ''
      },
      yAxis : {
        title: ''
      },
      chart: {
        type: 'column',
        title: 'Trips',
        series: [{
          name: 'Planned',
          color: '#62B8FF',
          fontSize: '11px',
          data: [20, 180, 160, 100, 100, 170, 40]

        },
        {
          name: 'Completed',
          color: '#34C481',
          data: [20, 150, 120, 110, 80, 100, 30]

        }],
      }
    };
    this.tempLevels = {
      id: 'chart2',
      xAxis : {
        name: ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        title: ''
      },
      yAxis : {
        title: '',
      },
      chart: {
        type: 'column',
        title: 'Trailer Temperature Incidents',
        fontSize: '10px',
        series: [{
          name: 'Above Setpoint',
          color: '#F45E5E',
          data: [2, 9, 7, 4, 19, 8, 3]

        },
        {
          name: 'Below Setpoint',
          color: '#62B8FF',
          data: [2, 7, 6, 8, 10, 8, 4]

        }],
      }
    };

  }

}
