import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-shp-chart',
  templateUrl: './shp-chart.component.html',
  styleUrls: ['./shp-chart.component.scss']
})
export class ShpChartComponent implements OnInit, AfterViewInit {

  @Input() xAxisData;
  @Input() yAxisData;
  @Input() chartData;
  @Input() chartId;
  @Input() chartObservable;
  @ViewChild('container') container: ElementRef;

  chart: any;
  public options: any;

  constructor() {

  }

  ngOnInit() {

    console.log(this.chartData);

  }

  ngAfterViewInit() {
    this.container.nativeElement.id = this.chartId;
    // this.chartObservable.subscribe(() => {
    //   this.setChart();
    // });
    this.setChart();
  }
  setChart() {


    this.options = {
      chart: {
        /*
          energy: line
          temp and flight traffic: spline
          Passengers: column
        */
        type: this.chartData.type,

      },
      title: {
        text: this.chartData.title,
        style: {
          display: 'none'
        }
      },
      xAxis: {
        categories: this.xAxisData.name.map(e => e),
      },
      yAxis: {
        min: 0,
        title: {
          text: this.yAxisData.title,
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      legend: {
        // layout: 'horizontal', // default
        itemDistance: 50,
        squareSymbol: true,
        symbolRadius: 2,
            itemStyle: {
                color: '#A5A5A5',
                fontWeight: '400',
                fontSize: '10px',
                align: 'left'
            }
      },
      credits: {
        enabled: false
      },
      series: this.chartData.series.map(e => {
        return {
          name: e.name,
          color: this.getColor(e.color, this.chartData.type.includes('Area'), this.chartData.type),
          // color: '#ff0000',
          data: e.data.map(e => e),
          // fillColor: this.getColor('#00ff00', true, 'areaspline')
          fillColor: this.getColor(
            e.color,
            this.chartData.type.includes('area'),
            null
          )
        };
      }) 
    };
    Highcharts.chart(this.chartId, this.options);
  }
  getColor(color, fill, chartType) {
    if (!fill && !chartType) {
      return '#ffffff00';
    }

    if (fill) {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, color + '5F'], // start
          [0.8, '#ffffff0D'], // middle
          [1, '#ffffff00'] // end
        ]
      };
    }

    if (chartType != null) {
      return color;
    }
  }
}
