import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnGoingTruckMaker } from 'src/app/shared/models/ongoing-truck-list/on-going-truck.model';
import { CommonService } from '../../services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClusterOptions, ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';


@Component({
  selector: 'app-active-trips',
  templateUrl: './active-trips.component.html',
  styleUrls: ['./active-trips.component.scss']
})
export class ActiveTripsComponent implements OnInit, OnDestroy {
  ischecked: boolean = false;
  isAllChecked = false;
  // truckList: any[] = [];
  truckList: any;
  tableRowToCollapse = -1;
  lat = 29.760427;
  lng = -95.369804;

  list = [];
  cityViewmarker: any;
  stateViewMarker: any[] = [];
  genericUserPic = "assets/img/generic-user-icon.png";

  search: any = "";
  activePageNo = 0;

  sortBy = {
    params: null,
    direction: "asc"
  };
  redTruck = "assets/img/greenTruck.svg";
  viewMarker: boolean = false;
  isMapOpen: boolean = true;
  shouldShowinfoWindow: any;
  zoom = 9;
  sortingPropName: string = "truckId"
  sortingOrder: string = "ASC"
  showSortingIcon: any;

  // routesList = [
  //   {
  //     id: 1,
  //     profilePic: 'assets/img/users/CharlesFoster-image.png',
  //     assignedDriver: 'Tyler Elliot',
  //     origin: '3333 Raleigh St, Houston',
  //     destination: 'The branch school, Houston',
  //     routeId: '30',
  //     miles: '75,000',
  //     stops: '12',
  //     isChecked: false
  //   },
  //   {
  //     id: 2,
  //     profilePic: 'assets/img/users/BruceBrewer-image.png',
  //     assignedDriver: 'Jose Wagner',
  //     origin: '4994 Woodbridge Lane,Houston',
  //     destination: '4352 Payne Street, Houston',
  //     routeId: '21',
  //     miles: '68,500',
  //     stops: '15',
  //     isChecked: false
  //   },
  //   {
  //     id: 3,
  //     profilePic: 'assets/img/users/RyanPowell-image.png',
  //     assignedDriver: 'Larry Evans',
  //     origin: '375 Margaret Street, Houston',
  //     destination: '2724 Circle Drive, Houston',
  //     routeId: '18',
  //     miles: '70,350',
  //     stops: '11',
  //     isChecked: false
  //   },
  //   {
  //     id: 4,
  //     profilePic: 'assets/img/users/DanJackson-image.png',
  //     assignedDriver: 'Bobby Coleman',
  //     origin: '1987 Werninger Street, Houston',
  //     destination: '1662 Payne Street, Houston',
  //     routeId: '22',
  //     miles: '58,150',
  //     stops: '16',
  //     isChecked: false
  //   },
  //   {
  //     id: 5,
  //     profilePic: 'assets/img/users/CharlesFoster-image.png',
  //     assignedDriver: 'Tyler Elliot',
  //     origin: '3333 Raleigh St, Houston',
  //     destination: 'The branch school, Houston',
  //     routeId: '30',
  //     miles: '75,000',
  //     stops: '12',
  //     isChecked: false
  //   },
  //   {
  //     id: 6,
  //     profilePic: 'assets/img/users/BruceBrewer-image.png',
  //     assignedDriver: 'Jose Wagner',
  //     origin: '4994 Woodbridge Lane,Houston',
  //     destination: '4352 Payne Street, Houston',
  //     routeId: '21',
  //     miles: '68,500',
  //     stops: '15',
  //     isChecked: false
  //   },
  //   {
  //     id: 7,
  //     profilePic: 'assets/img/users/RyanPowell-image.png',
  //     assignedDriver: 'Larry Evans',
  //     origin: '375 Margaret Street, Houston',
  //     destination: '2724 Circle Drive, Houston',
  //     routeId: '18',
  //     miles: '70,350',
  //     stops: '11',
  //     isChecked: false
  //   },
  //   {
  //     id: 8,
  //     profilePic: 'assets/img/users/DanJackson-image.png',
  //     assignedDriver: 'Bobby Coleman',
  //     origin: '1987 Werninger Street, Houston',
  //     destination: '1662 Payne Street, Houston',
  //     routeId: '22',
  //     miles: '58,150',
  //     stops: '16',
  //     isChecked: false
  //   }
  // ]

  //********Pagination********
  currentPage = 1;
  page: number;
  returnedDriverList: string[];
  startItem = 0;
  endItem = 5;

  recordsPerPage = 10;
  totalTripsCount = 0;
  refreshSubscription: Subscription;
  currentPageIndex = 0;

  clusterStyles: ClusterStyle[];
  clusterOptions: ClusterOptions;
  public mapMarkerOverTripId: string;

  constructor(
    private commonService: CommonService,
    private router: Router
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getTruckList();
    });
  }

  ngOnInit() {
    this.getTruckList();
    this.clusterStyles = [
      {
          textColor: "white",
          url: "assets/img/map-cluster.svg",
          width: 40,
          height: 40
      }
  ];

  this.clusterOptions = {
        gridSize: 30,
        minimumClusterSize: 2,
        averageCenter: true
    }
  }

  getTruckList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage) {
    this.activePageNo = pageNumber;
    this.totalTripsCount = 0;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.search.trim() === '' ? null : this.search.trim(),
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder

    };
    this.commonService.getData('get', '/v0/api/v1/trips/active', body).subscribe(res => {
      if (res.data) {
        this.truckList = [];
        if(res.data.rows && res.data.rows.length == 0){
          return false;
        }
        this.setMarker(res.data.rows);
        this.truckList = res.data.rows.map(e => OnGoingTruckMaker.castInto(e));
        this.totalTripsCount = res.data.count;

        this.showSortingIcon = this.sortingPropName + "_" + this.sortingOrder;
      }
    });
  }

  collapseRow(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  // checkAll(status) {
  //   // this.ischecked = status;
  //   this.routesList.forEach(e => {
  //     e.isChecked = !this.isAllChecked;
  //   });
  //   this.countCheckedRows();
  // }

  // selectRow(obj: any, event) {
  //   obj.isChecked = event.target.checked;
  //   console.log({
  //     name: obj.id,
  //     check: obj.isChecked
  //   })
  //   this.countCheckedRows();
  // }

  // countCheckedRows() {
  //   let checkedFlag = true;
  //   let counter = 0;
  //   this.routesList.forEach(e => {
  //     if (!e.isChecked) {
  //       checkedFlag = false;
  //     } else {
  //       counter++;
  //     }
  //   });
  //   this.isAllChecked = checkedFlag;
  //   return counter;
  // }


  sortList(params) {
    if (this.sortBy.direction == "asc") {
      this.sortBy.direction = "des";
      this.truckList.sort((a, b) => {
        if (isNaN(a[params]) && isNaN(b[params])) {
          if (a[params] < b[params]) {
            return -1;
          }

          if (a[params] == b[params]) {
            return 0;
          }

          if (a[params] > b[params]) {
            return 1;
          }
        } else {
          if (Number(a[params]) < Number(b[params])) {
            return -1;
          }

          if (Number(a[params]) == Number(b[params])) {
            return 0;
          }

          if (Number(a[params]) > Number(b[params])) {
            return 1;
          }
        }
      });
      return true;
    }
    if (this.sortBy.direction == "des") {
      this.sortBy.direction = "asc";
      this.truckList.sort((b, a) => {
        if (isNaN(a[params]) && isNaN(b[params])) {
          if (a[params] < b[params]) {
            return -1;
          }

          if (a[params] == b[params]) {
            return 0;
          }

          if (a[params] > b[params]) {
            return 1;
          }
        } else {
          if (Number(a[params]) < Number(b[params])) {
            return -1;
          }

          if (Number(a[params]) == Number(b[params])) {
            return 0;
          }

          if (Number(a[params]) > Number(b[params])) {
            return 1;
          }
        }
      });
      return true;
    }
  }

  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    //this.driverList = this.driverList.slice(this.startItem, this.endItem);
  }

  markerClickEvent(event, data) {
    this.shouldShowinfoWindow = data.truckMasterId;
    //this.shouldShowinfoWindow = true;
  }

  zoomChangeEvent(event) {
    if (event > 6) {
      console.log('zoom');
    } else {
      // this.shouldShowinfoWindow = false;
      this.cityViewmarker = [];
    }
  }

  // setMarker(rows) {
  //   rows.forEach(element => {
  //     console.log(element)
  //     let iconUrl = element.tripAlerts.length >0?'assets/img/redTruck.svg':'assets/img/greenTruck.svg';
  //     this.stateViewMarker.push(
  //       {
  //         latitude: element.latitude,
  //         longitude: element.longitude,
  //         iconUrl: iconUrl,
  //         driverName: element.driverName,
  //         startTrip: element.startTrip,
  //         endTrip: element.endTrip,
  //         truckId: element.truckId,
  //         truckMasterId: element.truckMasterId,
  //           }
  //     )
  //   });
  //   console.log("marker:", this.stateViewMarker)
  // }

  setMarker(trucks) {
    let body: any[] = []
    trucks.forEach(element => {
      let id = { truckId: element.truckId }
      body.push(id);
    });

    this.commonService.getData('post', '/v1/getTruckLocations', body).subscribe(res => {
      let resData: any[] = res ? res : [];
      if (resData.length > 0) {
        this.stateViewMarker = [];
        trucks.forEach((truck) => {
          resData.forEach((e) => {
            if (e.truckid.truckId == truck.truckId) {
              let isAlreadyPushed = this.stateViewMarker.find(x => x.truckId == truck.truckId);
              if (isAlreadyPushed) {
                return;
              }
              this.stateViewMarker.push(
                {
                  latitude: e.lat,
                  longitude: e.lng,
                  iconUrl: truck.tripAlerts.length > 0 ? 'assets/img/redTruck.svg' : 'assets/img/greenTruck.svg',
                  driverName: truck.driverName,
                  startTrip: truck.startTrip,
                  endTrip: truck.endTrip,
                  truckId: truck.truckId,
                  dailyTripId: truck.dailyTripId,
                  truckMasterId: truck.truckMasterId,
                  ambientTemp: truck.ambientTemp,
                  gatewayStatus: truck.gatewayStatus,
                  tripMasterId: truck.tripMasterId,
                  profile: truck.profile,
                  tripId: truck.tripId
                })
            }
          })
        });
        console.log("active trucks :", this.stateViewMarker);
      }
    });
  }

  loadPageData(pageNo) {
    // console.log(pageNo);
    this.currentPageIndex = pageNo;
    this.getTruckList(pageNo);
  }

  goToTruckView(list) {
    this.router.navigate(['/truck-view'], { queryParams: { truckID: list.truckMasterId, dailyTripId: list.dailyTripId, truckNumber: list.truckId } });
  }



  onSortTripList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.showSortingIcon = propertyName + "_" + sortOrder;
    this.getTruckList();
  }

  // clear search
  onClearSearch() {
    if (!this.search) {
      return;
    }
    this.search = "";
    this.getTruckList(0);
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }


  validateInput(event) {
    this.commonService.validateInput(event);
  }
}
