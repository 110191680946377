import { formatDate } from '@angular/common';
export class TripTrackerHistoryExport {
    tripId: any;
    DriverName: any;
    truckId: any;
    CoolerCount: any;
    routeId: any;
    Stop_not_available: any;
    date: any;
    status: any;

    constructor(
        tripId: any,
        userName: any,
        truckNo: any,
        CoolerCount: any,
        route: any,
        missedStop: any,
        tripStartDate: any,
        status: any,
    ) {
        this.tripId=tripId,
        this.DriverName=userName,
        this.truckId=truckNo,
        this.CoolerCount = CoolerCount,
        this.routeId = route;
        this.Stop_not_available = missedStop,
        this.date = tripStartDate,
        this.status=status
    }
}
export class TripTrackerHistoryExportMaker {
    static exportToExcel(obj: any) {
        let returnData = new TripTrackerHistoryExport(
            obj.tripNo,
            obj.userName,
            obj.truckNo,
            obj.coolerCount,
            obj.route,
            obj.missedStop,
            obj.tripStartDate ? formatDate(obj.tripStartDate,'MMM dd, yyyy','en-US'):'',
            obj.status,
        )
        return returnData;
    }
}
