import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/components/home/home.component';
import { CallbackComponent } from 'src/app/components/callback/callback.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { TruckDetailsComponent } from "./components/truck-details/truck-details.component";
import { ShpKpiCardsComponent } from './home/shp-kpi-cards/shp-kpi-cards.component';
import { AuthGuard } from './auth.guard';

import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { AlertConfigurationComponent } from 'src/app/components/alert-configuration/alert-configuration.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { RootOptimizationComponent } from "./components/root-optimization/root-optimization.component";
import { TruckViewComponent } from 'src/app/components/truck-view/truck-view.component';

import { AuthguardGuard } from "./services/authguard/authguard.guard";
import { TrucksComponent } from './components/trucks/trucks.component';
import { TripListComponent } from './components/trip-list/trip-list.component';
import { TripPlannerComponent } from './components/trip-planner/trip-planner.component';
import { DriverComponent } from './components/driver/driver/driver.component';
import { AllSensorsComponent } from './components/all-sensors/all-sensors.component';
import { SensorDetailsViewComponent } from './components/sensor-details-view/sensor-details-view.component';
import { CoolerListComponent } from './components/cooler-list/cooler-list.component';
import { RoutesComponent } from './components/routes/routes.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { DriverDetailsComponent } from './components/driver/driver-details/driver-details.component';
import { ActiveTripsComponent } from './components/active-trips/active-trips.component';
import { TripPlannerListComponent } from './components/trip-planner/trip-planner-list/trip-planner-list.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportsDetailsComponent } from './components/reports/reports-details/reports-details.component';
import { AlertsManagementComponent } from './components/alerts-management/alerts-management.component';
import { DriverTripHistoryComponent } from './components/driver/driver-trip-history/driver-trip-history.component';
import { CreateAlertComponent } from './components/alerts-management/create-alert/create-alert.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component' ;
import { AddTruckComponent } from './components/my-profile/add-truck/add-truck.component' ;
import { AddUsersComponent } from './components/alerts-management/add-users/add-users.component';
import { TripTrackerComponent } from './components/trip-tracker/trip-tracker.component';
import { UserNRoleComponent } from './components/user-n-roles/user-role/user-n-role.component';
import { ModifyAccessComponent } from './components/user-n-roles/modify-access/modify-access.component';
import { UserRoleDriverComponent } from './components/user-n-roles/user-role-driver/user-role-driver.component';
import { DashboardDetailsComponent } from './components/dashboard/dashboard-details/dashboard-details.component';
import { TripHistoryComponent } from './components/trip-tracker/trip-history/trip-history.component';
import { OutOfRangeReportComponent } from './components/out-of-range-report/out-of-range-report.component'

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'authorize', component: CallbackComponent, pathMatch: 'full'},
  {
    path: '', component: NavbarComponent,
  //   // canActivateChild : [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'truck-details', component: TruckDetailsComponent, canActivate: [AuthGuard] },
      { path: 'kpi-cards', component: ShpKpiCardsComponent, canActivate: [AuthGuard] },
      { path: 'alert-configuration', component: AlertConfigurationComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'route-optimization', component: RootOptimizationComponent, canActivate: [AuthGuard]},
      { path: 'trucks', component: TrucksComponent, canActivate: [AuthGuard] },
      { path: 'truck-view', component: TruckViewComponent, canActivate: [AuthGuard] },
      { path: 'trip-list', component: TripListComponent, canActivate: [AuthGuard] },
      { path: 'trip-planner', component: TripPlannerComponent, canActivate: [AuthGuard] },
      { path: 'drivers',component:DriverComponent, canActivate: [AuthGuard] },
      { path: 'all-sensors', component: AllSensorsComponent, canActivate: [AuthGuard] },
      { path: 'sensor-details/:id', component: SensorDetailsViewComponent, canActivate: [AuthGuard] },
      { path: 'truck-view', component: TruckViewComponent, canActivate: [AuthGuard] },
      { path: 'cooler-list', component: CoolerListComponent, canActivate: [AuthGuard] },
      { path: 'routes', component: RoutesComponent, canActivate: [AuthGuard] },
      { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuard] },
      { path: 'driver-details', component: DriverDetailsComponent, canActivate: [AuthGuard] },
      { path: 'active-trips', component: ActiveTripsComponent, canActivate: [AuthGuard] },
      { path: 'trip-planner-list', component: TripPlannerListComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'reports-details', component: ReportsDetailsComponent, canActivate: [AuthGuard] },
      { path: 'alerts-management', component: AlertsManagementComponent, canActivate: [AuthGuard] },
      { path: 'driver-trip-history', component: DriverTripHistoryComponent, canActivate: [AuthGuard] },
      { path: 'create-alert', component: CreateAlertComponent, canActivate: [AuthGuard] },
      { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
      { path: 'profile', component: MyProfileComponent, canActivate: [AuthGuard] },
      { path: 'add-truck', component: AddTruckComponent, canActivate: [AuthGuard] },
      { path: 'add-user', component: AddUsersComponent, canActivate: [AuthGuard] },
      { path: 'trip-tracker', component: TripTrackerComponent, canActivate: [AuthGuard] },
      { path: 'user-role', component: UserNRoleComponent, canActivate: [AuthGuard] },
      { path: 'modify-access', component: ModifyAccessComponent, canActivate: [AuthGuard] },
      { path: 'user-role-driver', component: UserRoleDriverComponent, canActivate: [AuthGuard] },
      { path: 'dashboard-details', component: DashboardDetailsComponent, canActivate: [AuthGuard] },
      { path: 'trip-history', component: TripHistoryComponent, canActivate: [AuthGuard] },
      { path: 'out-of-range-report', component: OutOfRangeReportComponent, canActivate: [AuthGuard] },

    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
