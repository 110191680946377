import { Component, OnInit, Output, ViewChild, Input } from '@angular/core';
import { Truck, TruckMaker } from '../truck.model';
import { EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-truck-list',
  templateUrl: './truck-list.component.html',
  styleUrls: ['./truck-list.component.scss']
})
export class TruckListComponent implements OnInit {
  truckArray: Array<Truck>;
  @Output('onTruckSelect') onTruckSelect = new EventEmitter();
  @Input() set selectedTruckId(newId: string) {
    this._selectedTruckId = newId;
  }
  private _selectedTruckId: any;;
  get selectedTruckId(): string {
    return this._selectedTruckId;
  }
  pageCounter = 0;
  trucksPerPage = 6;
  selectedTruck: any;
  trucks = [];
  truckStatus: any;
  totalTruckCount: any;
  disabledNextBtn: any;


  skids = [];
  constructor(
    private commonService: CommonService
  ) {
    
  }


  ngOnInit() {
    // Get Truck List
    this.getTrucksList();
  }

  paginateTruckList(change = 'next') {
    if (change == 'next' && (this.pageCounter+1) * this.trucksPerPage < this.totalTruckCount) {
      this.pageCounter++;
      this.getTrucksList(this.pageCounter, this.trucksPerPage);
      if ((this.pageCounter + 1) * this.trucksPerPage > this.totalTruckCount) {
        this.disabledNextBtn = true;
      }
    } else if (change == 'next' && (this.pageCounter + 1) * this.trucksPerPage == this.totalTruckCount) {
      this.disabledNextBtn = true;
    }
    else if (change == 'prev' && this.pageCounter > 0) {
      this.pageCounter--;
      this.getTrucksList(this.pageCounter, this.trucksPerPage);
      this.disabledNextBtn = false;
    }
    else return;

    //if (this.pageCounter < 1) {
    //  this.pageCounter = 1;
    //  return false;
    //}
    //if (this.pageCounter * this.trucksPerPage > this.totalTruckCount) {
    //  if (change == 'next') {
    //    this.pageCounter--;
    //  } else {
    //    this.pageCounter++;
    //  }
    //  return false;
    //}
    //this.getTrucksList(this.pageCounter, this.trucksPerPage)
  }

  selectTruck(truck) {
    this.selectedTruck = truck;
    this.onTruckSelect.emit(truck);
    this.selectedTruckId = truck.truckMasterId;
  }

  getTrucksList(pageNumber = 0, pageSize = this.trucksPerPage) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      truckStatus: this.truckStatus
    };
    this.truckArray = [];
    this.trucks = [];
    this.commonService.getData('get', '/v0/api/v1/trucks', body).subscribe(truckListResponse => {
      console.log(truckListResponse);
      this.totalTruckCount = truckListResponse.data.count;
      truckListResponse.data.rows.forEach(element => {
        this.trucks.push({
          id: element.customerFacilityId,
          name: element.truckId,
          skids: [],
          conditions: element.status,
          status: element.isActive,
          tripID: element.tripId,
          truckID: element.truckId,
          truckMasterId: element.truckMasterId
        });
      });
      console.log("trucks-before::", this.trucks);    
      this.truckArray = this.trucks.map(e => TruckMaker.createTruck(e));
    // console.log("trucks-before::", this.trucks);    
      console.log("trucks-af::", this.truckArray);    
    });
  }
  onFilterChange(eve) {
    this.truckStatus = eve.target.checked ? 'Available' : undefined;
      this.getTrucksList();
  }

}
