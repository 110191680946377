export class Driver {
  id: string;
  name: string;
  profilePic: any;
  conditions: string;
  constructor(
    id: string,
    name: string,
    profilePic: any,
    conditions: string
  ) {
    this.id = id;
    this.name = name;
    this.profilePic = profilePic;
    this.conditions = conditions;
  }
}

export class DriverMaker {
  static createEmpty() {
    return new Driver(null, null, null, null );
  }
  static clone(obj: Driver) {
    return new Driver(obj.id, obj.name, obj.profilePic, obj.conditions);
  }
  static createDriver(obj: any) {
    return new Driver(obj.id, obj.name, obj.profilePic, obj.conditions);
  }
}
