import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { ExportExcelService } from '../../../services/export-to-excel/export-exel.service';
import { DriverTripDetailsMaker, DriverTripDetails } from '../driver';
import { Subscription } from 'rxjs';
import { DriverListExportMaker } from './driver-list-export';


@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit, OnDestroy {
  @ViewChild('tabsetTripDetails') tabsetTripDetails: TabsetComponent;
  @ViewChild('tabsetTripLists') tabsetTripLists: TabsetComponent;


  constructor(
    private router: Router,
    private modalService: BsModalService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private exportExelService: ExportExcelService
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getDriversList();
    });
  }

  // driverList: any[]=[];

  refreshSubscription: Subscription;

  driverList: any;
  modalRef: BsModalRef;
  ischecked: boolean = false;
  isAllChecked = false;
  showSidebar = false;
  onclickAddbtn: boolean = false;
  isShowFilter = false;
  sortOrder: any;
  routeAssigned: string;
  dateOfJoiningfrom: any;
  formatedateOfJoiningfrom: any;
  dateOfJoiningto: any;
  formatedateOfJoiningto: any;
  search: any="";
  dailyTripId: any;
  // sidebar variable
  todaysTrips: any[] = [];
  upcomongTrips: any[] = [];
  driversId: any;
  driverDetailsById: any;//= { "userMasterId": 0, "image": "", "score": 0, "driverName": "", "truckId": "", "routeId": "", "trips": 0, "hoursOfService": "", "totalDistanceDriven": "" };
  showSidebarTripDetails: boolean = false;
  tableRowToCollapse = -1;
  stopDetailsData: any;
  stopTempDetailsData: any;
  tempHistory: any[] = [];
  stopCoolers: any[] = [];
  // endRecord= 4;
  showTempHistory = false;


  driverTripDetailsData: any;// = { truckId: '', origin: '', destination: '', status: '', alerts: '', coolers:''};
  driverTripDetailsData_door: any;
  driverTripDetailsData_truckStatus: any[] = [];
  driverTripDetailsData_cooler: any[] = [];
  driverTripDetailsData_alerts: any[] = [];
  driverTripDetailsData_ddlCooler: any[] = [];
  driverTripDetailsData_receiverDetails: any[] = [];
  ddlAssignRoute: any[] = [];
  plannedStop: any[] = [];
  unplannedStop: any[] = [];

  plannedCoolerData: any[] = [];
  unpalnnedCoolerData: any[] = [];

  plannedReceivers: any[] = [];
  unPlannedReceivers: any[] = [];


  //********Pagination********
  totaldriverCount: any;
  recordsPerPage = 10;
  currentPage = 1;
  page: number;
  returnedDriverList: string[];
  startItem = 0;
  endItem = 10;
  activePageNo = 0;
  selectedTripId: any;
  selectedRouteStopCount: any;
  selectedStopId: any;
  currentPageIndex = 0;


  ngOnInit() {
    this.getDriversList();
    this.getDDLAssignRoute();
  }

  getDriversList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = "onInit") {
    //if(this.dateOfJoiningfrom && this.dateOfJoiningto) {
    this.formatedateOfJoiningfrom = this.dateOfJoiningfrom ? formatDate(this.dateOfJoiningfrom, 'yyyy-MM-dd', 'en-US') : this.dateOfJoiningfrom;
    this.formatedateOfJoiningto = this.dateOfJoiningto ? formatDate(this.dateOfJoiningto, 'yyyy-MM-dd', 'en-US') : this.dateOfJoiningto;
    //}
    this.activePageNo = pageNumber;
    this.totaldriverCount = 0;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.search,
      fromDate: this.formatedateOfJoiningfrom,
      toDate: this.formatedateOfJoiningto,
      routeAssigned: this.routeAssigned == 'null' ? '' : this.routeAssigned, //? this.routeAssigned.toLowerCase() : this.routeAssigned,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder

    }
    this.commonService.getData('get', '/v0/api/v1/drivers', body).subscribe(driverListResponse => {
      this.totaldriverCount = driverListResponse.data.count;
      if (action == 'export') {
        let exportableData = driverListResponse && driverListResponse.data && driverListResponse.data.rows ? driverListResponse.data.rows.map(e => DriverListExportMaker.exportToExcel(e)):[];
        this.exportExelService.exportAsExcelFile(exportableData, 'Driver List');
      }
      this.driverList = driverListResponse.data.rows;
    });


  }

  driverDetails(list, driverDetailsPopup: TemplateRef<any>) {
    // this.modalRef = this.modalService.show(driverDetailsPopup, { class: "modal-lg" });
    this.showSidebar = true;
  }


  // checkAll(status) {
  //   this.ischecked = status;
  // }

  checkAll(status) {
    // this.ischecked = status;
    this.driverList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }

  selectRow(obj: any, event) {
    obj.isChecked = event.target.checked;
    console.log({
      name: obj.id,
      check: obj.isChecked
    })
    this.countCheckedRows();
    console.log(this.countCheckedRows());
  }

  countCheckedRows() {
    let checkedflag = true;
    let counter = 0;
    this.driverList.forEach(e => {
      if (!e.isChecked) {
        checkedflag = false;
        // return true;
      } else {
        counter++;
      }
    });

    this.isAllChecked = checkedflag;
    return counter;
  }
  //*******sorting sensor-List table *******/
  sortSensorlist(propType, prop, asc) {
    switch (propType) {
      case "int":
        this.driverList = this.driverList.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;
      default:
        this.driverList = this.driverList.sort(function (a, b) {
          if (asc) {
            return (a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0;
          } else {
            return (b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0;
          }
        });
        this.driverList;
    }
  }


  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    //this.driverList = this.driverList.slice(this.startItem, this.endItem);
  }
  onDriverlistClick(driver) {
    let params = {
      driverId: this.driversId,
      tripMasterId: driver.tripMasterId,
      dailyTripId: driver.dailyTripId == null ? '0' : driver.dailyTripId,
    }
    this.router.navigate(['/driver-details'], { queryParams: params });
  }
  openAddModal(addDriverModal) {
    this.modalRef = this.modalService.show(addDriverModal, { class: "modal-mdm modal-dialog-centered", backdrop: 'static' })
    this.onclickAddbtn = true;;
  }
  closeModal() {
    this.modalRef.hide();
    this.onclickAddbtn = false;
  }

  getStars(score) {
    let rating = 5 - Math.ceil(score);
    let stars = '';

    //yellow stars
    for (var i = 0; i < score; i++) {
      stars += '<img src="assets/img/yellow_star.svg" alt="" class="star-img">';
      // stars.push(
      //   '<img src="assets/img/yellow_star.svg" alt="" style="height: 9px;width: 10% !important;">'
      // );
    }
    //gray stars
    for (var j = 0; j < rating; j++) {
      stars += '<img src="assets/img/gray_star.svg" alt="" class="star-img">';
      // stars.push(
      //   '<img src="assets/img/gray_star.svg" alt="" style="height: 9px;width: 10% !important;">'
      // );
    }
    return stars;
  }
  selectedTruckId: any;
  getDriverTripDetails(driver) {
    this.driversId = driver.driversId;
    this.selectedTruckId = driver.truckId;
    this.showSidebar = true;
    this.getTodayTrips();
    this.getDriverTripDetailsById();
  }

  getDriverTripDetailsById() {
    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driversId).subscribe(driverDetailResponse => {
      this.driverDetailsById = driverDetailResponse.data[0];
    });
    this.getTodayTrips();
  }

  getTodayTrips(pageNumber = 0, pageSize = this.recordsPerPage) {
    this.tabsetTripLists.tabs[0].active = true;
    let dateToday = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');
  
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      status: "today",
      propertyName: this.sortingPropTripName,
      propertyValue: this.sortingOrder,
      currentDate: dateToday,

    };
    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driversId + '/trips', body).subscribe(driverDetailTodayResponse => {
      this.todaysTrips = driverDetailTodayResponse.data.rows;

    });
  }

  getUpcomingTrips(pageNumber = 0, pageSize = this.recordsPerPage) {

    let todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');
    // this.tabsetTripLists.tabs[0].active = true;

    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      status: "upcoming",
      propertyName: this.sortingPropTripName,
      propertyValue: this.sortingOrder,
      currentDate: todayDate
    };
    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driversId + '/trips', body).subscribe(driverDetailUpcomingResponse => {
      this.upcomongTrips = driverDetailUpcomingResponse.data.rows;
    });
  }

  goToHistoryTrips() {
    this.router.navigate(['/driver-trip-history'], { queryParams: { id: this.driversId, truckID: this.selectedTruckId } });
  }

  toggleFilter() {
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.routeAssigned = 'null';
  }

  resetFilter() {
    this.setDefaultFilterValues();
    this.dateOfJoiningfrom = "";
    this.dateOfJoiningto = "";
    this.getDriversList(0);
  }

  sortTodayslist(type, prop, asc) {
    this.todaysTrips = this.todaysTrips.sort(function (a, b) {
      if (asc) {
        return ((a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0);
      } else {
        return ((b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }
  sortupcomingtrips(type, prop, asc) {
    this.upcomongTrips = this.upcomongTrips.sort(function (a, b) {
      if (asc) {
        return ((a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0);
      } else {
        return ((b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }
  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getDriversList(pageNo);
  }


  collapseRowAlert(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  collapseRow(i, item) {
    this.showTempHistory = false;
    if (this.tableRowToCollapse != i) {
      this.getStopTempHistory(item);
    }
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  onExportToExcel() {
    if (!this.totaldriverCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getDriversList(0, this.totaldriverCount, 'export')
  }


  goToTripDetails(dailyTripId, tripNumber) {
    this.tableRowToCollapse = -1;
    this.tabsetTripDetails.tabs[0].active = true;
    this.dailyTripId = dailyTripId;

    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driversId + '/trips/' + dailyTripId).subscribe(resp => {
      if (resp.data) {
        this.selectedTripId = tripNumber;
        this.driverTripDetailsData = resp.data.tripDetails;
        this.driverTripDetailsData_door = resp["data"]["door"];
        this.driverTripDetailsData_truckStatus = resp["data"]["truckStatus"];
        this.driverTripDetailsData_cooler = resp["data"]["cooler"];
        this.plannedCoolerData = this.driverTripDetailsData_cooler.filter(x => x.coolerStatusType != 'Received');
        this.unpalnnedCoolerData = this.driverTripDetailsData_cooler.filter(x => x.coolerStatusType == 'Received');


        this.driverTripDetailsData_alerts = resp["data"]["alerts"];
        this.driverTripDetailsData_ddlCooler = resp["data"]["routeStops"];
        this.driverTripDetailsData_receiverDetails = resp && resp["data"] && resp["data"]["receiverDetails"] ? resp["data"]["receiverDetails"] : [];
        this.plannedReceivers = this.driverTripDetailsData_receiverDetails.filter(x => x.stopStatus != "Received")
        this.unPlannedReceivers = this.driverTripDetailsData_receiverDetails.filter(x => x.stopStatus == "Received");

        this.stopList = this.driverTripDetailsData_ddlCooler.length > 0 && this.driverTripDetailsData_ddlCooler[0].stops ? this.driverTripDetailsData_ddlCooler[0].stops : [];
        this.unplannedStop = this.stopList.filter(x => x.stopType == 'Received');
        this.plannedStop = this.stopList.filter(x => x.stopType != 'Received');
        // this.selectedCoolerType = this.driverTripDetailsData_ddlCooler && this.driverTripDetailsData_ddlCooler.length > 0 ? this.driverTripDetailsData_ddlCooler[0].coolerType : [];
        // this.selectedCoolerId = this.driverTripDetailsData_ddlCooler && this.driverTripDetailsData_ddlCooler.length > 0 ? this.driverTripDetailsData_ddlCooler[0].coolerId : [];
        this.selectedRouteStopCount = this.driverTripDetailsData_ddlCooler.length;
      }
    });
  }
  selectedCoolerId: any;
  selectedCoolerType: any;
  stopList: any[] = [];
  onCoolerChange(event) {
    let selectedCooler = event.target.value;
    let stopList = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler);
    this.stopList = stopList && stopList.stops ? stopList.stops : [];
    this.plannedStop = this.stopList.filter(x => x.stopType != 'Received');
    this.unplannedStop = this.stopList.filter(x => x.stopType == 'Received');
    this.selectedCoolerType = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).coolerType;
    this.selectedCoolerId = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).coolerId;
    this.selectedRouteStopCount = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).routeStopCount;
  }
  getDDLAssignRoute() {
    this.commonService.getData('get', '/v0/api/v1/drivers-assigned-routes').subscribe(res => {
      this.ddlAssignRoute = res.data;
    }
    );
  }


  sortingPropName: string = "name";
  sortingOrder: string = "ASC";
  showSortingIcon: any;
  onSortDriverList(propertyName, sortOrder) {

    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;

    this.showSortingIcon = propertyName + "_" + sortOrder;
    this.getDriversList();
  }

  sortingPropTripName: string = "dateTime";
  onSortTodayTripList(propertyName, sortOrder){
    this.sortingPropTripName = propertyName;
    this.sortingOrder = sortOrder;

    this.showSortingIcon = propertyName + "_" + sortOrder;
    this.getTodayTrips();
  }

  onSortUpcomingTripList(propertyName, sortOrder){
    this.sortingPropTripName = propertyName;
    this.sortingOrder = sortOrder;

    this.showSortingIcon = propertyName + "_" + sortOrder;
    this.getUpcomingTrips();
  }

  //clear Search
  onClearSearch() {
    if(!this.search){
      return;
    }
    this.search = "";
    this.getDriversList(0);
  }

  getStopsDetails(dailyTripStopId) {
    this.tableRowToCollapse = -1;
    this.selectedStopId = dailyTripStopId;
    this.commonService.getData('get', '/v0/api/v1/daily-trip/route-stop-coolers/' + this.dailyTripId + '/' + dailyTripStopId + '/details').subscribe(res => {
      this.stopDetailsData = res;
      this.stopCoolers = this.stopDetailsData.coolerData;
    }
    );

  }

  onStopTabSelect() {
    if (this.driverTripDetailsData_ddlCooler.length > 0) {
      this.selectedStopId = this.driverTripDetailsData_ddlCooler[0].dailyTripStopId;
      this.getStopsDetails(this.selectedStopId);
    }
  }

  getStopTempHistory(item) {
    this.commonService.getData('get', '/v0/api/v1/trucks/route-stop-coolers/' + this.dailyTripId + '/' + item.dailyTripCoolerId + '/' + this.selectedStopId + '/details').subscribe(res => {
      this.stopTempDetailsData = res;
      this.tempHistory = this.stopTempDetailsData.historyData;
    }
    );
  }

  ngOnDestroy(){
    this.refreshSubscription.unsubscribe();
  }
 
  onCloseSidebar(){
    this.sortingPropTripName = "dateTime";
    this.sortingOrder = "ASC";
  }

  validateInput(event) {
    console.log("input");
    this.commonService.validateInput(event);
  }
}
