// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://shipcom-api-dev.azure-api.net",
  // azureUrl: "https://shipcom-api-dev.azure-api.net"
  version: "2.0.19",
  auth0: {
    domain: "shipcom-dev.auth0.com",
    client_id: "qPvtwZhc9Wj3BdWkToCuZ1pOVVZDi4eZ",
    redirect_uri: `${window.location.origin}`,
    audience: "https://iotdemo.shipcomwireless.com:12307/api/v1/"
  },
  report_urls: 
    {
      dashboard: {
        TripsDashboard: {
          url: 'https://app.powerbi.com/reportEmbed?reportId=f64a5195-96c0-46f3-84a0-20fd2189bd1b&groupId=d57c02f0-13eb-421d-ae0d-9da151dfccd9',
          reportId: 'f64a5195-96c0-46f3-84a0-20fd2189bd1b'
        },
        AlertsDashboard: {
          url: 'https://app.powerbi.com/reportEmbed?reportId=23e98b31-dca6-4985-9f4f-cf1e305a2189&groupId=d57c02f0-13eb-421d-ae0d-9da151dfccd9', 
          reportId: '23e98b31-dca6-4985-9f4f-cf1e305a2189'
        },
        SensorAnalyticsDashboard: {
          url: 'https://app.powerbi.com/reportEmbed?reportId=d25dfe6f-1f1b-4c54-bcdc-f7927607011a&groupId=d57c02f0-13eb-421d-ae0d-9da151dfccd9',
          reportId: 'd25dfe6f-1f1b-4c54-bcdc-f7927607011a'
        }
    },
      reports: {
        TripsReport: {
          url: 'https://app.powerbi.com/reportEmbed?reportId=fb2c7219-8bc8-400c-84fc-ee0c82df047b&groupId=d57c02f0-13eb-421d-ae0d-9da151dfccd9',
          reportId: 'fb2c7219-8bc8-400c-84fc-ee0c82df047b'
        },
        AlertsReport: {
          url: 'https://app.powerbi.com/reportEmbed?reportId=a72cda6c-7b62-4d51-a424-6df8c65b472b&groupId=d57c02f0-13eb-421d-ae0d-9da151dfccd9',
          reportId: 'a72cda6c-7b62-4d51-a424-6df8c65b472b'
        },
        SensorHistoryReport: {
          url: 'https://app.powerbi.com/reportEmbed?reportId=79fb47c4-756b-491a-9349-fd5377e34f89&groupId=d57c02f0-13eb-421d-ae0d-9da151dfccd9',
          reportId: '79fb47c4-756b-491a-9349-fd5377e34f89'
        }
    }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
