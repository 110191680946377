import { Component, OnInit, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap';
import { CommonService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-trip-tracker',
  templateUrl: './trip-tracker.component.html',
  styleUrls: ['./trip-tracker.component.scss']
})
export class TripTrackerComponent implements OnInit, OnDestroy {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @ViewChild('RefCoolerContent') RefCoolerContent: ElementRef;
  @ViewChild('frozenCoolerContent') frozenCoolerContent: ElementRef;
  @ViewChild('ambientCoolerContent') ambientCoolerContent: ElementRef;
  @ViewChild('tabsetTripDetails') tabsetTripDetails: TabsetComponent;


  refreshSubscription: Subscription;

  isShowFilter: boolean = false;
  tripPlannedList = [];
  tripCompletedList = [];
  tripInProgressList = [];
  tripTrackerInProgressList = [];
  tripTrackerPlannedList = [];
  tripTrackerCompletedList = [];
  ddlRoutesList: any[] = [];
  status: any;
  modalRef: BsModalRef;
  currentId: any;
  showSidebar: boolean = false;
  showDetailsSidebar: boolean = false;
  showEditSidebar: boolean = false;
  showCoolerSidbar: boolean = false;
  showMoreStops: boolean = false;
  viewAllRefrigeratedCooler = false;
  viewAllFrozenCooler = false;
  viewAllAmbientCooler = false;
  viewAllTripCooler = false;
  tableRowToCollapse = -1;
  recordsPerPage = 100;
  driverMasterId: any;
  dailyTripsId: any;
  isChecked: boolean = false;
  selectedTripId: any;
  selectedRouteStopCount: any;
  search: any = "";
  searchDriverKey: string = '';
  ddlAssignRoute: any[] = [];
  allDriversList: any[] = [];
  selectedDriver: any[] = [];
  ddlTruckList: any[] = [];
  selectedTruck: any[] = [];
  tripCoolerList: any[] = [];
  stopsCount: any;

  SelectedRouteDetails: any;
  origin: any;
  origin_onEdit: any;
  destination: any;
  destination_onEdit: any;
  routeStopList: any[] = [];
  temp_RemovedStopsArray: any[] = [];

  selectedCoolerId: any;
  selectedCoolerType: any;
  stopList: any[] = [];

  plannedStopData: any[] = [];
  unpalnnedStopData: any[] = [];
  unpalnnedCoolerData: any[] = [];
  plannedCoolerData: any[] = [];
  unpalnnedReceiverData: any[] = [];
  plannedReceiverData: any[] = [];
  genericProfilePic = "assets/img/generic-user-icon.png";
  searchCooler = "";
  selectedRefCoolercount = 0;
  selectedFrozenCoolercount = 0;
  selectedAmbientCoolercount = 0;
  tripTrackerList = []

  driverList = []

  alertList = []

  tripStopsDetails = []

  tripReceiverDetails = []

  driverTripDetailsData: any;// = { truckId: '', origin: '', destination: '', status: '', alerts: '', coolers:''};
  driverTripDetailsData_door: any;
  driverTripDetailsData_truckStatus: any[] = [];
  driverTripDetailsData_cooler: any[] = [];
  driverTripDetailsData_alerts: any[] = [];
  driverTripDetailsData_ddlCooler: any[] = [];
  driverTripDetailsData_receiverDetails: any[] = [];
  driverTripDetailsData_coolerDetails: any[] = [];
  driverTripDetailsData_Coolers: any[] = [{ status: 'Unloaded' }, { status: 'Loaded' }, { status: 'Transferred' },];


  routeId: any;
  coolersType: string;
  coolersValue: any;
  alertsType: string
  alertsValue: any;
  statusfilter: string;
  fromdate: any;
  todate: any;
  selectedTab = "todayTab";

  selectedStopId: any;
  stopDetailsData: any;
  stopTempDetailsData: any;
  stopCoolers: any[] = [];
  tempHistory: any[] = [];
  dailyTripId: any;

  tripTomInProgressList:any[]=[];
  tripTomCompletedList:any[]=[];
  showTempHistory = false;
  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private detectChange: ChangeDetectorRef
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getAllDrivers();
      this.getTruckList();
      this.getRoutesList();
      this.getTodayTab();
    });
  }

  ngOnInit() {
    this.getDDLAssignRoute();
    this.staticTabs.tabs[1].active = true;
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.status = params["params"].status;
      if (this.status == "Completed") {
        // this.getHistoryTab();
        // this.staticTabs.tabs[0].active = true;
        // this.staticTabs.tabs[1].active = false;
      }
    });
    this.getAllDrivers();
    this.getTruckList();
    this.getRoutesList();
    this.getTodayTab();
  }

  getHistoryTab() {
    this.getDDLAssignRoute();
    this.tripTrackerCompletedList = [];
    this.tripInProgressList = [];
    this.tripTrackerPlannedList = [];
    let todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');


    let body = {
      day: 'Yesterday',
      search: this.search.trim() === '' ? null : this.search.trim(),
      routeId: this.routeId == 'null' ? '' : this.routeId,
      coolersType: this.coolersType == 'null' ? '' : this.coolersType,
      coolers: this.coolersValue,
      alertsType: this.alertsType == 'null' ? '' : this.alertsType,
      alerts: this.alertsValue,
      from: this.fromdate ? formatDate(this.fromdate, 'yyyy-MM-dd', 'en-US') : this.fromdate,
      to: this.todate ? formatDate(this.todate, 'yyyy-MM-dd', 'en-US') : this.todate,
      currentDate: todayDate
    }
    this.commonService.getData('get', '/v0/api/v1/trip-tracking', body).subscribe(res => {
      if (res.data.Completed) {
        this.tripTrackerCompletedList = res.data.Completed
      } if (res.data.InProgress) {
        this.tripInProgressList = res.data.InProgress
      }
      if (res.data.Planned) {
        this.tripTrackerPlannedList = res.data.Planned
      }

      if (this.tripTrackerPlannedList) {
        this.tripPlannedList = this.tripTrackerPlannedList;
      }

      if (this.tripTrackerCompletedList) {
        this.tripCompletedList = this.tripTrackerCompletedList;
      }
    })
  }

  getTodayTab() {
    this.getDDLAssignRoute();
    let todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');

    let body = {
      day: 'Today',
      status: this.status,
      search: this.search.trim() === '' ? null : this.search.trim(),
      routeId: this.routeId == 'null' ? '' : this.routeId,
      coolersType: this.coolersType == 'null' ? '' : this.coolersType,
      coolers: this.coolersValue,
      alertsType: this.alertsType == 'null' ? '' : this.alertsType,
      alerts: this.alertsValue,
      from: this.fromdate ? formatDate(this.fromdate, 'yyyy-MM-dd', 'en-US') : this.fromdate,
      to: this.todate ? formatDate(this.todate, 'yyyy-MM-dd', 'en-US') : this.todate,
      currentDate: todayDate
    }

    this.tripTrackerInProgressList = [];
    this.tripTrackerCompletedList = [];
    this.tripTrackerPlannedList = [];
    if (this.status == 'InProgress') {
      this.commonService.getData('get', '/v0/api/v1/trip-tracking', body).subscribe(res => {
        if (res.data.InProgress) {
          this.tripTrackerInProgressList = res.data.InProgress;
        } if (res.data.Completed) {
          this.tripTrackerCompletedList = res.data.Completed
        } if (res.data.Planned) {
          this.tripTrackerPlannedList = res.data.Planned
        }

        if (this.tripTrackerCompletedList || this.tripTrackerInProgressList || this.tripTrackerPlannedList) {
          this.tripPlannedList = this.tripTrackerPlannedList;
          this.tripInProgressList = this.tripTrackerInProgressList;
          this.tripCompletedList = this.tripTrackerCompletedList;
        }
      })
    } else if (this.status == 'Completed') {
      this.commonService.getData('get', '/v0/api/v1/trip-tracking', body).subscribe(res => {
        if (res.data.InProgress) {
          this.tripTrackerInProgressList = res.data.InProgress;
        } if (res.data.Completed) {
          this.tripTrackerCompletedList = res.data.Completed
        } if (res.data.Planned) {
          this.tripTrackerPlannedList = res.data.Planned
        }

        if (this.tripTrackerCompletedList || this.tripTrackerInProgressList || this.tripTrackerPlannedList) {
          this.tripPlannedList = this.tripTrackerPlannedList;
          this.tripInProgressList = this.tripTrackerInProgressList;
          this.tripCompletedList = this.tripTrackerCompletedList;
        }
      })
    }
    else {
      this.commonService.getData('get', '/v0/api/v1/trip-tracking', body).subscribe(res => {
        if (res.data.InProgress) {
          this.tripTrackerInProgressList = res.data.InProgress;
        } if (res.data.Completed) {
          this.tripTrackerCompletedList = res.data.Completed
        } if (res.data.Planned) {
          this.tripTrackerPlannedList = res.data.Planned
        }

        if (this.tripTrackerCompletedList || this.tripTrackerInProgressList || this.tripTrackerPlannedList) {
          this.tripPlannedList = this.tripTrackerPlannedList;
          this.tripInProgressList = this.tripTrackerInProgressList;
          this.tripCompletedList = this.tripTrackerCompletedList;
        }
      })
    }
  }

  getTomorrowTab() {
    this.getDDLAssignRoute();
    this.tripTrackerPlannedList = [];
    this.tripTomInProgressList = [];
    this.tripTomCompletedList = [];
    let todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');

    let body = {
      day: 'Tomorrow',
      search: this.search.trim() === '' ? null : this.search.trim(),
      routeId: this.routeId == 'null' ? '' : this.routeId,
      coolersType: this.coolersType == 'null' ? '' : this.coolersType,
      coolers: this.coolersValue,
      alertsType: this.alertsType == 'null' ? '' : this.alertsType,
      alerts: this.alertsValue,
      from: this.fromdate ? formatDate(this.fromdate, 'yyyy-MM-dd', 'en-US') : this.fromdate,
      to: this.todate ? formatDate(this.todate, 'yyyy-MM-dd', 'en-US') : this.todate,
      currentDate: todayDate
    }
    this.commonService.getData('get', '/v0/api/v1/trip-tracking', body).subscribe(res => {
      console.log(res);
  
      if (res.data.Planned) {
        this.tripTrackerPlannedList = res.data.Planned;
      }
      if(res.data && res.data.InProgress){
        this.tripTomInProgressList = res.data.InProgress;
      }
      if(res.data && res.data.Completed){
        this.tripTomCompletedList = res.data.Completed;
      }

      if (this.tripTrackerPlannedList) {
        this.tripPlannedList = this.tripTrackerPlannedList;
      }
    })

  }

  getDriversList(pageNumber = 0, pageSize = this.recordsPerPage, id) {

    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.searchDriverKey,

    }
    this.commonService.getData('get', '/v0/api/v1/drivers', body).subscribe(driverListResponse => {
      this.driverList = driverListResponse.data.rows;
      this.currentId = id;
    });
  }

  isChangeDriverModal(changeDriverModal: TemplateRef<any>, id, dailyTripId) {
    this.modalRef = this.modalService.show(changeDriverModal, { class: 'modal-dialog-centered modal-mdm', backdrop: 'static' });
    this.getDriversList(0, this.recordsPerPage, id);
    this.dailyTripsId = dailyTripId;
  }
  tripData: any;
  shouldShowSidebar(data, target) {
    if (target == 'planned') {
      this.getPlannedTripDetails(data);
      this.showSidebar = false;
      this.showDetailsSidebar = true;
      this.showMoreStops = false;
      return;
    } else {
      this.showDetailsSidebar = false;
      this.tableRowToCollapse = -1;
      this.tabsetTripDetails.tabs[0].active = true;
      this.showSidebar = true;
    }

    //this.tripData = data;
    this.dailyTripId = data.dailyTripId;

    this.commonService.getData('get', '/v0/api/v1/drivers/' + data.driverId + '/trips/' + data.dailyTripId).subscribe(resp => {
      if (resp.data) {
        this.selectedTripId = data.tripNumber;
        this.driverTripDetailsData = resp.data.tripDetails;
        this.driverTripDetailsData_door = resp["data"]["door"];
        this.driverTripDetailsData_truckStatus = resp["data"]["truckStatus"];
        this.driverTripDetailsData_cooler = resp["data"]["cooler"];
        this.driverTripDetailsData_alerts = resp["data"]["alerts"];
        this.driverTripDetailsData_ddlCooler = resp["data"]["routeStops"];
        this.driverTripDetailsData_receiverDetails = resp["data"]["receiverDetails"];
        this.driverTripDetailsData_coolerDetails = resp["data"]["cooler"];
        this.stopList = this.driverTripDetailsData_ddlCooler;
        this.stopsCount = this.driverTripDetailsData_ddlCooler;

        // this.selectedCoolerType = this.driverTripDetailsData_ddlCooler[0].coolerType;
        // this.selectedCoolerId = this.driverTripDetailsData_ddlCooler[0].coolerId;
        this.selectedRouteStopCount = this.driverTripDetailsData_ddlCooler.length;

        // coolers planned and unplanned 
        let unplannedCoolerStatus = [];
        let plannedCoolerStatus = [];
        this.driverTripDetailsData_coolerDetails.forEach(item => {
          if (item.coolerStatusType == 'Received') {
            unplannedCoolerStatus.push(item);
          } else {
            plannedCoolerStatus.push(item);
          }
        })
        this.unpalnnedCoolerData = unplannedCoolerStatus;
        this.plannedCoolerData = plannedCoolerStatus;

        // stops planned and unplanned 
        let unplannedStopsStatus = [];
        let plannedStopsStatus = [];
        this.stopList.forEach(item => {
          if (item.stopType == 'Received') {
            unplannedStopsStatus.push(item);
          } else {
            plannedStopsStatus.push(item);
          }
        })
        this.unpalnnedStopData = unplannedStopsStatus;
        this.plannedStopData = plannedStopsStatus;

        // Receiver tab planned and unplanned 
        let unplannedReceiverStatus = [];
        let plannedReceiverStatus = [];
        this.unpalnnedReceiverData = unplannedReceiverStatus;
        this.plannedReceiverData = plannedReceiverStatus;
      }
    });
    this.getDDLAssignRoute();
  }


  onCoolerChange(event) {
    let selectedCooler = event.target.value;
    this.stopList = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).stops;
    this.selectedCoolerType = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).coolerType;
    this.selectedCoolerId = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).coolerId;
    this.selectedRouteStopCount = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).routeStopCount;
  }

  collapseRow(i, item) {
    this.showTempHistory = false;
    if (this.tableRowToCollapse != i) {
      this.getStopTempHistory(item);
    }
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }
  collapseAlertRow(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }
  changeDriver(event, id) {
    if (event.target.checked) {
      this.driverMasterId = id;
    }
  }

  changeDriverBtn() {
    let body = {
      "dailyTripId": this.dailyTripsId,
      "driverMasterId": this.driverMasterId
    }
    this.commonService.getData('put', '/v0/api/v1/trip-tracking/change-driver', body).subscribe(res => {
      if (res.data.status == 1) {
        this.getTodayTab();
        // this.getTomorrowTab();
      }
    })
  }
  getDDLAssignRoute() {
    this.commonService.getData('get', '/v0/api/v1/drivers-assigned-routes').subscribe(res => {
      this.ddlAssignRoute = res.data;
    }
    );
  }

  // onRouteFilterChange(e) {
  //   this.routeId = e.target.value;
  // }
  onClearFilter() {
    this.setDefaultFilterValues();
    this.onApplyFilter();
  }
  onApplyFilter() {
    if (this.selectedTab == "todayTab") {
      this.getTodayTab();
    }
    if (this.selectedTab == "tomarrowTab") {
      this.getTomorrowTab();
    }
    if (this.selectedTab == "historyTab") {
      this.getHistoryTab();
    }
  }
  onSearchFilter() {
    this.onApplyFilter();
  }
  searchDriver() {
    this.getDriversList(0, this.recordsPerPage, this.currentId);
  }
  getAllDrivers(pageNumber = 0, pageSize = 1000) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
    }
    this.commonService.getData('get', '/v0/api/v1/drivers', body).subscribe(r => {
      this.allDriversList = r.data.rows;
    });
  }
  sdId: any;
  selectedDriver_onEdit
  onEditDriver(event) {
    this.selectedDriver_onEdit = event.driversId;
    this.sdId = event.driversId;
    this.selectedDriver = []
    this.selectedDriver.push(event);
  }

  //trucks
  getTruckList() {
    let body = {
      pageIndex: 0,
      pageSize: 1000,
    };
    this.commonService.getData('get', '/v0/api/v1/trucks', body).subscribe(res => {
      this.ddlTruckList = res.data.rows;
    });
  }
  stId: any;
  selectedTruck_onEdit: any;
  onEditTruck(event) {
    this.selectedTruck_onEdit = event;
    this.stId = event.truckMasterId;
    this.selectedTruck = []
    this.selectedTruck.push(event);
  }

  getRoutesList() {
    let body = {
      pageIndex: 0,
      pageSize: 1000,
    };
    this.commonService.getData('get', '/v0/api/v1/routes', body).subscribe(res => {
      this.ddlRoutesList = res.data.rows;
    })
  }
  onRouteChange(e) {
    this.routeId_onEdit = e.target.value;
    this.showStopsList(e.target.value);
  }

  showStopsList(routeMasterId) {
    this.commonService.getData('get', '/v0/api/v1/routes/' + routeMasterId).subscribe(res => {
      // this.routeId = res.data.routeId;

      this.SelectedRouteDetails = res.data;
      this.origin = res.data.origin;
      this.origin_onEdit = res.data.origin;
      this.destination = res.data.destination;
      this.destination_onEdit = res.data.destination;
      this.routeStopList = res.data.routestops.map(e => {
        return {
          routeStopId: e.routeStopsId,
          stopName: e.stop,
          stopOrderId: e.orderId,
        }
      });

      this.addStopsArray = res.data.routestops.map(e => {
        return {
          routeStopId: e.routeStopsId,
          stopName: e.stop,
          orderId: e.orderId,
        }
      });
      this.removedStops = this.temp_RemovedStopsArray;
      this.detectChange.detectChanges();
    })
  }


  handleAddressChange(event) {
    this.newStopName = event.name + " (" + event.formatted_address + ")";
    this.stopsLatitude = event.geometry.location.lat();
    this.stopsLongitude = event.geometry.location.lng();
  }

  handleAddressOrigin(event) {
    this.origin = event.name + " (" + event.formatted_address + ")";
    this.origin_onEdit = event.formatted_address;
    this.originLatitude = event.geometry.location.lat();
    this.originLongitude = event.geometry.location.lng();
  }

  handleAddressDestination(event) {
    this.destination = event.name + " (" + event.formatted_address + ")";
    this.destination_onEdit = event.formatted_address;
    this.destLatitude = event.geometry.location.lat();
    this.destLongitude = event.geometry.location.lng();
  }

  stopsLatitude: any;
  stopsLongitude: any;
  originLatitude: any;
  originLongitude: any;
  destLatitude: any;
  destLongitude: any;

  newStopName: string = '';
  orderNumber: any = 0;
  addStops() {
    if (this.newStopName == undefined || this.newStopName == "") {
      this.commonService.showToast('Please enter valid address', 0)
      return;
    }
    if (this.routeStopList.filter(res => res.stopOrderId).length > 0) {
      // let stopss = this.tripData.stops.filter(res => res.stopOrderId > 0 || res.orderId>0);
      this.orderNumber = Math.max(...this.routeStopList.map(res => res.stopOrderId || res.orderId)) + 1;

    } else {
      this.orderNumber = this.orderNumber + 1;
    }

    let body = {
      "stopName": this.newStopName,
      "orderId": this.orderNumber,
      "latitude": this.stopsLatitude,
      "longitude": this.stopsLongitude
    }
    this.routeStopList.push(body);
    this.addStopsArray.push(body);

    this.newStopName = "";
    this.stopsLatitude = undefined;
    this.detectChange.detectChanges();
  }
  removeStops(stoplist) {
    this.routeStopList.pop();
    this.addStopsArray.pop();
    if (stoplist && stoplist.dailyTripStopId) {
      this.removedStops.push(stoplist.dailyTripStopId);
    }
    this.detectChange.detectChanges();
  }

  //Getting Coolers list
  RefrigeratedCoolers: any[] = [];
  FrozenCoolers: any[] = [];
  AmbientCoolers: any[] = [];
  addCoolers: any[] = [];
  removedCoolers: any[] = [];
  addStopsArray: any[] = [];
  removedStops: any[] = [];
  routeId_onEdit: any;

  getCoolersList(pageNumber = 0, pageSize = 1000) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
    };
    this.commonService.getData('get', '/v0/api/v1/coolers', body).subscribe(resp => {
      let totalCoolers: any[] = resp.data.rows;
      totalCoolers.forEach((item, i, obj) => {
        let cooler: any[] = this.tripCoolerList.filter(x => x.coolerId == item.coolerId);
        if (cooler && cooler.length > 0) {
          item["isChecked"] = true;
        }
        else { item["isChecked"] = false; }
      })
      this.RefrigeratedCoolers = totalCoolers.filter(x => x.coolerType == "Refrigerated");
      this.FrozenCoolers = totalCoolers.filter(x => x.coolerType == "Frozen");
      this.AmbientCoolers = totalCoolers.filter(x => x.coolerType == "Ambient");

      this.selectedRefCoolercount = this.RefrigeratedCoolers.filter(e => e.isChecked).length;
      this.selectedFrozenCoolercount = this.FrozenCoolers.filter(e => e.isChecked).length;
      this.selectedAmbientCoolercount = this.AmbientCoolers.filter(e => e.isChecked).length;
    })
  }
  onAddNewCooler() {
    this.getCoolersList();
  }
  onSelectCooler(item, event, coolerType) {
    if (event.target.checked) {
      this.addCoolers.push(item.assetMasterId);
      this.tripCoolerList.push(item);
      this.getSelectedCoolerCount(item, true, coolerType);
    } else {
      this.addCoolers.forEach((i, index, obj) => {
        if (item.coolerId == i.coolerId) {
          obj.splice(index, 1)
        }
      });
      this.tripCoolerList.forEach((j, indx, ob) => {
        if (item.coolerId == j.coolerId) {
          ob.splice(indx, 1)
        }
      });
      this.getSelectedCoolerCount(item, false, coolerType);
    }
  }
  getSelectedCoolerCount(cooler, isChecked, coolerType) {
    if (coolerType == "refrigerated") {
      this.RefrigeratedCoolers.forEach((el) => {
        if (el.assetMasterId == cooler.assetMasterId) {
          el["isChecked"] = isChecked;
        }
      })
    }
    else if (coolerType == "frozen") {
      this.FrozenCoolers.forEach((el) => {
        if (el.assetMasterId == cooler.assetMasterId) {
          el["isChecked"] = isChecked;
        }
      })
    }
    else if (coolerType == "ambient") {
      this.AmbientCoolers.forEach((el) => {
        if (el.assetMasterId == cooler.assetMasterId) {
          el["isChecked"] = isChecked;
        }
      })
    }
    this.selectedRefCoolercount = this.RefrigeratedCoolers.filter(e => e.isChecked).length;
    this.selectedFrozenCoolercount = this.FrozenCoolers.filter(e => e.isChecked).length;
    this.selectedAmbientCoolercount = this.AmbientCoolers.filter(e => e.isChecked).length;
  }
  onDeleteCooler(item) {
    if (item.dailyTripCoolerId) {
      this.removedCoolers.push(item.dailyTripCoolerId);
      this.tripCoolerList.forEach((i, index, obj) => {
        if (item.dailyTripCoolerId == i.dailyTripCoolerId) {
          obj.splice(index, 1)
        }
      });
    } else {
      this.tripCoolerList.forEach((j, indx, ob) => {
        if (item.assetMasterId == j.assetMasterId) {
          ob.splice(indx, 1)
        }
      });
    }
  }
  deleteNewlyAddedCooler() {
    this.searchCooler = '';
    this.addCoolers = [];
    this.tripCoolerList = this.tripCoolerList.filter(x => x.dailyTripCoolerId);
  }
  refLeft = 0;
  refRight = 0
  frozLeft = 0;
  frozRight = 0
  ambLeft = 0;
  ambRight = 0
  //cooler scroll 
  scrollLeft(target) {
    if (target == 'ref') {
      this.RefCoolerContent.nativeElement.scrollLeft -= 150;
      this.refLeft = this.RefCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'frozen') {
      this.frozenCoolerContent.nativeElement.scrollLeft -= 150;
      this.frozLeft = this.frozenCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'ambient') {
      this.ambientCoolerContent.nativeElement.scrollLeft -= 150;
      this.ambLeft = this.ambientCoolerContent.nativeElement.scrollLeft;
    }
  }
  scrollRight(target) {
    if (target == 'ref') {
      this.RefCoolerContent.nativeElement.scrollLeft += 150;
      this.refRight = this.RefCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'frozen') {
      this.frozenCoolerContent.nativeElement.scrollLeft += 150;
      this.frozRight = this.frozenCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'ambient') {
      this.ambientCoolerContent.nativeElement.scrollLeft += 150;
      this.ambRight = this.ambientCoolerContent.nativeElement.scrollLeft;
    }
  }
  dailytripID: any;
  getPlannedTripDetails(data) {
    this.dailytripID = data.dailyTripId;
    this.selectedTripId = data.tripNumber;
    this.commonService.getData('get', '/v0/api/v1/daily-trips/' + this.dailytripID).subscribe(res => {
      this.tripData = res;
      this.routeStopList = this.tripData.stops;
      this.tripCoolerList = this.tripData.coolers;
      this.origin = res.origin;
      this.origin_onEdit = res.origin;
      this.originLatitude = res.originLatitude;
      this.originLongitude = res.originLongitude;
      this.destination = res.destination;
      this.destination_onEdit = res.destination;
      this.destLatitude = res.destinationLatitude;
      this.destLongitude = res.destinationLongitude;
      this.temp_RemovedStopsArray = this.routeStopList.map(x => x.dailyTripStopId);
      this.sdId = res.driverId;
      this.selectedDriver = [{
        driversId: res.driverId,
        name: res.driverName,
        image: res.driverImage,
        driverStatus: res.driverStatus,
      }];

      this.stId = res.truckMasterId;
      this.selectedTruck = [{
        truckMasterId: res.truckMasterId,
        truckId: res.truckId,
        status: res.truckStatus,
      }];
    });
  }
  onUpdateTrip() {
    this.searchCooler = '';
    if (this.newStopName.length > 0 && this.stopsLatitude) {
      this.addStops();
    }
    if (this.routeStopList.length == 0) {
      this.commonService.showToast("Please add atleast one stop", 0);
      return;
    }

    if (this.origin_onEdit == undefined || this.origin_onEdit.toString().trim() == '' || !this.originLatitude || !this.originLongitude ) {
      this.commonService.showToast('Please enter valid origin address', 0);
      return;
    } else if (this.destination_onEdit == undefined || this.destination_onEdit.toString().trim() == '' || !this.destLatitude || !this.destLongitude) {
      this.commonService.showToast('Please enter valid destination address', 0);
      return;
    }

    let body = {
      "id": this.dailytripID,
      "truckId": this.selectedTruck_onEdit ? this.selectedTruck_onEdit.truckId : null,
      "truckMasterId": this.selectedTruck_onEdit ? this.selectedTruck_onEdit.truckMasterId : null,
      "driverId": this.selectedDriver.length > 0 ? this.selectedDriver[0].driversId : null,
      "driverName": this.selectedDriver.length > 0 ? this.selectedDriver[0].name : null,
      "routeId": this.routeId_onEdit ? this.routeId_onEdit : null,
      "origin": this.origin_onEdit ? this.origin_onEdit : null,
      "originLat": this.originLatitude ? this.originLatitude : null,
      "originLong": this.originLongitude ? this.originLongitude : null,
      "destination": this.destination_onEdit ? this.destination_onEdit : null,
      "destLat": this.destLatitude ? this.destLatitude : null,
      "destLong": this.destLongitude ? this.destLongitude : null,
      "addCoolers": this.addCoolers.length > 0 ? this.addCoolers : null,
      "removeCoolers": this.removedCoolers.length > 0 ? this.removedCoolers : null,
      "removeStops": this.removedStops.length > 0 ? this.removedStops : null,
      "addStops": this.addStopsArray.length > 0 ? this.addStopsArray : null,
    }
    body = this.cleanPostData(body);
    this.clearVariable();
    this.commonService.getData('put', '/v0/api/v1/daily-trips', body).subscribe(res => {
      this.addStopsArray = [];
      this.removedStops = [];
      this.removedCoolers = [];
      this.addCoolers = [];
      this.destLongitude = undefined;
      this.destLatitude = undefined;
      this.destination_onEdit = undefined;
      this.originLongitude = undefined;
      this.originLatitude = undefined;
      this.origin_onEdit = undefined;
      this.routeId_onEdit = undefined;
      this.selectedTruck_onEdit = undefined;
      this.showCoolerSidbar = false;
      this.showEditSidebar = false;
      this.showDetailsSidebar = false;
      // this.getTodayTab();
      if (this.selectedTab == "todayTab") {
        this.getTodayTab();
      }
      if (this.selectedTab == "tomarrowTab") {
        this.getTomorrowTab();
      }
      if (this.selectedTab == "historyTab") {
        this.getHistoryTab();
      }
      this.getAllDrivers();
      this.getTruckList();
    })
  }
  clearVariable(){
    this.origin_onEdit = "";
    this.destination_onEdit = "";
    this.originLatitude = "";
    this.originLongitude = "";
    this.destLatitude = "";
    this.destLongitude = ""
  }
  cleanPostData(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  //clear Search
  onClearSearch() {
    if (!this.search) {
      return;
    }
    this.search = "";
    if (this.selectedTab == "todayTab") {
      this.getTodayTab();
    }
    if (this.selectedTab == "tomarrowTab") {
      this.getTomorrowTab();
    }
    if (this.selectedTab == "historyTab") {
      this.getHistoryTab();
    }
  }

  onClearDriverSearch() {
    this.searchDriverKey = "";
    this.getDriversList(0, this.recordsPerPage, this.currentId);
  }

  onClearCoolerSearch() {
    this.searchCooler = "";
    this.getCoolersList();
  }

  toggleFilter() {
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.routeId = "null";
    this.coolersType = "null";
    this.coolersValue = "";
    this.alertsType = "null";
    this.alertsValue = "";
  }



  getStopsDetails(dailyTripStopId) {
    this.tableRowToCollapse = -1;
    this.selectedStopId = dailyTripStopId;
    this.commonService.getData('get', '/v0/api/v1/daily-trip/route-stop-coolers/' + this.dailyTripId + '/' + dailyTripStopId + '/details').subscribe(res => {
      this.stopDetailsData = res;
      this.stopCoolers = this.stopDetailsData.coolerData;
    }
    );

  }

  onStopTabSelect() {
    if (this.driverTripDetailsData_ddlCooler.length > 0) {
      this.selectedStopId = this.driverTripDetailsData_ddlCooler[0].dailyTripStopId;
      this.getStopsDetails(this.selectedStopId);
    }
  }

  getStopTempHistory(item) {
    this.commonService.getData('get', '/v0/api/v1/trucks/route-stop-coolers/' + this.dailyTripId + '/' + item.dailyTripCoolerId + '/' + this.selectedStopId + '/details').subscribe(res => {
      this.stopTempDetailsData = res;
      this.tempHistory = this.stopTempDetailsData.historyData;
    }
    );
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  validateInput(event) {
    this.commonService.validateInput(event);
  }

}
