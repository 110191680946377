import { Component, OnInit, TemplateRef } from "@angular/core";
import * as Highcharts from "highcharts";
import HC_more from "highcharts/modules/variable-pie";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "src/app/services";
import { Pipe, PipeTransform } from "@angular/core";
import { StoreDataService } from '../../../services/common/store-data.service';
import { BsModalService } from 'ngx-bootstrap';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
HC_more(Highcharts);

@Pipe({ name: "replace" })
export class ReplacePipe implements PipeTransform {
  transform(
    value: string,
    strToReplace: string,
    replacementStr: string
  ): string {
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }

    return value.replace(new RegExp(strToReplace, "g"), replacementStr);
  }
}

@Component({
  selector: "app-user-role-driver",
  templateUrl: "./user-role-driver.component.html",
  styleUrls: ["./user-role-driver.component.scss"]
})
export class UserRoleDriverComponent implements OnInit {
  isMoreProfile: boolean = false;
  isEditingAccess: boolean = false;
  EditUserRole = false
  userList = [];
  userName = [];
  accessList = [];
  userFullList :any[]= [];
  userRoleAccessDetails :any[]= [];
  modalRef: any;
  selectedUserToEditRole: any;
  editRoleForm: FormGroup;
  searchUserKey: string='';

  public optionsUserActivity: any = {
    chart: {
      type: "column",
      height: 300
      // styledMode: true
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.y + "Minutes";
      }
    },
    plotOptions: {
      // series: {
      //   type: 'column',
      //   pointPlacement: 'on',
      //   zIndex: 1000
      // },
      column: {
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10
      }
    },
    xAxis: {
      // tickmarkPlacement: 'on',
      gridLineColor: "trasparent",
      gridLineWidth: 1,
      lineWidth: 1,
      categories: this.userName
      // plotLines: [{
      //   color: '#1AB0DB', // Color value
      //   dashStyle: 'longdashdot', // Style of the plot line. Default to solid
      //   value: 8, // Value of where the line will appear
      //   width: 1, // Width of the line
      // }]
    },
    yAxis: {
      gridLineColor: "#7070702b",
      gridLineWidth: 1,
      lineWidth: 1,
      min: 0,
      max: 60,
      tickInterval: 10,
      title: {
        useHTML: true,
        text: "mins",
        align: "middle"
      },
      labels: {
        useHTML: true,
        text: "mins",
        overflow: "justify"
      }
    },
    legend: {
      itemDistance: 50,
      squareSymbol: true,
      symbolRadius: 2,
      itemStyle: {
        color: "#A5A5A5",
        fontWeight: "400",
        fontSize: "10px",
        align: "left"
      }
    },
    series: [
      {
        name: "Activity (mins)",
        color: "#62B8FF",
        data: [12, 38, 18, 58, 37, 10, 23, 15, 50, 18, 35]
      }
    ]
  };
  roleMasterId: any;
  accessListPermission: any[]=[];
  // accessListPermission: any;
  userByRole: any [] = [];
  roleName: string;
  accessCount: number = 0;
  DDLRole: any[] = [];
  refreshSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dataService: StoreDataService,
    private modalService: BsModalService,
    private fb: FormBuilder,
  ) {
    this.userList.forEach(element => {
      this.userName.push(element.name);
    });
    //this.roleName = this.dataService.roleNameHeading;
    this.route.queryParams.subscribe(param => {
      this.roleMasterId = param.roleMasterId;
      this.roleName = param.roleName;
    });
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getAccessList();
      this.getUserByRoleId();
      this.getUserRoleAccess();
    });
  }

  ngOnInit() {
    //if (this.roleName == undefined || this.roleName == null || this.roleName =="") {
    //  this.roleName = this.dataService.roleNameHeading;
    //}

    // Highcharts.chart("chartUserActivity", this.optionsUserActivity);
    this.getAccessList();
    this.getUserByRoleId();
    this.getRoleDDL();
    this.createForm();
    this.getUserRoleAccess();

  }
  goToAddUser(addUserModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addUserModal, {
      class: 'modal-mdm modal-dialog-centered', ignoreBackdropClick: true
    });
    // this.dataService.alreadyAddedDriverList = this.userByRole;
    //this.dataService.roleNameHeading = this.roleName;
    //this.router.navigate(["/add-user"], { queryParams: { redirectedFrom: "Add_Driver_Role", roleMasterId: this.roleMasterId, roleName: this.roleName } });
  }
  onEditDriver(template, user) {
    this.selectedUserToEditRole = user;
    this.dataService.alreadyAddedDriverList = this.userByRole;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-sm add-role modal-dialog-centered', ignoreBackdropClick: true
    });
    // this.dataService.roleNameHeading = this.roleName;
    //this.router.navigate(["/add-user"], { queryParams: { redirectedFrom: "Edit_Driver_Role", roleMasterId: this.roleMasterId, roleName: this.roleName } });
  }
  getAccessList() {
    this.commonService
      .getData('get', "/v0/api/v1/roles/" + this.roleMasterId + "/permissions")
      .subscribe(res => {
        this.accessListPermission = res.data;
        this.accessListPermission.forEach((access, accessIndex) => {
          access.permissions.forEach((perm, permIndex) => {
            if (perm.status) {
              perm["isChecked"] = true;
              this.accessCount++
            }
          })
        })
      });
  }

  getUserByRoleId() {
    this.commonService
      .getData('get', "/v0/api/v1/users/by-role-id/" + this.roleMasterId)
      .subscribe(res => {
        this.userByRole = res && res.data ? res.data:[];
        this.getUserList();
      });
  }

  convertImgUrl(url) {
    // if (url !== "Alert Management" || url !== "Users") {
    let urldata = url.replace(/ /g, "_");
    return urldata;
    // }
  }

  selcetedRoleAccess() {
    //this.dataService.roleNameHeading = this.roleName;

    this.router.navigate(['/modify-access'], { queryParams: { roleMasterId: this.roleMasterId, roleName: this.roleName } });
  }
  getRoleDDL() {
    this.commonService.getData('get', '/v0/api/v1/roles/names').subscribe(x => {
      this.DDLRole = x.data;
     // console.log('this.DDLRole', this.DDLRole)
    });
  }
  createForm() {
    this.editRoleForm = this.fb.group({
      roleName: new FormControl('', Validators.required)
    });
  }
  currentSeletedRoleId: any;
  onRoleSelect(val) {
    this.currentSeletedRoleId = val;
  }
  onCancel() {
    this.modalRef.hide();
  }
  createRole() {
    if (this.currentSeletedRoleId) {
      this.selectedUserToEditRole;
      let param = {
        "roleId": +this.currentSeletedRoleId,
        "users": [+this.selectedUserToEditRole.userMasterId],
      }
      this.commonService.getData('put', '/v0/api/v1/user-roles', param).subscribe(res => {
        this.getAccessList();
        this.getUserByRoleId();
        this.modalRef.hide();
        this.EditUserRole = false;
        this.currentSeletedRoleId = null;
      });
    } else {
      this.commonService.showToast("Please select other role", 0);
    }
  }
  getUserList(pageNumber = 0, pageSize = 1000) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.searchUserKey.trim()
    }

    this.commonService.getData('get', '/v0/api/v1/users', body).subscribe(resp => {
      this.userFullList = [];
      let userFullList: any[] = resp.data.rows;
      let preUsers = this.userByRole.map(x => x.userMasterId);
      userFullList.forEach(x => {
        if (preUsers.find(i => i == x.userMasterId) == undefined) {
          this.userFullList.push(x);
        }         
      })     
    })
  }
  searchDriver() {
    this.getUserList();
  }
  updateUserRoleToDriver() {
    let param = {
      "roleId": +this.roleMasterId,
      "users": this.selectedUsers,
    }
    this.commonService.getData('put', '/v0/api/v1/user-roles', param).subscribe(res => {
      this.modalRef.hide();
      this.getUserByRoleId();
    });
  }
  selectedUsers:any[]=[]
  changeDriver(user, event) {
    if (event.target.checked) {
      this.selectedUsers.push(user.userMasterId)
    } else {
      this.selectedUsers = this.selectedUsers.filter(x => x != user.userMasterId);
    }
  }
  getUserRoleAccess() {
    this.commonService.getData('get', '/v0/api/v1/user-roles/access').subscribe(res => { 
      this.userRoleAccessDetails = res.data;
    })
  }
  onAddRemovePermission(access, event, permissionType) {
    this.accessListPermission.forEach((ax, i) => {
      ax.permissions.forEach((perm, j) => {
        if (ax.permissionMasterId == access.permissionMasterId && perm.permissionType.toLowerCase() == permissionType) {
          perm["isChecked"] = event.target.checked;        
        }
      })
    })


    let tempData = this.userRoleAccessDetails.filter(e => e.permissionMasterId == access.permissionMasterId);
    let roleData = tempData[0].roleData.filter(x => x.roleMasterId == this.roleMasterId);
    if (roleData && roleData.length) {
      let permissionDetails = roleData[0].permissionTypes.filter(i => i.permissionType.toLowerCase() == permissionType);
      let postData;
      if (event.target.checked) {
        if (permissionDetails[0].status) {
          this.updateAccessData.removePermissions.forEach((e, i, object) => {
            if (e.rolePermissionId == roleData[0].rolePermissionId && e.permissionTypeMasterId == permissionDetails[0].permissionTypeMasterId) {
              object.splice(i, 1);
            }
          })
        } else {
          if (roleData[0].rolePermissionId) {
            postData = {
              rolePermissionId: +roleData[0].rolePermissionId,
              permissionTypeMasterId: +permissionDetails[0].permissionTypeMasterId,
              roleMasterId: +this.roleMasterId,
              permissionMasterId: +access.permissionMasterId,
            }
          } else {
            postData = {
              permissionTypeMasterId: +permissionDetails[0].permissionTypeMasterId,
              roleMasterId: +this.roleMasterId,
              permissionMasterId: +access.permissionMasterId,
            }
          }        
          this.updateAccessData.addPermissions.push(postData);
        }
      } else {
        if (!permissionDetails[0].status) {
          this.updateAccessData.addPermissions.forEach((e, i, object) => {
            if (e.permissionMasterId == access.permissionMasterId && e.permissionTypeMasterId == permissionDetails[0].permissionTypeMasterId) {
              object.splice(i, 1);
            }
          })
        } else {
          postData = {
            rolePermissionId: +roleData[0].rolePermissionId,
            permissionTypeMasterId: +permissionDetails[0].permissionTypeMasterId
          }
          this.updateAccessData.removePermissions.push(postData);
        }
      }
    }   
  }
  updateAccessData = { addPermissions: [], removePermissions: []}
  onUpdateAccess() {
    if(this.updateAccessData.addPermissions.length == 0 && this.updateAccessData.removePermissions.length == 0){
      this.commonService.showToast('Please add permissions to the role', 0);
      return;
    }
    this.commonService.getData('put', '/v0/api/v1/user-roles/access', this.updateAccessData).subscribe(res => {
      if (res) {
        this.isEditingAccess = false;
        this.updateAccessData = {
          addPermissions: [],
          removePermissions: []
        }
        //this.updateAccessData.addPermissions = [];
        //this.updateAccessData.removePermissions = [];
        this.getAccessList();
        this.getUserRoleAccess();
      }
    })
  }

  // clear search
  onClearSearch(){
    this.searchUserKey= "";
    this.getUserList();
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  validateInput(event) {
    this.commonService.validateInput(event);
  }
}
