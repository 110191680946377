import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { from, Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { TripPlanner, TripPlannerMaker  } from '../tripPlanner.model';
import { formatDate } from '@angular/common';
import { ExportExcelService } from '../../../services/export-to-excel/export-exel.service';


@Component({
  selector: 'app-trip-planner-list',
  templateUrl: './trip-planner-list.component.html',
  styleUrls: ['./trip-planner-list.component.scss']
})
export class TripPlannerListComponent implements OnInit {

  tripPlannerList: any[] = [];
  // tripPlannerList: any;
  sortOrder: any;
  sortCoolerlist: any;
  startItem: any = 0;
  endItem: any = 10;
  showSidebar: boolean = false;
  isShowFilter: boolean = false;
  onclickAddbtn: any;
  filterRouteId: any;

  search:any;
  filterRoute:any;
  coolersLable:string;
  coolers:any;
  startDate:any;
  endDate:any;
  formatStartDate:any;
  formatEndDate:any;

  totalTripPlannerCount = 0;
  recordsPerPage = 10;
  activePageNo=0;
  ddlAssignRoute: any[] = [];

  isAllChecked :any;
  isChecked: boolean = false;
  tripPlannerResponseList: any[] = [];
  sortingPropName: string = "tripId";
  sortingOrder: string = "DESC";
  currentPageIndex = 0;
  refreshSubscription: Subscription;

  constructor(private router: Router, private commonService: CommonService,
    private exportExelService: ExportExcelService,) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getTripPlannerList();
    });
  }

  ngOnInit() {
    this.getTripPlannerList();
   this.getDDLAssignRoute();
  }

  getDDLAssignRoute() {
    this.commonService.getData('get', '/v0/api/v1/trip-plans-assigned-routes').subscribe(res => {
      this.ddlAssignRoute = res.data;
    });
  }
  loadTrip(item) {
    this.router.navigate(['trip-planner'], { queryParams: { tripMasterId: item.tripMasterId, tripId: item.tripId } });
  }

  getTripPlannerList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action="onInit") {
    this.activePageNo = pageNumber;
    if(this.startDate && this.endDate){
      this.formatStartDate = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US');
      this.formatEndDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US');
    }
    this.totalTripPlannerCount = 0;
    this.tripPlannerResponseList = [];
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search:this.search,
      routeId: this.filterRoute == 'null' ? '' : this.filterRoute,
      coolerFilterType: this.coolersLable == 'null' ? '' : this.coolersLable,
      coolerFilterValue:this.coolers,
      startDate:this.formatStartDate,
      endDate:this.formatEndDate,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData('get', '/v0/api/v1/trip-plans', body).subscribe(tripPlannerListResponse => {
      console.log(tripPlannerListResponse);
      this.totalTripPlannerCount = tripPlannerListResponse.data.count;
      this.tripPlannerResponseList = tripPlannerListResponse && tripPlannerListResponse.data && tripPlannerListResponse.data.rows ? tripPlannerListResponse.data.rows : [];
      if (action == 'export') {
        let exportableData = this.tripPlannerResponseList.map(e => TripPlannerMaker.exportToExcel(e));
        this.exportExelService.exportAsExcelFile(exportableData, 'Trip Planner List');
      }else
      this.tripPlannerList = this.tripPlannerResponseList.length > 0 ? this.tripPlannerResponseList.map(e => TripPlannerMaker.castInto(e)): [];
    });
  }

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }
  
  toggleFilter(){
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.filterRoute="null";
    this.coolersLable="null";
    this.filterRouteId="";
    this.coolers="";
    this.startDate="";
    this.endDate="";
  }

  resetFilter(){
    this.setDefaultFilterValues();
    this.getTripPlannerList(0);
  }

  loadPageData(pageNo){
    this.currentPageIndex = pageNo;
    this.getTripPlannerList();
  }

  checkAll() {
    this.tripPlannerList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }

  selectRow(obj: TripPlanner, event) {
    obj.isChecked = event.target.checked;
    this.countCheckedRows();
  }

  countCheckedRows() {
    if (this.tripPlannerList.length == 0) {
      return;
    }
    let checkedFlag = true;
    let counter = 0;
    this.tripPlannerList.forEach((e) => {
      if (!e.isChecked) {
        checkedFlag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedFlag;
    return counter;
  }
  onExportToExcel() {
    if (!this.totalTripPlannerCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getTripPlannerList(0, this.totalTripPlannerCount, 'export')
  }
  //*******sorting Routes-List table *******/
  onSortList( prop, asc) {
 
    this.tripPlannerList = this.tripPlannerList.sort(function (a, b) {
        if (asc) {
          return ((a[prop]) > (b[prop])) ? 1 : (((a[prop]) < (b[prop])) ? -1 : 0);
        } else {
          return ((b[prop]) > (a[prop])) ? 1 : (((b[prop]) < (a[prop])) ? -1 : 0);
        }
      });    
  }

  // onRouteFilterChange(event) {
  //   this.filterRouteId = event.target.value;
  // }

 
  onSortTripPlansList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getTripPlannerList();
  }

  onClearSearch(){
    if(!this.search){
      return;
    }
    this.search = "";
    this.getTripPlannerList(0);
  }
  validateInput(event) {
    this.commonService.validateInput(event);
  }
}
