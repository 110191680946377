export class Sensor {
  sensorId: string;
  sensorType: any;
  sensorName: any;
  name:any;
  currentReading: any;
  idealRange: string;
  date: any;
  eventTime: any;
  batteryLevel: any;
  signalStrength: any;
  status: any;
  icon: any;
  isChecked: boolean;
  units: any;
  startRange: any;
  endRange: any;
  time: any;
  key: any;

  constructor(
    sensorId: string,
    sensorType: any,
    sensorName: any,
    name: any,
    currentReading: any,
    idealRange: string,
    date: any,
    eventTime: any,
    batteryLevel: any,
    signalStrength: any,
    status: any,
    icon: any,
    isChecked: boolean,
    units:any,
    startRange: any,
    endRange: any,
    time:any,
    key:any
  ) {
    this.sensorId = sensorId;
    this.sensorType = sensorType;
    this.sensorName = sensorName;
    this.name = name;
    this.currentReading = currentReading;
    this.idealRange = idealRange;
    this.date = date;
    this.eventTime = eventTime;
    this.batteryLevel = batteryLevel;
    this.signalStrength = signalStrength;
    this.status = status;
    this.icon = icon;
    this.isChecked = false;
    this.units = units;
    this.startRange = startRange;
    this.endRange = endRange;
    this.time = time;
    this.key = key;
  }
}

export class SensorMaker {
  static create() {
    return new Sensor(null, null, null, null, null, null, null, null, null, null, null, null,null,null,null,null,null, null);
  }

  static castInto(obj: any) {
    return new Sensor(
      obj.sensorId,
      obj.sensorType,
      obj.sensorName,
      obj.name,
      obj.currentReading,
      obj.idealRange,
      obj.date,
      obj.eventTime,
      obj.batteryLevel,
      obj.signalStrength,
      obj.status,
      obj.icon,
      obj.isChecked,
      obj.units,
      obj.startRange,
      obj.endRange,
      obj.time,
      obj.key
    );
  }

  static clone(obj: Sensor) {
    return new Sensor(
      obj.sensorId,
      obj.sensorType,
      obj.sensorName,
      obj.name,
      obj.currentReading,
      obj.idealRange,
      obj.date,
      obj.eventTime,
      obj.batteryLevel,
      obj.signalStrength,
      obj.status,
      obj.icon,
      obj.isChecked,
      obj.units,
      obj.startRange,
      obj.endRange,
      obj.time,
      obj.key
    );
  }

}
