import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "src/app/services";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"]
})
export class MyProfileComponent implements OnInit {
  modalRef: BsModalRef;
  editProfileForm: FormGroup;
  changePassform: FormGroup;
  userDetails: any;
  id: any = 0;
  isUserProfileList: boolean = false;
  isActive = 1;
  firstName: string = "";
  lastName: string = "";
  mobile: string = "";
  roleId: number;
  userRoles: any;
  newPassword: string = "";
  confirmPassword: string = "";
  verifyEmailId: string = "";
  redirectedFrom = "";
  fileData: File = null;
  previewUrl:any = null;

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.route.queryParams.subscribe(param => {
      this.redirectedFrom =param && param.redirectedFrom ? param.redirectedFrom:"";
      if (param.id) {
        this.id = param.id;
        this.isUserProfileList = true;
      }
      this.getUserDetails();
    });
  }

  ngOnInit() {
    this.getUserRoles();
    this.prepareEditForm();
    this.createChangePassForm();

  }

  getUserDetails() {
    let apiPath = "";
    if (this.id) {
      apiPath = "/v0/api/v1/profile/" + this.id;
    } else {
      apiPath = "/v0/api/v1/my-profile";
    }
    this.commonService.getData('get', apiPath).subscribe(res => {
      console.log(res);
      this.userDetails = res;
      this.firstName = this.userDetails.firstName;
      this.lastName = this.userDetails.lastName;
      this.mobile = this.userDetails.mobile;
      this.roleId = this.userDetails.roleMasterId;
    });
  }

  updateUser() {
    if (this.editProfileForm.valid) {

      let body = {
        id: this.userDetails.userMasterId,
        firstName: this.firstName,
        lastName: this.lastName,
        mobile: this.mobile,
        roleId: parseInt(this.roleId + "")
      };
      console.log(body);
      this.commonService.getData('put', "/v0/api/v1/users", body).subscribe(res => {
        console.log(res);
        if (res.status) {
          this.modalRef.hide();
          (this.userDetails.firstName = this.firstName),
            (this.userDetails.lastName = this.lastName),
            (this.userDetails.mobile = this.mobile);
          this.userDetails.roleId = this.roleId;
          this.userDetails.roleName = this.userRoles.filter(
            item => item.roleMasterId == this.roleId
          )[0].roleName;
        }
      });
    }
  }

  changePasswordModal(changePassword: TemplateRef<any>) {
    this.modalRef = this.modalService.show(changePassword, {
      class: "modal-dialog-centered modal-sm" , backdrop: 'static'
    });
  }

  resetPasswordModal(resetPassword: TemplateRef<any>) {
    this.modalRef = this.modalService.show(resetPassword, {
      class: "modal-dialog-centered modal-sm", backdrop: 'static'
    });
    this.verifyEmailId = this.userDetails.emailId;
  }

  openEditProfileModal(editProfile: TemplateRef<any>) {
    this.firstName = this.userDetails.firstName;
    this.lastName = this.userDetails.lastName;
    this.mobile = this.userDetails.mobile;
    this.roleId = this.userDetails.roleMasterId;
    this.modalRef = this.modalService.show(editProfile, {
      class: "modal-dialog-centered modal-mdm", backdrop: 'static'
    });
  }

  goToAddTruck() {
    this.router.navigate(["/add-truck"], {
      queryParams: { userMasterId: this.userDetails.userMasterId }
    });
  }

  getUserRoles() {
    this.commonService.getData('get', "/v0/api/v1/user-roles").subscribe(res => {
      this.userRoles = res.data;
    });
  }

  prepareEditForm() {
    this.editProfileForm = this.fb.group({
      firstName: new FormControl('', [Validators.required, Validators.pattern("([a-zA-Z',.-]+( [a-zA-Z',.-]+)*)")]),
      lastName: new FormControl('', [Validators.required, Validators.pattern("([a-zA-Z',.-]+( [a-zA-Z',.-]+)*)")]),
      mobile: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
      roleId: new FormControl(''),
    });
  }
  createChangePassForm() {
    this.changePassform = new FormGroup({

      'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
      'confirmPassword': new FormControl(null, [Validators.required])
    })
  }

  changePasswordFunc() {
    if (this.newPassword !== this.confirmPassword) {
      this.commonService.showToast('Passwords does not match', 0);
      return false;
    }
    this.markFormGroupTouched(this.changePassform);
    const ctrl = this.changePassform.controls;
    let password = ctrl["password"].value;
    
    if (this.changePassform.valid) {
      let body = {
        password: this.newPassword
      };
      this.commonService.getData('post', '/v1/users/changePassword', body).subscribe(res => {
        if(res){
          this.newPassword = "";
          this.confirmPassword = "";
          this.modalRef.hide();
        }
      });
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onFileChanged(event) {
    let imageUpload = event.target.files[0];
    if(imageUpload && imageUpload.type == 'image/jpeg' || imageUpload && imageUpload.type == 'image/png') {
      this.fileData = imageUpload;
      let formData = new FormData();
      formData.append('image', this.fileData);
      this.commonService.getData('post', '/v1/profile/uploadImage', formData).subscribe(res => {
        if(res) {
          this.preview();
        }
      });
    } else {
      this.commonService.showToast('Only JPEG and PNG image supported', 0);
    }
  }

  preview() {
    // Show preview 
    var fileType = this.fileData.type;
    if (fileType.match(/image\/*/) == null) {
      return;
    }
 
    var reader = new FileReader();      
    reader.readAsDataURL(this.fileData); 
    reader.onload = (_event) => { 
      this.previewUrl = reader.result; 
    }
}

  resetPasswordFunc() {
    if (!this.verifyEmailId) {
      this.commonService.showToast('Please enter email id', 0);
      return false;
    }

    if (this.commonService.isInvalidEmail(this.verifyEmailId)) {
      this.commonService.showToast('Please enter valid email id', 0);
      return false;
    }

    this.commonService.getData('post','/v0/api/v1/change-password',{email:this.verifyEmailId}).subscribe(res=>{
      console.log('reset-password response', res);
      this.modalRef.hide();
    });
  }

  onCancelRoleModel() {
    this.editProfileForm.reset();
    this.modalRef.hide();
  }

  goToUser() {
    this.router.navigate(['/user-role'], { queryParams: { user: 'user-tab'} });
  }
}
