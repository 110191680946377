import { formatDate } from '@angular/common';
export class SensorAlertHistoryExport {
    date: any;
    time: any;
    openTime: any;
    assignTo: any;
    description: any;
    status: any;

    constructor(
        date: any,
        time: any,
        openTime: any,
        assignTo: any,
        description: any,
        status: any,
    ) {
        this.date = date;
        this.time = time;
        this.openTime = openTime;
        this.assignTo = assignTo;
        this.description = description;
        this.status = status;
    }
}
export class SensorAlertHistoryExportMaker {
    static create() {
        return new SensorAlertHistoryExport(null, null, null, null, null, null);
    }
    static exportToExcel(obj: SensorAlertHistoryExport) {
       
        let returnData = new SensorAlertHistoryExport(
            obj.date,
            formatDate(obj.time, "HH:mm", 'en-US'),  
            obj.openTime,
            obj.assignTo,
            obj.description,
            obj.status,
        )
        return returnData;
    }
}
