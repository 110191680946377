import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/';
import { ActivatedRoute } from '@angular/router';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { formatDate, DatePipe } from "@angular/common";
import { Subscription } from 'rxjs';
import { AlertListExportMaker } from './alert-list-Export';



@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  alertList: any = [];
  fullList = [];

  search: any = "";



  isShowFilter: boolean = false;
  sortOrder: any;

  //********Pagination********
  currentPage = 1;
  page: number;
  returnedDriverList: string[];
  startItem = 0;
  endItem = 10;
  startDate: any;
  endDate: any;
  status: any;
  location: any;
  openTime: string;
  value: any;
  // date range

  alertRangeValue: Date[];
  //nextDate = new Date(new Date().getTime() + (8.64e+7 * 7));
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 1));
  cardDataList = [];
  truckId: any;
  totalAlertsCount = 0;
  activePageNo = 0;

  recordsPerPage = 10;
  sortingPropName: string = "dateTime"
  sortingOrder: string = "DESC"
  currentPageIndex = 0;
  refreshSubscription: Subscription;
  datePipeAngular: DatePipe;
  constructor(private commonService: CommonService,
    private actRoute: ActivatedRoute,
    private exportExcelService: ExportExcelService,
  ) {
    this.datePipeAngular = new DatePipe('en-US');
    // this.alertRangeValue = [new Date(), this.nextDate];
    this.actRoute.queryParamMap.subscribe(params => {
      if (params["params"].truckId && !params["params"].search) {
        this.truckId = params["params"].truckId;
        console.log(this.truckId);
        // let body = {
        //   pageIndex: 0,
        //   pageSize: 10,
        //   truckId: this.truckId
        // }
        // this.getList();
        this.alertRangeValue = [new Date(), new Date()];
      }

      if (params["params"].status) {
        this.status = params['params'].status;
      }
      if (params["params"].search) {
        this.search = params["params"].search;
        console.log(this.search);
        let body = {
          pageIndex: 0,
          pageSize: 10,
          search: this.search
        }
        // this.getList();
        this.alertRangeValue = [new Date(), new Date()];
      }
      // else if (params["params"].key == 'coolerAlerts') {
      //   this.search = 'cooler';
      // } else if (params["params"].key == 'doorSensorsAlerts') {
      //   this.search = 'sensor';
      // } else if (params["params"].key == 'trucksWithAlerts') {
      //   this.search = 'Vehicle';
      // }
    });
    this.getCardData();

    if (!this.truckId && !this.search) {
      // this.getList();
      this.alertRangeValue = [new Date(), new Date()];
      // console.log("in constr")
    }

    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getCardData();
      this.getList();
    });


  }

  ngOnInit() {
    //this.alertRangeValue = [new Date(), this.nextDate];
  }

  /* Get Card Data */
  getCardData() {
    const body = {
      currentDate: this.datePipeAngular.transform(new Date().toISOString(), 'yyyy-MM-dd')
    }
    this.commonService.getData('get', '/v0/api/v1/alerts/count', body).subscribe(cardDataResponse => {
      //console.log(cardDataResponse);
      this.cardDataList = cardDataResponse.data;
    });
  }

  /* Get Alert List */
  getList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = 'onInit', fromDate = formatDate(this.alertRangeValue[0], "yyyy-MM-dd", "en-US"), toDate = formatDate(this.alertRangeValue[1], "yyyy-MM-dd", "en-US")) {
    this.activePageNo = pageNumber;

    this.totalAlertsCount = 0;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      from: fromDate,
      to: toDate,
      startDate: this.startDate,
      endDate: this.endDate,
      // openTimeType: this.openTime ? this.openTime.toLowerCase() : this.openTime,
      openTimeType: this.openTime == 'null' ? '' : this.openTime,
      openTime: this.value,
      // temprory location commented
      // location: this.location,
      status: this.status == 'null' ? '' : this.status,
      search: this.search.trim() === '' ? null : this.search.trim(),
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    if (this.truckId) {
      body['truckId'] = this.truckId;
    }
    this.commonService.getData('get', '/v0/api/v1/alerts', body).subscribe(listResponse => {
      console.log(listResponse);
      // this.search = '';
      this.totalAlertsCount = listResponse.data.count;
      this.fullList = listResponse.data.rows;
      if (action == "export") {
        let exportableData = listResponse && listResponse.data && listResponse.data.rows ? listResponse.data.rows.map(e => AlertListExportMaker.exportToExcel(e)) : [];
        this.exportExcelService.exportAsExcelFile(exportableData, 'Alert List');
      }
      this.alertList = this.fullList;

    });
  }
  //*******sorting Routes-List table *******/
  sortSensorlist(propType, prop, asc) {

    switch (propType) {
      case "int":
        this.alertList = this.alertList.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case "date":
        this.alertList = this.alertList.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        })
        break;
      default:
        this.alertList = this.alertList.sort(function (a, b) {
          if (asc) {
            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
          } else {
            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
          }
        });
        this.alertList;
    }
  }

  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    //this.driverList = this.driverList.slice(this.startItem, this.endItem);
  }

  onAlertHistyDateChange(event) {
    if (event != undefined && event != null && event.length > 1) {
      let alertStartDate = event[0].getTime();
      let alertEndDate = event[1].getTime();

      let startDate = this.converDateFormat(alertStartDate);
      let endDate = this.converDateFormat(alertEndDate);

      console.log(startDate, 'startDate');
      console.log(endDate, 'endDate');
      this.getList(0, this.recordsPerPage, 'dateChange', startDate, endDate);

      //this.alertList = this.fullList;

      //don't remove
      // this.alertList = this.fullList.filter(element=> 
      //   new Date(element.dateTime).getTime() >= alertStartDate && new Date(element.dateTime).getTime() <= alertEndDate
      // );

      //this.alertList = this.alertList.filter(x => new Date(x.date).getTime() >= alertStartDate && new Date(x.date).getTime() <= alertEndDate)
    }
  }

  converDateFormat(dateFormat) {
    let from = new Date(dateFormat);
    let fromYear = from.getFullYear();
    let fromMonth = from.getMonth() + 1;
    let fromDate = from.getDate();

    let month = "" + fromMonth;
    let date = "" + fromDate;

    if (fromMonth < 10) {
      month = "0" + fromMonth;
    }
    if (fromDate < 10) {
      date = "0" + fromDate;
    }
    let finalDate = fromYear + "-" + month + "-" + date;

    return finalDate;
  }

  toggleFilter() {
    this.isShowFilter = !this.isShowFilter;

    this.setDefaultFilterValues();
  }

  applyFilter() {
    // let filterData = {
    //   startDate: this.startDate,
    //   endDate: this.endDate,
    //   openTime: this.openTime,
    //   value: this.value,
    //   location: this.location,
    //   status: this.status
    // }

    // console.log(filterData);
    this.getList();
  }

  setDefaultFilterValues() {
    this.status = 'null';
    this.startDate = "";
    this.endDate = "";
    this.openTime = 'null';
    this.value = "";
    // this.location = "";
  }

  resetFilter() {
    this.setDefaultFilterValues();
    this.getList(0);
  }

  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getList(pageNo);
  }
  onExportToExcel() {
    if (!this.totalAlertsCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getList(0, this.totalAlertsCount, 'export')
  }

  onSortAlertList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getList();
  }

  //clear Search
  onClearSearch() {
    if (!this.search) {
      return;
    }
    this.search = "";
    this.getList(0);
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  validateInput(event) {
    this.commonService.validateInput(event);
  }

}
