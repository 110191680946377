import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit,OnDestroy {
  AdminStatus = "Active"
  supervisorStatus = "Active"
  d_managerStatus = "Active"
  driverStatus = "Active"
  modalRef: any;
  fakeval = true;
  isShowFilter: boolean = false;
  roleList: any;
  roleName: any;
  search: any;

  addRoleForm: FormGroup;
  onclickAddbtn: boolean = false;
  refreshSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private commonService: CommonService,
    private fb: FormBuilder,
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getRoleList();
    });
   }


  ngOnInit() {
    this.getRoleList();
    this.createForm();
  }
  createForm() {
    this.addRoleForm = this.fb.group({
      roleName: new FormControl('', Validators.required)
    });
  }
  getRoleList() {
    let body = {
      search: this.search
    }
    this.commonService.getData('get', '/v0/api/v1/user-roles', body).subscribe(res => {
      this.roleList = res.data;
    })
  }

  onAddRole(template) {
    this.addRoleForm.reset();
    this.roleName = '';
    this.createForm();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-sm add-role modal-dialog-centered', ignoreBackdropClick: true
    });
    this.onclickAddbtn = true;
  }

  onModifyAccess() {
    this.router.navigate(['/modify-access'])
  }

  onCancel() {
    this.addRoleForm.reset();
    this.modalRef.hide();
    this.onclickAddbtn = false;
  }

  goTOUserRoles(id, role) {
    if (role) {
      this.router.navigate(['/user-role-driver'], { queryParams: { roleMasterId: id, roleName:role, landedFrom:"role"}});
    }
  }
  createRole() {
    this.addRoleForm.controls["roleName"].markAsTouched();

    let roleName = this.addRoleForm.controls["roleName"].value;
    if (this.addRoleForm.valid) {
      let body = {
        "roleName": roleName,
        "roleDescription": ""
      }
      this.commonService.getData('post', '/v0/api/v1/roles', body).subscribe(res => {
        if (res.data == 'Role added successfully') {
          this.getRoleList();
          this.modalRef.hide();
        }
      });
    } 
  }

  // clear search
  onClearSearch(){
    if (!this.search) {
      return;
    }
    this.search = "";
    this.getRoleList();
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  validateInput(event) {
    this.commonService.validateInput(event);
  }
  closeModal() {
    this.modalRef.hide();
  }
}
