import { Component, OnInit, TemplateRef } from '@angular/core';
import { CoolerMaker, Cooler } from './coolerList.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { Subscription } from 'rxjs';
import { CoolerListExportMaker } from './cooler-list-export';

@Component({
  selector: 'app-cooler-list',
  templateUrl: './cooler-list.component.html',
  styleUrls: ['./cooler-list.component.scss']
})
export class CoolerListComponent implements OnInit {
  modalRef: BsModalRef;

  search: any = "";
  coolerType: any;
  alertsLable: string;
  alerts: any;
  status: any;
  alertStatus: any;
  coolerStatus: any;
  //****** Pagination *********/
  currentPage = 1;
  page: number;
  startItem = 0;
  endItem = 10;
  currentPageIndex = 0;

  // coolerList: any[] = [];
  coolerList: any;
  isAllChecked = false;
  onclickAddbtn: boolean = false;
  totalCoolerCount = 0;
  recordsPerPage = 10;
  activePageNo = 0;

  sortOrder: any;
  isShowFilter: boolean = false;
  ischecked: boolean = false;
  coolerTypeList: any;
  coolerListResponseList: any[] = [];

  sortingPropName: string="coolerId";
  sortingOrder: string = "DESC";
  refreshSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private exportExcelService: ExportExcelService,
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getCoolerList();
    });
   }

  ngOnInit() {
    this.getCoolerList();
  }

  //*********Dummy Data **********/
  getCoolerList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = "onInit") {

    this.activePageNo = pageNumber
    this.totalCoolerCount = 0;
    this.coolerListResponseList = [];
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.search.trim() === '' ? null : this.search.trim(),
      coolerType: this.coolerType == 'null' ? '' : this.coolerType,
      alert: this.alerts,
      coolerStatus: this.coolerStatus == 'null' ? '' : this.coolerStatus,
      alertStatus: this.alertStatus == 'null' ? '' : this.alertStatus,
      // alertType: this.alertsLable ? this.alertsLable.toLowerCase() : this.alertsLable,
      alertType: this.alertsLable == 'null' ? '' : this.alertsLable,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData('get', '/v0/api/v1/coolers', body).subscribe(res => {
      this.totalCoolerCount = res.data.count;
      this.coolerListResponseList = res && res.data && res.data.rows ? res.data.rows : [];
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => CoolerListExportMaker.exportToExcel(e)): [];
        this.exportExcelService.exportAsExcelFile(exportableData, 'Coolers List');
      }
      this.coolerList = this.coolerListResponseList.length > 0 ? this.coolerListResponseList.map(e => CoolerMaker.castInto(e)) : [];
    })


  }

  //********* Checkbox selections **********/
  checkAll(status) {
    this.coolerList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }
  countCheckedRows(): any {
    if (this.coolerList.length == 0) {
      return;
    }
    let checkedflag = true;
    let counter = 0;

    this.coolerList.forEach((e) => {
      if (!e.isChecked) {
        checkedflag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedflag;
    return counter;
  }
  selectRow(obj: Cooler, event) {
    obj.isChecked = event.target.checked;
    this.countCheckedRows();
  }

  //******Page change Event ******//
  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  //*********sorting *********/
  sortCoolerlist(prop, asc) {
    this.coolerList = this.coolerList.sort(function (a, b) {
      if (asc) {
        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
      } else {
        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
    this.onclickAddbtn = false;
  }

  showAddCoolerModal(addCoolerModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addCoolerModal, { class: 'modal-dialog-centered add-new-cooler' , backdrop: 'static' });
    this.onclickAddbtn = true;
  }


  setDefaultFilterValues() {
    this.coolerType = "null";
    this.alertsLable = "null";
    this.alerts = "";
    this.coolerStatus = "null";
    this.alertStatus = "null";
  }

  resetFilter() {
    this.setDefaultFilterValues();
    this.getCoolerList(0);
  }

  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getCoolerList(pageNo)
  }
  isShowsFilter() {
    if (!this.isShowFilter) {
      this.isShowFilter = true;
      this.setDefaultFilterValues();
      this.getCoolerTypes();
    } else {
      this.isShowFilter = false
    }
  }
  getCoolerTypes() {
    this.commonService.getData('get', '/v0/api/v1/master-data/cooler-types').subscribe(res => {
      this.coolerTypeList = res.data;
    })
  }
  onExportToExcel() {
    if (!this.totalCoolerCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getCoolerList(0, this.totalCoolerCount, 'export')
  }


  onSortCoolerList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getCoolerList();
  }

  //clear Search
  onClearSearch() {
    if(!this.search){
      return;
    }
    this.search = "";
    this.getCoolerList(0);
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  // search validate input
  validateInput(event) {
    this.commonService.validateInput(event);
  }
}

