export class Truck {
  id: string;
  name: string;
  skids: Array<any>;
  conditions: string;
  status: string;
  driver: any;
  tripID: any;
  truckMasterId: any;
  route: any;
  truckID: string;
  constructor(
    id: string,
    name: string,
    skids: Array<any>,
    conditions: string,
    status: string,
    truckID: string,
    truckMasterId: string,
    driver?: any,
    tripID?: any,
    route?: any,
    
  ) {
    this.id = id;
    this.name = name;
    this.skids = skids ? skids : [];
    this.conditions = conditions;
    this.status = status;
    this.driver = driver ? driver : null;
    this.tripID = tripID;
    this.route = route;
    this.truckID = truckID;
    this.truckMasterId = truckMasterId;
  }
}

export class TruckMaker {
  static createEmpty() {
    return new Truck(null, null, [], null, null, null, null, null,null,null);
  }
  static clone(obj: Truck) {
    return new Truck(
      obj.id,
      obj.name,
      obj.skids,
      obj.conditions,
      obj.status,
      obj.truckID,
      obj.truckMasterId,
      obj.driver,
      obj.tripID,
      obj.route,
      
    );
  }
  static createTruck(obj: any) {
    return new Truck(
      obj.id,
      obj.name,
      obj.skids,
      obj.conditions,
      obj.status,
      obj.truckID,
      obj.truckMasterId,
      null,
      obj.tripID,
      obj.route,
      
    );
  }
}
