import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import * as Highcharts from "highcharts/highstock";
import HC_more from "highcharts/modules/variable-pie";
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
HC_more(Highcharts);

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) { }
  form = new FormGroup({
    currentDate: new FormControl(new Date())
  });
  lat = 29.760427;
  lng = -95.369804;
  zoom = 4;
  timestamp: any;

  todaysTrips: any[] = [];
  upcomongTrips: any[] = [];
  tripsHistory: any[] = [];
  modalRef: BsModalRef;
  modaldata: any;
  tripHistoryDetails: any[] = [];
  alertList: any[] = [];
  sortOrder: any;
  alerts: any;
  isMapOpen: boolean = true;
  status: any;
  driverID: any;
  selectCoolerName:any
  //********Pagination********
  currentPage = 1;
  page: number;
  startItem = 0;
  endItem = 10;
  route: any;
  tripMasterId: any;
  dailyTripId: any;
  upcomongTripsDetails: any;
  ddlCoolerList: any[] = [];
  stopsList: any[] = [];
  alertListByTrip: any[] = [];

  trackedData = [
    {
      time: "8:00",
      coords: "29.720628, -95.211858",
      sensorReading: "-8"
    },
    {
      time: "9:00",
      coords: "29.731828, -95.211957",
      sensorReading: "8"
    },
    {
      time: "10:00",
      coords: "29.732723, -95.216080",
      sensorReading: "5"
    },
    {
      time: "11:00",
      coords: "29.732533, -95.224670",
      sensorReading: "-7"
    },
    {
      time: "12:00",
      coords: "29.732425, -95.229917",
      sensorReading: "-3"
    },
    {
      time: "13:00",
      coords: "29.732479, -95.233291",
      sensorReading: "-6"
    },
    {
      time: "14:00",
      coords: "29.732506, -95.240225",
      sensorReading: "-4"
    },
    {
      time: "15:00",
      coords: "29.732425, -95.244036",
      sensorReading: "-8"
    },
    {
      time: "16:00",
      coords: "29.732452, -95.248658",
      sensorReading: "-8"
    }
  ];

  trackingMarker = null;

  trackingChart = {
    chart: null,
    indexFocused: null
  };
  public options: any = {
    that: this,
    chart: {
      type: "spline",
      height: 355
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    tooltip: {
      backgroundColor: '#000000bf',
      borderColor: '#000000bf',
      borderRadius: 10,
      borderWidth: 0,
      style: {
        color: '#fff'
      },
      useHTML: true,
      formatter() {
        return (
          'Temp. <img src="assets/img/ice-crystal.svg"> ' +
          this.y +
          "<sup>0</sup>C &nbsp;&nbsp;"
        );
      }
    },
    xAxis: {
      crosshair: {
        color: "#0EA72E",
        dashStyle: "ShortDash",
        snap: "true",
        width: "2",
        zIndex: "2"
      },
      gridLineColor: "#7070702b",
      gridLineWidth: 1,
      lineWidth: 1,
      categories: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00"
      ]
      // plotLines: [
      //   {
      //     color: '#1AB0DB', // Color value
      //     dashStyle: 'longdashdot', // Style of the plot line. Default to solid
      //     value: 8, // Value of where the line will appear
      //     width: 1 // Width of the line
      //   }
      // ]
    },
    yAxis: {
      gridLineColor: "#7070702b",
      gridLineWidth: 1,
      lineWidth: 1,
      min: -40,
      max: 40,
      tickInterval: 10,
      title: {
        useHTML: true,
        text: "<sup>0</sup>C (avg)",
        align: "middle"
      },
      labels: {
        overflow: "justify"
      }
    },
    legend: {
      useHTML: true,
      symbolWidth: 0,
      labelFormatter: function () {
        return '<div>' +
          '<div style="border-radius: 50%; width: 10px; height: 10px; border:2px solid ' + this.color + '; display: inline-block; margin-right:5px"> </div>' +
          "<span style='color:#666666;font-waight:300;opacity:0.7; font-family:proximaNovaBold'> " + this.name + " </span>" +
          '</div>'
      }

    },
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            mouseOver: function (event) {
              this.fetchTruckLocationFromGraphByIndex(event.target.x);
              console.log(this.trackingMarker);
            }.bind(this),
            mouseOut: function (event) {
              if (this.trackingChart.indexFocused != null) {
                event.preventDefault();
                return true;
              }
              this.trackingMarker = null;
              console.log("mouseout");
            }.bind(this)
          }
        }
      }
    },
    series: [
      {
        that: this,
        name: "Actual Temperature",
        color: "#1AB0DB",
        data: [-10, 8, -15, -18, 20, 8, 7, 0, -9.5, -11, -10, 8, 15],
        marker: {
          symbol: "round"
        },
        threshold: -40,
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, "#1AB0DB"],
            [0.8, "#FFFFFF1D"]
          ]
        }
      },

      {
        lineWidth: 2,
        name: "Ideal Temperature",
        color: "#0EA72E",
        data: [-10, -10, -10, -10, -10, -10, -10, -10, -10, -10, -10, -10, -10],
        marker: {
          enabled: false
        },
        fillColor: {
          // linearGradient: [0, 0, 0, 0],
          stops: [
            [0, "#1AB0DB"],
            [0.7, "#FFFFFF1D"]
          ]
        },
        enableMouseTracking: false
      }
    ]
  };
  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.driverID = params.driverId;
      this.tripMasterId = params.tripMasterId;
      this.dailyTripId = params.dailyTripId;
    });
    this.getUpcomingTripsByDriver();
    this.getDriverDetails();
    this.getAllAlertsByTrips();

    //this.getUpcomingTrips();
    //this.getHistoryTrips();
    this.gettripHistoryDetails();
    this.getcoolersyByTrips();
    this.getAlertsList();
    this.trackingChart.chart = Highcharts.chart("chart3", this.options);
  }

  getDriverDetails(pageNumber = 0, pageSize = 10) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      status: "today"
    };
    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driverID + '/trips', body).subscribe(driverDetailTodayResponse => {
      console.log(driverDetailTodayResponse);
      this.todaysTrips = driverDetailTodayResponse.data.rows;
    });

    /*this.todaysTrips = [
      {
        id: "1",
        truckID: 'ABC1234',
        route: 'F 30',
        tripID: '#12567',
        coolers: '3',
        startTrip: '3333 Raleigh St, Houston',
        endTrip: 'The branch school, Houston',
        date: 'Jan 17, 2020 10:30 am',
        missedStop: '02',
        alerts: '1 alert',
        tripTracking: 'History',
        status: 'In Progress'
      },
      {
        id: "2",
        truckID: 'ABC1234',
        route: 'F 30',
        tripID: '#12580',
        coolers: '2',
        startTrip: '4994 Woodbridge Lane,Houston',
        endTrip: '4352 Payne Street, Houston',
        date: 'Jan 18, 2020 11:15 am',
        missedStop: '-',
        alerts: '-',
        tripTracking: 'History',
        status: 'To Do'
      },
    ];*/
  }
  getUpcomingTripsByDriver() {
    this.commonService.getData('get', '/v0/api/v1/trips/'+ this.tripMasterId + '/daily-trip/' + this.dailyTripId+'/details').subscribe(resp => {
      console.log("UptripDetails :",resp);
      this.upcomongTripsDetails = resp.data;
    });
  }
  getcoolersyByTrips() {
    this.commonService.getData('get', '/v0/api/v1/trips/' + this.tripMasterId + '/coolers').subscribe(resp => {
      console.log("coolers :", resp);
      this.ddlCoolerList = resp.data;
    });
  }
  getAllStopsByTrips(coolerId) {
    let param = {
      coolerId: coolerId,
    }
    this.commonService.getData('get', '/v0/api/v1/trips/' + this.tripMasterId + '/daily-trip/' + this.dailyTripId + '/stops', param).subscribe(resp => {
      console.log("stops :", resp);
      this.stopsList = resp.data;
    });
  }
  getAllAlertsByTrips() {
    let param = {
      pageIndex: 0,
      pageSize: 100,
      dailyTripId: this.dailyTripId,
    }
    this.commonService.getData('get', '/v0/api/v1/alerts', param).subscribe(resp => {
      console.log("stops :", resp);
      this.alertListByTrip = resp.data;
    });
  }
  getUpcomingTrips(pageNumber = 0, pageSize = 10) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      status: "upcoming"
    };
    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driverID + '/trips', body).subscribe(driverDetailUpcomingResponse => {
      console.log(driverDetailUpcomingResponse);
      this.upcomongTrips = driverDetailUpcomingResponse.data.rows;
    });

    /*this.upcomongTrips = [{
      id: "1",
      driverID: 'ABC1234',
      route: 'F 30',
      tripID: '#12621',
      coolers: '3',
      startTrip: '3333 Raleigh St, Houston',
      endTrip: 'The branch school, Houston',
      date: 'Jan 17, 2020 10:30 am',
      status: 'To Do'
    },
    {
      id: "2",
      driverID: 'ABC1234',
      route: 'F 30',
      tripID: '#12620',
      coolers: '2',
      startTrip: '4994 Woodbridge Lane,Houston',
      endTrip: '4352 Payne Street, Houston',
      date: 'Jan 18, 2020 11:15 am',
      status: 'To Do'
    },]*/
  }

  getHistoryTrips(pageNumber = 0, pageSize = 10) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      status: "history"
    };
    this.commonService.getData('get', '/v0/api/v1/drivers/' + this.driverID + '/trips', body).subscribe(driverDetailHistoryResponse => {
      console.log(driverDetailHistoryResponse);
      this.tripsHistory = driverDetailHistoryResponse.data.rows;
    });

    /*this.tripsHistory = [{
      id: "1",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12540',
      coolers: '3',
      startTrip: '3333 Raleigh St, Houston',
      endTrip: 'The branch school, Houston',
      date: 'Jan 17, 2020 10:30 am',
      missedStop: '02',
      alerts: '1 Alert',
      tripTracking: 'History',
      status: 'Completed'
    },
    {
      id: "3",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12536',
      coolers: '2',
      startTrip: '4994 Woodbridge Lane,Houston',
      endTrip: '4352 Payne Street, Houston',
      date: 'Jan 18, 2020 11:15 am',
      missedStop: '-',
      alerts: '-',
      tripTracking: 'History',
      status: 'Completed'
    },
    {
      id: "4",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12533',
      coolers: '3',
      startTrip: '3333 Raleigh St, Houston',
      endTrip: 'The branch school, Houston',
      date: 'Jan 17, 2020 10:30 am',
      missedStop: '02',
      alerts: '1 Alert',
      tripTracking: 'History',
      status: 'Completed'
    },
    {
      id: "5",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12532',
      coolers: '2',
      startTrip: '4994 Woodbridge Lane,Houston',
      endTrip: '4352 Payne Street, Houston',
      date: 'Jan 18, 2020 11:15 am',
      missedStop: '-',
      alerts: '-',
      tripTracking: 'History',
      status: 'Completed'
    },
    {
      id: "6",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12525',
      coolers: '3',
      startTrip: '3333 Raleigh St, Houston',
      endTrip: 'The branch school, Houston',
      date: 'Jan 17, 2020 10:30 am',
      missedStop: '02',
      alerts: '1 Alert',
      tripTracking: 'History',
      status: 'Completed'
    },
    {
      id: "7",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12520',
      coolers: '2',
      startTrip: '4994 Woodbridge Lane,Houston',
      endTrip: '4352 Payne Street, Houston',
      date: 'Jan 18, 2020 11:15 am',
      missedStop: '-',
      alerts: '-',
      tripTracking: 'History',
      status: 'Completed'
    }, {
      id: "8",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12519',
      coolers: '3',
      startTrip: '3333 Raleigh St, Houston',
      endTrip: 'The branch school, Houston',
      date: 'Jan 17, 2020 10:30 am',
      missedStop: '02',
      alerts: '1 Alert',
      tripTracking: 'History',
      status: 'Completed'
    },
    {
      id: "9",
      truckID: 'ABC1234',
      route: 'F 30',
      tripID: '#12518',
      coolers: '2',
      startTrip: '4994 Woodbridge Lane,Houston',
      endTrip: '4352 Payne Street, Houston',
      date: 'Jan 18, 2020 11:15 am',
      missedStop: '-',
      alerts: '-',
      tripTracking: 'History',
      status: 'Completed'
    }
    ]*/
  }
  gettripHistoryDetails() {
    this.tripHistoryDetails = [
      {
        id: '1',
        stops: 1,
        time: '08:00 AM',
        temp: '10',
        coolertemp: '4',
        location: '900 Portway Dr, Houston, TX 77024',
        isArrival: true
      },
      {
        id: '2',
        stops: 2,
        time: '09:00 AM',
        temp: '16',
        coolertemp: '12',
        location: '2200 W Loop S, Houston, TX 77056',
        isArrival: true
      },
      {
        id: '3',
        stops: 3,
        time: '10:00 AM',
        temp: '17',
        coolertemp: '3',
        location: 'W Loop S, Houston, TX',
        isArrival: false
      },
      {
        id: '4',
        stops: 4,
        time: '11:30 AM',
        temp: '9',
        coolertemp: '1',
        location: 'I-69, Houston, TX 77006',
        isArrival: true
      },
      {
        id: '5',
        stops: 5,
        time: '12:00 PM',
        temp: '7',
        coolertemp: '3',
        location: 'Eastex Fwy, Houston, TX 77003',
        isArrival: true
      },
    ]
  }
  getAlertsList() {
    this.alertList = [
      {
        id: '1',
        alertType: 'Temperature',
        alertName: 'Vehicle Supply Air - Temp 23487',
        reading: '18',
        duration: '2',
        openTime: '4',
        Description: 'For Truck ABC1234 door has been open since 5 minutes',
        status: 'Open'
      },
      {
        id: '1',
        alertType: 'Door',
        alertName: 'Door Open/Close Light Sensor 23157',
        reading: '-',
        duration: '5',
        openTime: '8',
        Description: 'Truck ABC1234, Cooler 1 temperature above normal level',
        status: 'Closed'
      },
    ]

  }

  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    //this.returnedSensorsList = this.sensorsList.slice(this.startItem, this.endItem);
  }
  openModal(template: TemplateRef<any>, listData: any, colName) {
    if (colName == "missedStop") {
      this.modalRef = this.modalService.show(template, { class: 'modal-mdm modal-dialog-centered' });
      this.modaldata = listData;
    } else
      if (colName == "alert") {
        this.modalRef = this.modalService.show(template, { class: 'modal-md modal-dialog-centered' });
      } else
        if (colName == "tripTracking") {
          this.modalRef = this.modalService.show(template, { class: 'add-alert modal-dialog-centered' });
        } else

          if (colName == 'tripTrackingGraph') {
            this.modalRef = this.modalService.show(template, { class: 'modal-lg modal-dialog-centered' });
            setTimeout(() => {
              this.route = this.trackedData.map(e => e.coords);
              this.trackingChart.chart = Highcharts.chart("chart3", this.options);
            }, 500);
          }

  }
  closeModal() {
    this.modalRef.hide();
  }
  sortTodayslist(type, prop, asc) {
    this.todaysTrips = this.todaysTrips.sort(function (a, b) {
      if (asc) {
        return ((a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0);
      } else {
        return ((b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }
  sortupcomingtrips(type, prop, asc) {
    debugger;
    this.upcomongTrips = this.upcomongTrips.sort(function (a, b) {
      if (asc) {
        return ((a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0);
      } else {
        return ((b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }
  sorthistorytrips(type, prop, asc) {
    this.tripsHistory = this.tripsHistory.sort(function (a, b) {
      if (asc) {
        return ((a[prop] > b[prop]) ? 1 : (a[prop] < b[prop]) ? -1 : 0);
      } else {
        return ((b[prop] > a[prop]) ? 1 : (b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }
  sortAlertlist(type, prop, asc) {
    this.alertList = this.alertList.sort(function (a, b) {
      if (asc) {
        return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
      } else {
        return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
      }
    });
  }


  getLongLats(str) {
    const coords = str.split(",");
    return {
      lat: Number(coords[0]),
      long: Number(coords[1])
    };
  }

  public fetchTruckLocationFromGraph(str) {
    this.trackedData.some(e => {
      if (e.time == str) {
        this.trackingMarker = {
          lat: this.getLongLats(e.coords).lat,
          long: this.getLongLats(e.coords).long
        };
        return true;
      }
    });
  }

  public fetchTruckLocationFromGraphByIndex(index) {
    const el = this.trackedData[index];
    if (el) {
      this.trackingMarker = {
        lat: this.getLongLats(el.coords).lat,
        long: this.getLongLats(el.coords).long
      };
    }
    // else {
    //   this.trackingMarker = null;
    // }
    console.log(el);
  }

  focusOnSpline(index) {
    console.log(index);
    console.log(this.trackingChart);
    this.trackingChart.chart.series[0].points[index].setState("hover");
    this.fetchTruckLocationFromGraphByIndex(index);
    this.trackingChart.chart.tooltip.refresh(
      this.trackingChart.chart.series[0].data[index]
    );
    this.trackingChart.indexFocused = index;
  }

  unfocusOnSpline() {
    if (this.trackingChart.indexFocused != null) {
      this.trackingChart.chart.series[0].points[
        this.trackingChart.indexFocused
      ].setState();
      console.log("OUT");
    }
    this.trackingChart.chart.tooltip.hide();
    this.trackingChart.indexFocused = null;
    this.trackingMarker = null;
  }
  nextDay() {
    const currentDate = Date.parse(this.form.get("currentDate").value);
    this.form.patchValue({ currentDate: new Date(currentDate + 8.64e7) });
  }

  prevDay() {
    const currentDate = Date.parse(this.form.get("currentDate").value);
    this.form.patchValue({ currentDate: new Date(currentDate - 8.64e7) });
  }

  onCoolerSelect(eve, ) {
    let val = eve.target.value
    this.getAllStopsByTrips(val);
    this.selectCoolerName = this.ddlCoolerList.filter(x => x.assetMasterId == val)[0];
  }
}
