import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  username: any;
  password: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private commonService: CommonService, 
    public auth: AuthService
  ) { 
      const redirect = '/home';
      if (this.auth.isAuthenticated$){
      this.router.navigate([redirect]);
    } else {
      this.auth.login();
    }
}

  ngOnInit() {
    
    // this.createForm();
    
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(form) {
    if (!this.loginForm.valid) {
      if (!form.username && !form.password) {
        this.commonService.showToast('Please enter valid Credentials', 0);
      } else {
        if (!form.username) {
          this.commonService.showToast('Please enter username', 0);
        }
        if (!form.password) {
          this.commonService.showToast('Please enter password', 0);
        }
      }
      return false;
    }
    this.login(this.loginForm.value);
  }

  login(loginDetails) {
    if(loginDetails.username == "shahrzad" && loginDetails.password == "12345"){
      const redirect = '/home';
      let data = {
        username: loginDetails.username,
        password: loginDetails.password
      }
      localStorage.setItem('loginDetails', JSON.stringify(data));
      this.router.navigate([redirect]);
    } else {
      this.commonService.showToast('Invalid Credentials', 0);
    }
    this.loginForm.reset();
  }

}
