import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modify-access',
  templateUrl: './modify-access.component.html',
  styleUrls: ['./modify-access.component.scss']
})
export class ModifyAccessComponent implements OnInit,OnDestroy {
  userHeaderRoleAccess: any;
  userHeaderRoleAccessElements: any;
  paramRoleMasterId: any;
  userRoles: any;
  roleName: any;
  roleNameParam: any;
  addRoleForm: FormGroup;
  search: any;
  refreshSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) { 
    this.route.queryParams.subscribe(param => {
      this.paramRoleMasterId = param.roleMasterId;
      this.roleNameParam = param.roleName;
    });
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getUserRoleAccess();
      this.getUserRoles();
    });
  }
  AdminStatus = "Active"
  modalRef: any;
  accessList: any;
  tableRowToCollapse = -1;
  fakeVal = true;
  userRoleAccessDetails: any;
  modifiedAccessBody = {
    addPermissions: [],
    removePermissions: []
  }
 
  ngOnInit() {
    this.getAccessList();
    this.getUserRoleAccess();
    this.getUserRoles();
    this.createForm();
  }
  getAccessList() {
    this.accessList = [
      {
        elements: 'Truck List',
        admin: {
          web: true,
          mobile:true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: false,
          mobile: false
        },
        driver: {
          web: false,
          mobile: false
        },
      },
      {
        elements: 'Trip Tracker',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: false,
          mobile: false
        },
        driver: {
          web: false,
          mobile: false
        },
      },
      {
        elements: 'Resource Planning',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: false,
          mobile: false
        },
        driver: {
          web: false,
          mobile: true,
        },
      },
      {
        elements: 'Workflow',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: false,
          mobile: false
        },
        driver: {
          web: false,
          mobile: true,
        },
      },
      {
        elements: 'Route List',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: false,
          mobile: false
        },
        driver: {
          web: false,
          mobile: false,
        },
      },
      {
        elements: 'Sensor List',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: true,
          mobile: false
        },
        driver: {
          web: false,
          mobile: false,
        },
      },
      {
        elements: 'Trip Planner',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: false,
          mobile: true
        },
        driver: {
          web: false,
          mobile: true,
        },
      },
      {
        elements: 'Dashboard',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: true,
          mobile: false
        },
        driver: {
          web: false,
          mobile: false,
        },
      },
      {
        elements: 'Reports',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: false,
          mobile: false
        },
        dispatchManager: {
          web: true,
          mobile: false
        },
        driver: {
          web: false,
          mobile: false,
        },
        subElement: [{
          elements: 'Report 1',
          admin: {
            web: true,
            mobile: true
          },
          supervisor: {
            web: false,
            mobile: false
          },
          dispatchManager: {
            web: true,
            mobile: false
          },
          driver: {
            web: false,
            mobile: false,
          },

        },
          {
            elements: 'Report 2',
            admin: {
              web: true,
              mobile: true
            },
            supervisor: {
              web: false,
              mobile: false
            },
            dispatchManager: {
              web: true,
              mobile: false
            },
            driver: {
              web: false,
              mobile: false,
            },
          }]
      },
      {
        elements: 'Alerts Management',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: true,
          mobile: true
        },
        dispatchManager: {
          web: true,
          mobile: true
        },
        driver: {
          web: false,
          mobile: true,
        },
      },
      {
        elements: 'User Roles',
        admin: {
          web: true,
          mobile: true
        },
        supervisor: {
          web: true,
          mobile: false
        },
        dispatchManager: {
          web: true,
          mobile: true
        },
        driver: {
          web: false,
          mobile: true,
        },
      }
    ]
  }
  onAddRole(template) {
    // console.log(template);
    this.addRoleForm.reset();
    this.roleName = '';
    this.modalRef = this.modalService.show(template, {
      class: 'modal-sm add-role', ignoreBackdropClick: true
    });
  }

  onCancel() {
    this.addRoleForm.reset();
    this.modalRef.hide();
  }
  collapseRow(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  getUserRoleAccess(){
    this.commonService.getData('get', '/v0/api/v1/user-roles/access').subscribe(res => {
      this.userHeaderRoleAccess = res.roles;
      this.userHeaderRoleAccessElements = res.roles.permissionTypes;
      this.userRoleAccessDetails = res.data;
    })
  }

  checkedAccess(rolePermissionId,roleMasterId,permissionMasterId,permissionTypeMasterId, event) {
    let edited = 0;
    if(event.target.checked){
      this.modifiedAccessBody.removePermissions.forEach((e, i, object) => {
        if (e.rolePermissionId == rolePermissionId && e.permissionTypeMasterId == permissionTypeMasterId) {
          object.splice(i, 1);
          edited++;
        }
      })
    } else {
      this.modifiedAccessBody.addPermissions.forEach((e, i, object) => {
        if (e.rolePermissionId == rolePermissionId && e.permissionTypeMasterId == permissionTypeMasterId && e.roleMasterId == roleMasterId && e.permissionMasterId == permissionMasterId) {
          object.splice(i, 1);
          edited++;
        }
      })
    }
    if(edited > 0){
      return;
    }
    if(event.target.checked){
      if(rolePermissionId){
        this.modifiedAccessBody.addPermissions.push(
          {
            "rolePermissionId": parseInt(rolePermissionId),
            "permissionTypeMasterId": parseInt(permissionTypeMasterId),
            "roleMasterId": parseInt(roleMasterId),
            "permissionMasterId": parseInt(permissionMasterId)
          })
      } else {
        this.modifiedAccessBody.addPermissions.push(
          {
            "permissionTypeMasterId": parseInt(permissionTypeMasterId),
            "roleMasterId": parseInt(roleMasterId),
            "permissionMasterId": parseInt(permissionMasterId)
          })
      }
      
    } else {
      if(rolePermissionId) {
        this.modifiedAccessBody.removePermissions.push(
          {
            "rolePermissionId": parseInt(rolePermissionId),
            "permissionTypeMasterId": parseInt(permissionTypeMasterId)
          })
      } 
      // else {
      //   this.modifiedAccessBody.removePermissions.push(
      //     {
      //       "permissionTypeMasterId": parseInt(permissionTypeMasterId)
      //     })
      // }
      
    }
  }

  updateAccess() {
    if(this.modifiedAccessBody.addPermissions.length == 0 && this.modifiedAccessBody.removePermissions.length == 0){
      this.commonService.showToast('Please add permissions to the role', 0);
      return;
    }
    this.commonService.getData('put', '/v0/api/v1/user-roles/access', this.modifiedAccessBody).subscribe(res => {
      if(res) {
        this.modifiedAccessBody.addPermissions = [];
        this.modifiedAccessBody.removePermissions = [];
      }
    })
  }

  getUserRoles() {
    let body = {
      search: this.search,
    }
    this.commonService.getData('get', "/v0/api/v1/user-roles", body).subscribe(res => {
      this.userRoles = res.data;
      
    });
  }
  createForm() {
    this.addRoleForm = this.fb.group({
      roleName: new FormControl('', Validators.required)
    });
  }
  createRole() {
    this.addRoleForm.controls["roleName"].markAsTouched();

    let roleName = this.addRoleForm.controls["roleName"].value;
    if (this.addRoleForm.valid) {
      let body = {
        "roleName": roleName,
        "roleDescription": ""
      }
      this.commonService.getData('post', '/v0/api/v1/roles', body).subscribe(res => {
        if (res.data == 'Role added successfully') {
          // this.getRoleList();
          this.modalRef.hide();
          this.getUserRoleAccess();
          this.getUserRoles();
        }
      });
    }
  }
  onCancelClick() {
    this.router.navigate(['/user-role']);
    //this.router.navigate(['/user-role-driver'], { queryParams: { roleMasterId: this.paramRoleMasterId, roleName: this.roleNameParam } } );
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}
