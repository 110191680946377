//import { formatDate } from '@angular/common';
export class CoolerListExport {
    coolerId: any;
    coolerType: any;
    currentTemperature: any;
    statusValue: any;
    currentTemperatureStatus: any;
    units: any;
    date: any;
    totalAlerts: any;
    coolerStatus: any;
    alertStatus: any;

    constructor(
        coolerId: any,
        coolerType: any,
        currentTemperature: any,
        statusValue: any,
        currentTemperatureStatus: any,
        units: any,
        date: any,
        totalAlerts: any,
        coolerStatus: any,
        alertStatus: any,
    ) {
        this.coolerId = coolerId;
        this.coolerType = coolerType;
        this.currentTemperature = currentTemperature;
        this.statusValue = statusValue;
        this.currentTemperatureStatus = currentTemperatureStatus;
        this.units = units;
        this.date = date;
        this.totalAlerts = totalAlerts;
        this.coolerStatus = coolerStatus;
        this.alertStatus = alertStatus;
    }
}
export class CoolerListExportMaker {
    static exportToExcel(obj: CoolerListExport) {
        let selectedUnit = obj.units;
        if (selectedUnit && (selectedUnit.toLowerCase() == 'f' || selectedUnit.toLowerCase() == 'c')) {
            selectedUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType').toLowerCase() : 'c';

            var currentTemperature;
            var statusValue;
            if (selectedUnit == 'f') {
                currentTemperature = this.convertTemp(obj.currentTemperature);
                statusValue = this.convertTemp(obj.statusValue);
            } else {
                currentTemperature = obj.currentTemperature;
                statusValue = obj.statusValue;
            }
        } else {
            currentTemperature = obj.currentTemperature;
            statusValue = obj.statusValue;
        }
        let returnData = new CoolerListExport(
            obj.coolerId,
            obj.coolerType,
            currentTemperature,
            statusValue ? (statusValue*100)/100 : statusValue,
            obj.currentTemperatureStatus,
            selectedUnit,
            obj.date,
            obj.totalAlerts,
            obj.coolerStatus,
            obj.alertStatus,
        )
        return returnData;
    }
    static convertTemp(temp): any {
        let modifiedTemp;
        modifiedTemp = (temp * 9 / 5) + 32;
        modifiedTemp = Math.round(parseFloat(modifiedTemp) * 10) / 10
        return modifiedTemp;
    }
}
