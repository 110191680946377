export class OngoingTruckAlert {
  id: string;
  img: string;
  sensorName: string;
  alert: string;
  duration: any;
  currentTemperature: number;
  idealRange: number;
  idealRangeStart: number;
  idealRangeEnd: number;
  status: string;
  startRange:any;
  endRange:any;
  units:any;
  alertColor:any;

  constructor(
    id: string,
    img: string,
    sensorName: string,
    alert: string,
    duration: any,
    currentTemperature: number,
    idealRange: number,
    idealRangeStart: number,
    idealRangeEnd: number,
    status: string,
    startRange:any,
    endRange:any,
    units:any,
    alertColor?:any
  ) {
    this.id = id;
    this.img = img;
    this.sensorName = sensorName;
    this.alert = alert;
    this.duration = duration;
    this.currentTemperature = currentTemperature;
    this.idealRange = idealRange;
    this.idealRangeStart = idealRangeStart;
    this.idealRangeEnd = idealRangeEnd;
    this.status = status;
    this.startRange=startRange;
    this.endRange=endRange;
    this.units=units;
    this.alertColor = alertColor ? alertColor : null;
  }
}

export class OngoingTruckAlertMaker {
  static clone(obj: any) {
    return new OngoingTruckAlert(
      obj.id,
      obj.img,
      obj.sensorName,
      obj.alert,
      obj.duration,
      obj.currentTemperature,
      obj.idealRange,
      obj.idealRangeStart,
      obj.idealRangeEnd,
      obj.status,
      obj.startRange,
      obj.endRange,
      obj.units,
      obj.alertColor
    );
  }

  static castInto(obj: any) {
    let icon = 'questionmark';
   if(obj.alert.toLowerCase() == 'cooler') {
     if(obj.coolerType.toLowerCase() == 'refrigerated') {
      icon = 'ref-list-icon'
     }if(obj.coolerType.toLowerCase() == 'ambient') {
      icon = 'amb-list-icon'
     }
     if(obj.coolerType.toLowerCase() == 'frozen') {
      icon = 'frozen-list-icon'
     }
   } else {
    if(obj.alert.toLowerCase() == 'door') {
      icon = 'door-list-icon'
     }
     if(obj.alert.toLowerCase() == 'truck') {
      icon = 'truck-icon-list'
     }
   }
    return new OngoingTruckAlert(
      obj.alert.toLowerCase() === 'truck' || obj.alert.toLowerCase() === 'door' ? '-' : obj.coolerId,
      icon,
      obj.sensorName,
      obj.alert.toLowerCase() === 'truck' || obj.alert.toLowerCase() === 'door' ? obj.alert : obj.coolerType,
      obj.duration,
      obj.currentTemperature,
      obj.idealRange,
      obj.idealStart,
      obj.idealEnd,
      obj.status,
      obj.startRange,
      obj.endRange,
      obj.units,
      obj.alertColor.toLowerCase()
    );
  }
}
