import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { tap, mergeMap, catchError } from 'rxjs/operators';
import { UserService, CommonService } from "../index";
import { environment } from "../../../environments/environment";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../auth.service';

@Injectable()
export class CommonHttpInterceptor implements HttpInterceptor {
    private baseUrl;
    constructor(
        private inj: Injector,
        private router: Router,
        private commonService: CommonService,
        private spinner: NgxSpinnerService
    ) {
        this.baseUrl = environment["baseUrl"];
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.spinner.show();
        let userService = this.inj.get(UserService);
        let authService = this.inj.get(AuthService);
        let headers = req.headers;
        headers = headers.set("Content-Language", "en");
        let exculedToastUrls = ['https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken'];

        return authService.getTokenSilently$().pipe(
            mergeMap(token => {
                let reqCloned: any;
                let requestUrl = JSON.stringify(req);
                if (requestUrl.includes('https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken')) {
                    reqCloned = req.clone({
                        url: "https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken",
                        setHeaders: { Authorization: `Bearer ${token}` }
                    });
                 } else {
                    reqCloned = req.clone({
                        url: this.baseUrl + req.url,
                        setHeaders: { Authorization: `Bearer ${token}` }
                    });
                 }

                return next.handle(reqCloned).pipe(tap(
                    (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.spinner.hide();
                            // show message if available
                            let resBody: any = event.body;
                            console.log(typeof (resBody));
                            if (typeof (resBody) == "string") {
                                resBody = JSON.parse(resBody);
                            }
                            if (resBody && resBody.message && !exculedToastUrls.includes(event.url)) {
                                let status = resBody.status;
                                if(status == undefined) {
                                    status = event.status == 200 ? 1 : 0;
                                }
                                this.commonService.showToast(resBody.message, status);
                            }
                        }
                    },
                    (err: any) => {
                        //handle errors in global section
                        if (err instanceof HttpErrorResponse) {
                            this.spinner.hide();
                            if (err.error && err.error.code === 401) {
                                // redirect to the login route
                                // or show a modal
                                userService.logout();
                                return;
                            }

                            if (err.error && err.status && err.error.message) {
                                this.commonService.showToast(err.error.message, 0);
                            } else if (err.error && err.status && err.error.errors) {
                                this.commonService.showToast(err.status + " - " + err.error.errors, 0);
                            }
                        }
                    }
                ));
            }),
            catchError(err => throwError(err))
        );

        // let reqCloned: any;
        // if (req.body && req.body.apiType == 'uploadFile') {
        //     reqCloned = req.clone({
        //         url: this.baseUrl + req.url,
        //         body: req.body.formData,
        //         headers: headers
        //     });
        // } else {
        //     reqCloned = req.clone({
        //         url: this.baseUrl + req.url,
        //         headers: headers
        //     });
        // }
    }
}