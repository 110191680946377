export class OutOfRangeReportModel {
    truckId: any;
    driverName: any;
    coolerId: any;
    coolerType: any;
    status: any;
    belowAbove: any;
    pickupDatetime: any;
    tempAtPickup: any;
    alertDatetime: any;
    temp: boolean;
    dropoffDatetime: Date;
    tempAtDropoff: any;
    timeInTransit: any;
    deliveredInAcceptableRange: any;
    groupedData: any[];
    dropoffCoolerColor: any;
    pickUpCoolerColor: any;
    isRowExpanded: boolean
  
    constructor(
      truckId: any,
      driverName: any,
      coolerId: any,
      coolerType: any,
      status: any,
      belowAbove: any,
      pickupDatetime: any,
      tempAtPickup: any,
      alertDatetime: any,
      temp: any,
      dropoffDatetime: any,
      tempAtDropoff: any,
      timeInTransit: any,
      deliveredInAcceptableRange: any,
      groupedData: any[],
      dropoffCoolerColor: any,
      pickUpCoolerColor: any,
      isRowExpanded: boolean
    ) {
      this.truckId = truckId;
      this.driverName = driverName;
      this.coolerId = coolerId;
      this.coolerType = coolerType;
      this.status = status;
      this.belowAbove = belowAbove;
      this.pickupDatetime = pickupDatetime;
      this.alertDatetime = alertDatetime;
      this.tempAtPickup = tempAtPickup;
      this.temp = temp;
      this.dropoffDatetime = dropoffDatetime;
      this.tempAtDropoff = tempAtDropoff;
      this.timeInTransit = timeInTransit;
      this.deliveredInAcceptableRange = deliveredInAcceptableRange;
      this.groupedData = groupedData,
      this.dropoffCoolerColor = dropoffCoolerColor,
      this.pickUpCoolerColor = pickUpCoolerColor,
      this.isRowExpanded = isRowExpanded
    }
  }
  
  export class OutOfRangeReportModelMaker {
    static create() {
      return new OutOfRangeReportModel(null, null, null, null, null, null, null, null, null, null, null, null, null, null, [], null, null, false);
    }
  
    static castInto(obj: any) {
      return new OutOfRangeReportModel(
        obj.truckId,
        obj.driverName,
        obj.coolerId,
        obj.coolerType,
        obj.status,
        obj.belowAbove,
        obj.pickupDatetime,
        obj.tempAtPickup,
        obj.alertDatetime,
        obj.temp,
        obj.dropoffDatetime,
        obj.tempAtDropoff,
        obj.timeInTransit,
        obj.deliveredInAcceptableRange,
        obj.groupedData,
        obj.dropoffCoolerColor,
        obj.pickUpCoolerColor,
        false
      );
    }
  
    static clone(obj: OutOfRangeReportModel) {
      return new OutOfRangeReportModel(
        obj.truckId,
        obj.driverName,
        obj.coolerId,
        obj.coolerType,
        obj.status,
        obj.belowAbove,
        obj.pickupDatetime,
        obj.tempAtPickup,
        obj.alertDatetime,
        obj.temp,
        obj.dropoffDatetime,
        obj.tempAtDropoff,
        obj.timeInTransit,
        obj.deliveredInAcceptableRange,
        obj.groupedData,
        obj.dropoffCoolerColor,
        obj.pickUpCoolerColor,
        false
      );
    }
  
  }
  