import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreDataService {

  constructor() { }
  public alertUserData: any[] = [];
  //Driver Role
  public alreadyAddedDriverList = [];
  public alertManagementData = {
    "alertConfigId": 0,
    "alertName": "",
    "alertType": "",
    "status": 0,
    "createdOn": "",
    "createdBy": "",
    "subscription": [],
    "asset": "",
    "minValue": 0,
    "maxValue": 0,
    "tolerance": 0,
    "toleranceUnits": "",
    "escalation": 0,
    "escalationUnits": "",
    "firstAlert": [],
    "escalationAlert": [],
    //"firstAccessTypeName": "",
    //"escalatedAccessType": "",
    //"roledata": 0,
    "userdata": 0,
    // "escRoledataLen": 0,
    "escUserdataLen": 0,
    "addUsersSource": ""
  };

  public alertFirstUserDataForCreate: any[] = [];
  public alertEscUserDataForCreate: any[] = [];
  public alertManagementDataForCreate = {
    "alertConfigId": 0,
    "alertName": '',
    "alertType": '',
    "status": 1,
    "subscription": [],
    "asset": '',
    "minValue": 0,
    "maxValue": 0,
    "tolerance": 0,
    "toleranceUnits": '',
    "escalation": 0,
    "escalationUnits": '',
    "firstAlert": [],
    "firstAlertUser": [],
    "escalationAlert": [],
    "escalationAlertUser": [],
  }
  resteCreateAlertdata() {
    this.alertManagementDataForCreate = {
      "alertConfigId": 0,
      "alertName": '',
      "alertType": '',
      "status": 1,
      "subscription": [
        {
          checked: false,
        },
        {
          checked: false,
        }
      ],
      "asset": '',
      "minValue": 0,
      "maxValue": 0,
      "tolerance": 0,
      "toleranceUnits": '',
      "escalation": 0,
      "escalationUnits": '',
      "firstAlert": [],
      "firstAlertUser": [],
      "escalationAlert": [],
      "escalationAlertUser": [],
    }
  }
}



