import { CommonService} from "../../services/common/common.service"
import { from } from 'rxjs';
export class AlertListExport {
    sensorAlertName: any;
    truckId: any;
    currentTempStatusValue: any;
    currentTemp: any;
    idealRange: any;
    dateTime: any;
    openTime: any;
    description: any;
    status: any;
    units: any;
    startRange: any;
    endRange: any;


    constructor(
        sensorAlertName: any,
        truckId: any,
        currentTempStatusValue: any,
        currentTemp: any,
        idealRange: any,
        units: any,
        dateTime: any,
        openTime: any,
        description: any,
        status: any,
        startRange: any,
        endRange: any,
    ) {
        this.sensorAlertName = sensorAlertName;
        this.truckId = truckId;
        this.currentTempStatusValue = currentTempStatusValue;
        this.currentTemp = currentTemp;
        this.idealRange = idealRange;
        this.units = units;
        this.dateTime = dateTime;
        this.openTime = openTime;
        this.description = description;
        this.status = status;
        this.startRange = startRange;
        this.endRange = endRange;
    }
}
export class AlertListExportMaker {
    static create() {
        return new AlertListExport(null, null, null, null, null, null, null, null, null, null,null,null);
    }
    static exportToExcel(obj: AlertListExport) {
        let selectedUnit = obj.units;
        if (selectedUnit && (selectedUnit.toLowerCase() == 'f' || selectedUnit.toLowerCase() == 'c')) {
            selectedUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType').toLowerCase() : "c";
            var startRange;
            var endRange;
            var currentTemp;
            if (selectedUnit == 'f') {
                startRange = this.convertTemp(obj.startRange);
                endRange = this.convertTemp(obj.endRange);
                currentTemp = this.convertTemp(obj.currentTemp);
            } else {
                startRange = obj.startRange;
                endRange = obj.endRange;
                currentTemp = obj.currentTemp;
            }
        } else {
            startRange = obj.startRange;
            endRange = obj.endRange;
            currentTemp = obj.currentTemp;
        }
        let returnData= new AlertListExport(
            obj.sensorAlertName,
            obj.truckId,
            obj.currentTempStatusValue,
            currentTemp ,
            startRange + " to " + endRange,
            selectedUnit.toUpperCase(),
            obj.dateTime,
            obj.openTime,
            obj.description,
            obj.status,
            null,
            null
        )
    
        for (var propName in returnData) {
            if (returnData[propName] === null || returnData[propName] === undefined) {
                delete returnData[propName];
            }
        }
        return returnData;
    }
    static convertTemp(temp):any {
        let modifiedTemp;
        modifiedTemp = (temp * 9 / 5) + 32;
        modifiedTemp = Math.round(parseFloat(modifiedTemp) * 10) / 10
        return modifiedTemp;
    }
}
