import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCooler',
   pure: false
})
export class FilterCoolerPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args)
      return value;
    return value.filter(
      item => (item.coolerId && item.coolerId.toLowerCase().indexOf(args.toLowerCase()) > -1) || (item.coolerType && item.coolerType.toLowerCase().indexOf(args.toLowerCase()) > -1));
  }

}
