import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Route, RouteMaker } from '../route.model';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.scss']
})
export class RouteListComponent implements OnInit {
  routeArray: Array<Route>;
  @Output('onRouteSelect') onRouteSelect = new EventEmitter();
  @Input() set selectedRouteId(newId: string) {
    this._selectedRouteId = newId;
  }
  private _selectedRouteId: any;;
  get selectedRouteId(): string {
    return this._selectedRouteId;
  }
  pageCounter = 0;
  routePerPage = 6;
  selectedRoute: any;
  routes = [];
  totalRouteCount: any;
  disabledNextBtn = false;

  constructor(
    private commonService: CommonService
  ) {
    // this.routeArray = this.routes.map(e => RouteMaker.createRoute(e));
    // console.log(this.routeArray);
    // this.paginateRouteList();
  }

  ngOnInit() {
    this.getRoutesList();
  }

  paginateRouteList(change = 'next') {
    if (change == 'next' && (this.pageCounter + 1) * this.routePerPage < this.totalRouteCount) {
      this.pageCounter++;
      this.getRoutesList(this.pageCounter, this.routePerPage);
      if ((this.pageCounter + 1) * this.routePerPage > this.totalRouteCount) {
        this.disabledNextBtn = true;
      }
    } else if (change == 'next' && (this.pageCounter + 1) * this.routePerPage == this.totalRouteCount) {
      this.disabledNextBtn = true;
    }
    else if (change == 'prev' && this.pageCounter > 0) {
      this.pageCounter--;
      this.getRoutesList(this.pageCounter, this.routePerPage);
      this.disabledNextBtn = false;
    }
    else return;
  }

  selectRoute(route) {
    this.selectedRoute = route;
    this.onRouteSelect.emit(route);
  }

  paginateDriverList(step = 'next') { }

  getRoutesList(pageNumber = 0, pageSize = this.routePerPage) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize
    };
    this.routeArray = [];
    this.routes = [];
    this.commonService.getData('get', '/v0/api/v1/routes', body).subscribe(routeListResponse => {
      console.log(routeListResponse);
      this.totalRouteCount = routeListResponse.data.count;
      routeListResponse.data.rows.forEach(element => {
        this.routes.push({
          id: element.routeMasterId,
          routeId: element.routeId,
          stops: element.stops,
          status: element.isActive ? 'active' : 'inactive',
          routeMasterId: element.routeMasterId
        });
      });
      console.log("routeA", this.routes)
      this.routeArray = this.routes.map(e => RouteMaker.createRoute(e))
      console.log("routeb", this.routeArray)
    });
  }
  //selectedRouteId: any;
  //onEditTrip(route) {
  //  this.selectedRouteId = route.id;
  //}
}
