

export class TruckListExport {
    truckId: any;
    truckType: any;
    tripId: any;
    status: any;


    constructor(
        truckId: any,
        truckType: any,
        tripId: any,
        status: any,

    ) {
        this.truckId = truckId;
        this.truckType = truckType;
        this.tripId = tripId;
        this.status = status;
     
    }
}
export class TruckListExportMaker {
    static create() {
        return new TruckListExport(null, null, null, null);
    }
    static exportToExcel(obj: any) {

        let returnData = new TruckListExport(
            obj.truckId,
            obj.truckType,
            obj.tripId,
            obj.status,
         
        )
        return returnData;
    }
}
