import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

// import { HomeService } from '../shared/services/home/home.service';
// import { Menu, MenuMaker } from '../shared/models/home/menu/menu.model';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { OnGoingTruckMaker } from 'src/app/shared/models/ongoing-truck-list/on-going-truck.model';
import { CommonService } from '../../services';
import { DatePipe } from '@angular/common';
import { ClusterOptions, ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedView = 'airport';
  blueTruck = 'assets/img/blueTruck.svg';
  redTruck = 'assets/img/redTruck.svg';
  greenTruck = 'assets/img/greenTruck.svg';
  viewMarker = false;
  zoom = 9;
  // menuItems: Array<Menu>;
  kpiCards: Array<any>;
  airportList: Array<any>;
  zonesList: Array<any>;
  listData: Array<any>;
  device: any;
  priority: any;
  energy: any;
  water: any;
  sensor: any;
  lat = 29.760427;
  lng = -95.369804;
  cityViewmarker: any;
  stateViewMarker: any[] = [];
  genericUserPic = 'assets/img/generic-user-icon.png';
  sensorsList = [];
  shouldShowinfoWindow = false;
  truckList: any;
  tableRowToCollapse = -1;
  isMapOpen = true;
  refreshSubscription: Subscription;
  datePipeAngular: DatePipe;

  clusterStyles: ClusterStyle[];
  clusterOptions: ClusterOptions;
  public mapMarkerOverTripId: string;

  constructor(
    private router: Router, // private homeService: HomeService,
    private commonService: CommonService
  ) {
    this.datePipeAngular = new DatePipe('en-US');
    this.zonesList = [];
    this.getSensorsData();

    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.zonesList = [];
      this.getSensorsData();
      this.getTruckList();
    });
  }

  getSensorsData() {
    const body = {
      currentDate: this.datePipeAngular.transform(new Date().toISOString(), 'yyyy-MM-dd')
    };
    this.commonService.getData('get', '/v0/api/v1/dashboard/sensor/count', body).subscribe(res => {
      this.sensorsList = res.data;
    });
  }

  /*Map Function start*/
  markerClickEvent(event, data) {
    this.shouldShowinfoWindow = data.truckMasterId;
    //this.shouldShowinfoWindow = true;
  }

  zoomChangeEvent(event) {
    if (event > 6) {
    } else {
      //this.shouldShowinfoWindow = false;
      this.cityViewmarker = [];
    }
  }

  /*Map Function end*/

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('Resizing');
      // this.AgmMap.nativeElement();
    }, 100);
  }

  ngOnInit() {
    this.getTruckList();
    this.clusterStyles = [
      {
          textColor: "white",
          url: "assets/img/map-cluster.svg",
          width: 40,
          height: 40
      }
  ];

  this.clusterOptions = {
        gridSize: 30,
        minimumClusterSize: 2,
        averageCenter: true
    }
  }

  getTruckList(pageNumber = 0, pageSize = 10) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize
    };
    this.commonService.getData('get', '/v0/api/v1/trips/active', body).subscribe(res => {
      // this.cityViewmarker = res.data.rows;
      if (res.data) {
        this.truckList = [];
        if(res.data.rows && res.data.rows.length == 0){
          return false;
        }
        this.setMarker(res.data.rows);
        //this.newSetMaker(res.data.rows);
        this.truckList = res.data.rows.map(e => OnGoingTruckMaker.castInto(e));


        // console.clear();
        console.log(this.truckList);
      }

    });
  }
  openMapView() {
    this.isMapOpen = !this.isMapOpen;
  }


  setMarker(trucks) {
    let body: any[] = []
    trucks.forEach(element => {
      let id = { truckId: element.truckId }
      body.push(id);
    });
    this.commonService.getData('post', '/v1/getTruckLocations', body).subscribe(res => {
      let resData: any[] = res ? res : [];

      if (resData.length > 0) {
        this.stateViewMarker = [];
        console.log(resData)
        trucks.forEach((truck) => {
          resData.forEach((e) => {
            if (e.truckid.truckId == truck.truckId) {
              let isAlreadyPushed = this.stateViewMarker.find(x => x.truckId == truck.truckId);
              if (isAlreadyPushed) {
                return;
              }
              this.stateViewMarker.push(
                {
                  latitude: e.lat,
                  longitude: e.lng,
                  iconUrl: truck.tripAlerts.length > 0 ? 'assets/img/redTruck.svg' : 'assets/img/greenTruck.svg',
                  driverName: truck.driverName,
                  startTrip: truck.startTrip,
                  endTrip: truck.endTrip,
                  truckId: truck.truckId,
                  dailyTripId: truck.dailyTripId,
                  truckMasterId: truck.truckMasterId,
                  ambientTemp: truck.ambientTemp,
                  gatewayStatus: truck.gatewayStatus,
                  tripMasterId: truck.tripMasterId,
                  profile: truck.profile,
                  tripId: truck.tripId
                })
            }
          })
        });
        console.log(this.stateViewMarker);
      }
    });
  }


  goToTruckView(list) {
    this.router.navigate(['/truck-view'], { queryParams: { truckID: list.truckMasterId, dailyTripId: list.dailyTripId, truckNumber: list.truckId } });
  }

  collapseRow(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  goToAllSensor(key) {
    this.router.navigate(['/all-sensors'], { queryParams: { key: key } });
  }

  goToAlerts(sensorName) {
    this.router.navigate(['/alerts'], { queryParams: { search: sensorName, status: 'Open' } });
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}
