import { formatDate } from '@angular/common';
export class SensorListExport {
    sensorType: any;
    sensorName: any;
    currentReading: any;
    idealRange: any;
    units: any;
    date: any;
    time: any;
    batteryLevel: any;
    signalStrength: any;
    status: any;
    startRange: any;
    endRange: any;

    constructor(
        sensorType: any,
        sensorName: any,
        currentReading: any,
        idealRange: any,
        units:any,
        date:any,
        time:any,
        batteryLevel:any,
        signalStrength: any,
        status: any,
        startRange:any,
        endRange: any,
        
    ) {
        this.sensorType = sensorType;
        this.sensorName = sensorName;
        this.currentReading = currentReading;
        this.idealRange = idealRange;
        this.units = units;
        this.date = date;
        this.time = time;
        this.batteryLevel = batteryLevel;
        this.signalStrength = signalStrength;
        this.status = status;
        this.startRange = startRange;
        this.endRange = endRange;
        
    }
}
export class SensorListExportMaker {
    static create() {
        return new SensorListExport(null, null, null, null, null, null, null, null, null, null, null,null);
    }

    static exportToExcel(obj: SensorListExport) {
        let selectedUnit = obj.units;
        if (selectedUnit && (selectedUnit.toLowerCase() == 'f' || selectedUnit.toLowerCase() == 'c')) {
            selectedUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType').toLowerCase() : 'c';
            var startRange;
            var endRange;
            var currentTemp;
            if (selectedUnit == 'f') {
                startRange = this.convertTemp(obj.startRange);
                endRange = this.convertTemp(obj.endRange);
                currentTemp = this.convertTemp(obj.currentReading);
            } else {
                startRange = obj.startRange;
                endRange = obj.endRange;
                currentTemp = obj.currentReading;
            }
        } else {
            startRange = obj.startRange;
            endRange = obj.endRange;
            currentTemp = obj.currentReading;
        }
       
        let returnData = new SensorListExport(
            obj.sensorType,
            obj.sensorName,
            currentTemp,
            startRange + " to " + endRange,
            selectedUnit.toUpperCase(),
            obj.date,
            formatDate(obj.time,"HH:mm",'en-US'),  
            obj.batteryLevel,
            obj.signalStrength,
            obj.status,
            null,
            null
        )
        for (var propName in returnData) {
            if (returnData[propName] === null || returnData[propName] === undefined) {
                delete returnData[propName];
            }
        }
        return returnData;
    }
    static convertTemp(temp): any {
        let modifiedTemp;
        modifiedTemp = (temp * 9 / 5) + 32;
        modifiedTemp = Math.round(parseFloat(modifiedTemp) * 10) / 10
        return modifiedTemp;
    }
}