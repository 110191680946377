import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../services';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SensorMaker, Sensor } from '../all-sensors/sensors.model';
import { CardsDetailMaker } from '../all-sensors/cards.model';
import { formatDate } from '@angular/common';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { Subscription } from 'rxjs';
import { SensorAlertHistoryExportMaker } from './sensor-alert-history/sensor-alert-history-export';
import { SensorHistoryExportMaker } from './sensors-history/sensor-history-export';



@Component({
  selector: 'app-sensor-details-view',
  templateUrl: './sensor-details-view.component.html',
  styleUrls: ['./sensor-details-view.component.scss']
})
export class SensorDetailsViewComponent implements OnInit {
  @Input() sensorHistoryData: any;
  recordsPerPage = 10;
  activePageNo = 0;
  totalAlertHistoryCount: any;
  totalSensorHistoryCount:any
  // propertyName: any;
  // propertyValue: any;
  alertPropertyName = 'date';
  alertPropertyValue = 'DESC';
  sensorHistoryPropertyName = 'date';
  sensorHistoryPropertyValue = 'DESC';
  sensorDetails = [
    {
      sensorName : "Supply Air Temp. 23487",
      sensorType : "Supply air Temp.",
      gatewayId : "#94621",
      lastCheckIn : "Jan 13, 2020. 11:30 AM",
      signal : "92",
      battery : "90"
    }
  ];
  sensorId: any;
  dateRangeOnAlertHistory:any;
  dateRangeOnSensorHistory:any;
  alertRangeValue: Date[];
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 1));
  dateRangeSensorHistory: any[] = [];
  currentDateSensorHistory = formatDate(new Date(), "yyyy-MM-dd", "en-US")
  selectedAlertHistoryTab = 'AlertTab';
  refreshSubscription: Subscription;
  currentIndexAlert = 0;
  currentIndexSensorHistory = 0;

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private exportService: ExportExcelService,
    ) {
      this.activatedRoute.params.subscribe((params: Params) => {
        this.sensorId = params.id;
        this.getAllSensorCount();
        // this.alertRangeValue = [new Date(), this.nextDate];
        this.alertRangeValue = [new Date(), new Date()];
        // this.getSensorAlertHistory({from : '2020-01-01',to : '2020-02-03'});
        // this.getSensorAlertHistory();
        // let dateRange = {
        //   // from: '2020-02-05',
        //   from: formatDate(this.alertRangeValue[0], "yyyy-MM-dd", "en-US"),
        //   to: formatDate(this.alertRangeValue[1], "yyyy-MM-dd", "en-US")
        // };
        // this.getSensorHistory(dateRange);
    });
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getAllSensorCount();
      if(this.selectedAlertHistoryTab == 'AlertTab'){
        this.getSensorAlertHistory();
      }
      if(this.selectedAlertHistoryTab == 'HistoryTab'){
        this.getSensorHistory();
      }
    });
   }

  sensorsList: any[] = [];
  sensorDetailsList: any;
  // sensorsAlertsHistoryList: any;
  sensorsAlertsHistoryList: any = [];
  // sensorsHistoryList: any;
  sensorsHistoryList: any = [];
  cardDetailsList : any[] = [];


  ngOnInit() {
    // this.getAllSensorCount();
    // this.getSensorAlertHistory({from : '2020-01-01',to : '2020-02-03'});
    
  }


  getAllSensorCount() {
    // current date
    let dateToday = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');
    let body = {
      currentDate: dateToday,
    }
    this.commonService.getData('get', '/v0/api/v1/sensors/'+ this.sensorId +'/alert/counts', body).subscribe(res => {
      this.sensorsList = res.data.cards;
      this.sensorDetailsList = res.data.sensorDetail;
      console.log(this.sensorsList);
    });
  }

  getSensorAlertHistory(dateRange = this.dateRangeOnAlertHistory, pageNumber = this.currentIndexAlert, pageSize = this.recordsPerPage,action='oninit') {
    this.selectedAlertHistoryTab = 'AlertTab'
    this.activePageNo = pageNumber;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      from: dateRange.from,
      to: dateRange.to,
      propertyName: this.alertPropertyName,
      propertyValue: this.alertPropertyValue
    };
    this.commonService.getData('get', '/v0/api/v1/sensors/'+this.sensorId+'/alert/history', body).subscribe(res => {
      this.sensorsAlertsHistoryList = res.data;
      if(res && res.data && res.data.count) {
        this.totalAlertHistoryCount = res.data.count;
      }
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => SensorAlertHistoryExportMaker.exportToExcel(e)):[];
        this.exportService.exportAsExcelFile(exportableData,'Sensor Alert history')
      }
    });
  }

  getSensorHistory(dateRange = this.dateRangeOnSensorHistory, pageNumber = this.currentIndexSensorHistory, pageSize = this.recordsPerPage, action = 'oninit') {
    this.selectedAlertHistoryTab = 'HistoryTab'
    // debugger
    // this.dateRangeSensorHistory = [new Date(), new Date()];
    this.activePageNo = pageNumber;

    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      from:dateRange.from,
      to: dateRange.to,
      propertyName: this.sensorHistoryPropertyName,
      propertyValue: this.sensorHistoryPropertyValue
    };
    this.commonService.getData('get', '/v0/api/v1/sensors/'+this.sensorId+'/history/data', body).subscribe(res => {
      this.sensorsHistoryList = res.data;
      if(res && res.data && res.data.count) {
        this.totalSensorHistoryCount = res.data.count;
      }
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => SensorHistoryExportMaker.exportToExcel(e)):[];
        this.exportService.exportAsExcelFile(exportableData, 'Sensor history')
      }
    });
  }
  getSensorAlertHistoryfromPaging(params) {
    this.currentIndexAlert = params.pageNumber;
    this.getSensorAlertHistory(params.currentDateRange, params.pageNumber, params.pageSize)
  }
  getSensorHistoryfromPaging(params) {
    this.currentIndexSensorHistory = params.pageNumber;
    this.getSensorHistory(params.currentDateRange, params.pageNumber, params.pageSize)
  }
  onAlertHistoryDateRangeChange(eve) {
    this.getSensorAlertHistory(eve, 0);
    this.dateRangeOnAlertHistory = eve;
  }
  onSensorHistoryDateRangeChange(e) {
    this.getSensorHistory(e, 0);
    this.dateRangeOnSensorHistory = e;
  }
  onAlertHistoryExport() {
    if (!this.totalAlertHistoryCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getSensorAlertHistory(this.dateRangeOnAlertHistory, 0, this.totalAlertHistoryCount,'export')
  }
  onSensorHistoryExport() {
    if (!this.totalSensorHistoryCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getSensorHistory(this.dateRangeOnSensorHistory, 0, this.totalSensorHistoryCount, 'export')
  }
  
  onSortingAlertHistory(params) {
    this.alertPropertyName = params.propertyName;
    this.alertPropertyValue = params.propertyValue;
    this.getSensorAlertHistory();
  }

  onSortingSensorHistory(params){
    this.sensorHistoryPropertyName = params.propertyName;
    this.sensorHistoryPropertyValue = params.propertyValue;
    this.getSensorHistory();
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  
}
