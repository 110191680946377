
import { formatDate } from '@angular/common';
export class DriverListExport {
    driversId: any;
    name: any;
    dateOfJoining: any;
    truckId: any;
    routeAssigned: any;
    trips: any;

    constructor(
        driversId: any,
        name: any,
        dateOfJoining: any,
        truckId: any,
        routeAssigned: any,
        trips: any,
    ) {
        this.driversId = driversId;
        this.name = name;
        this.dateOfJoining = dateOfJoining;
        this.truckId = truckId;
        this.routeAssigned = routeAssigned;
        this.trips = trips;
    }
}
export class DriverListExportMaker {
    static create() {
        return new DriverListExport(null, null, null, null, null, null);
    }
    static exportToExcel(obj: DriverListExport) {

        let returnData = new DriverListExport(
            obj.driversId,
            obj.name,
            obj.dateOfJoining ? formatDate(obj.dateOfJoining, "MMM dd, yyyy", 'en-US') : obj.dateOfJoining,
            obj.truckId,
            obj.routeAssigned,
            obj.trips,
            
        )
        return returnData;
    }
}
