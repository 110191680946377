import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { reportList } from './reports-list'
import { HttpClient, HttpHandler } from '@angular/common/http';
import { CommonService } from 'src/app/services/';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  // reportList = [
  //   {
  //     img: 'assets/img/pink-temp-hist-report.svg',
  //     name: 'Temp. History Report',
  //     status: 'active'
  //   },
  //   {
  //     img: 'assets/img/pink-alert-hist.svg',
  //     name: 'Alerts History Report',
  //     status: 'active'
  //   },
  //   {
  //     img: 'assets/img/pink-ref-his.svg',
  //     name: 'Reference Summary Report',
  //     status: 'active'
  //   },
  //   {
  //     img: 'assets/img/pink-summary.svg',
  //     name: 'Sensors Summary Report',
  //     status: 'active'
  //   },
  //   {
  //     img: 'assets/img/pink-trip-report.svg',
  //     name: 'Trip History Report',
  //     status: 'active'
  //   },
  //   {
  //     img: 'assets/img/pink-truck-report.svg',
  //     name: 'Truck Temp. Report',
  //     status: 'active'
  //   }
  // ]
  reportList = reportList;
  timestamp: any;
  refreshSubscription: Subscription;

  customReportList = [
    {
      img: 'assets/img/custom-hist-rep.svg',
      name: 'History - Truck #AA1231',
      status: 'active'
    },
    {
      img: 'assets/img/history-dave.svg',
      name: 'History - Dave (#12712)',
      status: 'active'
    },
    {
      img: 'assets/img/custom-hist-rep.svg',
      name: 'History - Truck #AA1248',
      status: 'active'
    }
  ]
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private commonService: CommonService
  ) { 
    const today = new Date();
    this.timestamp = Date.parse(today.toISOString());
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      const today = new Date();
      this.timestamp = Date.parse(today.toISOString());
    });
  }

  ngOnInit() {
  }

  reportHistory(index,name) {
    this.httpClient.get("https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken").subscribe(res => {
      if (res) {
        let token = JSON.parse(res.toString()).message;
        this.router.navigate(['/reports-details'], { queryParams: { index: index, token: token, name: name } });
      }
      }, (err) => {
        console.log(JSON.stringify(err));
      });

  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

}
