import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, Route } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

//add custom interceptor
import { CommonHttpInterceptor } from './services/http-interceptor/http.interceptor';
const httpInterceptor = { provide: HTTP_INTERCEPTORS, useClass: CommonHttpInterceptor, multi: true };

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ShpChartComponent } from './components/home/shp-chart/shp-chart.component';
import { CalendarComponent } from './components/home/calendar/calendar.component';
import { LoginComponent } from './components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TruckDetailsComponent } from "./components/truck-details/truck-details.component";
import { ShpKpiCardsComponent } from './home/shp-kpi-cards/shp-kpi-cards.component';
import { NavbarComponent } from './components/navbar/navbar.component';


/*-----------------------Third Party Library----------------------*/
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { HomeChartComponent } from './components/home/home-chart/home-chart.component';
import { AlertConfigurationComponent } from './components/alert-configuration/alert-configuration.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RootOptimizationComponent } from './components/root-optimization/root-optimization.component';
import { TrucksComponent } from './components/trucks/trucks.component';
import { TripPlannerComponent } from './components/trip-planner/trip-planner.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PaginationModule } from 'ngx-bootstrap';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TagInputModule } from 'ngx-chips';
import { PowerBIModule } from 'angular2-powerbi';
/* ------------ANGULAR CDK-------------- */

import { DragDropModule } from '@angular/cdk/drag-drop';
import { TruckListComponent } from './components/trip-planner/truck-list/truck-list.component';
import { DriverListComponent } from './components/trip-planner/driver-list/driver-list.component';
import { DriverComponent } from './components/driver/driver/driver.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AllSensorsComponent } from './components/all-sensors/all-sensors.component';
import { SensorDetailsViewComponent } from './components/sensor-details-view/sensor-details-view.component';
import { SensorAlertHistoryComponent } from './components/sensor-details-view/sensor-alert-history/sensor-alert-history.component';
import { SensorsHistoryComponent } from './components/sensor-details-view/sensors-history/sensors-history.component';
import { TruckViewComponent } from './components/truck-view/truck-view.component';
import { CoolerListComponent } from './components/cooler-list/cooler-list.component';
import { RoutesComponent } from './components/routes/routes.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { DriverDetailsComponent } from './components/driver/driver-details/driver-details.component';
import { ActiveTripsComponent } from './components/active-trips/active-trips.component';
import { TripListComponent } from './components/trip-list/trip-list.component';
import { RouteListComponent } from './components/trip-planner/route-list/route-list.component';
import { TripPlannerListComponent } from './components/trip-planner/trip-planner-list/trip-planner-list.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportsDetailsComponent } from './components/reports/reports-details/reports-details.component';
import { AlertsManagementComponent } from './components/alerts-management/alerts-management.component';
import { DriverTripHistoryComponent } from './components/driver/driver-trip-history/driver-trip-history.component';
import { ScheduleListComponent } from './components/trip-planner/schedule-list/schedule-list.component';
import { CreateAlertComponent } from './components/alerts-management/create-alert/create-alert.component';
import { CustomPaginationComponent } from './shared/components/custom-pagination/custom-pagination.component';
import { TemperatureConvertPipe } from './shared/pipes/temperature-convert/temperature-convert.pipe';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { AddTruckComponent } from './components/my-profile/add-truck/add-truck.component';
import { AddUsersComponent } from './components/alerts-management/add-users/add-users.component';
import { TripTrackerComponent } from './components/trip-tracker/trip-tracker.component';
import { UserNRoleComponent } from './components/user-n-roles/user-role/user-n-role.component';
import { UsersComponent } from './components/user-n-roles/users/users.component';
import { RolesComponent } from './components/user-n-roles/roles/roles.component';
import { ModifyAccessComponent } from './components/user-n-roles/modify-access/modify-access.component';
import { UserRoleDriverComponent, ReplacePipe } from './components/user-n-roles/user-role-driver/user-role-driver.component';
import { DashboardDetailsComponent } from './components/dashboard/dashboard-details/dashboard-details.component';
import { TripHistoryComponent } from './components/trip-tracker/trip-history/trip-history.component';
import { CallbackComponent } from './components/callback/callback.component';
import { FilterCoolerPipe } from './components/trip-planner/pipe/filter-cooler.pipe';
import { TodayDateFormatPipe } from './shared/pipes/today-date-convert/today-date-format.pipe';
import { NumberDirective } from './shared/directives/number-only.directives';
import { OutOfRangeReportComponent } from './components/out-of-range-report/out-of-range-report.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CallbackComponent,
    ShpChartComponent,
    CalendarComponent,
    LoginComponent,
    TruckDetailsComponent,
    ShpKpiCardsComponent,
    NavbarComponent,
    HomeChartComponent,
    AlertConfigurationComponent,
    DashboardComponent,
    RootOptimizationComponent,
    TrucksComponent,
    TruckViewComponent,
    TripPlannerComponent,
    TruckListComponent,
    DriverListComponent,
    DriverComponent,
    AllSensorsComponent,
    SensorDetailsViewComponent,
    SensorAlertHistoryComponent,
    SensorsHistoryComponent,
    CoolerListComponent,
    RoutesComponent,
    AlertsComponent,
    DriverDetailsComponent,
    ActiveTripsComponent,
    TripListComponent,
    RouteListComponent,
    TripPlannerListComponent,
    ReportsComponent,
    ReportsDetailsComponent,
    AlertsManagementComponent,
    DriverTripHistoryComponent,
    ScheduleListComponent,
    CreateAlertComponent,
    CustomPaginationComponent,
    TemperatureConvertPipe,
    MyProfileComponent,
    AddTruckComponent,
    AddUsersComponent,
    TripTrackerComponent,
    UserNRoleComponent,
    UsersComponent,
    RolesComponent,
    ModifyAccessComponent,
    UserRoleDriverComponent,
    DashboardDetailsComponent,
    ReplacePipe,
    TripHistoryComponent,
    CallbackComponent,
    FilterCoolerPipe,
    TodayDateFormatPipe,
    NumberDirective,
    OutOfRangeReportComponent,
  ],
  imports: [
    GooglePlaceModule,
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCsWCKcdRMH3ysf71TVZWj2feeTanFckU0'
    }),
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapSliderModule,
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    ModalModule.forRoot(),
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    TimepickerModule.forRoot(),
    PowerBIModule.forRoot(),
    TagInputModule,
    AgmJsMarkerClustererModule,
    SelectDropDownModule
  ],
  providers: [
    httpInterceptor
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
