import { OngoingTruckAlertMaker } from "../ongoing-truck-alerts/ongoing-truck-alert.model";

export class OnGoingTruck {
  _id: string;
  id: string;
  name: string;
  truckId: string;
  route: string;
  driver: string;
  driverName: string;
  alert: any;
  coolers: string;
  startTrip: string;
  endTrip: string;
  status: string;
  truckMasterId: string;
  dailyTripId: string;
  ambientTemp: string;
  gatewayStatus: string;
  tripMasterId: string;
  profile: string;
  alertData: Array<any>;
  constructor(
    _id,
    id,
    name,
    truckId,
    route,
    driver,
    driverName,
    alert,
    coolers,
    startTrip,
    endTrip,
    status,
    truckMasterId,
    dailyTripId,
    ambientTemp,
    gatewayStatus,
    tripMasterId,
    profile,
    alertData?
  ) {
    this._id = _id;
    this.id = id;
    this.name = name;
    this.truckId = truckId;
    this.route = route;
    this.driver = driver;
    this.driverName = driverName;
    this.alert = alert;
    this.coolers = coolers;
    this.startTrip = startTrip;
    this.endTrip = endTrip;
    this.status = status;
    this.truckMasterId = truckMasterId;
    this.dailyTripId = dailyTripId;
    this.ambientTemp = ambientTemp;
    this.gatewayStatus = gatewayStatus;
    this.tripMasterId = tripMasterId;
    this.profile = profile;
    this.alertData = alertData ? alertData : null;
  }
}

export class OnGoingTruckMaker {
  static castInto(obj: any) {
    const alertArray = obj.tripAlerts.map(e => OngoingTruckAlertMaker.castInto(e));
    return new OnGoingTruck(
      'T-' + obj.tripId,
      obj.tripId,
      obj.truckName,
      obj.truckId,
      obj.route,
      obj.DriverName,
      obj.driverName,
      alertArray.map(e => e),
      obj.coolers,
      obj.startTrip,
      obj.endTrip,
      obj.status,
      obj.truckMasterId,
      obj.dailyTripId,
      obj.ambientTemp,
      obj.gatewayStatus,
      obj.tripMasterId,
      obj.profile,
      obj.alertData
    );
  }

  static clone(obj: OnGoingTruck) {
    console.log(obj);
    return new OnGoingTruck(
      obj._id,
      obj.id,
      obj.name,
      obj.truckId,
      obj.route,
      obj.driver,
      obj.driverName,
      obj.alert.map(e => e),
      obj.coolers,
      obj.startTrip,
      obj.endTrip,
      obj.status,
      obj.truckMasterId,
      obj.dailyTripId,
      obj.ambientTemp,
      obj.gatewayStatus,
      obj.tripMasterId,
      obj.profile,
      obj.alertData
    );
  }
}
