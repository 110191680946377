import { formatDate } from '@angular/common';
export class DriverTripHistoryExport {
    truckId: any;
    route: any;
    tripId: any;
    coolers: any;
    alerts: any;
    StopNotAvailable : any;
    dateTime: any;
    status: any;

    constructor(
        truckId: any,
        route: any,
        tripId: any,
        coolers: any,
        alerts: any,
        StopNotAvailable: any,
        dateTime: any,
        status: any,
    ) {
        this.truckId=truckId;
        this.route=route;
        this.tripId =tripId;
        this.coolers =coolers;
        this.alerts=alerts;
        this.StopNotAvailable = StopNotAvailable;
        this.dateTime=dateTime;
        this.status = status;
    }
}
export class DriverTripHistoryExportMaker {
    static create() {
        return new DriverTripHistoryExport(null, null, null, null, null, null,null,null);
    }
    static exportToExcel(obj:any) {
        let returnData = new DriverTripHistoryExport(
            obj.truckId,
            obj.route,
            obj.tripId,
            obj.coolers,
            obj.alerts,
            obj.missedStops,
            obj.dateTime ? formatDate(obj.dateTime, "MMM dd, yyyy", 'en-US') : obj.dateTime,
            obj.status,
            // obj.driversId,
            // obj.name,
            // obj.dateOfJoining ? formatDate(obj.dateOfJoining, "MMM dd, yyyy", 'en-US') : obj.dateOfJoining,
            // obj.truckId,
            // obj.routeAssigned,
            // obj.trips,

        )
        return returnData;
    }
}
