import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag
} from '@angular/cdk/drag-drop';
import { Truck } from './truck.model';
import { Driver } from './driver.model';
import { Route } from './route.model';
import { Schedule } from './schedule.model';
import { CommonService } from '../../services';
import { TruckListComponent } from './truck-list/truck-list.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trip-planner',
  templateUrl: './trip-planner.component.html',
  styleUrls: ['./trip-planner.component.scss']
})
export class TripPlannerComponent implements OnInit,OnDestroy,AfterViewInit {
  tripPlannerData: Array<any> = [];
  goodsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  assignedTrucks: Array<any>;
  @ViewChild(TruckListComponent) truckListComponent: TruckListComponent;
  @ViewChild('RefCoolerContent') RefCoolerContent: ElementRef;
  @ViewChild('frozenCoolerContent') frozenCoolerContent: ElementRef;
  @ViewChild('ambientCoolerContent') ambientCoolerContent: ElementRef;


  truckImg = 'assets/img/Truck-planner-add.svg';
  largeCoolers:any[]=[];
  medCoolers: any[]=[];
  smallCoolers: any[]=[];
  truckData = [];
  dateToday = new Date();
  showListView = '';
  totalCoolers: any[] = [];
  refrigeratedCoolers: any = [];
  ambientCoolers: any = [];
  frozenCoolers: any = [];

  currentTruck: Truck;
  currentDriver: Driver;
  currentRoute: Route;
  currentSchedule: Schedule;
  loadedTripPlanner: any;

  tripMasterId: any;
  truckMasterId: any;
  driverMasterId: any;
  routeMasterId: any;
  tripNoData: any;
  selectedCoolers: any[] = [];
  //selectedCoolers: any[] = [];
  lcooler: any;
  mcooler: any;
  scooler: any;
  currentTripId: string;
  selectedDriverId: any;
  selectedRouteId: any;
  selectedTruckId: any=0;
  coolerInEdit: any[] = [];
  tripsCoolersIds: any;

  //for validation
  isDriverSelected = false;
  isRouteSelected = false;
  isTruckSelected = false;

//for cooler scoller arrow
  refLeft = 0;
  refRight = 0
  frozLeft = 0;
  frozRight = 0
  ambLeft = 0;
  ambRight = 0

  showAllRefrigeratedCooler = false;
  showAllAmbientCooler = false;
  showAllFrozenCooler = false;
  searchAllCooler = '';
  searchCooler = '';
  selecteCoolerCount = 0;
  refreshSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute,
    private detectChange:ChangeDetectorRef,
    private commonService: CommonService) {
    this.currentTruck = null;
    this.currentDriver = null;
    this.currentRoute = null;
    this.currentSchedule = null;
    this.assignedTrucks = [];
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getCoolersList();
    });
  }

  ngOnInit() {
    this.getCoolersList();
    this.route.queryParams.subscribe(params => {
      this.currentTripId = params.tripMasterId;
      this.tripNoData = params.tripId;
     
      //if (params.id && localStorage.tripPlannerList) {
      //  this.loadedTripPlanner = JSON.parse(localStorage.tripPlannerList);
      //  this.loadedTripPlanner = this.loadedTripPlanner.filter(trip => trip.tripID == params.id)[0];
      //  console.log(this.loadedTripPlanner);
      //  this.currentDriver = this.loadedTripPlanner.driver;
      //  this.loadedTripPlanner.skids.forEach(skids => {
      //    this.truckData.push(skids);
      //  });
      //  this.currentTruck = this.loadedTripPlanner;
      //  this.currentRoute = this.loadedTripPlanner.route;
      //  this.assignedTrucks.push(this.currentTruck);
      //}
    });
  }
  getCoolersList(pageNumber = 0, pageSize = 1000) {
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,   
    };
    this.commonService.getData('get', '/v0/api/v1/coolers', body).subscribe(resp => {
      this.showListView = 'truck';
      this.totalCoolers = resp.data.rows;
      this.largeCoolers = this.totalCoolers.filter(x => x.coolerType == "Refrigerated");  
      this.medCoolers = this.totalCoolers.filter(x => x.coolerType == "Frozen");
      this.smallCoolers = this.totalCoolers.filter(x => x.coolerType == "Ambient");
      if (this.currentTripId && this.currentTripId.length >0) {
        this.getTripDetailsById();
      }
      
    })
  }

  onTaskDrop(event) {
   // console.log(event);
    if (event.previousContainer === event.container || !this.currentTruck) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else if (event.previousContainer.connectedTo.length == 3) {
      let draggedCoolerMasterId = event.item.element.nativeElement.id;
      this.truckData.forEach((item, i, obj) => {
        if (item.assetMasterId == draggedCoolerMasterId) {
          obj.splice(i, 1);
          this.fillSkidArray(item, i);
        }
      });
    } else if (event.previousContainer.connectedTo.length == 1) {
      let coolerMasterId = event.item.element.nativeElement.id;
      let skid;
      skid = event.previousContainer.data.find(x => x.assetMasterId == coolerMasterId);
      this.fillTruck(skid);
      this.getSelectedCoolers();
    }
    //   if (event.previousContainer.id == "cdk-drop-list-0") {
    //     //this.fillSkidSkidArrayById(event.item.element.nativeElement.id.split("#"))
    //     let draggedCoolerMasterId = event.item.element.nativeElement.id;
    //     this.truckData.forEach((item, i, obj) => {
    //       if (item.assetMasterId == draggedCoolerMasterId) {
    //         obj.splice(i, 1);
    //         this.fillSkidArray(item, i);
    //       }
    //     })
    //   } else
    //   {
    //   transferArrayItem(
    //     event.previousContainer.data,
    //     event.container.data,
    //     event.previousIndex,
    //     event.currentIndex
    //   );
    //   this.getSelectedCoolers();
    // }
  }
  fillSkidSkidArrayById(cooler) {
    let coolerType = cooler[1]
    coolerType = coolerType ? coolerType.toLowerCase() : ''; 
    var skid = this.truckData.find(x => x.assetMasterId == cooler[0]);
    let index;
    switch (coolerType) {
      case 'refrigerated':
          this.largeCoolers.push(skid);
          break;

      case 'frozen':
          this.medCoolers.push(skid);
          break;

      case 'ambient':
          this.smallCoolers.push(skid);
          break;
    }
    this.truckData.forEach((item, i, obj) => { 
      if (item.assetMasterId == cooler[0]) {
        obj.splice(i, 1);
        this.fillSkidArray(item,i);
      }
    })
    this.getSelectedCoolers();
  }

  // onCoolerDrag(eve,dragFrom) {
    
  // }
  setSelectedTruck(truck) {
    this.currentTruck = truck;
    this.truckMasterId = truck.truckMasterId;
     this.selectedTruckId = truck.truckMasterId;
    
  }

  setSelectedDriver(driver) {
    this.currentDriver = driver;
    this.driverMasterId = driver.id;
    this.selectedDriverId = driver.id;
  }

  setSelectedRoute(route) {
    this.currentRoute = route;
    this.selectedRouteId=route.routeMasterId;
    this.routeMasterId = route.routeMasterId;
  }

  setSelectedSchedule(schedule) {
    console.log(schedule);
    this.currentSchedule = schedule;
  }

  fillSkidArray(skid, index) {
   // console.log(skid);
    let coolerType = skid.coolerType? skid.coolerType.toLowerCase():'';//: "Refrigerated"

    if (skid) {
      switch (coolerType) {
        case 'refrigerated':
          this.largeCoolers.push(skid);
          break;

        case 'frozen':
          this.medCoolers.push(skid);
          break;

        case 'ambient':
          this.smallCoolers.push(skid);
          break;
      }
      this.truckData.forEach((item, i, obj) => {
        if (item.assetMasterId == skid.assetMasterId) {
          obj.splice(i, 1);
        }
      });
      if (skid && skid.tripCoolersId)
      this.commonService.getData('delete', '/v0/api/v1/trip-plans/'+skid.tripCoolersId+'/coolers').subscribe(res => {
        if(res.status == 1){
          //console.log(res);
          this.getSelectedCoolers();
        }
      })
      
    }
  }

  // isLargeCooler(item: CdkDrag<any>) {
  //   console.log(item.data);
  //   return item.data.coolerSize == "LARGE";
  // }
  // isMedCooler(item: CdkDrag<any>) {
  //   console.log(item.data);
  //   return item.data.coolerSize == 'MEDIUM';
  // }
  // isSmallCooler(item: CdkDrag<any>) {
  //   console.log(item.data);
  //   return item.data.coolerSize == 'SMALL';
  // }

  fillTruck(skid) {
  //  this.selectedCoolers.push(skid);
    // added toast msg if truck is not selected
    if(!this.currentTruck) {
      this.commonService.showToast('Please select truck', 0);
    }
    let coolerType = skid.coolerType ? skid.coolerType.toLowerCase() : '';//: "Refrigerated"
    if (skid && this.currentTruck != null) {
      switch (coolerType) {
        case 'refrigerated':
          this.largeCoolers.forEach((item, i, obj) => { 
            if (item.assetMasterId == skid.assetMasterId) {
              obj.splice(i, 1);
            }
          });
          break;

        case 'frozen':
          this.medCoolers.forEach((item, i, obj) => {
            if (item.assetMasterId == skid.assetMasterId) {
              obj.splice(i, 1);
            }
          });
          break;

        case 'ambient':
          this.smallCoolers.forEach((item, i, obj) => {
            if (item.assetMasterId == skid.assetMasterId) {
              obj.splice(i, 1);
            }
          });
          break;
      }
      this.truckData.push(skid);
      this.getSelectedCoolers();
      this.detectChange.detectChanges();
    }

  }

  redirectback(){
    this.router.navigate(['/trip-planner-list']);
  }

  clearTruck() {
    this.currentTruck = null;
    this.truckData = [];
    this.selectedTruckId = this.selectedTruckId >= 0 ? -1 : 0;
    this.selectedDriverId = this.selectedDriverId >= 0 ? -1 : 0;
    this.selectedRouteId = this.selectedRouteId >= 0 ? -1 : 0;
  }
  getSelectedCoolers() {
    this.selectedCoolers = [];
    this.truckData.forEach(element => {
      this.selectedCoolers.push({ coolerMasterId: element.assetMasterId })
    });
  }
  assignTruck() {
    let errorMsg = this.isTripValid();
    if (errorMsg.length > 0) {
      this.commonService.showToast(errorMsg, 0);
      return;
    } else {
      let paramBody = {
        date: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
        truckMasterId: this.truckMasterId,
        driverMasterId: this.driverMasterId,
        routeMasterId: +this.routeMasterId,
        tripName: "",
        tripNo: new Date().getTime().toString(),
        tripCoolers: this.truckData.map(x => Number(x.assetMasterId)),
      }
      console.log("coolser:", paramBody);
      this.commonService.getData('post', '/v0/api/v1/trips/', paramBody).subscribe(res => {
        console.log("post:", res)
        if (res.status) {
          this.router.navigate(['trip-planner-list']);
          this.clearTruck();
        }
      })

      //this.currentTruck.status = 'Planned';
      //this.currentTruck.driver = this.currentDriver;
      //this.currentTruck.skids = this.truckData.map(e => e);
      //this.currentTruck.tripID = "Trip-" + this.randomTripID();
      //this.currentTruck.route = this.currentRoute;

      //this.assignedTrucks.push(this.currentTruck);

      //if (localStorage.tripPlannerList) {
      //  this.tripPlannerData = JSON.parse(localStorage.tripPlannerList);
      //  this.tripPlannerData.push(this.assignedTrucks[0]);
      //} else {
      //  this.tripPlannerData.push(this.assignedTrucks[0]);
      //}
      //localStorage.tripPlannerList = JSON.stringify(this.tripPlannerData);




    }
  }
  processName(name) {
    return name.replace(/ +/g, "") + "-image";
  }

  randomTripID() {
    return Math.floor((Math.random() * 100000) + 1);
  }

  removeTruck() {
    // this.currentTruck.name = null;
    this.selectedTruckId = this.selectedTruckId>=0?-1:0;
    // Added condition if truck data is empty then remove the truck
    if(this.truckData && this.truckData.length == 0) {
      this.currentTruck = null;
    }
  }

  removeDriver() {
    this.selectedDriverId = this.selectedDriverId >= 0 ? -1 : 0;
    this.currentDriver = null;
  }

  removeRoute() {
    this.selectedRouteId = this.selectedRouteId >= 0 ? -1 : 0;
    this.currentRoute = null;
  }

  getMyStyles() {
    let myStyles = {
      'background-image': (this.currentTruck && this.currentTruck != null) ? 'url(\'assets/img/Truck-planner-add.svg\')' : 'url(\'\')',   
    };
    return myStyles;
  }
  getTripDetailsById() {
    this.commonService.getData('get', '/v0/api/v1/trip-plans/' + this.currentTripId).subscribe(resp => {
      this.truckData = [];
      this.tripMasterId = resp["data"]["tripMasterId"];
      this.truckMasterId = resp["data"]["truckMasterId"];
      this.driverMasterId = resp["data"]["driverMasterId"];
      this.routeMasterId = resp["data"]["routeMasterId"];

      this.currentTruck = {
        id: resp["data"]["truckMasterId"],
        name: resp["data"]["truckId"],
        skids: [],
        conditions: "available",
        driver: null,
        tripID: null,
        route: undefined,
        truckID: "",
        truckMasterId: null,
        status: ''
      };
      this.currentDriver = {
        id: resp["data"]["driverMasterId"],
        name: resp["data"]["driverName"],
        profilePic: '',
        conditions: 'available',
      };
        
      this.currentRoute = {
        id: resp["data"]["routeMasterId"],
        routeId: resp["data"]["routeId"],
        routeMasterId: resp["data"]["routeMasterId"],
        status: '',
        stops:'0'
      };
      
      this.selectedTruckId = resp["data"]["truckMasterId"];
      this.selectedDriverId = resp["data"]["driverMasterId"];
      this.selectedRouteId = resp["data"]["routeMasterId"];


      let tripCoolers: any[] = resp["data"]["tripcoolers"];
      this.coolerInEdit = tripCoolers;

      //let tripcoolersIds = tripCoolers.map(x => x.coolerId);
      // for (var i = 0; i < tripcoolersIds.length; i++) {
      //   var ccccc = this.totalCoolers;
      //   let cool = this.totalCoolers.filter(x => x.coolerId == tripcoolersIds[i])[0];
      //   let coolerTripId = this.coolerInEdit.filter(x => x.coolerId == tripcoolersIds[i])[0].tripCoolersId;
      //   cool.tripCoolersId = coolerTripId;
      //   this.truckData.push(cool);
      // }
      
      this.totalCoolers.forEach((cooler,i) => {
        tripCoolers.forEach(tc => {
          if (cooler.assetMasterId == tc.coolerMasterId) {
            let _tcooler = cooler;
            _tcooler["tripCoolersId"] = tc.tripCoolersId;
            this.truckData.push(_tcooler);
          }
        })
      })
      this.truckData.forEach(data => {
        this.totalCoolers.forEach((item, i, obj) => {
          if (data.assetMasterId == item.assetMasterId) {
            obj.splice(i, 1);
          }
        });
      });
      this.commonService.getData('get', '/v0/api/v1/routes', { pageIndex: 0, pageSize: 100 }).subscribe(resp => {
        let routeData: any[] = resp.data.rows;
        let stops = routeData.filter(x => x.routeMasterId == this.currentRoute.id)[0].stops;
        this.currentRoute.stops = stops;
      });
      this.largeCoolers = this.totalCoolers.filter(x => x.coolerType == "Refrigerated");
      this.medCoolers = this.totalCoolers.filter(x => x.coolerType == "Frozen");
      this.smallCoolers = this.totalCoolers.filter(x => x.coolerType == "Ambient"); 
    });
  }
  updateTripDetails() {
    let errorMsg = this.isTripValid();
    if (errorMsg.length > 0) {
      this.commonService.showToast(errorMsg, 0);
      return;
    } else {
      let coolerArray: any[] = [];
      for (var i = 0; i < this.truckData.length; i++) {
        let tripCooler = this.coolerInEdit.filter(x => x.coolerId == this.truckData[i].coolerId)[0] ? this.coolerInEdit.filter(x => x.coolerId == this.truckData[i].coolerId)[0].tripCoolersId : null;
        let cooler: any;
        if (tripCooler == undefined || tripCooler == null) {
          cooler = {
            "coolerMasterId": this.truckData[i].assetMasterId,
          }
        } else {
          cooler = {
            "tripCoolersId": this.coolerInEdit.filter(x => x.coolerId == this.truckData[i].coolerId)[0].tripCoolersId,
            "coolerMasterId": this.truckData[i].assetMasterId,
          }
        }
        coolerArray.push(cooler);
      }
      let body = {
        "truckMasterId": this.truckMasterId,
        "tripMasterId": this.tripMasterId,
        "driverMasterId": this.driverMasterId,
        "routeMasterId": this.routeMasterId,
        "tripCoolers": coolerArray
      }
      this.commonService.getData('put', '/v0/api/v1/trip-plans', body).subscribe(
        (response) => {
          if (response.status) {
            this.router.navigateByUrl('/trip-planner-list');
          }
        }
      );
    }
  }

  isTripValid() {
    let errorMsg = '';
   
    if (this.currentRoute) {
      this.isRouteSelected = true;
    } else {
      this.isRouteSelected = false;
      errorMsg = 'No route selected'
    }
    if (this.currentDriver) {
      this.isDriverSelected = true;
    } else {
      this.isDriverSelected = false;
      errorMsg = 'No driver selected'
    }
    if (this.currentTruck && this.currentTruck.name && this.currentTruck.name.length >0) {
      this.isTruckSelected = true;
    } else {
      this.isTruckSelected = false;
      errorMsg = 'No truck selected'
    }
    return errorMsg;
  }
  getFilterdCooler(searchKey="") {
    // let allCoolers= this.totalCoolers;
    // this.largeCoolers = allCoolers.filter(x => x.coolerType == "Refrigerated");
    // this.medCoolers = allCoolers.filter(x => x.coolerType == "Frozen");
    // this.smallCoolers = allCoolers.filter(x => x.coolerType == "Ambient");
   let refrigeratedCoooler= this.largeCoolers;
    let frozenCooler = this.medCoolers;
    let ambientCooler= this.smallCoolers;
  }
  
  scrollLeft(target) {
    if (target == 'ref') {
      this.RefCoolerContent.nativeElement.scrollLeft -= 150;
      this.refLeft = this.RefCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'frozen') {
      this.frozenCoolerContent.nativeElement.scrollLeft -= 150;
      this.frozLeft = this.frozenCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'ambient') {
      this.ambientCoolerContent.nativeElement.scrollLeft -= 150;
      this.ambLeft = this.ambientCoolerContent.nativeElement.scrollLeft;
    }
  }
  scrollRight(target) {
    if (target == 'ref') {
      this.RefCoolerContent.nativeElement.scrollLeft += 150;
      this.refRight = this.RefCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'frozen') {
      this.frozenCoolerContent.nativeElement.scrollLeft += 150;
      this.frozRight = this.frozenCoolerContent.nativeElement.scrollLeft;
    } else if (target == 'ambient') {
      this.ambientCoolerContent.nativeElement.scrollLeft += 150;
      this.ambRight = this.ambientCoolerContent.nativeElement.scrollLeft;
    }
  }
  selectedCoolerlistToAdd: any[] = [];
  onSelectCooler(cooler, event) { 
    if (event.target.checked) {
      this.selectedCoolerlistToAdd.push(cooler);
    } else {
      this.selectedCoolerlistToAdd.forEach((item, i, obj) =>
      {
        if (cooler.coolerMasterId == item.coolerMasterId) {
          obj.splice(i, 1);
        }
      });
    }
    this.selecteCoolerCount = this.selectedCoolerlistToAdd.length;
  }
  onAddCoolerInBulk() {
    if (!this.currentTruck) {
      this.commonService.showToast("Truck not selected", 0);
      return;
    }
    this.selectedCoolerlistToAdd.forEach((item) => {
      this.fillTruck(item)
    });   
    this.resetToinitial();
  }
  resetToinitial() {
    this.selectedCoolerlistToAdd = [];
    this.selecteCoolerCount = 0;
    this.selectedCoolerlistToAdd = [];
    this.showAllRefrigeratedCooler = false;
    this.showAllFrozenCooler = false;
    this.showAllAmbientCooler = false;
   // this.getFilterdCooler();
    //this.detectChange.detectChanges();
  }

  onClearSearch(){
    this.searchAllCooler = "";
    this.searchCooler = "";
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.detectChange.detectChanges();
  }
}