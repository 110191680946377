export class AlertConfig {
    alertConfigId: number;
    alertName: string;
    alertSubType: string;
    alertType: string;
    assets: string;
    createdBy: string;
    createdOn: string;
    email: any;
    inApp: any;
    status: any;
    constructor(
        alertConfigId: number,
        alertName: string,
        alertSubType: string,
        alertType: string,
        assets: string,
        createdBy: string,
        createdOn: string,
        email: any,
        inApp: any,
        status: any,
    ) {
        this.alertConfigId = alertConfigId;
        this.alertName = alertName;
        this.alertSubType = alertSubType;
        this.alertType = alertType;
        this.assets = assets;
        this.createdBy = createdBy;
        this.createdOn = createdOn;
        this.email=email;
        this.inApp = inApp;
        this.status = status;
    }
}

export class AlertConfigMaker {
    static create() {
        return new AlertConfig(null, null, null, null, null, null, null, null, null, null);
    }
    static castInto(obj: any) {
        return new AlertConfig(
            obj.alertConfigId,
            obj.alertName,
            obj.alertSubType,
            obj.alertType,
            obj.assets,
            obj.createdBy,
            obj.createdOn,
            obj.email,
            obj.inApp,
            obj.status
        )
    }
    static exportToExcel(obj: any) {
        return new AlertConfig(
            obj.alertName,
            obj.alertType,
            obj.assets,
            obj.createdBy,
            obj.createdOn,
            obj.email,
            obj.inApp,
            obj.status,
            null,null
        )
    }
}