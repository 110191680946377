import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common/common.service";
import { ExportExcelService } from "../../../services/export-to-excel/export-exel.service";
import { formatDate } from "@angular/common";
import { TabsetComponent } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { DriverTripHistoryExportMaker } from './driver-trip-history-export';


@Component({
  selector: "app-driver-trip-history",
  templateUrl: "./driver-trip-history.component.html",
  styleUrls: ["./driver-trip-history.component.scss"]
})
export class DriverTripHistoryComponent implements OnInit, OnDestroy {
  @ViewChild('tabsetTripDetails') tabsetTripDetails: TabsetComponent;

  // tripsHistory: any[] = [];
  refreshSubscription: Subscription;

  tripsHistory: any;
  driverID: any;
  isShowFilter: boolean = false;
  // sortOrder: boolean = false;
  sortOrder: any;
  // alerts: any;
  isMapOpen: boolean = true;
  status: any;
  selectedTripId: any;
  search: any;

  //********Pagination********
  currentPage = 1;
  page: number;
  startItem = 0;
  endItem = 10;
  totalRecordCount: any;
  recordsPerPage = 10;
  showSidebarTripDetails: boolean = false;
  tableRowToCollapse = -1;
  currentTruckID: any;
  activePageNo = 0;

  driverTripDetailsData: any; // = { truckId: '', origin: '', destination: '', status: '', alerts: '', coolers:''};
  driverTripDetailsData_door: any;
  driverTripDetailsData_truckStatus: any[] = [];
  driverTripDetailsData_cooler: any[] = [];
  driverTripDetailsData_alerts: any[] = [];
  driverTripDetailsData_ddlCooler: any[] = [];
  driverTripDetailsData_receiverDetails: any[] = [];

  plannedStop: any[] = [];
  unplannedStop: any[] = [];

  plannedCoolerData: any[] = [];
  unpalnnedCoolerData: any[] = [];

  plannedReceivers: any[] = [];
  unPlannedReceivers: any[] = [];

  routeAssigned: any;
  coolerType: any;
  coolersValue: any;
  alertsLable: any;
  alerts: any;
  // fromdate: any;
  // todate: any;
  fromdate = '';
  todate = '';
  formatedateOfJoiningfrom: any;
  formatedateOfJoiningto: any;
  selectedRouteStopCount: any;
  ddlAssignRoute: any[] = [];
  sortingPropName: string = "dateTime";
  sortingOrder: string = "DESC";
  showSortingIcon: any;

  selectedStopId: any;
  stopDetailsData: any;
  stopTempDetailsData: any;
  stopCoolers: any[] = [];
  tempHistory: any[] = [];
  dailyTripId: any;
  endRecord = 4;
  currentPageIndex=0;


  tripStopsDetails = [];
  showTempHistory = false;

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private exportExelService: ExportExcelService
  ) { 
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getHistoryTrips();
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.driverID = params.id;
      this.currentTruckID = params.truckID;
    });
    this.getHistoryTrips();
  }

  getHistoryTrips(pageNumber = this.currentPageIndex,pageSize = this.recordsPerPage,action = "onInit") {
    this.activePageNo = pageNumber;
    this.totalRecordCount = 0;
    let todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');

    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      status: "history",
      search: this.search,
      routeAssigned: this.routeAssigned == 'null' ? '' : this.routeAssigned,
      coolerType: this.coolerType == 'null' ? '' : this.coolerType,
      coolers: this.coolersValue,
      alert: this.alerts,
      alertType: this.alertsLable == 'null' ? '' : this.alertsLable,
      from: this.fromdate ? formatDate(this.fromdate, "yyyy-MM-dd", "en-US") : this.fromdate,
      to: this.todate ? formatDate(this.todate, "yyyy-MM-dd", "en-US") : this.todate,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder,
      currentDate:todayDate
    };
    this.commonService.getData("get", "/v0/api/v1/drivers/" + this.driverID + "/trips", body).subscribe(driverDetailHistoryResponse => {
      this.tripsHistory = driverDetailHistoryResponse.data.rows;
      this.totalRecordCount = driverDetailHistoryResponse.data.count;
      if (action == "export") {
        let exportableData = driverDetailHistoryResponse && driverDetailHistoryResponse.data && driverDetailHistoryResponse.data.rows ? driverDetailHistoryResponse.data.rows.map(e => DriverTripHistoryExportMaker.exportToExcel(e)) : [];
        this.exportExelService.exportAsExcelFile( exportableData,  "Driver Detail History");
      }
    });
  }

  getDDLAssignRoute() {
    let body = {
      driverId: this.driverID
    }
    this.commonService.getData('get', '/v0/api/v1/drivers-history-routes', body).subscribe(res => {
      this.ddlAssignRoute = res;
    }
    );
  }

  onDriverlistClick() {
    this.router.navigate(["/driver-details"], {
      queryParams: { id: this.driverID }
    });
  }


  sorthistorytrips(propType, prop, asc) {

    switch (propType) {
      case "int":
        this.tripsHistory = this.tripsHistory.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case "date":
        this.tripsHistory = this.tripsHistory.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        })
        break;
      default:
        this.tripsHistory = this.tripsHistory.sort(function (a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
            return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
          }
        });
    }
  }
  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    //this.driverList = this.driverList.slice(this.startItem, this.endItem);
  }
  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getHistoryTrips(pageNo);
  }


  collapseRowAlert(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  collapseRow(i, item) {
    this.showTempHistory = false;
    if (this.tableRowToCollapse != i) {
      this.getStopTempHistory(item);
    }
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  onExportToExcel() {
    if (!this.totalRecordCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getHistoryTrips(0, this.totalRecordCount, "export");
  }


  toggleFilter() {
    this.isShowFilter = !this.isShowFilter;
    this.getDDLAssignRoute();
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.routeAssigned = 'null';
    this.coolerType = "null";
    this.coolersValue = "";
    this.alerts = "";
    this.alertsLable = "null";
    this.fromdate = "";
    this.todate = "";
  }

  resetFilter() {
    this.setDefaultFilterValues();
    this.getHistoryTrips(0);
  }

  goToTripDetails(dailyTripId, tripNumber) {
    this.tableRowToCollapse = -1;
    this.tabsetTripDetails.tabs[0].active = true;
    this.dailyTripId = dailyTripId;

    this.commonService.getData("get", "/v0/api/v1/drivers/" + this.driverID + "/trips/" + dailyTripId).subscribe(resp => {
      if (resp.data) {
        this.selectedTripId = tripNumber;
        this.driverTripDetailsData = resp["data"]["tripDetails"];
        this.driverTripDetailsData_door = resp["data"]["door"];
        this.driverTripDetailsData_truckStatus = resp["data"]["truckStatus"];
        this.driverTripDetailsData_cooler = resp["data"]["cooler"];

        this.plannedCoolerData = this.driverTripDetailsData_cooler.filter(x => x.coolerStatusType != 'Received');
        this.unpalnnedCoolerData = this.driverTripDetailsData_cooler.filter(x => x.coolerStatusType == 'Received');

        this.driverTripDetailsData_alerts = resp["data"]["alerts"];
        this.driverTripDetailsData_ddlCooler = resp["data"]["routeStops"];
        this.driverTripDetailsData_receiverDetails = resp["data"]["receiverDetails"];


        this.stopList = this.driverTripDetailsData_ddlCooler;
        this.unplannedStop = this.stopList.filter(x => x.stopType == 'Received');
        this.plannedStop = this.stopList.filter(x => x.stopType != 'Received');

        // this.selectedCoolerType = this.driverTripDetailsData_ddlCooler[0].coolerType;
        // this.selectedCoolerId = this.driverTripDetailsData_ddlCooler[0].coolerId;
        this.selectedRouteStopCount = this.driverTripDetailsData_ddlCooler.length;

      }
    });
  }
  selectedCoolerId: any;
  selectedCoolerType: any;
  stopList: any[] = [];
  onCoolerChange(event) {
    let selectedCooler = event.target.value;
    this.stopList = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).stops;
    this.plannedStop = this.stopList.filter(x => x.stopType != 'Received');
    this.unplannedStop = this.stopList.filter(x => x.stopType == 'Received');

    this.selectedCoolerType = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).coolerType;
    this.selectedCoolerId = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).coolerId;
    this.selectedRouteStopCount = this.driverTripDetailsData_ddlCooler.find(x => x.coolerId == selectedCooler).routeStopCount;
  }

  //clear Search
  onClearSearch() {
    if(!this.search){
      return;
    }
    this.search = "";
    this.getHistoryTrips(0);
    
  }

  onSortDriverTripHistoryList(propertyName, sortOrder) {

    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.showSortingIcon = propertyName + "_" + sortOrder;
    this.getHistoryTrips();
  }

  getStopsDetails(dailyTripStopId) {
    this.tableRowToCollapse = -1;
    this.selectedStopId = dailyTripStopId;
    this.commonService.getData('get', '/v0/api/v1/daily-trip/route-stop-coolers/' + this.dailyTripId + '/' + dailyTripStopId + '/details').subscribe(res => {
      this.stopDetailsData = res;
      this.stopCoolers = this.stopDetailsData.coolerData;
    }
    );
  }

  onStopTabSelect() {
    if (this.driverTripDetailsData_ddlCooler.length > 0) {
      this.selectedStopId = this.driverTripDetailsData_ddlCooler[0].dailyTripStopId;
      this.getStopsDetails(this.selectedStopId);
    }
  }

  getStopTempHistory(item) {
    this.commonService.getData('get', '/v0/api/v1/trucks/route-stop-coolers/' + this.dailyTripId + '/' + item.dailyTripCoolerId + '/' + this.selectedStopId + '/details').subscribe(res => {
      this.stopTempDetailsData = res;
      this.tempHistory = this.stopTempDetailsData.historyData;
    }
    );
  }

  ngOnDestroy(){
    this.refreshSubscription.unsubscribe();
  }

  validateInput(event) {
    console.log("input");
    this.commonService.validateInput(event);
  }

}
