import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { TabsetComponent } from 'ngx-bootstrap';
import { CommonService } from "src/app/services/common/common.service";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { formatDate } from "@angular/common";
import { ExportExcelService } from "../../../services/export-to-excel/export-exel.service";
import { Subscription } from 'rxjs';
import { TripTrackerHistoryExportMaker } from './trip-tracker-history-export';

@Component({
  selector: "app-trip-history",
  templateUrl: "./trip-history.component.html",
  styleUrls: ["./trip-history.component.scss"],
})
export class TripHistoryComponent implements OnInit {

  @ViewChild('tabsetTripDetails') tabsetTripDetails: TabsetComponent;

  refreshSubscription: Subscription;

  totalTripHistoryCount = 1;
  isShowFilter: boolean = false;
  filterRoute: any;
  totalHistoryTripCount = 0;
  recordsPerPage = 10;
  activePageNo = 0;
  showSidebar: boolean = false;
  selectedTripId: any;
  search: any;
  startItem: number = 0;
  endItem: number = 10;

  tableRowToCollapse = -1;
  tripHistoryCount: any;
  tripHistoryListData: any;
  tripHistoryData: any;
  tripHistoryData_door: any;
  tripHistoryData_truckStatus: any[] = [];
  tripHistoryData_cooler: any[] = [];
  tripHistoryData_alerts: any[] = [];
  tripHistoryData_ddlCooler: any[] = [];
  tripHistoryData_receiverDetails: any[] = [];
  stopList: any[] = [];
  selectedCoolerId: any;
  selectedCoolerType: any;
  selectedRouteStopCount: any;
  routeId: any;
  coolersType: string;
  coolersValue: any;
  missedStopsType: string;
  missedStopsValue: any;
  statusType: string;
  fromdate: any;
  todate: any;

  plannedStopData: any[] = [];
  unpalnnedStopData: any[] = [];
  unpalnnedCoolerData: any[] = [];
  plannedCoolerData: any[] = [];
  unpalnnedReceiverData: any[] = [];
  plannedReceiverData: any[] = [];
  routeFilterList: any[] = [];

  selectedStopId: any;
  stopDetailsData: any;
  stopTempDetailsData: any;
  stopCoolers: any[] = [];
  tempHistory: any[] = [];
  dailyTripId: any;
  // endRecord = 4;
  showTempHistory = false;
  sortingPropName: string = "tripStartDate";
  sortingOrder: string = "DESC";
  showSortingIcon: any;

  currentPageIndex=0;

  driverTripDetailsData_receiverDetails = [
    {
      stopName: "Buckingham Palace Rd, Victoria, London SW1W, UK",
      date: "",
      stopStatus: null,
      receiverName: null,
      signature: null,
    },
    {
      stopName: "3000 W Gate City Blvd, Greensboro, NC 27403, USA",
      date: "",
      stopStatus: null,
      receiverName: null,
      signature: null,
    },
  ];

  driverTripDetailsData_alerts = [];

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private exportExelService: ExportExcelService
  ) {
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getTripHistoryList();

    });
   }

  ngOnInit() {
    this.getTripHistoryList();
    this.getRouteListFilter();
  }


  onSortList(prop, asc) {
    const config = {
      sortDirection: asc ? 'asc' : 'des',
      sortBy: prop
    };

    this.tripHistoryListData = this.commonService.sortTable(prop, this.tripHistoryListData, config);
  }

  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getTripHistoryList(pageNo);
  }

  resetFilter() {
    this.isShowFilter = false;
  }

  shouldShowSidebar(data) {
    this.showSidebar = true;
    this.tabsetTripDetails.tabs[0].active = true;
    this.tableRowToCollapse = -1;
    this.dailyTripId = data.dailyTripId;

    this.commonService.getData("get", "/v0/api/v1/drivers/" + data.driverId + "/trips/" + data.dailyTripId).subscribe((res) => {
      this.tripHistoryData = res.data.tripDetails;
      this.tripHistoryData_truckStatus = res["data"]["truckStatus"];
      this.tripHistoryData_door = res["data"]["door"];
      this.tripHistoryData_cooler = res["data"]["cooler"];
      this.tripHistoryData_alerts = res["data"]["alerts"];
      this.tripHistoryData_ddlCooler = res["data"]["routeStops"];
      this.tripHistoryData_receiverDetails = res["data"]["receiverDetails"];
      this.stopList = this.tripHistoryData_ddlCooler;

      // this.selectedCoolerType = this.tripHistoryData_ddlCooler[0].coolerType;
      // this.selectedCoolerId = this.tripHistoryData_ddlCooler[0].coolerId;
      this.selectedRouteStopCount = this.tripHistoryData_ddlCooler.length;

      let unplannedCoolerStatus = [];
      let plannedCoolerStatus = [];
      this.tripHistoryData_cooler.forEach((item) => {
        if (item.coolerStatusType == "Received") {
          unplannedCoolerStatus.push(item);
        } else {
          plannedCoolerStatus.push(item);
        }
      });
      this.unpalnnedCoolerData = unplannedCoolerStatus;
      this.plannedCoolerData = plannedCoolerStatus;

      // stops planned and unplanned
      let unplannedStopsStatus = [];
      let plannedStopsStatus = [];
      this.stopList.forEach((item) => {
        if (item.stopType == "Received") {
          unplannedStopsStatus.push(item);
        } else {
          plannedStopsStatus.push(item);
        }
      });
      this.unpalnnedStopData = unplannedStopsStatus;
      this.plannedStopData = plannedStopsStatus;

      // Receiver tab planned and unplanned
      let unplannedReceiverStatus = [];
      let plannedReceiverStatus = [];
      this.unpalnnedReceiverData = unplannedReceiverStatus;
      this.plannedReceiverData = plannedReceiverStatus;
    });
  }

  getTripHistoryList(pageNumber = this.currentPageIndex,pageSize = this.recordsPerPage,action = "onInit") {
    this.activePageNo = pageNumber;
    this.totalHistoryTripCount = 0;
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.search,
      routeId: this.routeId == 'null' ? '' : this.routeId,

      coolersType: this.coolersType ? this.coolersType.toLowerCase() : this.coolersType,
      coolers: this.coolersValue,
      // missedStopsType: this.missedStopsType ? this.missedStopsType.toLowerCase() : this.missedStopsType,
      missedStopsType: this.missedStopsType == 'null' ? '' : this.missedStopsType,
      missedStops: this.missedStopsValue,
      // status: this.statusType ? this.statusType.toLowerCase() : this.statusType,
      status: this.statusType == 'null' ? '' : this.statusType,
      from: this.fromdate ? formatDate(this.fromdate, "yyyy-MM-dd", "en-US") : this.fromdate,
      to: this.todate ? formatDate(this.todate, "yyyy-MM-dd", "en-US") : this.todate,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData("get", "/v0/api/v1/trip-tracking-history", body).subscribe((resp) => {
      this.totalHistoryTripCount = resp.data.count;
      this.tripHistoryListData = resp.data.rows;
      if (action == "export") {
        let exportableData = resp && resp.data && resp.data.rows ? resp.data.rows.map(e => TripTrackerHistoryExportMaker.exportToExcel(e)): [];
        this.exportExelService.exportAsExcelFile(exportableData, 'Trip tracker histoy');
      }
    });
  }

  onClearFilter() {
    // this.routeId = "";
    // this.coolersType = "";
    // this.coolersValue = "";
    // this.missedStopsType = "";
    // this.missedStopsValue = "";
    // this.statusType = "";
    // this.fromdate = "";
    // this.todate = "";
    this.setDefaultFilterValues();
    this.getTripHistoryList(0);
    // this.isShowFilter = false;
  }

  collapseRow(i, item) {
    this.showTempHistory = false;
    if (this.tableRowToCollapse != i) {
      this.getStopTempHistory(item);
    }
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }
  collapseAlertRow(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  onCoolerChange(event) {
    let selectedCooler = event.target.value;
    this.stopList = this.tripHistoryData_ddlCooler.find((x) => x.coolerId == selectedCooler).stops;
    this.plannedStopData = this.stopList.filter(x => x.stopType != 'Received');
    this.unpalnnedStopData = this.stopList.filter(x => x.stopType == 'Received');
    this.selectedCoolerType = this.tripHistoryData_ddlCooler.find((x) => x.coolerId == selectedCooler).coolerType;
    this.selectedCoolerId = this.tripHistoryData_ddlCooler.find((x) => x.coolerId == selectedCooler).coolerId;
    this.selectedRouteStopCount = this.tripHistoryData_ddlCooler.find((x) => x.coolerId == selectedCooler).routeStopCount;
  }


  getRouteListFilter() {
    this.commonService.getData("get", "/v0/api/v1/trip-tracking-history-route-id").subscribe((res) => {
      this.routeFilterList = res;
    });
  }
  onExportToExcel() {
    if (!this.totalHistoryTripCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getTripHistoryList(0, this.totalHistoryTripCount, 'export')
  }

  //clear Search
  onClearSearch() {
    if(!this.search){
      return;
    }
    this.search = "";
    this.getTripHistoryList(0);
  }

  toggleFilter() {
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.routeId = "null";
    this.coolersType = "null";
    this.coolersValue = "";
    this.missedStopsType = "null";
    this.missedStopsValue = "";
    this.statusType = "null";
    this.fromdate = "";
    this.todate = "";
  }

  getStopsDetails(dailyTripStopId) {
    this.tableRowToCollapse = -1;
    this.selectedStopId = dailyTripStopId;
    this.commonService.getData('get', '/v0/api/v1/daily-trip/route-stop-coolers/' + this.dailyTripId + '/' + dailyTripStopId + '/details').subscribe(res => {
      this.stopDetailsData = res;
      this.stopCoolers = this.stopDetailsData.coolerData;
    }
    );

  }

  onStopTabSelect() {
    if (this.tripHistoryData_ddlCooler.length > 0) {
      this.selectedStopId = this.tripHistoryData_ddlCooler[0].dailyTripStopId;
      this.getStopsDetails(this.selectedStopId);
    }
  }

  getStopTempHistory(item) {
    this.commonService.getData('get', '/v0/api/v1/trucks/route-stop-coolers/' + this.dailyTripId + '/' + item.dailyTripCoolerId + '/' + this.selectedStopId + '/details').subscribe(res => {
      this.stopTempDetailsData = res;
      this.tempHistory = this.stopTempDetailsData.historyData;
    }
    );
  }

  onSortTripTrackerHistoryList(propertyName, sortOrder) {

    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;

    this.showSortingIcon = propertyName + "_" + sortOrder;
    this.getTripHistoryList();
  }

  ngOnDestroy(){
    this.refreshSubscription.unsubscribe();
  }

  validateInput(event) {
    this.commonService.validateInput(event);
  }

}
