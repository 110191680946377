import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Trucks, TruckMaker } from '../trucks/truckList.model';
import { CommonService } from '../../services';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-trip-list',
  templateUrl: './trip-list.component.html',
  styleUrls: ['./trip-list.component.scss']
})
export class TripListComponent implements OnInit {

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private commonService: CommonService
  ) {
    this.getTriplist();
  }


  search:any;
  route:any;
  coolersLable:any;
  coolers:any;
  alertsLable:any;
  alerts:any;
  startDate:any;
  endDate:any;
  formatStartDate:any;
  formatEndDate:any;
  status:any


  sortOrder: boolean = false;
  isShowFilter: boolean = false;
  tripList = [];
  list = [];
  modalRef: BsModalRef;
  ischecked: boolean = false;
  isAllChecked = false;
  showSidebar = false;
  onclickAddbtn: boolean = false;
  tableRowToCollapse = -1;
  totalTripsCount: any;
  recordsPerPage = 5;
  activePageNo = 0;
  //****** Pagination *********/
  currentPage = 1;
  page: number;
  startItem = 0;
  endItem = 5;

  ngOnInit() {
    this.getTriplist();
  }

  gotoDetailsPage() {
    this.router.navigate(['truck-view']);
  }


  getTriplist(pageNumber = 0, pageSize = this.recordsPerPage) {
    this.activePageNo = pageNumber;

    if(this.startDate && this.endDate){
      this.formatStartDate = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US');
      this.formatEndDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US');
    }
    this.totalTripsCount = 0;
  
    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search:this.search,
      route:this.route,
      alerts:this.alerts,
      coolers:this.coolers,
      startDate:this.formatStartDate,
      endDate:this.formatEndDate,
      status:this.status
    };
    this.commonService.getData('get', '/v0/api/v1/trips', body).subscribe(res => {
      // this.sensorsList = res.data;
      // this.truckList = res.data.rows.map(e => TruckMaker.castInto(e));
      console.log(res);
      this.totalTripsCount = res.data.count;
      this.tripList = res.data.rows;
    });


  }

  truckDetails(list, truckDetailsPopup: TemplateRef<any>) {
    //this.modalRef = this.modalService.show(truckDetailsPopup, { class: "modal-lg" });
    this.showSidebar = true;
  }
  closeModal() {
    this.modalRef.hide();
    this.onclickAddbtn = false;
  }
  //********* Checkbox selections **********/
  checkAll(status) {
    this.tripList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }
  countCheckedRows() {
    let checkedflag = true;
    let counter = 0;
    this.tripList.forEach(e => {
      if (!e.isChecked) {
        checkedflag = false;
        // return true;
      } else {
        counter++;
      }
    });

    this.isAllChecked = checkedflag;
    return counter;
  }

  selectRow(obj: Trucks, event) {
    obj.isChecked = event.target.checked;
    this.countCheckedRows();
  }
  //******Page change Event ******//
  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  //*********sorting *********/
  sortCoolerlist(prop, asc) {
    this.tripList = this.tripList.sort(function (a, b) {
      if (asc) {
        return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
      } else {
        return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
      }
    });
  }

  showAddTruckModal(addTruckModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addTruckModal, { class: 'modal-dialog-centered max-widthModal' });
    this.onclickAddbtn = true;
  }

  collapseRow(i) {
    this.tableRowToCollapse = this.tableRowToCollapse === i ? -1 : i;
  }

  resetFilter(){
    this.route="";
    this.coolersLable="";
    this.coolers="";
    this.coolers="";
    this.alertsLable="";
    this.alerts="";
    this.startDate="";
    this.endDate="";
    this.status="";
    this.getTriplist();
  }

  loadPageData(pageNo){
    console.log(pageNo);
    this.getTriplist(pageNo);
  }
}
