import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { Subscription } from 'rxjs';
import { RouteListExportMaker } from './route-list-export';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {
  isShownDropdown = false;
  isStopList = false;
  ischecked = false;
  isAllChecked = false;
  onclickAddbtn = false;
  routesTotalCount = 0;
  activePageNo = 0;
  isShowFilter = false;
  sortOrder: any;

  search: any = "";
  fromOrigin: any;
  toDestination: any;
  stopValue: any;
  stopsLatitude: any;
  stopsLongitude: any;

  originLatitude: any;
  originLongitude: any;

  destLatitude: any;
  destLongitude: any;

  recordsPerPage = 10;
  actionType: any;
  stopslabel: string;

  routeStopList = [];
  newStopName: any;
  routeId: any;
  origin: any;
  destination: any;
  masterId: any;

  stopList = [];
  routesList: any = [];
  // routesList : any;
  routeDetail: any;
  modalRef: BsModalRef;
  orderNumber = 0;


  // ********Pagination********
  currentPage = 0;
  page: number;
  returnedDriverList: string[];
  startItem = 0;
  endItem = 10;

  removedStops: any[] = [];
  
  sortingPropName: string = "routeId";
  sortingOrder: string = "DESC";
  showSortingIcon: any;
  refreshSubscription: Subscription;
  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private exportExelService: ExportExcelService,
    private detectChange: ChangeDetectorRef
  ) {
    this.getRoutesList();
    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getRoutesList();
    });
  }

  ngOnInit() { }


  handleAddressChange(event) {
    console.log(event);
    this.newStopName = event.name + ' (' + event.formatted_address + ')';
    this.stopsLatitude = event.geometry.location.lat();
    this.stopsLongitude = event.geometry.location.lng();
  }

  handleAddressOrigin(event) {
    console.log(event);
      this.origin = event.name + ' (' + event.formatted_address + ')';
      this.originLatitude = event.geometry.location.lat();
      this.originLongitude = event.geometry.location.lng();
  }

  handleAddressDestination(event) {
    console.log(event);
    this.destination = event.name + ' (' + event.formatted_address + ')';
    this.destLatitude = event.geometry.location.lat();
    this.destLongitude = event.geometry.location.lng();
  }




  getRoutesList(pageNumber = this.currentPage, pageSize = this.recordsPerPage, action = 'onInit') {
    this.activePageNo = pageNumber;
    this.routesTotalCount = 0;
    const body = {
      pageIndex: pageNumber,
      pageSize,
      search: this.search.trim() === '' ? null : this.search.trim(),
      origin: this.fromOrigin,
      destination: this.toDestination,
      stopValue: this.stopValue,
      stopType: this.stopslabel == 'null' ? '' : this.stopslabel,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    };
    this.commonService.getData('get', '/v0/api/v1/routes', body).subscribe(res => {
      this.routesList = res.data.rows;
      this.routesTotalCount = res.data.count;
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => RouteListExportMaker.exportToExcel(e)): [];
        this.exportExelService.exportAsExcelFile(exportableData, 'Route List');
      }
    });
  }
  // onStopsTypeFilterChange(e) {
  //   this.stopslabel = e.target.value;
  // }
  // onStopsValueFilterChange(ev) {
  //   this.stopValue = ev.target.value;
  // }
  checkAll(status) {
    // this.ischecked = status;
    this.routesList.forEach(e => {
      e.isChecked = !this.isAllChecked;
    });
    this.countCheckedRows();
  }

  selectRow(obj: any, event) {
    obj.isChecked = event.target.checked;
    console.log({
      name: obj.id,
      check: obj.isChecked
    });
    this.countCheckedRows();
  }

  countCheckedRows() {
    let checkedFlag = true;
    let counter = 0;
    this.routesList.forEach(e => {
      if (!e.isChecked) {
        checkedFlag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedFlag;
    return counter;
  }

  // *******sorting Routes-List table *******/
  sortSensorlist(propType, prop, asc) {

    switch (propType) {
      case 'int':
        this.routesList = this.routesList.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case 'date':
        this.routesList = this.routesList.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        });
        break;
      default:
        this.routesList = this.routesList.sort(function (a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
            return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
          }
        });
        break;
      // this.routesList;
    }
  }

  // ******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    // this.driverList = this.driverList.slice(this.startItem, this.endItem);
  }

  showAddRouteModal(addRouteModal: TemplateRef<any>) {
    this.routeId = '';
    this.origin = '';
    this.destination = '';
    this.routeStopList = [];
    this.newStopName = '';
    this.modalRef = this.modalService.show(addRouteModal, {
      class: 'modal-dialog-centered'
    , backdrop: 'static'});
    this.onclickAddbtn = true;
  }

  closeModal() {
    this.modalRef.hide();
    this.onclickAddbtn = false;
    this.newStopName = null;
    this.stopsLatitude = null;
    this.stopsLongitude = null;
    this.clearVariable();
  }

  showStopsList(routeMasterId) {
    this.masterId = routeMasterId;
    this.commonService.getData('get', '/v0/api/v1/routes/' + routeMasterId).subscribe(res => {
      this.routeId = res.data.routeId;
      this.origin = res.data.origin;
      this.destination = res.data.destination;
      this.stopList = res.data.routestops;
      this.routeStopList = res.data.routestops;
      this.originLatitude = res.data.originLatitude;
      this.originLongitude = res.data.originLongitude;
      this.destLatitude = res.data.destinationLatitude;
      this.destLongitude = res.data.destinationLongitude;
      this.routeDetail = res.data;
      if (this.actionType != 'put') {
        this.isStopList = true;
      }
    });
  }

  toggleFilter(){
    this.isShowFilter = !this.isShowFilter;
    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.fromOrigin = '';
    this.toDestination = '';
    this.stopValue = '';
    this.stopslabel = 'null';
  }

  resetFilter() {
    this.setDefaultFilterValues(); 
    this.getRoutesList(0);
  }
  loadPageData(pageNo) {
    this.currentPage = pageNo;
    this.getRoutesList(pageNo);
  }


  addStops() {
    if (this.newStopName == undefined || this.newStopName == '') {
      this.commonService.showToast('Please enter valid address', 0);
      return;
    }
    if (!this.stopsLatitude || !this.stopsLongitude) {
      this.commonService.showToast('Invalid address. Please select address from the list', 0);
      return;
    }
    if (this.routeStopList.map(res => res.orderId).length > 0) {
      this.orderNumber = Math.max(...this.routeStopList.map(res => res.orderId)) + 1;

    } else {
      this.orderNumber = this.orderNumber + 1;
    }

    const body = {
      stop: this.newStopName,
      orderId: this.orderNumber,
      latitude: this.stopsLatitude,
      longitude: this.stopsLongitude
    };
    this.routeStopList.push(body);
    this.detectChange.detectChanges();
    this.newStopName = '';
    this.stopsLatitude = null;
    this.stopsLongitude = null;

  }

  removeStops(stop) {
    const body = {
      stop: this.newStopName,
      orderId: this.orderNumber,
      latitude: 19.175976,
      longitude: 72.943594
    };
    if (stop && stop.routeStopsId) {
      this.removedStops.push(stop.routeStopsId);
    }
    this.detectChange.detectChanges();
    this.routeStopList.pop();
    this.detectChange.detectChanges();
    console.log(this.routeStopList);
    this.stopsLatitude = null;
    this.stopsLongitude = null;
  }

  addRoute() {
    if (this.newStopName && this.newStopName.length > 0 && this.stopsLatitude) {
      this.addStops();
    }
    if (this.routeId == undefined || this.routeId.toString().trim() == '') {
      this.commonService.showToast('Please enter route id', 0);
      return;
    } else if (this.origin == undefined || this.origin.toString().trim() == '' || !this.originLatitude || !this.originLongitude ) {
      this.commonService.showToast('Please enter valid origin address', 0);
      return;
    } else if (this.destination == undefined || this.destination.toString().trim() == '' || !this.destLatitude || !this.destLongitude) {
      this.commonService.showToast('Please enter valid destination address', 0);
      return;
    }
    let  routeBody = {
      routeMasterId: this.masterId,
      origin: this.origin,
      destination: this.destination,
      originLatitude: this.originLatitude,
      originLongitude: this.originLongitude,
      destinationLatitude: this.destLatitude,
      destinationLongitude: this.destLongitude,
      routeId: this.routeId,
      routeStops: this.routeStopList,
      removeRouteStops: this.removedStops,
      // "stops":this.routeStopList.length
    };
    if (this.actionType == 'post') {
      delete routeBody['removeRouteStops'];
      if (this.routeStopList.length == 0) {
        this.commonService.showToast("Please add atleast one stop",0);
        return;
      }
    }
    this.commonService.getData(this.actionType, '/v0/api/v1/routes', routeBody).subscribe(res => {
      this.actionType = '';
      this.clearVariable();
      this.getRoutesList(this.currentPage, this.recordsPerPage);
      this.closeModal();
    });

  }

  clearVariable(){
    this.removedStops = [];
    this.origin = "";
    this.destination = "";
    this.originLatitude = "";
    this.originLongitude = "";
    this.destLatitude = "";
    this.destLongitude = ""
  }

  onExportToExcel() {
    if (!this.routesTotalCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getRoutesList(0, this.routesTotalCount, 'export');
  }
  routeTrackByFn(index, item) {
    return item.routeMasterId;
  }

  onSortRoutesList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getRoutesList();
  }

  //clear Search
  onClearSearch() {
    if(!this.search){
      return;
    }
    this.search = "";
    this.getRoutesList(0);
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  // search validate input
  validateInput(event) {
    this.commonService.validateInput(event);
  }
}
