import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/modules/variable-pie';
HC_more(Highcharts);
import { dashboardReportList } from '../dashboard/dashbord.model'
import { PowerBIModule } from 'angular2-powerbi';
import { Router } from '@angular/router';
import { HttpClient, HttpHandler } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardReportList = dashboardReportList;
  public optionsTruckTemp: any = {
    chart: {
      type: 'column',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return 'Temp. ' + this.y +'<sup>0</sup>F';
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun' ],
      plotLines: [{
          color: '#1AB0DB', // Color value
          dashStyle: 'longdashdot', // Style of the plot line. Default to solid
          value: 8, // Value of where the line will appear
          width: 1, // Width of the line 
      }]
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: 0,
      max: 25,
      tickInterval: 5,
      title: {
        useHTML: true,
        text: 'Temp. ( <sup>0</sup>F )',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: '<sup>0</sup>F (avg)',
        overflow: 'justify'
      }
    },
    legend: {
      itemDistance: 50,
      squareSymbol: true,
      symbolRadius: 2,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    series: [
      {
        name: 'Min Temp.',
        color: '#62B8FF',
        data: [11, 7, 8, 9, 10, 7, 9],
      },
      {
        name: 'Avg Temp.',
        color: '#34C481',
        data: [13,11,8,12,14,11,9.5],
      },
      {
        name: 'Max Temp.',
        color: '#FFA376',
        data: [13,14.5,8,13,15,13,11],
      }
    ]
  }

  
  public optionsSensorTemp: any = {
    chart: {
      type: 'area',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return 'Temp. ' + this.y +'<sup>0</sup>F';
      }
    },
    xAxis: {
      tickmarkPlacement: 'on',
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      categories: ['11:30','11:00', '10:30', '10:00', '09:30', '09:00', '08:30'],
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: -5,
      max: 20,
      tickInterval: 5,
      title: {
        useHTML: true,
        text: 'Temp. ( <sup>0</sup>F )',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: 'Temp. (<sup>0</sup>F)',
        overflow: 'justify'
      },
      plotLines: [{
          color: '#34C481', // Color value
          dashStyle: 'longdashdot', // Style of the plot line. Default to solid
          value: 10, // Value of where the line will appear
          width: 1, // Width of the line 
          zIndex: 10,
          label: {
            text: 'Setpoint',
            align: 'right',
            style: {
              color: '#34C481'
            }
        }
      }]
    },
    plotOptions: {
      series: {
        type: 'area',
        pointPlacement: 'on',
        zIndex: 1000
      }
    },
    legend: {
      itemDistance: 50,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    series: [
      {
        name: 'Temperature',
        color: '#62B8FF',
        data: [9, 13, 10, 14, 11, 13, 10.5],
        marker: {
          symbol: 'round'
      },
      threshold: -5,
      fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
              [0,   '#62B8FF'],
              [.9, '#FFFFFF1D']
          ]
      }
      }
    ]
  }
  
  public optionsTrips: any = {
    chart: {
      type: 'area',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return  + this.y;
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'],
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: 0,
      max: 500,
      tickInterval: 100,
      title: {
        text: '',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: '',
        overflow: 'justify'
      }
    },
    legend: {
      itemDistance: 50,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    plotOptions: {
      series: {
        pointPlacement: 'on'
      }
    },
    series: [
      {
        name: 'Planned',
        color: '#62B8FF',
        data: [40, 190, 160, 210, 90, 200, 50],
        marker: {
          symbol: 'round'
        },
        threshold: -5,
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0,   '#62B8FF'],
                [.9, '#FFFFFF1D']
            ]
        }
      },
      {
        name: 'Completed',
        color: '#34C481',
        data: [30, 170, 140, 190, 70, 180, 40],
        marker: {
          symbol: 'round'
        },
        threshold: -5,
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0,   '#34C481'],
                [.9, '#FFFFFF1D']
            ]
        }
      }
    ]
  }

  public optionsTrailerTemp: any = {
    chart: {
      type: 'column',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return this.y;
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun' ]
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: 0,
      max: 25,
      tickInterval: 5,
      title: {
        text: '',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: '',
        overflow: 'justify'
      }
    },
    legend: {
      itemDistance: 50,
      squareSymbol: true,
      symbolRadius: 2,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    series: [
      {
        name: 'Above Setpoint',
        color: '#F45E5E',
        data: [2, 8, 7, 5, 19, 9, 3],
      },
      {
        name: 'Below Setpoint',
        color: '#62B8FF',
        data: [2,7,5.5,8,6,5,2],
      }
    ]
  }
  public optionsFuelConsumption: any = {
    chart: {
      type: 'column',
      height: 218
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return 'Fuel (Gallon)'+this.y;
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      // gridLineWidth: 1,
      lineWidth:1,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun' ]
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: 0,
      max: 50,
      tickInterval: 10,
      title: {
        text: 'Fuel (Gallon)',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: '',
        overflow: 'justify'
      }
    },
    legend: {
      itemDistance: 50,
      squareSymbol: true,
      symbolRadius: 2,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    series: [
      {
        name: 'Fuel',
        color: '#62B8FF',
        data: [37, 29, 20, 42, 29, 19, 37],
      }
    ]
  }

  public optionsRunningCost: any = {
    chart: {
      type: 'areaspline',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return 'Cost. ' + this.y +'$';
      }
    },
    xAxis: {
      tickmarkPlacement: 'on',
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'],
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: 0,
      max: 250,
      tickInterval: 50,
      title: {
        text: 'Cost ($)',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: 'Csost. ($)',
        overflow: 'justify'
      },
      plotLines: [{
          color: '#808080', // Color value
          dashStyle: 'longdashdot', // Style of the plot line. Default to solid
          value: 160, // Value of where the line will appear
          width: 1, // Width of the line 
          label: {
            text: '',
        }
      }]
    },
    plotOptions: {
      series: {
        pointPlacement: 'on'
      }
    },
    legend: {
      itemDistance: 50,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    series: [
      {
        name: 'Fuel Cost',
        color: '#62B8FF',
        data: [75, 140, 90, 100, 110, 100, 120],
        marker: {
          symbol: 'round'
      },
      threshold: -5,
      fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
              [0,   '#62B8FF'],
              [.9, '#FFFFFF1D']
          ]
      }
      }
    ]
  }
  public optionsIdlingRateHistory: any = {
    chart: {
      type: 'column',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return this.y;
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      // gridLineWidth: 1,
      lineWidth:1,
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun' ]
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: 0,
      max: 250,
      tickInterval: 50,
      title: {
        text: '',
        align: 'middle'
      },
      labels: {
        useHTML: true,
        text: '',
        overflow: 'justify'
      }
    },
    legend: {
      itemDistance: 50,
      squareSymbol: true,
      symbolRadius: 2,
      itemStyle: {
          color: '#A5A5A5',
          fontWeight: '400',
          fontSize: '10px',
          align: 'left'
      }
    },
    series: [
      {
        name: 'Engine Idle Time',
        color: '#FFA376',
        data: [130, 130, 120, 180, 130, 150, 120],
      }
    ]
  };
  public token: string;
  constructor(private router: Router, public httpClient: HttpClient) {
    //  this.httpClient.get("https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken").subscribe(res => {
    //   if (res) {
    //     this.token = JSON.parse(res.toString()).message;
    //   }
    // }, (err) => {
    //   console.log(JSON.stringify(err));
    // });
    // this.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkN0VHVoTUptRDVNN0RMZHpEMnYyeDNRS1NSWSIsImtpZCI6IkN0VHVoTUptRDVNN0RMZHpEMnYyeDNRS1NSWSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYWU2OTFmODEtM2NiNC00YzZmLWE2ZDQtZGZiYjRjNjMwM2JiLyIsImlhdCI6MTU5MDk1NzE5MywibmJmIjoxNTkwOTU3MTkzLCJleHAiOjE1OTA5NjEwOTMsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiI0MmRnWVBoWUw2Rmd3dlNYdFkxOWxuN21wT2pQeGFmUFJHWHRlN1RFVStsMis1K1F4ZHdBIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6IjFhNWFiMDFmLWVjYjItNDE4Yi04MjZmLWJlOWJjODVmMDYxYyIsImFwcGlkYWNyIjoiMCIsImZhbWlseV9uYW1lIjoiQWRtaW4gMiIsImdpdmVuX25hbWUiOiJISFMiLCJpcGFkZHIiOiI1Mi4xNzYuMTY1LjY5IiwibmFtZSI6IkhIUyBBZG1pbiAyIiwib2lkIjoiMzI3NzVjYWMtYjE3Mi00NjhkLTgxZTktN2VhZTFmNGJmM2Y3IiwicHVpZCI6IjEwMDMyMDAwQjMyQTY0RDQiLCJzY3AiOiJBcHAuUmVhZC5BbGwgQ2FwYWNpdHkuUmVhZC5BbGwgQ2FwYWNpdHkuUmVhZFdyaXRlLkFsbCBDb250ZW50LkNyZWF0ZSBEYXNoYm9hcmQuUmVhZC5BbGwgRGFzaGJvYXJkLlJlYWRXcml0ZS5BbGwgRGF0YWZsb3cuUmVhZC5BbGwgRGF0YWZsb3cuUmVhZFdyaXRlLkFsbCBEYXRhc2V0LlJlYWQuQWxsIERhdGFzZXQuUmVhZFdyaXRlLkFsbCBHYXRld2F5LlJlYWQuQWxsIEdhdGV3YXkuUmVhZFdyaXRlLkFsbCBSZXBvcnQuUmVhZC5BbGwgUmVwb3J0LlJlYWRXcml0ZS5BbGwgU3RvcmFnZUFjY291bnQuUmVhZC5BbGwgU3RvcmFnZUFjY291bnQuUmVhZFdyaXRlLkFsbCBXb3Jrc3BhY2UuUmVhZC5BbGwgV29ya3NwYWNlLlJlYWRXcml0ZS5BbGwiLCJzdWIiOiJPa2NXNFM5SjRvYVBsTkdKTXV2M29WNHZUeWlYZC1COVRITVVVV2hkY1djIiwidGlkIjoiYWU2OTFmODEtM2NiNC00YzZmLWE2ZDQtZGZiYjRjNjMwM2JiIiwidW5pcXVlX25hbWUiOiJoaHNhZG1pbjJAc2hpcGNvbXdpcmVsZXNzLmNvbSIsInVwbiI6Imhoc2FkbWluMkBzaGlwY29td2lyZWxlc3MuY29tIiwidXRpIjoiMFhRWHlITTZBVS10VW5JR0xlUkxBQSIsInZlciI6IjEuMCJ9.i6w4ykIBa971j_Q_GVSvQ8_qxkH1LZr6994t7okacfa9_qpdo7fnwmPamUyJ8hAzxBMqu2Tjew8xuzc4l7AHU9WBEainj3nvZLVXJYPNSEJ_XHjN9Txt-8ueyanyuHcJdqS3VF8R3cJAAfSZ6gERp7q0X7Bl-byRxOoVkLRCKv6XpKMcHECv9cOidMtmK-cQ8Dud5eh3TucMXR6SuxPql9j95VOIWf-QqSAJSLOEQ8QwGzEjcALZ7I8zB0IkQyv7fkhaeigbO3oIYwK0EhyLBmLpt15QjEILyz336yOORUKYbKH1YK5eYwt-n4HhUQj1KIkZGBt--7sN-xvx4PNdLQ"
  }

  ngOnInit() {
    //Highcharts.chart('chartTruckTemp', this.optionsTruckTemp);
    //Highcharts.chart('chartSensorTemp', this.optionsSensorTemp);
    //Highcharts.chart('chartTrips', this.optionsTrips);
    //Highcharts.chart('chartTrailerTemp', this.optionsTrailerTemp);
    //Highcharts.chart('chartFuelConsumption', this.optionsFuelConsumption);
    //Highcharts.chart('chartRunningCost', this.optionsRunningCost);
    //Highcharts.chart('chartIdlingRateHistory', this.optionsIdlingRateHistory);

  }

  dashboardReportHistory(index: number, name: string) {
    //switch(index){
      //case 1:
        this.httpClient.get("https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken").subscribe(res => {
        if (res) {
          let token = JSON.parse(res.toString()).message;
          this.router.navigate(['/dashboard-details'], { queryParams: { index: index, token: token, name: name } });

        }
        }, (err) => {
          console.log(JSON.stringify(err));
        });
   // }
    // this.httpClient.get("https://shipcom-powerbiauth-api.azurewebsites.net/api/AuthToken").subscribe(res => {
    //   if (res) {
    //     this.token = JSON.parse(res.toString()).message;
    //     this.router.navigate(['/dashboard-details'], { queryParams: { name: this.token } });

    //   }
    // }, (err) => {
    //   console.log(JSON.stringify(err));
    // });

    //this.router.navigate(['/dashboard-details'], { queryParams: { index: this.token, name: name } });
  }
}
