import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/modules/variable-pie';
HC_more(Highcharts);

@Component({
  selector: 'app-truck-details',
  templateUrl: './truck-details.component.html',
  styleUrls: ['./truck-details.component.scss']
})
export class TruckDetailsComponent implements OnInit {
  modalRef: BsModalRef;
  isFilterOn: boolean = false;
  tempValue: number = 10; 
  public options: any = {
    chart: {
      type: 'areaspline',
      height: 300
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        return 'Temp. <img src="assets/img/ice-crystal.svg"> ' + this.y +'<sup>0</sup>F';
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      categories: ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00','17:00','18:00','19:00','20:00' ],
      plotLines: [{
          color: '#1AB0DB', // Color value
          dashStyle: 'longdashdot', // Style of the plot line. Default to solid
          value: 8, // Value of where the line will appear
          width: 1, // Width of the line 
      }]
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth:1,
      min: -40,
      max: 40,
      tickInterval: 10,
      title: {
        useHTML: true,
        text: '<sup>0</sup>F (avg)',
        align: 'middle'
      },
      labels: {
        overflow: 'justify'
      }
    },
    legend: {
      // layout: 'horizontal', // default
      itemDistance: 50,
          itemStyle: {
              color: '#A5A5A5',
              fontWeight: '400',
              fontSize: '10px',
              align: 'left'
          }
    },
    series: [
      {
        name: 'Actual Temperature',
        color: '#1AB0DB',
        data: [-10, -8, -7, -8, -9, -8, -7, -8, -9.5],
        marker: {
          symbol: 'round'
      },
      threshold: -40,
      fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
              [0,   '#1AB0DB'],
              [.8, '#FFFFFF1D']
          ]
      }
      },
      {
        name: 'Ideal Temperature',
        color: '#0EA72E',
        data: [-10,-10,-10,-10,-10,-10,-10,-10,-10,-10,-10,-10,-10],
        marker: {
          enabled: false
        },
        fillColor: {
            // linearGradient: [0, 0, 0, 0],
            stops: [
                [0,   '#1AB0DB'],
                [.7, '#FFFFFF1D']
            ]
        }
      }
    ]
  }
  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.onActivate();
  }

  openGraphModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-lg' });
    Highcharts.chart('chart3', this.options);
  }

  onActivate() {
    console.log('App Component');
    console.log(event); 
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 16);
}
  openSetTempModal(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  openHistoryInfoModal(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  setTemp(){
    console.log();
  }

}
