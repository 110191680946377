import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { reportList } from '../reports-list'
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-reports-details',
  templateUrl: './reports-details.component.html',
  styleUrls: ['./reports-details.component.scss']
})
export class ReportsDetailsComponent implements OnInit {
  alertRangeValue: Date[];
  public token: string;
  public reportId: string;
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 7));
  reportHistoryList = [
    {
      date: 'Jan 23, 2020',
      time: '05:00 PM',
      currentTemp: '18',
      idealRangeMin: '15',
      idealRangeMax: '25',
      alerts: 'Cooler #23154',
      status: 'normal'
    },
    {
      date: 'Jan 23, 2020',
      time: '12:00 PM',
      currentTemp: '12',
      idealRangeMin: '15',
      idealRangeMax: '25',
      alerts: 'Cooler #23168',
      status: 'below'
    },
    {
      date: 'Jan 23, 2020',
      time: '11:00 AM',
      currentTemp: '27',
      idealRangeMin: '15',
      idealRangeMax: '25',
      alerts: 'Cooler #23879',
      status: 'above'
    },
    {
      date: 'Jan 23, 2020',
      time: '09:00 AM',
      currentTemp: '21',
      idealRangeMin: '15',
      idealRangeMax: '25',
      alerts: 'Cooler #23667',
      status: 'normal'
    },
    {
      date: 'Jan 22, 2020',
      time: '5:00 PM',
      currentTemp: '1',
      idealRangeMin: '2',
      idealRangeMax: '8',
      alerts: 'Cooler #23667',
      status: 'below'
    },
    {
      date: 'Jan 22, 2020',
      time: '02:00 PM',
      currentTemp: '7',
      idealRangeMin: '2',
      idealRangeMax: '8',
      alerts: 'Cooler #23115',
      status: 'normal'
    }
  ];
  sortOrder: any;

  //********Pagination********
  currentPage = 1;
  page: number;
  returnedDriverList: string[];
  startItem = 0;
  endItem = 5;
  reportList = reportList;
  currentUrl: any;
  reportName: any;
  public optionsTempHumidity: any = {
    chart: {
      type: 'spline',
      zoomType: 'xy',
      height: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      shared: true
    },
    plotOptions: {
      series: {
        pointPlacement: 'on',
        zIndex: 1000
      }
    },
    xAxis: {
      tickmarkPlacement: 'on',
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      title: {
        text: '',
        style: {
          color: '#A5A5A5',
          fontSize: '11px',
        }
      },
      categories: ['Jan 07', 'Jan 08', 'Jan 09', 'Jan 10', 'Jan 11', 'Jan 12', 'Jan 13', 'Jan 14', 'Jan 15', 'Jan 16', 'Jan 17', 'Jan 18', 'Jan 19', 'Jan 20', 'Jan 21', 'Jan 22', 'Jan 23'],
    },
    yAxis: [{ // Primary yAxis
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      min: 0,
      max: 30,
      tickInterval: 5,
      labels: {
        format: '{value}°C',
        style: {
          color: '#A5A5A5',
          fontSize: '11px',
        }
      },
      title: {
        text: '°C (avg)',
        style: {
          color: '#A5A5A5',
          fontSize: '11px'
        }
      }
    }, { // Secondary yAxis
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      min: 0,
      max: 60,
      tickInterval: 10,
      title: {
        text: '% (avg)',
        style: {
          color: '#A5A5A5'
        }
      },
      labels: {
        format: '{value}%',
        style: {
          color: '#A5A5A5'
        }
      },
      opposite: true
    }],
    legend: {
      itemDistance: 50,
      squareSymbol: true,
      symbolRadius: 2,
      itemStyle: {
        color: '#A5A5A5',
        fontWeight: '400',
        fontSize: '10px',
        align: 'left'
      }
    },
    series: [
      {
        name: 'Temperature',
        color: '#1AB0DB',
        data: [10, 18, 8, 9, 7, 25, 17, 19, 9.5, 11, 10, 15, 14, 20, 18, 10, 12],
        tooltip: {
          valueSuffix: '°C'
        },
        marker: {
          enabled: false
        },
      },
      {
        name: 'Humidity',
        color: '#FFBA00',
        data: [20, 8, 28, 13, 21, 13, 15, 20, 18, 13, 21, 18, 15, 17, 20, 22, 15],
        tooltip: {
          valueSuffix: '%'
        },
        marker: {
          enabled: false
        },
      }
    ]
  }

  constructor(private route: ActivatedRoute,
    private sanitizerSevice: DomSanitizer) {
    this.route.queryParams.subscribe(param => {
      console.log(reportList[+param["index"]]);
      this.reportName = param.name;
      this.currentUrl  = reportList[+param["index"]].url;
      this.token = param.token;
      this.reportId = reportList[+param["index"]].reportId;
    });
  }

  ngOnInit() {
    // this.alertRangeValue = [new Date(), this.nextDate];
    //this.alertRangeValue = [new Date("2019/12/24"), new Date("2020/01/24")];
    //Highcharts.chart('chartTempHumidity', this.optionsTempHumidity);
  }

  //******Page change Event ******//

  pageChanged(event: any): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    // this.reportHistoryList = this.reportHistoryList.slice(this.startItem, this.endItem);
  }

  //*******sorting Routes-List table *******/
  sortSensorlist(propType, prop, asc) {

    switch (propType) {
      case "int":
        this.reportHistoryList = this.reportHistoryList.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case "date":
        this.reportHistoryList = this.reportHistoryList.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        })
        break;
      default:
        this.reportHistoryList = this.reportHistoryList.sort(function (a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
            return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
          }
        });
        this.reportHistoryList;
    }
  }

}
