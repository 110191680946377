import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { dashboardReportList } from '../dashbord.model'

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.scss']
})
export class DashboardDetailsComponent implements OnInit {
  dashboardReportList = dashboardReportList;
  reportName: any;
  currentUrl: any;
  token: string;
  public reportId: string;

  constructor(private route: ActivatedRoute,
    private sanitizerSevice: DomSanitizer) {
    this.route.queryParams.subscribe(param => {
      //console.log(dashboardReportList[+param["index"]]);
      this.token = param.token;
      this.reportName = param.name;
      this.currentUrl = dashboardReportList[+param["index"]].url;
      this.reportId = dashboardReportList[+param["index"]].reportId;
    });
  }

  ngOnInit() {
  }

}
