import { Component, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from "@angular/router";
import { SensorMaker, Sensor } from './sensors.model';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/modules/variable-pie';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { ExportExcelService } from '../../services/export-to-excel/export-exel.service';
import { Observable, of, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { TemperatureConvertPipe } from 'src/app/shared/pipes/temperature-convert/temperature-convert.pipe';
import { SensorListExportMaker } from './sensor-list-export';


HC_more(Highcharts);

@Component({
  selector: 'app-all-sensors',
  templateUrl: './all-sensors.component.html',
  styleUrls: ['./all-sensors.component.scss']
})
export class AllSensorsComponent implements OnInit {
  isShowFilter: boolean = false;
  batteryDown: boolean;
  unresponsiveSensor: boolean;
  sensorsToCompare: Array<any>;
  sensorsToCompareApi: any = [];
  sensorsToCompareApiData: any = [];
  onclickAddbtn: boolean = false;
  activeCard = 'card1';
  sortOrder: any;

  search: any = "";
  sensorType: any;
  currentReadingLable: string;
  currentReading: any;
  batteryLable: string;
  battery: any;
  signal: any;
  signalLable: any;
  status: any;
  keyValue: string = "all";
  activePageNo = 0;
  selectedChartDate: Date = new Date();
  startItem = 0;
  endItem = 6;
  graphsdata: any;
  form = new FormGroup({
    currentDate: new FormControl(new Date()),
  });
  bsValue = new Date();
  graphConfig = {
    light: {
      xAxis: {
        name: [],
        title: 'Time'
      },
      yAxis: {
        min: 0,
        max: 600,
        tickInterval: 100,
        title: {
          useHTML: true,
          text: 'lux',
          align: 'middle'
        }
      },
      chart: {
        type: 'column',
        title: 'Light Sensor',
        series: []
      }
    }
  };

  public options: any = {
    chart: {
      type: 'spline',
      height: 355
    },

    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter() {
        // return 'Temp. <img src="assets/img/ice-crystal.svg"> ' + this.y + '<sup>0</sup>F';
        // return this.y + "/" + this.x;
        return '<div>' + this.y + '</div><div>' +  this.x + '</div>'
      }
    },
    xAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      categories: [
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00'
      ],
      plotLines: [{
        color: '#1AB0DB', // Color value
        dashStyle: 'longdashdot', // Style of the plot line. Default to solid
        value: 8, // Value of where the line will appear
        width: 1 // Width of the line
      }]
    },
    yAxis: {
      gridLineColor: '#7070702b',
      gridLineWidth: 1,
      lineWidth: 1,
      min: -40,
      max: 40,
      tickInterval: 10,
      title: {
        useHTML: true,
        text: '<sup>0</sup>F (avg)',
        align: 'middle'
      },
      labels: {
        overflow: 'justify'
      }
    },
    legend: {
      // layout: 'horizontal', // default
      itemDistance: 50,
      itemStyle: {
        color: '#A5A5A5',
        fontWeight: '400',
        fontSize: '10px',
        align: 'left'
      }
    },
    series: []
  };
  isAllChecked = false;
  sensorFilter = [{
    id: 1,
    count: '72',
    filterType: "All sensor",
    isSelected: true,
  },
  {
    id: 2,
    count: '12',
    filterType: "Door light sensor",
    isSelected: false,
  },
  {
    id: 3,
    count: '08',
    filterType: "Supply air Temp.",
    isSelected: false,
  },
  {
    id: 4,
    count: '16',
    filterType: "Ambient Air Temp.",
    isSelected: false,
  },
  {
    id: 5,
    count: '11',
    filterType: "Cooler Dry Temp.",
    isSelected: false,
  },
  {
    id: 6,
    count: '07',
    filterType: "Cooler Mid Temp.",
    isSelected: false,
  },
  {
    id: 7,
    count: '18',
    filterType: "Cooler Low Temp.",
    isSelected: false,
  },
  ]
  sensorId: number;
  tempUnitConvertPipe: TemperatureConvertPipe;

  // sensorsList: any[] = [];
  sensorsList: any;
  sensorsCountList: any[] = [];
  sensorHistory: any[] = [];
  modalRef: BsModalRef;
  ischecked: boolean = false;
  sensorDetails: any[] = [];
  sensorAlertHistory: any[] = [];

  historyValue = new Date();
  historyRangeValue: Date[];

  alertHistoryValue = new Date();
  alertRangeValue: Date[];
  nextDate = new Date(new Date().getTime() + (8.64e+7 * 7));
  totalSensorsCount = 0;
  recordsPerPage = 10;
  sortingPropName: string = "date"
  sortingOrder: string = "DESC"
  currentPageIndex = 0;
  refreshSubscription: Subscription;
  selectedGraph: any[] = [];

  constructor(
    private modalService: BsModalService,
    private commonService: CommonService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private exportExcelService: ExportExcelService,
    private cdRef:ChangeDetectorRef
  ) {

    this.tempUnitConvertPipe = new TemperatureConvertPipe();
    this.actRoute.queryParamMap.subscribe(params => {
      if (params["params"] && params["params"].key) {
        this.keyValue = params["params"].key;
      }
      if (params["params"].batteryDown) {
        this.batteryDown = params["params"].batteryDown;

        let body = {
          batteryDown: this.batteryDown
        }

        this.getSensorsList();

      }
      if (params["params"].unresponsiveSensor) {
        this.unresponsiveSensor = params["params"].unresponsiveSensor;
        let body = {
          unresponsiveSensor: this.unresponsiveSensor
        }
        this.getSensorsList();

      }
    });
    this.sensorsList = [];
    this.sensorsToCompare = [];
    //this.getSensorsList();

    this.refreshSubscription = this.commonService.refreshSub.subscribe(res => {
      this.getSensorsList();
    });
  }

  ngOnInit() {

    this.sensorHistory = this.sensorsList;
    this.sensorAlertHistory = this.sensorsList;
    this.historyRangeValue = [new Date(), this.nextDate];
    this.alertRangeValue = [new Date(), this.nextDate];
    this.getAllSensorCount(this.keyValue);

  }


  gotoDetailsPage() {
    this.router.navigate(['truck-view']);
  }

  getSensorsList(pageNumber = this.currentPageIndex, pageSize = this.recordsPerPage, action = 'onInit') {
    this.activePageNo = pageNumber;

    let body = {
      pageIndex: pageNumber,
      pageSize: pageSize,
      search: this.search.trim() === '' ? null : this.search.trim(),
      sensorType: this.sensorType,
      // currentReadingType: this.currentReadingLable ? this.currentReadingLable.toLowerCase() : this.currentReadingLable,
      currentReadingType: this.currentReadingLable == 'null' ? '' : this.currentReadingLable,
      currentReading: this.currentReading,
      // batteryType: this.batteryLable ? this.batteryLable.toLowerCase() : this.batteryLable,
      batteryType: this.batteryLable == 'null' ? '' : this.batteryLable,
      battery: this.battery,
      // signalType: this.signalLable ? this.signalLable.toLowerCase() : this.signalLable,
      signalType: this.signalLable == 'null' ? '' : this.signalLable,
      signal: this.signal,
      status: this.status == 'null' ? '' : this.status,
      keyValue: this.keyValue ? this.keyValue : 'all',
      batteryDown: this.batteryDown,
      unresponsiveSensor: this.unresponsiveSensor,
      propertyName: this.sortingPropName,
      propertyValue: this.sortingOrder
    }

    this.commonService.getData('get', '/v0/api/v1/sensors', body).subscribe(res => {
      this.totalSensorsCount = res.data.count;
      if (action == 'export') {
        let exportableData = res && res.data && res.data.rows ? res.data.rows.map(e => SensorListExportMaker.exportToExcel(e)):[];
        this.exportExcelService.exportAsExcelFile(exportableData, 'Sensors List');
      } else
        this.sensorsList = res && res.data && res.data.rows ? res.data.rows.map(e => SensorMaker.castInto(e)) : [];
        this.checkedSensorIdValue();
    });
  }

  getAllSensorCount(selectedKey = 'all') {
    let dateToday = formatDate(new Date(), 'yyyy-MM-dd', 'en-Us');
    let body = {
      currentDate: dateToday,
    }
    this.commonService.getData('get', '/v0/api/v1/dashboard/sensor/count', body).subscribe(res => {
      this.sensorsCountList = res.data;
      this.sensorsCountList.forEach(e => {
        if (e.key == selectedKey) {
          e.isSelected = true;
        }
      });
      this.getSensorsList(0, this.recordsPerPage);
    });
  }


  getSensorDetails(id) {
    this.commonService.getData('get', '/v0/api/v1/sensors/' + id + '/alert/counts').subscribe(res => {
      this.sensorsList = res.data.map(e => SensorMaker.castInto(e));
    });
  }


  closeModal() {
    this.modalRef.hide();
    this.onclickAddbtn = false;
  }

  checkAll(status) {
    // this.ischecked = status;
    this.sensorsList.forEach(e => {
      e.isChecked = !this.isAllChecked;
      this.getSelectedSensorId(e);
    });
    this.countCheckedRows();
  }

  showGraphModal(template: TemplateRef<any>) {
    this.sensorsToCompare = [];
    const selectedSensors = this.sensorsList.filter(e => e.isChecked);
    //this.sensorGraph(selectedSensors);

    const selectedSensorsTypes = [];
    selectedSensors.forEach(e => {
      if (!selectedSensorsTypes.includes(e.sensorType)) {
        selectedSensorsTypes.push(e.sensorType);
      }
    });

    selectedSensorsTypes.forEach(e => {
      const readings = selectedSensors
        .filter(el => el.sensorType == e)
        .map(e => {
          return {
            data: e.reading,
            name: e.sensorName,
            color: e.lineColor,
            fontSize: '11px'
          };
        });
    });

    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      backdrop: 'static'
    });
    this.cdRef.detectChanges();
  }

  sensorGraph(value) {
    const tempUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType') : 'c';
    // Commented code(SensorId push in array and then stringify and pass to body for display graph per sensorId)
    // let sensorIds = [];
    // value.forEach(element => {
    //   sensorIds.push(JSON.stringify(element.sensorId));
    // });

    let body = {
      // Commneted code of sensorIds display graph data
      // "sensors": sensorIds,
      "sensors": this.selectedGraph,
      "date": formatDate(this.selectedChartDate, 'yyyy-MM-dd', 'en-US'),//this.converDateFormat(this.selectedChartDate)
    }

    this.commonService.getData('post', '/v0/api/v1/sensors/comparison-graph', body).subscribe(res => {
      this.sensorsToCompareApiData = res.data;
      this.graphsdata = this.sensorsToCompareApiData;
      this.sensorsToCompare = [];
      this.sensorsToCompareApiData.graphs.forEach((e, i) => {

        this.graphConfig.light.xAxis = this.sensorsToCompareApiData.xAxisLabels;

        this.sensorsToCompare.push({
          type: e.graphName
        });
      });

      setTimeout(() => {
        this.sensorsToCompareApiData.graphs.forEach((e, i) => {
          const option = this.options;
          const unitToShow = e.graphName == 'Temperature sensor' ? tempUnit.toUpperCase() : e.units;
          option.series = e.data.map(el => {
            return {
              name: el.sensorName,
              data: el.values.map(tempInCel => {

                if (e.graphName == 'Temperature sensor' && tempUnit == 'f') {
                  const tempInFar = this.tempUnitConvertPipe.transform(tempInCel);
                  return Number(tempInFar.split('&deg;')[0]);
                } else {
                  return Number(tempInCel);
                }
              }),
              marker: {
                enabled: false
              }
            };
          });
          option.legend = {
            useHTML: true,
            symbolWidth: 0,
            labelFormatter: function () {
              return '<div>' +
                '<div style="border-radius: 50%; width: 10px; height: 10px; border:2px solid ' + this.color + '; display: inline-block; margin-right:5px"> </div>' +
                "<span style='color:#666666;opacity:0.7; font-size: 10px;font-family: sans-serif;'> " + this.name + " </span>" +
                '</div>'
            }

          },
            option.yAxis = {
              // gridLineColor: '#7070702b',
              gridLineColor: 'rgba(112, 112, 112, 0.16862745098039217)',
              gridLineWidth: 1,
              lineWidth: 1,
              //categories:-20,
              title: {
                useHTML: false,
                text: "<div style='color: #666666; opacity: 0.7; font-size: 10px; font-family: sans-serif; text-transform: capitalize'>"
                  + unitToShow + "</div>",
                align: 'middle'
              },
              min: -20,
              //max: 20,
              tickInterval: 5,

            }
          option.xAxis = {
            categories: this.sensorsToCompareApiData.xAxisLabels,
            title: {
              useHTML: true,
              text: "<div style='color: #666666; opacity: 0.7; font-size: 10px; font-family: sans-serif;'>Time</div>",
              align: 'middle'
            },
          }
          Highcharts.chart('chart' + i, option);
        });
      }, 500);
    })

    // this.modalService.onShown.subscribe((reason: string) => {
    // })
  }




  converDateFormat(dateFormat) {
    let from = new Date(dateFormat);
    let fromYear = from.getFullYear();
    let fromMonth = from.getMonth() + 1;
    let fromDate = from.getDate();

    let month = "" + fromMonth;
    let date = "" + fromDate;

    if (fromMonth < 10) {
      month = "0" + fromMonth;
    }
    if (fromDate < 10) {
      date = "0" + fromDate;
    }
    let finalDate = fromYear + "-" + month + "-" + date;

    return finalDate;
  }

  selectRow(obj: Sensor, event) {
    obj.isChecked = event.target.checked;
    this.countCheckedRows();
    this.getSelectedSensorId(obj);
  }


  countCheckedRows(): any {
    if (this.sensorsList.length == 0) {
      return;
    }
    let checkedflag = true;
    let counter = 0;

    this.sensorsList.forEach((e) => {
      if (!e.isChecked) {
        checkedflag = false;
      } else {
        counter++;
      }
    });
    this.isAllChecked = checkedflag;
    return counter;
  }

  nextDay() {
    const currentDate = Date.parse(this.form.get('currentDate').value);
    this.form.patchValue({
      currentDate: new Date(currentDate + 8.64e+7)
    });
  }

  prevDay() {
    const currentDate = Date.parse(this.form.get('currentDate').value);
    this.form.patchValue({
      currentDate: new Date(currentDate - 8.64e+7)
    });
  }
  onHistoryDateChange(eve) {
    if (eve != undefined && eve != null && eve.length > 1) {
      let startDate = eve[0].getTime();
      let endDate = eve[1].getTime();
      // this.historyRangeValue=[new Date(startDate),new Date(endDate)]
      this.sensorHistory = this.sensorsList.filter(x => new Date(x.date).getTime() >= startDate && new Date(x.date).getTime() <= endDate)
    }
  }
  onAlertHistyDateChange(event) {
    if (event != undefined && event != null && event.length > 1) {
      let alertStartDate = event[0].getTime();
      let alertEndDate = event[1].getTime();
      // this.alertRangeValue=[new Date(startDate),new Date(endDate)];
      this.sensorAlertHistory = this.sensorsList.filter(x => new Date(x.date).getTime() >= alertStartDate && new Date(x.date).getTime() <= alertEndDate)
    }
  }


  //*******sorting sensor-List table *******/
  sortSensorlist(propType, prop, asc) {


    switch (propType) {
      case "int":
        this.sensorsList = this.sensorsList.sort(function (a, b) {
          if (asc) {
            return (parseInt(a[prop]) > parseInt(b[prop])) ? 1 : ((parseInt(a[prop]) < parseInt(b[prop])) ? -1 : 0);
          } else {
            return (parseInt(b[prop]) > parseInt(a[prop])) ? 1 : ((parseInt(b[prop]) < parseInt(a[prop])) ? -1 : 0);
          }
        });
        break;

      case "date":
        this.sensorsList = this.sensorsList.sort(function (a, b) {
          if (asc) {
            return (new Date(a[prop]).getTime() > new Date(b[prop]).getTime()) ? 1 : ((new Date(a[prop]).getTime() < new Date(b[prop]).getTime()) ? -1 : 0);
          } else {
            return (new Date(b[prop]).getTime() > new Date(a[prop]).getTime()) ? 1 : ((new Date(b[prop]).getTime() < new Date(a[prop]).getTime()) ? -1 : 0);
          }
        })
        break;
      default:
        this.sensorsList = this.sensorsList.sort(function (a, b) {
          if (asc) {
            return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
          } else {
            return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
          }
        });
    }
  }

  goToSensorDetails(id) {
    this.router.navigate(['/sensor-details/' + id]);
  }

  showAddUserModal(addSensorModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addSensorModal, {
      class: 'modal-dialog-centered add-new-sensor',
      backdrop: 'static'
    });
    this.onclickAddbtn = true;
  }

  toggleFilter() {
    this.isShowFilter = !this.isShowFilter;

    this.setDefaultFilterValues();
  }

  setDefaultFilterValues() {
    this.currentReadingLable = 'null';
    this.currentReading = '';
    this.batteryLable = 'null';
    this.battery = '';
    this.signalLable = 'null';
    this.signal = '';
    this.status = 'null';

  }

  resetFilter() {
    this.setDefaultFilterValues();
    this.getSensorsList(0);
  }

  OnSensorfilterClick(filter) {
    if (filter.key == "all") {
      this.keyValue = "";
      this.sensorsCountList.forEach((e) => {
        if (e.key != "all") {
          e.isSelected = false;
        } else {
          e.isSelected = true;
        }
      })
    } else if (filter.isSelected) {
      this.sensorsCountList.forEach((e) => {
        if (e.key == "all") {
          e.isSelected = false;
        }
      })
      this.keyValue = this.keyValue ? this.keyValue + ',' + filter.key : filter.key;
      if (this.keyValue.startsWith("all")) {
        this.keyValue = this.keyValue.substr(4);
      }
    } else {
      // this.keyValue = this.keyValue.replace(filter.key + ',', "");
      let keyArr = this.keyValue.split(',');
      keyArr.splice(keyArr.indexOf(filter.key), 1);
      this.keyValue = keyArr.toString();
      if (this.keyValue.length == 0) {
        this.sensorsCountList.forEach((e) => {
          if (e.key == "all") {
            e.isSelected = true;
          }
        })
      }
    }
    this.getSensorsList()
  }

  loadPageData(pageNo) {
    this.currentPageIndex = pageNo;
    this.getSensorsList(pageNo);
    this.isAllChecked = false;
  }
  onDatechange(event) {
    if (event != undefined && event != null && event != "") {
      this.selectedChartDate = event;
      let selectedSensors = this.sensorsList.filter(e => e.isChecked);
      this.sensorGraph(selectedSensors);
    }

  }
  onExportToExcel() {
    if (!this.totalSensorsCount) {
      this.commonService.showToast('No data to export', 0);
      return false;
    }
    this.getSensorsList(0, this.totalSensorsCount, 'export')
  }
  onGraphExport() {
    var graphs = this.graphsdata.graphs;
    var xAxis = this.graphsdata.xAxisLabels;
    var tempArray: any[] = [];

    graphs.forEach(graph => {
      //Creating  a blank row
      let blankRow1 = {
        graphName: '',
        values: '',
        time: ''
      }
      //Creating  a Graph Name
      let firstGraphHeader = {
        graphName: graph.graphName,//graph.graphName
        values: '',
        time: ''
      }
      //Creating  a blank row 
      let blankRow2 = {
        graphName: '',
        values: '',
        time: ''
      }

      //Pushing Graph Name and blank row
      tempArray.push(blankRow2);
      tempArray.push(firstGraphHeader);
      tempArray.push(blankRow1);

      graph.data.forEach(data => {   
        data.values.forEach((value,i) =>  {
          let tempObj = {
            graphName: data.sensorName,
            values: this.convertTemp(value, graph.units),
            unit: this.getUnit(graph.units),
            time: xAxis[i]
          }
          tempArray.push(tempObj);
        });
      });
    });
    this.exportExcelService.exportAsExcelFile(tempArray, 'Sensors-graphs List');
  }
  public convertTemp(temp, unit): any {
    let modifiedTemp;
    let selectedUnit = unit;
    if (selectedUnit && (selectedUnit.toLowerCase() == 'f' || selectedUnit.toLowerCase() == 'c')) {
      selectedUnit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType').toLowerCase() : 'c';
      if (selectedUnit == 'f') {
        modifiedTemp = (temp * 9 / 5) + 32;
        modifiedTemp = Math.round(parseFloat(modifiedTemp) * 10) / 10;
      } else {
        modifiedTemp = temp;
      }
    } else {
      modifiedTemp = temp;
    }
    return modifiedTemp;
  }
  public getUnit(unit) {
    if (unit.toLowerCase() == 'c' || unit.toLowerCase() == 'f') {
      let ls_unit = localStorage.getItem('hhsPreferredTemperatureType') ? localStorage.getItem('hhsPreferredTemperatureType') : 'c';
      return ls_unit.toUpperCase();
    } else {
      return unit;
    }
  }


  onSortSensorList(propertyName, sortOrder) {
    this.sortingPropName = propertyName;
    this.sortingOrder = sortOrder;
    this.getSensorsList();
    this.isAllChecked = false;
  }
  //clear Search
  onClearSearch() {
    if (!this.search) {
      return;
    }
    this.search = "";
    this.getSensorsList(0);
  }
  // destrop refresh subscription
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
  // search validate input
  validateInput(event) {
    this.commonService.validateInput(event);
  }

  getSelectedSensorId(e) {
    if(e.isChecked) {
      this.selectedGraph.push(e.sensorId.toString());
    } else {
      let index = this.selectedGraph.indexOf(e.sensorId.toString());
      if(index != -1) {
        this.selectedGraph.splice(index, 1)
      }
    }
  }

  checkedSensorIdValue() {
    this.sensorsList.forEach(element => {
      if(this.selectedGraph.includes(element.sensorId.toString())) {
        element.isChecked = true;
      }
    });
  }

  clearSelectedGraph() {
    this.selectedGraph = [];
    this.sensorsList.forEach(element => {
      element.isChecked = false;
    });
  }

}
