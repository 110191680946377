

export class Trucks {
  customerFacilityId: any;
  truckId: any;
  truckType: any;
  truckCapacity: any;
  tripId: any;
  status: any;
  isActive: any;
  isChecked: boolean;
  dailyTripId: number;
  truckMasterId: number;
  truckSize: string;

  constructor(
    customerFacilityId: any,
    truckId: any,
    truckType: any,
    truckCapacity: any,
    tripId: any,
    status: any,
    isActive: any,
    isChecked: boolean,
    dailyTripId:number,
    truckMasterId:number,
    truckSize:string,
  ) {
    this.customerFacilityId = customerFacilityId;
    this.truckId = truckId;
    this.truckType = truckType;
    this.truckCapacity = truckCapacity;
    this.tripId = tripId;
    this.status = status;
    this.isActive = isActive;
    this.isChecked = false;
    this.dailyTripId = dailyTripId;
    this.truckMasterId = truckMasterId;
    this.truckSize = truckSize;
  }
}

export class TruckMaker {
  static create() {
    return new Trucks(null, null, null, null, null, null, null, null,0,0,null);
  }

  static castInto(obj: any) {
    return new Trucks(
      obj.customerFacilityId,
      obj.truckId,
      obj.truckType,
      obj.truckCapacity,
      obj.tripId,
      obj.status,
      obj.isActive,
      obj.isChecked,
      obj.dailyTripId,
      obj.truckMasterId,
      obj.truckSize,
    )
  }
  static clone(obj: Trucks) {
    return new Trucks(
      obj.customerFacilityId,
      obj.truckId,
      obj.truckType,
      obj.truckCapacity,
      obj.tripId,
      obj.status,
      obj.isActive,
      obj.isChecked,
      obj.dailyTripId,
      obj.truckMasterId,
      obj.truckSize,
    );
  }
}
